import { createSlice } from '@reduxjs/toolkit';
import { vendorApi } from 'src/apis/vendor-api';
import { VendorKey } from '../models/vendor-key';

const initialState: {
  keys: VendorKey[],
} = {
  keys: [],
};

const slice = createSlice({
  name: 'security-keys',
  initialState,
  reducers: {
    setKeys(state, action) {
      state.keys = action.payload?.keys || [];
    },
  },
});

export const { reducer } = slice;

export const getVendorKeys = () => async (dispatch: Function) => {
  const response = await vendorApi.getListKeys();
  dispatch(slice.actions.setKeys(response.data));
};
