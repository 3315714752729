export const INDEXEDDB_DB_NAME = 'Galaxy';

export enum INDEXEDDB_STORE_NAMES {
  Items = 'items',
  VehiclePackages = 'vehiclePackages',
  Templates = 'templates',
  Specifications = 'specifications',
}

type DataFields = {
  [K in INDEXEDDB_STORE_NAMES]: any;
};

interface StoredData extends DataFields {
  id: number;
}

export class IndexedDBAPI {
  private dbName: string;
  private static instance: IndexedDBAPI | null = null;

  private constructor(dbName: string) {
    this.dbName = dbName;
  }

  private deleteDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const deleteRequest = indexedDB.deleteDatabase(this.dbName);

      deleteRequest.onsuccess = () => {
        console.log(`Database ${this.dbName} deleted successfully.`);
        resolve();
      };

      deleteRequest.onerror = (event) => {
        reject(
          'Error deleting database: ' +
            (event.target as IDBOpenDBRequest).error?.message,
        );
      };
    });
  }

  private handleTransaction(
    storeName: INDEXEDDB_STORE_NAMES,
    mode: IDBTransactionMode,
  ): Promise<IDBObjectStore> {
    return this.openDB().then((db) => {
      return new Promise((resolve, reject) => {
        try {
          const transaction = db.transaction([storeName], mode);
          const store = transaction.objectStore(storeName);
          resolve(store);
        } catch (error) {
          if (error instanceof DOMException && error.name === 'NotFoundError') {
            reject(`Object store '${storeName}' not found.`);
          } else {
            reject('Transaction error: ' + error);
          }
        }
      });
    });
  }

  private openDB(version?: number): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, version);

      request.onerror = (event) => {
        reject(
          'Database error: ' +
            (event.target as IDBOpenDBRequest).error?.message,
        );
      };

      request.onsuccess = (event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        // Create all pre-defined stores
        Object.values(INDEXEDDB_STORE_NAMES).forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { keyPath: 'id' });
          }
        });
      };
    });
  }

  public getAllData(storeName: INDEXEDDB_STORE_NAMES): Promise<StoredData[]> {
    return this.handleTransaction(storeName, 'readonly').then((store) => {
      return new Promise((resolve, reject) => {
        try {
          const request = store.getAll();

          request.onsuccess = () => resolve(request.result as StoredData[]);
          request.onerror = (event) =>
            reject(
              'Error retrieving all data: ' +
                (event.target as IDBRequest).error?.message,
            );
        } catch (error) {
          if (error instanceof DOMException && error.name === 'NotFoundError') {
            reject(`Object store '${storeName}' not found.`);
          } else {
            reject('Transaction error: ' + error);
          }
        }
      });
    });
  }

  public addData(
    storeName: INDEXEDDB_STORE_NAMES,
    data: StoredData,
  ): Promise<string> {
    return this.handleTransaction(storeName, 'readwrite').then((store) => {
      return new Promise((resolve, reject) => {
        const request = store.add(data);

        request.onsuccess = () => resolve('Data added successfully');
        request.onerror = (event) =>
          reject(
            'Error adding data: ' + (event.target as IDBRequest).error?.message,
          );
      });
    });
  }

  public getData(
    storeName: INDEXEDDB_STORE_NAMES,
    key: number,
  ): Promise<StoredData | undefined> {
    return this.handleTransaction(storeName, 'readonly').then((store) => {
      return new Promise((resolve, reject) => {
        const request = store.get(key);

        request.onsuccess = () => resolve(request.result as StoredData);
        request.onerror = (event) =>
          reject(
            'Error fetching data: ' +
              (event.target as IDBRequest).error?.message,
          );
      });
    });
  }

  public updateData(
    storeName: INDEXEDDB_STORE_NAMES,
    data: StoredData,
  ): Promise<string> {
    return this.handleTransaction(storeName, 'readwrite').then((store) => {
      return new Promise((resolve, reject) => {
        const request = store.put(data);

        request.onsuccess = () => resolve('Data updated successfully');
        request.onerror = (event) =>
          reject(
            'Error updating data: ' +
              (event.target as IDBRequest).error?.message,
          );
      });
    });
  }

  public deleteData(
    storeName: INDEXEDDB_STORE_NAMES,
    key: number,
  ): Promise<string> {
    return this.handleTransaction(storeName, 'readwrite').then((store) => {
      return new Promise((resolve, reject) => {
        const request = store.delete(key);

        request.onsuccess = () => resolve('Data deleted successfully');
        request.onerror = (event) =>
          reject(
            'Error deleting data: ' +
              (event.target as IDBRequest).error?.message,
          );
      });
    });
  }

  public static getInstance(dbName: string): IndexedDBAPI {
    if (!IndexedDBAPI.instance) {
      IndexedDBAPI.instance = new IndexedDBAPI(dbName);
    }
    return IndexedDBAPI.instance;
  }

  public checkAndDeleteExistingDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        Object.values(INDEXEDDB_STORE_NAMES).forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.close();
            this.deleteDatabase().then(resolve).catch(reject);
          }
        });
      };

      request.onerror = (event) => {
        reject(
          'Error opening database: ' +
            (event.target as IDBOpenDBRequest).error?.message,
        );
      };
    });
  }
}
