import axios from 'axios';
import { showErrorAlert } from 'src/slices/common-settings-slice';
import { store } from 'src/store';
import { getEnv } from 'src/utils/utils';

const Client = axios.create({
  baseURL: getEnv().apiHost,
  timeout: 300000,
});

const requestHandler = (request) => {
  const state = store.getState();
  const { account } = state?.commonSettings;

  // Use particular ff-tools-project-id header to replace default
  const env = getEnv();
  const projectId = env.projectId;
  request.headers['ff-tools-project-id'] =
    request.headers['ff-tools-project-id'] || account?.id || projectId;

  const accessToken = window.localStorage.getItem('accessToken');

  if (accessToken) {
    setToken(accessToken);
    request.headers['Authorization'] = accessToken;
  }

  return request;
};

const responseHandler = (response) => {
  if (response?.status === 401) {
    window.location = '/authentication/login';
    clearToken();
  }

  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    window.location = '/authentication/login';
    clearToken();
  } else if (error.response && error.response.data) {
    store.dispatch(showErrorAlert(error.response.data.message));
    console.error(JSON.stringify(error.response));
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

export const setToken = (token) => {
  Client.defaults.headers = {
    Authorization: token,
  };
};

export const clearToken = () => {
  localStorage.removeItem('activeUser');
  localStorage.removeItem('accessToken');
  Client.defaults.headers = {};
};

// const oldToken = localStorage.getItem('token');
// setToken(oldToken);

Client.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
);

Client.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

export default Client;
