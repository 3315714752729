import { createSlice } from '@reduxjs/toolkit';
import { objectApi } from 'src/apis/object-api';

const initialState: {
  designSpec: any;
  designSpecEcuNodeId: string;
  // TODO: move to a separate store
  showECURepairXSLX: boolean;
  selectedStation: string;
  selectedSubFunc: any;
} = {
  designSpec: null,
  designSpecEcuNodeId: null,
  showECURepairXSLX: false,
  selectedStation: null,
  selectedSubFunc: null,
};

const slice = createSlice({
  name: 'shared-data',
  initialState,
  reducers: {
    setSelectedSubFunc(state, action) {
      state.selectedSubFunc = {service: action.payload.service, subFunc: action.payload.subFunc};
    },
    setSelectedStation(state, action) {
      state.selectedStation = action.payload;
    },
    setShowECURepairXSLX(state, action) {
      state.showECURepairXSLX = action.payload;
    },
    setDesignSpec(state, action) {
      state.designSpec = action.payload;
    },
    setDesignSpecEcuNodeId(state, action) {
      state.designSpecEcuNodeId = action.payload;
    },
  },
});

export const { reducer } = slice;

export const selectSubFunc =
  (service: object, subFunc: object) => async (dispatch: Function) => {
    dispatch(slice.actions.setSelectedSubFunc({service, subFunc}));
  };

export const selectStation =
  (station: string) => async (dispatch: Function) => {
    dispatch(slice.actions.setSelectedStation(station));
  };

export const toggleECURepairXSLS =
  (isShow: boolean) => async (dispatch: Function) => {
    dispatch(slice.actions.setShowECURepairXSLX(isShow));
  };

export const selectEcuNodeId =
  (ecuNodeId: string) => async (dispatch: Function) => {
    dispatch(slice.actions.setDesignSpecEcuNodeId(ecuNodeId));
  };

export const clearDesignSpec = () => async (dispatch: Function) => {
  dispatch(slice.actions.setDesignSpec(null));
};

export const getDesignSpec =
  (eeArchId: string, ecuNodeId: string) => async (dispatch: Function) => {
    const data = await objectApi.getDesignSpec(eeArchId, ecuNodeId);

    if (!data) {
      dispatch(slice.actions.setDesignSpec(null));
      return;
    }

    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    dispatch(slice.actions.setDesignSpec(parsedData));
  };
