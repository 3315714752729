import jsEventBus from 'js-event-bus';
import {
  SCREEN_POST_ENTER,
  SCREEN_POST_EXIT,
  SCREEN_PRE_ENTER,
  SCREEN_PRE_EXIT,
} from 'src/utils/constants';

// TODO: should be placed close to
// flash station comm? In utils?
export const eventBus = jsEventBus();

const FLASH_PREFIX = '/tools/flash/';
const NEED_RUN_CORE_PAGES = [
  '/tools/vehicle/flash/ecu-selection',
  '/tools/vehicle/flash/ecu-flash',
  '/tools/vehicle/diagnostic/ecu-test-station',
  '/tools/vehicle/diagnostic/ecu-version-check',
  '/tools/vehicle/settings/nfc',
];

eventBus.on(SCREEN_PRE_EXIT, (data) => {
  console.log('Screen_PreExit for: ', data);
});

eventBus.on(SCREEN_PRE_ENTER, (data) => {
  console.log('Screen_PreEnter for: ', data);
  if (data?.startsWith(FLASH_PREFIX) || NEED_RUN_CORE_PAGES.includes(data)) {
    window?.nativeUtils?.onFlashPreload(data);
  }
});

eventBus.on(SCREEN_POST_ENTER, (data) => {
  console.log('Screen_PostEnter for: ', data);
});

eventBus.on(SCREEN_POST_EXIT, (data) => {
  console.log('Screen_PostExit for: ', data);
});

export const s3Config = {
  baseHost: '.s3.amazonaws.com',
  defaultImageBucker: 'et-object-table.dev',
};

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};

// TODO: use S3 link directly to avoid cloudfront cache timing issue shortly
export const RELEASE_NOTE_SITE_HOSTNAME = 'https://ecutracker-rn.ff-tools.link';
// export const RELEASE_NOTE_SITE_HOSTNAME =
//   'http://ecutracker-rn.ff-tools.link.s3-website-us-west-2.amazonaws.com';
