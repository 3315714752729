import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { objectApi } from 'src/apis/object-api';
import { packageApi } from 'src/apis/package-api';
import {
  showErrorAlert,
  showSuccessAlert,
} from 'src/slices/common-settings-slice';
import { Dataset } from 'src/tools/tracker/models/object-models';
import {
  Package,
  PackageVersion,
} from 'src/tools/tracker/models/package-models';
import { prepareDataset } from 'src/utils/convert-object';
import { preparePackageVersion } from 'src/utils/convert-package';
import { showTalkToServer } from 'src/utils/toast-message';
import { getActiveUser } from 'src/utils/utils';

interface PackagesForVersion {
  ecus: number;
  vehicles: number;
  domains: number;
  libraries: number;
  packages: any[];
  defaultOTAs: any[];
  customizedOTAs: any[];
}

interface Pagination {
  total: number;
  pageCount: number;
  objects: Package[];
  lastEvaluatedKeys: any[];
}

const initialState: {
  onLoading: boolean;
  packages: Package[];
  selectedReleasePackage: Package;
  selectedPackageImages: Package;
  newBuildPackage: Package;
  noPackageDetailFound: boolean;
  packageCountForVersion: PackagesForVersion;
  pagination: Pagination;
} = {
  onLoading: false,
  packages: [],
  selectedReleasePackage: undefined,
  newBuildPackage: undefined,
  selectedPackageImages: undefined,
  noPackageDetailFound: false,
  packageCountForVersion: {
    ecus: 0,
    vehicles: 0,
    domains: 0,
    libraries: 0,
    packages: [],
    defaultOTAs: [],
    customizedOTAs: [],
  },
  pagination: {
    total: 0,
    pageCount: 0,
    objects: [],
    lastEvaluatedKeys: [],
  },
};

const slice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    updatePackageStatus(state, action) {
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );
      state.packages = [
        ...state.packages.slice(0, pkgIndex),
        {
          ...state.packages[pkgIndex],
          status: action.payload.status,
        },
        ...state.packages.slice(pkgIndex + 1),
      ];
    },
    setOnLoading(state, action) {
      state.onLoading = action.payload;
    },
    setReleasePackages(state, action) {
      state.packages = action.payload.objects || [];
      state.pagination.total = action.payload.total || 0;
      state.pagination.pageCount = action.payload.pageCount || 0;

      const currentPage = action.payload.page || 0;

      if (currentPage === 0) {
        state.pagination.lastEvaluatedKeys = [];
      }

      if (!!action.payload.lastEvaluatedKey) {
        const lastEvaluatedKey = action.payload.lastEvaluatedKey;
        const existEvaluatedKeyIndex =
          state.pagination.lastEvaluatedKeys.findIndex(
            (k) => k === lastEvaluatedKey,
          );
        if (existEvaluatedKeyIndex !== -1) {
          state.pagination.lastEvaluatedKeys =
            state.pagination.lastEvaluatedKeys.slice(0, existEvaluatedKeyIndex);
        }
        state.pagination.lastEvaluatedKeys = [
          ...state.pagination.lastEvaluatedKeys,
          lastEvaluatedKey,
        ];
      }
    },
    addReleasePackage(state, action) {
      state.packages = [...state.packages, action.payload];
      state.newBuildPackage = action.payload;
    },
    updateReleasePackage(state, action) {
      // Process the package version in the package list
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );
      if (pkgIndex !== -1) {
        state.packages.splice(pkgIndex, 1, action.payload.pkg);
      }
      // Process the package version in the selected package
      if (state.selectedReleasePackage?.id === action.payload.packageId) {
        state.selectedReleasePackage = action.payload.pkg;
      }
    },
    setNewPackage(state, action) {
      state.newBuildPackage = action.payload;
    },
    getReleasePackageDetails(state, action) {
      state.selectedReleasePackage = action.payload;
      state.noPackageDetailFound =
        !action.payload && !state.selectedPackageImages;
    },
    getReleasePackageImages(state, action) {
      state.selectedPackageImages = action.payload;
      state.noPackageDetailFound =
        !action.payload && !state.selectedReleasePackage;
    },
    addPackageVersion(state, action) {
      const newVersion = action.payload.newVersion;

      const pkg = state.packages.find((p) => p.id === action.payload.packageId);
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );
      // Process the package version in the package list
      if (!!pkg && pkgIndex !== -1) {
        // for a new package, there may be no this attribute, so add a protection here
        if (!pkg.versions) {
          pkg.versions = [];
        }

        state.packages.splice(pkgIndex, 1, {
          ...pkg,
          versions: [...pkg.versions, newVersion],
          internalVersions: [...pkg.internalVersions, newVersion.name],
        });
      }

      // Process the package version in the selected package
      if (state.selectedReleasePackage?.id === action.payload.packageId) {
        if (!state.selectedReleasePackage.versions) {
          state.selectedReleasePackage.versions = [];
        }

        state.selectedReleasePackage.versions = [
          ...state.selectedReleasePackage.versions,
          newVersion,
        ];
        state.selectedReleasePackage.internalVersions = [
          ...state.selectedReleasePackage.internalVersions,
          newVersion.name,
        ];
      }
    },
    removePackageVersion(state, action) {
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );
      // Process the package version in the package list
      if (pkgIndex !== -1) {
        const versionToRemove = state.packages[pkgIndex].versions.find(
          (v) => v.id != action.payload.versionId,
        );
        state.packages.splice(pkgIndex, 1, {
          ...state.packages[pkgIndex],
          versions: [
            ...state.packages[pkgIndex].versions.filter(
              (v) => v.id !== action.payload.versionId,
            ),
          ],
          internalVersions: [
            ...state.packages[pkgIndex].internalVersions.filter(
              (iv) => iv !== versionToRemove.name,
            ),
          ],
        });
      }
      // Process the package version in the selected package
      if (state.selectedReleasePackage?.id === action.payload.packageId) {
        const versionToRemove = state.selectedReleasePackage.versions.find(
          (v) => v.id != action.payload.versionId,
        );
        state.selectedReleasePackage.versions = [
          ...state.selectedReleasePackage.versions.filter(
            (v) => v.id !== action.payload.versionId,
          ),
        ];
        state.selectedReleasePackage.internalVersions = [
          ...state.selectedReleasePackage.internalVersions.filter(
            (iv) => iv !== versionToRemove.name,
          ),
        ];
      }
    },
    updatePackageVersion(state, action) {
      const versionObject = action.payload.object;

      const pkg = state.packages.find((p) => p.id === action.payload.packageId);
      const pkgIndex = state.packages.findIndex(
        (p) => p.id === action.payload.packageId,
      );
      // Process the package version in the package list
      if (!!pkg && pkgIndex !== -1) {
        const versionIndex = pkg.versions.findIndex(
          (v) => v.id === action.payload.versionId,
        );
        let versionToUpdate = pkg.versions.find(
          (v) => v.id === action.payload.versionId,
        );

        if (versionObject) {
          delete versionObject.id;
          for (const key of Object.keys(versionObject)) {
            versionToUpdate[key] = versionObject[key];
          }
        }

        state.packages.splice(pkgIndex, 1, {
          ...state.packages[pkgIndex],
          versions: [
            ...pkg.versions.slice(0, versionIndex),
            versionToUpdate,
            ...pkg.versions.slice(versionIndex + 1),
          ],
        });
      }

      // Process the package version in the selected package
      if (state.selectedReleasePackage?.id === action.payload.packageId) {
        const versionIndex = state.selectedReleasePackage.versions.findIndex(
          (v) => v.id === action.payload.versionId,
        );
        let versionToUpdate = state.selectedReleasePackage.versions.find(
          (v) => v.id === action.payload.versionId,
        );

        if (versionObject) {
          delete versionObject.id;
          for (const key of Object.keys(versionObject)) {
            versionToUpdate[key] = versionObject[key];
          }
        }

        state.selectedReleasePackage.versions = [
          ...state.selectedReleasePackage.versions.slice(0, versionIndex),
          versionToUpdate,
          ...state.selectedReleasePackage.versions.slice(versionIndex + 1),
        ];
      }
    },
    setPackageCount(state, action) {
      state.packageCountForVersion = {
        ecus: action.payload.ecuPackageCount || 0,
        vehicles: action.payload.vehiclePackageCount || 0,
        domains: action.payload.domainPackageCount || 0,
        libraries: action.payload.libraryPackageCount || 0,
        packages: action.payload.packages || [],
        defaultOTAs: action.payload.defaultOTAs || [],
        customizedOTAs: action.payload.customizedOTAs || [],
      };
    },
    setPackageStatus(state, action) {
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );
      state.packages = [
        ...state.packages.slice(0, pkgIndex),
        {
          ...state.packages[pkgIndex],
          status: action.payload.packageStatus,
        },
        ...state.packages.slice(pkgIndex + 1),
      ];
    },
    addReleaseTag(state, action) {
      const newPackage = action.payload.package;

      const pkg = state.packages.find((p) => p.id === action.payload.packageId);
      const pkgIndex = state.packages.findIndex(
        (p) => p.id == action.payload.packageId,
      );

      if (!!pkg && pkgIndex !== -1) {
        // for a new package, there may be no this attribute, so add a protection here
        if (!pkg.releaseTag) {
          pkg.releaseTag = {};
        }

        state.packages.splice(pkgIndex, 1, {
          ...pkg,
          releaseTag: newPackage.releaseTag,
        });
      }

      // Process the selected package
      if (state.selectedReleasePackage?.id === action.payload.packageId) {
        if (!state.selectedReleasePackage.releaseTag) {
          state.selectedReleasePackage.releaseTag = {};
        }
        state.selectedReleasePackage.releaseTag = newPackage.releaseTag;
      }
    },
  },
});

export const { reducer } = slice;

export const checkAndUpdatePackageStatus =
  (packageId: string) => async (dispatch: Function) => {
    const response = await objectApi.getObject(packageId);
    if (response?.status != 200) {
      return;
    }
    dispatch(
      slice.actions.updatePackageStatus({
        packageId,
        status: response.data?.status,
      }),
    );
  };

export const getPackageCount =
  (
    version: string,
    startDate: string,
    endDate: string,
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      const result = await packageApi.getPackageCount(
        version,
        startDate,
        endDate,
      );
      if (result?.status != 200) {
        onError?.();
        return;
      }
      dispatch(slice.actions.setPackageCount(result.data));
      onDone?.();
    };

export const setReleasePackages =
  (searchResult) => async (dispatch: Function) => {
    try {
      dispatch(
        slice.actions.setReleasePackages({
          ...searchResult,
        }),
      );
    } catch (error) {
      dispatch(showErrorAlert('Error occurs during set release packages.'));
    }
  };

export const buildReleasePackage =
  (
    itemRevisionId: string,
    version: string,
    revisionRuleId: string,
    build: string,
    variantRuleIds: string[],
    validateJiraTickets,
  ) =>
    async (dispatch: Function) => {
      showTalkToServer();
      const activeUser = getActiveUser();
      try {
        const response = await packageApi.buildPackage(
          itemRevisionId,
          version,
          revisionRuleId,
          build,
          variantRuleIds,
          activeUser.email,
          validateJiraTickets,
        );
        if (response.data.buildPackageErrorMessage) {
          dispatch(showErrorAlert(response.data.buildPackageErrorMessage));
        }
        const pkg = response.data;
        dispatch(slice.actions.addReleasePackage(pkg));
      } catch (error) {
        console.error(
          'Error occurs during process buildReleasePackage: ',
          error.response?.data?.message || error.message,
        );
        dispatch(
          showErrorAlert(`${error.response?.data?.message || error.message}`),
        );
      }
    };

export const rebuildReleasePackage =
  (packageId: string) => async (dispatch: Function) => {
    showTalkToServer();
    const activeUser = getActiveUser();
    dispatch(slice.actions.setOnLoading(true));
    try {
      const response = await packageApi.rebuildPackage(
        packageId,
        activeUser.email,
      );
      if (response.data.buildPackageErrorMessage) {
        dispatch(showErrorAlert(response.data.buildPackageErrorMessage));
      }
      const pkg = response.data;
      dispatch(slice.actions.updateReleasePackage({ packageId, pkg }));
    } catch (error) {
      console.error(
        'Error occurs during process rebuildReleasePackage: ',
        error.response?.data?.message || error.message,
      );
      dispatch(
        showErrorAlert(`${error.response?.data?.message || error.message}`),
      );
    }
    dispatch(slice.actions.setOnLoading(false));
  };

export const buildReleasePackageGroup =
  (
    itemRevisionId: string,
    version: string,
    revisionRuleId: string,
    variantRuleIds: string[],
  ) =>
    async (dispatch: Function) => {
      showTalkToServer();
      const activeUser = getActiveUser();
      try {
        const response = await packageApi.buildGroupPackage(
          itemRevisionId,
          version,
          revisionRuleId,
          variantRuleIds,
          activeUser.email,
        );
        const pkg = response.data;
        dispatch(slice.actions.addReleasePackage(pkg));
      } catch (error) {
        console.error(
          'Error occurs during process buildReleasePackageGroup: ',
          error.response?.data?.message || error.message,
        );
        if (error.message === 'Network Error') {
          dispatch(
            showSuccessAlert(
              'Creating domain/vehicle release package usually takes a few of minutes depends on package size, it would be better to subscribe "RC Package Released" notification or refresh app after some time.',
            ),
          );
        } else {
          dispatch(
            showErrorAlert(`${error.response?.data?.message || error.message}`),
          );
        }
      }
    };

export const setNewPackage = (newPackage: Package) => (dispatch: Function) => {
  dispatch(slice.actions.setNewPackage(newPackage));
};

export const getReleasePackageDetails =
  (id: string, needLoading = true) =>
    async (dispatch: Function) => {
      if (needLoading) {
        dispatch(slice.actions.setOnLoading(true));
      }
      let result;
      try {
        const response = await packageApi.getPackageDetails(id);
        result = response.data;
      } catch (error) {
        console.error(
          'Error occurs during process getReleasePackageDetails: ',
          error.response.data.message,
        );
        dispatch(showErrorAlert(`${error.response.data.message}`));
      }
      dispatch(slice.actions.getReleasePackageDetails(result));
      dispatch(slice.actions.setOnLoading(false));
    };

export const getReleasePackageImages =
  (id: string) => async (dispatch: Function) => {
    let result;
    try {
      const response = await packageApi.getPackageImages(id);
      result = response.data;
    } catch (error) {
      console.error(
        'Error occurs during process getReleasePackageImages: ',
        error.response?.data.message,
      );
      dispatch(showErrorAlert(`${error.response.data.message}`));
    }
    dispatch(slice.actions.getReleasePackageImages(result));
  };

export const addPackageVersionThunk =
  (packageId: string, objectToBeAdded: PackageVersion) =>
    async (dispatch: Function) => {
      const objectToAdd = preparePackageVersion(objectToBeAdded);
      let request = {
        create: {
          versions: [objectToAdd],
        },
      };
      try {
        await packageApi.updatePackageVersions(packageId, request);
        dispatch(
          slice.actions.addPackageVersion({ packageId, newVersion: objectToAdd }),
        );
      } catch (error) {
        console.error(
          'Error occurs during process addPackageVersionThunk: ',
          error.response?.data?.message || error.message,
        );
        dispatch(
          showErrorAlert(`${error.response?.data?.message || error.message}`),
        );
      }
    };

export const removePackageVersionThunk =
  (packageId: string, versionId: string) => async (dispatch: Function) => {
    let request = {
      delete: {
        versions: [{ id: versionId }],
      },
    };
    try {
      let result = await objectApi.getObject(packageId);
      if (result.status === 200) {
        const pkg = result.data;
        const version = pkg.versions?.find((pv) => pv.id === versionId);
        let datasetId = version.testReport;
        if (datasetId) {
          await objectApi.deleteObject(datasetId);
        }
      }
      await packageApi.updatePackageVersions(packageId, request);
      dispatch(slice.actions.removePackageVersion({ packageId, versionId }));
    } catch (error) {
      console.error(
        'Error occurs during process removePackageVersionThunk: ',
        error.response?.data?.message || error.message,
      );
      dispatch(
        showErrorAlert(`${error.response?.data?.message || error.message}`),
      );
    }
  };

export const updatePackageVersionThunk =
  (packageId: string, versionId: string, name: string) =>
    async (dispatch: Function) => {
      try {
        const versionTobeUpdate = {
          id: versionId,
          name: name,
        };
        const request = {
          update: {
            versions: [versionTobeUpdate],
          },
        };
        await packageApi.updatePackageVersions(packageId, request);
        dispatch(
          slice.actions.updatePackageVersion({
            packageId,
            versionId,
            object: versionTobeUpdate,
          }),
        );
      } catch (error) {
        console.error(
          'Error occurs during process updatePackageVersionThunk: ',
          error.response?.data?.message || error.message,
        );
        dispatch(
          showErrorAlert(`${error.response?.data?.message || error.message}`),
        );
      }
    };

export const updatePackageStatusThunk =
  (packageId: string, packageStatus: string) => async (dispatch: Function) => {
    const request = {
      type: 'Package',
      status: packageStatus,
    };
    await packageApi.updatePackage(packageId, request);
    dispatch(
      slice.actions.setPackageStatus({
        packageId: packageId,
        packageStatus: packageStatus,
      }),
    );
  };

const promotePackageVersionWithoutFile = async (
  packageId: string,
  versionId: string,
  promotedStatus: string,
  notes: string,
  testReportResult,
  dispatch: Function,
) => {
  const activeUser = getActiveUser();
  const versionTobeUpdate = {
    id: versionId,
    testReport: null,
    promoted: promotedStatus,
    notes: notes,
    filePath: null,
    promotedBy: activeUser.email,
    promotedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    ...testReportResult,
  };
  const request = {
    update: {
      versions: [versionTobeUpdate],
    },
  };

  await packageApi.updatePackageVersions(packageId, request);
  dispatch(
    slice.actions.updatePackageVersion({
      packageId,
      versionId,
      object: versionTobeUpdate,
    }),
  );
};

export const promotePackageVersionThunk =
  (
    packageId: string,
    versionId: string,
    promotedStatus: string,
    notes: string,
    testReport?: any,
    fileName?: string,
    filePath?: string,
    uploadReportData?: any,
  ) =>
    async (dispatch: Function) => {
      try {
        let testReportResult;
        if (promotedStatus?.toLowerCase() === 'success') {
          testReportResult = testReport;
        }
        // No file here, only notes
        if (!uploadReportData) {
          await promotePackageVersionWithoutFile(
            packageId,
            versionId,
            promotedStatus,
            notes,
            testReportResult,
            dispatch,
          );
          return;
        }

        let result = await objectApi.getObject(packageId);
        if (result.status !== 200) {
          return;
        }
        const activeUser = getActiveUser();
        const pkg = result.data;
        const version = pkg.versions?.find((pv) => pv.id === versionId);
        let datasetId = version.testReport;
        let versionTobeUpdate = {};
        let request = {};

        if (datasetId) {
          result = await objectApi.getObject(datasetId);
          const dataset = result.data as Dataset;
          dataset.bucket = uploadReportData.Bucket;
          dataset.key = uploadReportData.key;
          dataset.name = fileName;
          await objectApi.updateObject(datasetId, dataset);
          versionTobeUpdate = {
            id: versionId,
            promoted: promotedStatus,
            notes: notes,
            filePath: filePath,
            promotedBy: activeUser.email,
            promotedAt: moment().toISOString(),
            modifiedBy: activeUser.email,
            ...testReportResult,
          };
          request = {
            update: {
              versions: [versionTobeUpdate],
            },
          };
        } else {
          const newDataset = prepareDataset(
            uploadReportData.Bucket,
            uploadReportData.key,
            fileName,
          );
          result = await objectApi.createObject(newDataset);
          const dataset = result.data as Dataset;
          versionTobeUpdate = {
            id: versionId,
            testReport: dataset.id,
            promoted: promotedStatus,
            notes: notes,
            filePath: filePath,
            promotedBy: activeUser.email,
            promotedAt: moment().toISOString(),
            modifiedBy: activeUser.email,
            ...testReportResult,
          };
          request = {
            update: {
              versions: [versionTobeUpdate],
            },
          };
          datasetId = dataset.id;
        }
        await packageApi.updatePackageVersions(packageId, request);
        dispatch(
          slice.actions.updatePackageVersion({
            packageId,
            versionId,
            object: versionTobeUpdate,
          }),
        );
      } catch (error) {
        console.error(
          'Error occurs during process promotePackageVersionThunk: ',
          error.response?.data?.message || error.message,
        );
        dispatch(
          showErrorAlert(`${error.response?.data?.message || error.message}`),
        );
      }
    };

export const addReleaseTagThunk =
  (
    packageId: string,
    releaseTag: string,
    onError?: (message) => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      const request = {
        releaseTag,
      };
      try {
        const response = await packageApi.addReleaseTag(packageId, request);
        if (response.status === 200) {
          dispatch(
            slice.actions.addReleaseTag({
              packageId: packageId,
              package: response.data,
            }),
          );
          onDone?.();
        }
      } catch (error) {
        console.error(
          'Error occurs during process addReleaseTag: ',
          error.response.data.message,
        );
        onError?.(error.response.data.message);
      }
    };
