import {
  showErrorAlert,
  showInfoAlert,
  showSuccessAlert,
} from 'src/slices/common-settings-slice';
import { store } from 'src/store';
import {
  setVehicleOTAPackageDownloadResult,
  updateCoreModuleCacheData,
  updateCoreModuleGetProgress,
  updateCoreModuleResponse,
} from 'src/tools/flash/slices/ecu-versions';
import { isInNativeMode } from 'src/utils/native';
import { progress_pb, tool_commands } from './protobuf/commands';

// * Always save to coreModuleResponses store
const ALWAYS_SAVE_MESSAGE_TYPES = [
  'getDiagnosticTree',
  'getCalCfrmEolResult',
  'getCalCfrmEolResult',
  'getCalSvcEolResult',
  'getAutomationResult',
];

export const AUTOMATION_IDS = {
  CFRM_EOL_CALIBRATION: 0,
  SVC_EOL_CALIBRATION: 1,
  SRS_VARIANT_CODING: 2,
  SAS_CALIBRATION: 3,
  TPMS_EOL: 4,
  SVC_WRITE_REGION_CODE: 5,
  SRSEDR_READ_6_DIDS: 6,
  MAX_AUTOMATION_ID: 7,
};

class FlashApi {
  downloadOTAPackage(downloadInfo) {
    store.dispatch(setVehicleOTAPackageDownloadResult(undefined));
    window.tcp?.download('downloadOTA', { payload: downloadInfo });
  }

  adbRequest(commandLine, startupFolder, captureFile) {
    // console.log('send adb request check point: adb', commandLine);
    const adbRequest = tool_commands.ADBRequest.create({
      commandLine: commandLine,
      adbExecutable: 'adb.exe',
      startupFolder: startupFolder,
      captureFile: captureFile,
    });
    // console.log(adbRequest);
    const message = createRequest({ adbRequest: adbRequest });
    handleSocketCall(message);
  }

  getModels() {
    const message = createRequest({ getModels: {} });
    handleSocketCall(message);
  }

  getECUList(vehicleRevisionId, version, preferCached = false) {
    const ecuListRequest = tool_commands.EcuListRequest.create({
      revision: vehicleRevisionId,
      release: version,
    });
    const message = createRequest({ getECUList: ecuListRequest, preferCached });
    handleSocketCall(message);
  }

  setSelectedECUs(coreModuleECUList, selectedEcuIds) {
    const ecusInDomains = [];
    for (const domain of coreModuleECUList.domains) {
      const ecusOnBuses = [];
      for (const busList of domain.busList) {
        const ecuList = [];
        for (const ecu of busList.ecuList) {
          ecuList.push(
            tool_commands.ExistingECU.create({
              ...ecu,
              isSelected: selectedEcuIds.includes(`${ecu.revisionId}|${ecu.id}`)
                ? true
                : false,
            }),
          );
        }
        ecusOnBuses.push(
          tool_commands.ECUsOnBus.create({
            name: busList.name,
            busId: busList.busId,
            ecuList: ecuList,
          }),
        );
      }
      ecusInDomains.push(
        tool_commands.ECUsInDomain.create({
          name: domain.name,
          busList: ecusOnBuses,
          revisionName: domain.revisionName,
        }),
      );
    }
    const ecusInVehicle = tool_commands.ECUsInVehicle.create({
      revision: coreModuleECUList.revision,
      release: coreModuleECUList.release,
      domains: ecusInDomains,
    });

    // send request to socket
    const message = createRequest({ setSelectedECUs: ecusInVehicle });
    handleSocketCall(message);
  }

  startDownload() {
    const message = createRequest({ startDownload: {} });
    handleSocketCall(message);
  }

  getSelectedECUs() {
    const message = createRequest({ getSelectedECUs: {} });
    handleSocketCall(message);
  }

  versionComparison(ecus, root) {
    const versionComparisonRequest =
      tool_commands.VersionComparisonRequest.create({ ecus, root });
    const message = createRequest({
      versionComparisonReq: versionComparisonRequest,
    });
    handleSocketCall(message);
  }

  getVersionCompResult() {
    const message = createRequest({ getVersionCompResult: {} });
    handleSocketCall(message);
  }

  startUpdateSession(ecus, root) {
    const selectedECUs = tool_commands.SelectedECUs.create({ ecus, root });
    const message = createRequest({ startUpdateSession: selectedECUs });

    handleSocketCall(message);
  }

  getProgress(progressToken) {
    const progress = progress_pb.Progress.create({ token: progressToken });
    const message = createRequest({ getProgress: progress });

    handleSocketCall(message);
  }

  getSettings() {
    const message = createRequest({ getSettings: {} });
    handleSocketCall(message);
  }

  setSettings(settings) {
    const message = createRequest({ setSettings: settings });
    handleSocketCall(message);
  }

  // prefer use cached data
  getDiagnosticTree(ecuName, preferCached = true) {
    const message = createRequest({ getDiagnosticTree: ecuName, preferCached });
    handleSocketCall(message);
  }

  udsSequenceRequest(ecuName, udsRequests) {
    const ecus = Array.isArray(ecuName) ? ecuName : [ecuName];
    const udsSequenceRequest = {
      packages: [
        {
          ecus: [...ecus],
          requests: udsRequests,
        },
      ],
    };
    console.log('The udsSequenceRequest: ', udsSequenceRequest);
    const message = createRequest({ udsSequenceRequest: udsSequenceRequest });
    if (message) {
      console.log('Encoded request message for udsSequenceRequest: ', message);
      handleSocketCall(message);
      return true;
    }
    return false;
  }

  udsSequenceRequestByPackages(packages) {
    // console.log('send udsSequenceRequest CHECK POINT');
    const udsSequenceRequest = { packages };
    // console.log('The udsSequenceRequest with packages: ', udsSequenceRequest);
    const message = createRequest({ udsSequenceRequest: udsSequenceRequest });
    if (message) {
      console.log('Encoded request message for udsSequenceRequest: ', message);
      handleSocketCall(message);
      return true;
    }
    return false;
  }

  startVDM() {
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 259,
          subFn: 3,
          id: 2,
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1537,
          data: [1],
        },
      },
    });
    handleSocketCall(message);
  }

  stopVDM() {
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 259,
          subFn: 3,
          id: 2,
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1537,
          data: [0],
        },
      },
    });
    handleSocketCall(message);
  }

  startFactoryMode() {
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 61504,
          data: [0]
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [1],
        },
      },
    });
    handleSocketCall(message);
  }

  startProductionMode() {
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 61504,
          data: [16590]
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [1],
        },
      },
    });
    handleSocketCall(message);
  }

  enableOTAMode() {
    console.log("in enableOTAMode");
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [1],
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [1],
        },
      },
    });
    handleSocketCall(message);
  }
  disableOTAMode() {
    console.log("in disableOTAMode");
    const messageSecurity = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [0],
        },
      },
    });
    handleSocketCall(messageSecurity);
    const message = createRequest({
      udsRequest: {
        ecu: 'MPC',
        rr: {
          sid: 49,
          subFn: 1,
          id: 1538,
          data: [0],
        },
      },
    });
    handleSocketCall(message);
  }

  handleSeatCal(selectedECU) {
    console.log("In flash api handleSeatCal with selectedECU: ", selectedECU);
    if (selectedECU == "PSM") {
      updateCoreModuleResponse('getAutomationResult', null);
      updateCoreModuleResponse('startAutomation', null);
      if (coreModuleResponses['startAutomation']) {
        if (startAutomationResponse.result === 'OK') {
          dispatch(updateOnGetProgress(true));
          startProgressChecker();
          dispatch(updateCoreModuleResponse('startAutomation', undefined));
        }
      }
    }
  }
  udsSequenceResult() {
    const message = createRequest({ udsSequenceResult: {} });
    handleSocketCall(message);
  }

  securityAccess(securityAccessRequest) {
    const message = createRequest({ securityAccess: securityAccessRequest });
    handleSocketCall(message);
  }

  calibrateCfrmEol(calibrateCfrmRequest) {
    const message = createRequest({ calibrateCfrmEol: calibrateCfrmRequest });
    handleSocketCall(message);
  }

  getCalCfrmEolResult() {
    const message = createRequest({ getCalCfrmEolResult: {} });
    handleSocketCall(message);
  }

  calibrateSvcEol() {
    const message = createRequest({ calibrateSvcEol: {} });
    handleSocketCall(message);
  }

  getCalSvcEolResult() {
    const message = createRequest({ getCalSvcEolResult: {} });
    handleSocketCall(message);
  }

  loopTesterPresent(ecuName, intervalMs) {
    const loopTesterPresentRequest = { name: ecuName, intervalMs: intervalMs };
    const message = createRequest({
      loopTesterPresent: loopTesterPresentRequest,
    });
    handleSocketCall(message);
  }

  startAutomation(automationRequest) {
    const message = createRequest({ startAutomation: automationRequest });
    handleSocketCall(message);
  }

  getAutomationResult(automationId) {
    // automationId should be enum value number
    const message = createRequest({ getAutomationResult: automationId });
    handleSocketCall(message);
  }

  startDoorOffsetLearn(selectedECU) {
    switch (selectedECU) {
      case 'DCFL':
        const dcflMessage = createRequest({
          udsRequest: {
            ecu: 'DCFL',
            rr: {
              sid: 49,
              subFn: 1,
              id: 65456,
              data: [1],
            },
          },
        });
        handleSocketCall(dcflMessage);
        break;
      case 'DCFR':
        const dcfrMessage = createRequest({
          udsRequest: {
            ecu: 'DCFR',
            rr: {
              sid: 49,
              subFn: 1,
              id: 65456,
              data: [1],
            },
          },
        });
        handleSocketCall(dcfrMessage);
        break;
      case 'DCRL':
        const dcrlMessage = createRequest({
          udsRequest: {
            ecu: 'DCRL',
            rr: {
              sid: 49,
              subFn: 1,
              id: 65456,
              data: [1],
            },
          },
        });
        handleSocketCall(dcrlMessage);
        break;
      case 'DCRR':
        const dcrrMessage = createRequest({
          udsRequest: {
            ecu: 'DCRR',
            rr: {
              sid: 49,
              subFn: 1,
              id: 65456,
              data: [1],
            },
          },
        });
        handleSocketCall(dcrrMessage);
        break;
    }
  }
  /**
   *
   * @param {*} sensorValues - represents the sensor values in the following order:
   *      [
   *        <integer>, // representing FL
   *        <integer>, // representing FR
   *        <integer>, // representing RR
   *        <integer>, // representing RL
   *        <integer>, // tire pressure, 0x122 or 0x136
   *      ]
   */
  runTpmsEOL(sensorValues) {
    const message = createRequest({
      startAutomation: {
        id: AUTOMATION_IDS.TPMS_EOL,
        intParam: sensorValues,
      },
    });
    handleSocketCall(message);
  }
}

// console.log('getting response');

if (typeof window !== 'undefined' && window.tcp) {
  console.log('check window tcp', window.tcp);
  Object.assign(console, window.tcp.log);

  window.tcp?.receive('toGalaxy', (data) => {
    let response;
    try {
      console.log('Core module response: ', data);
      response = tool_commands.response.decode(data);
      console.groupCollapsed(
        `Response from %c${!!response && typeof response === 'object'
          ? Object.keys(response)?.[1]
          : 'Bad Response'
        }`,
        'color: green;',
      );
      console.log(
        `%c-- Received Response: -- %c${JSON.stringify(response)}`,
        'color: red; font-weight: 500',
        'color: blue',
      );
      console.groupEnd();
    } catch (error) {
      console.error('Error occurs during receiving message response', error);
      store.dispatch(
        showErrorAlert(
          'Error occurs during receiving core module response, please press CTRL+SHIFT+R to reload page.',
        ),
      );
      window.tcp?.resetMessageBuffer();
    }

    if (response && typeof response === 'object') {
      let messageType = Object.keys(response)[1];

      if (
        (response['result'] === 0 && !!messageType) ||
        ALWAYS_SAVE_MESSAGE_TYPES.includes(messageType)
      ) {
        if (messageType === 'getProgress') {
          store.dispatch(
            updateCoreModuleGetProgress(JSON.stringify(response.getProgress)),
          );
        } else {
          store.dispatch(
            updateCoreModuleResponse(messageType, JSON.stringify(response)),
          );
        }
      } else if (response.result === 2 && !!messageType) {
        store.dispatch(
          updateCoreModuleCacheData(messageType, JSON.stringify(response)),
        );
      } else if (response.result === -1 || response.result === -2) {
        messageType = Object.keys(response)[2] || Object.keys(response)[1];
        console.error(
          `Core module return Error for ${messageType} API call`,
          response,
        );
        store.dispatch(
          showErrorAlert(
            `Core module return Error for ${messageType} API call`,
          ),
        );
        store.dispatch(
          updateCoreModuleResponse(messageType, JSON.stringify(response)),
        );
      } else {
        store.dispatch(
          showErrorAlert('Could not get valid response from core module'),
        );
      }
    }
  });

  window.tcp?.receive('downloadOTAComplete', (data) => {
    console.log('download result: ', data);
    if (data.result === 'success') {
      switch (data.stage) {
        case 'download':
        case 'extract':
          store.dispatch(
            showSuccessAlert(
              'The OTA packages have been downloaded and extracted',
            ),
          );
          break;

        case 'copy':
          store.dispatch(
            showSuccessAlert('The Vehicle OTA package has been downloaded'),
          );
          break;

        default:
          store.dispatch(
            showSuccessAlert('Download OTA packages have been processed'),
          );
          break;
      }
    } else {
      store.dispatch(showErrorAlert(data.message));
    }
    store.dispatch(setVehicleOTAPackageDownloadResult(data));
  });

  window.tcp?.receive('downloadOTAProgress', (data) => {
    store.dispatch(
      showInfoAlert(
        `Please do NOT do any other action to interrupt download process, the OTA package of ${data.ecuName
        } is downloading - ${Math.round((data.progress.percent + Number.EPSILON) * 10000) / 100
        }%`,
      ),
    );
  });

  window.tcp?.receive('extractOTAProgress', (data) => {
    console.log('extract result: ', data);
    if (data.result === 'success') {
      store.dispatch(
        showInfoAlert(
          `Please do NOT do any other action to interrupt download process, extracting ${data.zipFileInfo.ecuName} OTA package files from downloaded file`,
        ),
      );
    } else {
      store.dispatch(showErrorAlert(data.message));
    }
  });
}

function createRequest(request) {
  let protoBufRequest;
  try {
    const requestMessage = tool_commands.request.create(request);
    console.groupCollapsed(
      `Request to %c${Object.keys(request)[0]}`,
      'color: green;',
    );
    console.info(
      `%c-- Request message: -- %c${JSON.stringify(requestMessage)}`,
      'color: red; font-weight: 500',
      'color: blue',
    );
    console.groupEnd();
    protoBufRequest = tool_commands.request.encode(requestMessage).finish();
  } catch (error) {
    console.error(
      'Error occurs during transferring message to protocol buffer',
      error,
    );
    store.dispatch(
      showErrorAlert(
        'The request data could not been converted to protocol buffer message.',
      ),
    );
  }
  return protoBufRequest;
}

const handleSocketCall = (message) => {
  if (isInNativeMode()) {
    if (message) {
      const messageType = Object.keys(message)[0];
      if (!!messageType) {
        window.tcp.sendRequest(message);
      } else {
        console.error('Message format is invalid');
      }
    } else {
      console.error('Message is empty');
    }
  } else {
    console.error('Native call is not supported in Galaxy Web Mode.');
  }
};

export const runCoreModule = () => {
  // window.tcp.runSocketClient();
};

export const flashApi = new FlashApi();
