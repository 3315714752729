import { createSlice } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { objectApi } from 'src/apis/object-api';
import { showErrorAlert } from 'src/slices/common-settings-slice';
import { getEnv, ObjectTypePrefixToRequestParam } from 'src/utils/utils';

const initialState: {
  eeArchStatus: 'Expired' | 'Loading' | 'Unexpired';
  eeArchId: string;
  vehicles: any[];
  domains: any[];
  ecus: any[];
  libraries: any[];
  releases: any[];
  templates: any[];
  didNumbers: any[];
  didFormats: any[];
  selectedDtcs: any[];
  selectedUdss: any[];
  selectedDids: any[];
  selectedRoutines: any[];
} = {
  eeArchStatus: 'Expired',
  eeArchId: undefined, // TODO: where to get default one?
  vehicles: [],
  domains: [],
  ecus: [],
  libraries: [],
  releases: [],
  templates: [],
  didNumbers: [],
  didFormats: [],
  selectedDtcs: [],
  selectedUdss: [],
  selectedDids: [],
  selectedRoutines: [],
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setEEArchStatus(state, action) {
      state.eeArchStatus = action.payload;
    },
    setEEArchId(state, action) {
      state.eeArchId = action.payload;
    },
    setGeneralSettings(state, action) {
      state.vehicles = action.payload.vehicles;
      state.domains = sortBy(action.payload.domains, 'name');
      state.ecus = sortBy(action.payload.ecus, 'name');
      state.libraries = sortBy(action.payload.libraries, 'name');
    },
    removeGeneralSetting(state, action) {
      const [itemType, itemId] = [action.payload.type, action.payload.id];
      state[itemType] = [...state[itemType].filter((i) => i.id != itemId)];
    },
    setTemplateSettings(state, action) {
      state.templates = action.payload.templates;
    },
    removeTemplateSettings(state, action) {
      const itemIds = action.payload;
      state.templates = [
        ...state.templates.filter((i) => !itemIds.includes(i.id)),
      ];
    },
    setReleaseSettings(state, action) {
      state.releases = sortBy(action.payload.releaseVersions, 'name');
    },
    removeReleaseSettings(state, action) {
      const itemId = action.payload.id;
      state.releases = [...state.releases.filter((i) => i.id != itemId)];
    },
    setDIDSettings(state, action) {
      state.didNumbers = sortBy(action.payload.didNumbers, 'name');
      state.didFormats = sortBy(action.payload.didFormats, 'name');
    },
    removeDIDSettings(state, action) {
      const itemId = action.payload.id;
      state.didNumbers = [...state.didNumbers.filter((i) => i.id != itemId)];
      state.didFormats = [...state.didFormats.filter((i) => i.id != itemId)];
    },
    setSelectedDtcs(state, action) {
      state.selectedDtcs = action.payload;
    },
    setSelectedUdss(state, action) {
      state.selectedUdss = action.payload;
    },
    setSelectedDids(state, action) {
      state.selectedDids = action.payload;
    },
    setSelectedRoutines(state, action) {
      state.selectedRoutines = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setEEArchId = (eeArchId: string) => async (dispatch: Function) => {
  dispatch(slice.actions.setEEArchId(eeArchId));
};

export const getEEArchDetails =
  (eeArchId?: string, onError?: () => void, onDone?: () => void) =>
    async (dispatch: Function, getState: Function) => {
      const eeArchStatus = getState().settings.eeArchStatus;

      if (eeArchStatus !== 'Expired') {
        onDone?.();
        return;
      }
      dispatch(slice.actions.setEEArchStatus('Loading'));
      let eeArchObjectId;
      let response;
      if (!eeArchId) {
        const filter = [{ name: 'type', value: 'EEArch' }];
        response = await objectApi.listObjects(filter);
        response.data = response.data.objects[0];
        eeArchObjectId = response.data.id;
      } else {
        response = await objectApi.getObject(eeArchId);
        eeArchObjectId = eeArchId;
      }

      if (response?.status !== 200) {
        onError?.();
        return;
      }

      dispatch(slice.actions.setEEArchId(eeArchObjectId));
      dispatch(slice.actions.setGeneralSettings(response.data));
      dispatch(slice.actions.setReleaseSettings(response.data));
      dispatch(slice.actions.setTemplateSettings(response.data));
      dispatch(slice.actions.setDIDSettings(response.data));

      dispatch(slice.actions.setEEArchStatus('Unexpired'));
      onDone?.();
    };

export const removeEEArchDetails =
  (eeArchId: string, item: any, onError?: () => void, onDone?: () => void) =>
    async (dispatch: Function) => {
      const response = await objectApi.updateEEArchDetails(
        eeArchId,
        item,
        'delete',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(
        slice.actions.removeGeneralSetting({
          id: item.id,
          type: ObjectTypePrefixToRequestParam[item.type],
        }),
      );
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const createEEArchDetails =
  (
    eeArchId: string,
    item: any,
    isUpdating: boolean,
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      let eeArchObjectId;
      if (!eeArchId) {
        const objectId = await dispatch(initialEEArch());
        eeArchObjectId = objectId;
      } else {
        eeArchObjectId = eeArchId;
      }

      const response = await objectApi.updateEEArchDetails(
        eeArchObjectId,
        item,
        isUpdating ? 'update' : 'create',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setGeneralSettings(response.data));
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const removeEEArchRelease =
  (eeArchId: string, item: any, onError?: () => void, onDone?: () => void) =>
    async (dispatch: Function) => {
      const response = await objectApi.updateEEArchDetails(
        eeArchId,
        item,
        'delete',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.removeReleaseSettings({ id: item.id }));
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const createEEArchRelease =
  (
    eeArchId: string,
    item: any,
    isUpdating: boolean,
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      let eeArchObjectId;
      if (!eeArchId) {
        const objectId = await initialEEArch();
        eeArchObjectId = objectId;
      } else {
        eeArchObjectId = eeArchId;
      }
      const response = await objectApi.updateEEArchDetails(
        eeArchObjectId,
        item,
        isUpdating ? 'update' : 'create',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setReleaseSettings(response.data));
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const removeEEArchTemplate =
  (
    eeArchId: string,
    items: object[],
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      if (items?.length && items.every((it) => it['type'] === items[0]['type'])) {
        const response = await objectApi.updateMultipleEEArchDetails(
          eeArchId,
          items,
          items[0]['type'],
          'delete',
        );
        if (response?.status != 200) {
          onError?.();
        }
        dispatch(
          slice.actions.removeTemplateSettings(items.map((it) => it['id'])),
        );
        dispatch(slice.actions.setEEArchStatus('Expired'));
        onDone?.();
      } else {
        dispatch(
          showErrorAlert(
            `Invalid update object ${items}, please report the issue to administrator.`,
          ),
        );
      }
    };

export const createEEArchTemplate =
  (
    eeArchId: string,
    items: object[],
    isUpdating: boolean,
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      let eeArchObjectId;
      if (!eeArchId) {
        const objectId = await initialEEArch();
        eeArchObjectId = objectId;
      } else {
        eeArchObjectId = eeArchId;
      }
      if (items?.length && items.every((it) => it['type'] === items[0]['type'])) {
        const response = await objectApi.updateMultipleEEArchDetails(
          eeArchObjectId,
          items,
          items[0]['type'],
          isUpdating ? 'update' : 'create',
        );
        if (response?.status != 200) {
          onError?.();
        }
        dispatch(slice.actions.setTemplateSettings(response.data));
        dispatch(slice.actions.setEEArchStatus('Expired'));
        onDone?.();
      } else {
        dispatch(
          showErrorAlert(
            `Invalid update object ${items}, please report the issue to administrator.`,
          ),
        );
      }
    };

export const removeEEArchDIDSetting =
  (eeArchId: string, item: any, onError?: () => void, onDone?: () => void) =>
    async (dispatch: Function) => {
      const response = await objectApi.updateEEArchDetails(
        eeArchId,
        item,
        'delete',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.removeDIDSettings({ id: item.id }));
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const createEEArchDIDSetting =
  (
    eeArchId: string,
    item: any,
    isUpdating: boolean,
    onError?: () => void,
    onDone?: () => void,
  ) =>
    async (dispatch: Function) => {
      let eeArchObjectId;
      if (!eeArchId) {
        const objectId = await initialEEArch();
        eeArchObjectId = objectId;
      } else {
        eeArchObjectId = eeArchId;
      }
      const response = await objectApi.updateEEArchDetails(
        eeArchObjectId,
        item,
        isUpdating ? 'update' : 'create',
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setDIDSettings(response.data));
      dispatch(slice.actions.setEEArchStatus('Expired'));
      onDone?.();
    };

export const getDtcs =
  (ecuNodeId: string, onError?: () => void, onDone?: () => void, limit = 100) =>
    async (dispatch: Function) => {
      const response = await objectApi.listObjects(
        // filters
        [
          {
            name: 'type',
            value: 'DTC',
          },
          {
            name: 'ecuNodeId',
            value: ecuNodeId,
          },
        ],
        undefined,
        undefined,
        limit,
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setSelectedDtcs(response.data.objects));
      onDone?.();
    };

export const getUdss =
  (ecuNodeId: string, onError?: () => void, onDone?: () => void, limit = 100) =>
    async (dispatch: Function) => {
      const response = await objectApi.listObjects(
        // filters
        [
          {
            name: 'type',
            value: 'UDS',
          },
          {
            name: 'ecuNodeId',
            value: ecuNodeId,
          },
        ],
        undefined,
        undefined,
        limit,
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setSelectedUdss(response.data.objects));
      onDone?.();
    };

export const getDids =
  (ecuNodeId: string, onError?: () => void, onDone?: () => void, limit = 100) =>
    async (dispatch: Function) => {
      const response = await objectApi.listObjects(
        // filters
        [
          {
            name: 'type',
            value: 'DID',
          },
          {
            name: 'ecuNodeId',
            value: ecuNodeId,
          },
        ],
        undefined,
        undefined,
        limit,
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setSelectedDids(response.data.objects));
      onDone?.();
    };

export const getRoutiness =
  (ecuNodeId: string, onError?: () => void, onDone?: () => void, limit = 100) =>
    async (dispatch: Function) => {
      const response = await objectApi.listObjects(
        // filters
        [
          {
            name: 'type',
            value: 'ROUTINES',
          },
          {
            name: 'ecuNodeId',
            value: ecuNodeId,
          },
        ],
        undefined,
        undefined,
        limit,
      );
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setSelectedRoutines(response.data.objects));
      onDone?.();
    };

const initialEEArch = () => async (dispatch: Function) => {
  const env = getEnv();
  const initEEArch = {
    name: `${env.projectId}_EEA`,
    type: 'EEArch',
    vehicles: [],
    domains: [],
    ecus: [],
    libraries: [],
    releaseVersions: [],
    didNumbers: [],
    didFormats: [],
  };
  let eeArchId = undefined;
  try {
    let result = await objectApi.createObject(initEEArch);
    if (result.status === 200) {
      const eeArch = result.data;
      eeArchId = eeArch.id;
      dispatch(slice.actions.setEEArchId(eeArch.id));
      dispatch(slice.actions.setEEArchStatus('Unexpired'));
    }
  } catch (error) {
    console.error('Cannot initial EEArch object' + error);
    dispatch(
      showErrorAlert(
        `${error?.data?.message || ''
        } Error occurred during initial vehicle setting, please report the issue to administrator.`,
      ),
    );
  }
  return eeArchId;
};
