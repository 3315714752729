import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { userApi } from '../apis/user-api';
import toast from 'react-hot-toast';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: (username, password) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        //const accessToken = window.localStorage.getItem('accessToken');
        const activeUser = JSON.parse(
          window.localStorage.getItem('activeUser'),
        );

        if (activeUser) {
          const user = activeUser;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const { accessToken, user } = await userApi.login({ username, password });
    // Finish login from the caller function
    return [
      user?.accounts || [],
      () => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('activeUser', JSON.stringify(user));
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      },
    ];
  };

  const logout = async () => {
    // userApi.logout(localStorage.getItem('accessToken'));
    localStorage.removeItem('accessToken');
    localStorage.removeItem('activeUser');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async ({
    email,
    firstName,
    lastName,
    phone,
    company,
    password,
    inviteToken,
  }) => {
    const user = await userApi.registerApi({
      email,
      firstName,
      lastName,
      phone,
      company,
      password,
      inviteToken,
    });

    if (!user) {
      return;
    }

    toast.success(`User ${email} is registered successfully, please login.`);

    //localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });

    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
