import { combineReducers } from '@reduxjs/toolkit';

import { reducer as commonSettingsReducer } from 'src/slices/common-settings-slice';
import { reducer as sharedDataReducer } from 'src/slices/shared-data-slice';

import { reducer as demReducer } from 'src/tools/dem/slices/dem-slice';
import { reducer as adminReducer } from '../tools/admin/slices/admin-slice';
import { reducer as flashReducer } from '../tools/flash/slices/ecu-versions';
import { reducer as notificationReducer } from '../tools/tracker/slices/notification-slice';
import { reducer as objectReducer } from '../tools/tracker/slices/object-slice';
import { reducer as packageReducer } from '../tools/tracker/slices/package-slice';
import { reducer as settingsReducer } from '../tools/tracker/slices/settings-slice';
import { reducer as structureReducer } from '../tools/tracker/slices/structure-slice';
import { reducer as vehicleReducer } from '../tools/vehicle/slices/vehicle-slice';
import { reducer as keysReducer } from '../tools/vendor/slices/vendor-keys-slice';

export const rootReducer = combineReducers({
  admin: adminReducer,
  commonSettings: commonSettingsReducer,
  flash: flashReducer,
  notification: notificationReducer,
  object: objectReducer,
  package: packageReducer,
  settings: settingsReducer,
  sharedData: sharedDataReducer,
  structure: structureReducer,
  vendorKeys: keysReducer,
  vehicle: vehicleReducer,
  dem: demReducer,
});
