import axios from './base';

const prefix = '/v1/setting';

class SettingApi {
  async updateSettings(request: object[]) {
    return axios
      .post(`${prefix}/updateSettings`, request)
      .then((response) => response);
  }

  async deleteSettings(request: object[]) {
    return axios
      .post(`${prefix}/deleteSettings`, {
        keys: request
      })
      .then((response) => response);
  }

  async getSettings(request: object[]) {
    return axios
      .post(`${prefix}/getSettings`, {
        keys: request
      })
      .then((response) => response);
  }

}

export const settingApi = new SettingApi();