import { createSlice } from '@reduxjs/toolkit';
import { DiagnosticEvent } from '../models/dem-models';

// TODO Example return list of events

const getEventList = () => {
  return new Promise<Array<DiagnosticEvent>>((resolve) => {
    setTimeout(() => {
      resolve([
        {
          id: '1',
          type: 'Event',
          name: 'E-1',
          createdAt: '2023-07-25T00:00:01.100Z',
          status: 'success',
          message: 'Diagnostic Event message',
        },
        {
          id: '2',
          type: 'Event',
          name: 'E-2',
          createdAt: '2023-07-25T00:00:01.100Z',
          status: 'success',
          message: 'Diagnostic Event message',
        },
        {
          id: '3',
          type: 'Event',
          name: 'E-3',
          createdAt: '2023-07-25T00:00:01.100Z',
          status: 'success',
          message: 'Diagnostic Event message',
        },
        {
          id: '4',
          type: 'Event',
          name: 'E-4',
          createdAt: '2023-07-25T00:00:01.100Z',
          status: 'success',
          message: 'Diagnostic Event message',
        },
      ]);
    }, 1000);
  });
};

const initialState: {
  events: DiagnosticEvent[];
} = {
  events: [],
};

const slice = createSlice({
  name: 'dem',
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getEvents =
  (onDone?: () => void, onError?: () => void) => async (dispatch: Function) => {
    const response = await getEventList();
    if (!response?.length) {
      onError?.();
    }
    dispatch(slice.actions.setEvents(response));
    onDone?.();
  };
