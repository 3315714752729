/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.tool_commands = (function() {

    /**
     * Namespace tool_commands.
     * @exports tool_commands
     * @namespace
     */
    var tool_commands = {};

    /**
     * ExtendedSId enum.
     * @name tool_commands.ExtendedSId
     * @enum {number}
     * @property {number} BASE=256 BASE value
     * @property {number} CONNECT=257 CONNECT value
     * @property {number} DSCONNECT=258 DSCONNECT value
     * @property {number} PROCESS_SECUTITY=259 PROCESS_SECUTITY value
     * @property {number} WAIT=260 WAIT value
     * @property {number} DOWNLOAD=261 DOWNLOAD value
     * @property {number} UPLOAD=262 UPLOAD value
     * @property {number} OPERATOR_WAIT=263 OPERATOR_WAIT value
     * @property {number} OPERATOR_RESUME=264 OPERATOR_RESUME value
     * @property {number} READ_TREE=265 READ_TREE value
     */
    tool_commands.ExtendedSId = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[256] = "BASE"] = 256;
        values[valuesById[257] = "CONNECT"] = 257;
        values[valuesById[258] = "DSCONNECT"] = 258;
        values[valuesById[259] = "PROCESS_SECUTITY"] = 259;
        values[valuesById[260] = "WAIT"] = 260;
        values[valuesById[261] = "DOWNLOAD"] = 261;
        values[valuesById[262] = "UPLOAD"] = 262;
        values[valuesById[263] = "OPERATOR_WAIT"] = 263;
        values[valuesById[264] = "OPERATOR_RESUME"] = 264;
        values[valuesById[265] = "READ_TREE"] = 265;
        return values;
    })();

    tool_commands.VersionInfo = (function() {

        /**
         * Properties of a VersionInfo.
         * @memberof tool_commands
         * @interface IVersionInfo
         * @property {number} VMajor VersionInfo VMajor
         * @property {number} VMinor VersionInfo VMinor
         * @property {number} Build VersionInfo Build
         * @property {string} Builder VersionInfo Builder
         * @property {string} When VersionInfo When
         */

        /**
         * Constructs a new VersionInfo.
         * @memberof tool_commands
         * @classdesc Represents a VersionInfo.
         * @implements IVersionInfo
         * @constructor
         * @param {tool_commands.IVersionInfo=} [properties] Properties to set
         */
        function VersionInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionInfo VMajor.
         * @member {number} VMajor
         * @memberof tool_commands.VersionInfo
         * @instance
         */
        VersionInfo.prototype.VMajor = 0;

        /**
         * VersionInfo VMinor.
         * @member {number} VMinor
         * @memberof tool_commands.VersionInfo
         * @instance
         */
        VersionInfo.prototype.VMinor = 0;

        /**
         * VersionInfo Build.
         * @member {number} Build
         * @memberof tool_commands.VersionInfo
         * @instance
         */
        VersionInfo.prototype.Build = 0;

        /**
         * VersionInfo Builder.
         * @member {string} Builder
         * @memberof tool_commands.VersionInfo
         * @instance
         */
        VersionInfo.prototype.Builder = "";

        /**
         * VersionInfo When.
         * @member {string} When
         * @memberof tool_commands.VersionInfo
         * @instance
         */
        VersionInfo.prototype.When = "";

        /**
         * Creates a new VersionInfo instance using the specified properties.
         * @function create
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {tool_commands.IVersionInfo=} [properties] Properties to set
         * @returns {tool_commands.VersionInfo} VersionInfo instance
         */
        VersionInfo.create = function create(properties) {
            return new VersionInfo(properties);
        };

        /**
         * Encodes the specified VersionInfo message. Does not implicitly {@link tool_commands.VersionInfo.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {tool_commands.IVersionInfo} message VersionInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.VMajor);
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.VMinor);
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.Build);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Builder);
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.When);
            return writer;
        };

        /**
         * Encodes the specified VersionInfo message, length delimited. Does not implicitly {@link tool_commands.VersionInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {tool_commands.IVersionInfo} message VersionInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionInfo message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.VersionInfo} VersionInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.VersionInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.VMajor = reader.uint32();
                        break;
                    }
                case 2: {
                        message.VMinor = reader.uint32();
                        break;
                    }
                case 3: {
                        message.Build = reader.uint32();
                        break;
                    }
                case 4: {
                        message.Builder = reader.string();
                        break;
                    }
                case 5: {
                        message.When = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("VMajor"))
                throw $util.ProtocolError("missing required 'VMajor'", { instance: message });
            if (!message.hasOwnProperty("VMinor"))
                throw $util.ProtocolError("missing required 'VMinor'", { instance: message });
            if (!message.hasOwnProperty("Build"))
                throw $util.ProtocolError("missing required 'Build'", { instance: message });
            if (!message.hasOwnProperty("Builder"))
                throw $util.ProtocolError("missing required 'Builder'", { instance: message });
            if (!message.hasOwnProperty("When"))
                throw $util.ProtocolError("missing required 'When'", { instance: message });
            return message;
        };

        /**
         * Decodes a VersionInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.VersionInfo} VersionInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionInfo message.
         * @function verify
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.VMajor))
                return "VMajor: integer expected";
            if (!$util.isInteger(message.VMinor))
                return "VMinor: integer expected";
            if (!$util.isInteger(message.Build))
                return "Build: integer expected";
            if (!$util.isString(message.Builder))
                return "Builder: string expected";
            if (!$util.isString(message.When))
                return "When: string expected";
            return null;
        };

        /**
         * Creates a VersionInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.VersionInfo} VersionInfo
         */
        VersionInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.VersionInfo)
                return object;
            var message = new $root.tool_commands.VersionInfo();
            if (object.VMajor != null)
                message.VMajor = object.VMajor >>> 0;
            if (object.VMinor != null)
                message.VMinor = object.VMinor >>> 0;
            if (object.Build != null)
                message.Build = object.Build >>> 0;
            if (object.Builder != null)
                message.Builder = String(object.Builder);
            if (object.When != null)
                message.When = String(object.When);
            return message;
        };

        /**
         * Creates a plain object from a VersionInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {tool_commands.VersionInfo} message VersionInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.VMajor = 0;
                object.VMinor = 0;
                object.Build = 0;
                object.Builder = "";
                object.When = "";
            }
            if (message.VMajor != null && message.hasOwnProperty("VMajor"))
                object.VMajor = message.VMajor;
            if (message.VMinor != null && message.hasOwnProperty("VMinor"))
                object.VMinor = message.VMinor;
            if (message.Build != null && message.hasOwnProperty("Build"))
                object.Build = message.Build;
            if (message.Builder != null && message.hasOwnProperty("Builder"))
                object.Builder = message.Builder;
            if (message.When != null && message.hasOwnProperty("When"))
                object.When = message.When;
            return object;
        };

        /**
         * Converts this VersionInfo to JSON.
         * @function toJSON
         * @memberof tool_commands.VersionInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionInfo
         * @function getTypeUrl
         * @memberof tool_commands.VersionInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.VersionInfo";
        };

        return VersionInfo;
    })();

    /**
     * Transport enum.
     * @name tool_commands.Transport
     * @enum {number}
     * @property {number} CAN=0 CAN value
     * @property {number} IP=1 IP value
     * @property {number} LIN=2 LIN value
     * @property {number} SERIAL=3 SERIAL value
     */
    tool_commands.Transport = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CAN"] = 0;
        values[valuesById[1] = "IP"] = 1;
        values[valuesById[2] = "LIN"] = 2;
        values[valuesById[3] = "SERIAL"] = 3;
        return values;
    })();

    /**
     * Result enum.
     * @name tool_commands.Result
     * @enum {number}
     * @property {number} OK=0 OK value
     * @property {number} ERROR=-1 ERROR value
     * @property {number} FAIL=-2 FAIL value
     * @property {number} BUSY=1 BUSY value
     * @property {number} CACHED=2 CACHED value
     * @property {number} RETRY=3 RETRY value
     * @property {number} INVALID_REQUEST=-3 INVALID_REQUEST value
     * @property {number} INVALID_ARGUMENT=-4 INVALID_ARGUMENT value
     */
    tool_commands.Result = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OK"] = 0;
        values[valuesById[-1] = "ERROR"] = -1;
        values[valuesById[-2] = "FAIL"] = -2;
        values[valuesById[1] = "BUSY"] = 1;
        values[valuesById[2] = "CACHED"] = 2;
        values[valuesById[3] = "RETRY"] = 3;
        values[valuesById[-3] = "INVALID_REQUEST"] = -3;
        values[valuesById[-4] = "INVALID_ARGUMENT"] = -4;
        return values;
    })();

    /**
     * PCAN_BaudRate enum.
     * @name tool_commands.PCAN_BaudRate
     * @enum {number}
     * @property {number} PCAN_BAUD_1M=20 PCAN_BAUD_1M value
     * @property {number} PCAN_BAUD_800K=22 PCAN_BAUD_800K value
     * @property {number} PCAN_BAUD_500K=28 PCAN_BAUD_500K value
     * @property {number} PCAN_BAUD_250K=284 PCAN_BAUD_250K value
     * @property {number} PCAN_BAUD_125K=796 PCAN_BAUD_125K value
     * @property {number} PCAN_BAUD_100K=17199 PCAN_BAUD_100K value
     * @property {number} PCAN_BAUD_95K=49998 PCAN_BAUD_95K value
     * @property {number} PCAN_BAUD_83K=34091 PCAN_BAUD_83K value
     * @property {number} PCAN_BAUD_50K=18223 PCAN_BAUD_50K value
     * @property {number} PCAN_BAUD_47K=5140 PCAN_BAUD_47K value
     * @property {number} PCAN_BAUD_33K=35631 PCAN_BAUD_33K value
     * @property {number} PCAN_BAUD_20K=21295 PCAN_BAUD_20K value
     * @property {number} PCAN_BAUD_10K=26415 PCAN_BAUD_10K value
     * @property {number} PCAN_BAUD_5K=32639 PCAN_BAUD_5K value
     */
    tool_commands.PCAN_BaudRate = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[20] = "PCAN_BAUD_1M"] = 20;
        values[valuesById[22] = "PCAN_BAUD_800K"] = 22;
        values[valuesById[28] = "PCAN_BAUD_500K"] = 28;
        values[valuesById[284] = "PCAN_BAUD_250K"] = 284;
        values[valuesById[796] = "PCAN_BAUD_125K"] = 796;
        values[valuesById[17199] = "PCAN_BAUD_100K"] = 17199;
        values[valuesById[49998] = "PCAN_BAUD_95K"] = 49998;
        values[valuesById[34091] = "PCAN_BAUD_83K"] = 34091;
        values[valuesById[18223] = "PCAN_BAUD_50K"] = 18223;
        values[valuesById[5140] = "PCAN_BAUD_47K"] = 5140;
        values[valuesById[35631] = "PCAN_BAUD_33K"] = 35631;
        values[valuesById[21295] = "PCAN_BAUD_20K"] = 21295;
        values[valuesById[26415] = "PCAN_BAUD_10K"] = 26415;
        values[valuesById[32639] = "PCAN_BAUD_5K"] = 32639;
        return values;
    })();

    /**
     * PCAN_USB_Bus enum.
     * @name tool_commands.PCAN_USB_Bus
     * @enum {number}
     * @property {number} PCAN_USBBUS1=81 PCAN_USBBUS1 value
     * @property {number} PCAN_USBBUS2=82 PCAN_USBBUS2 value
     * @property {number} PCAN_USBBUS3=83 PCAN_USBBUS3 value
     * @property {number} PCAN_USBBUS4=84 PCAN_USBBUS4 value
     * @property {number} PCAN_USBBUS5=85 PCAN_USBBUS5 value
     * @property {number} PCAN_USBBUS6=86 PCAN_USBBUS6 value
     * @property {number} PCAN_USBBUS7=87 PCAN_USBBUS7 value
     * @property {number} PCAN_USBBUS8=88 PCAN_USBBUS8 value
     * @property {number} PCAN_USBBUS9=1289 PCAN_USBBUS9 value
     * @property {number} PCAN_USBBUS10=1290 PCAN_USBBUS10 value
     * @property {number} PCAN_USBBUS11=1291 PCAN_USBBUS11 value
     * @property {number} PCAN_USBBUS12=1292 PCAN_USBBUS12 value
     * @property {number} PCAN_USBBUS13=1293 PCAN_USBBUS13 value
     * @property {number} PCAN_USBBUS14=1294 PCAN_USBBUS14 value
     * @property {number} PCAN_USBBUS15=1295 PCAN_USBBUS15 value
     * @property {number} PCAN_USBBUS16=1296 PCAN_USBBUS16 value
     */
    tool_commands.PCAN_USB_Bus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[81] = "PCAN_USBBUS1"] = 81;
        values[valuesById[82] = "PCAN_USBBUS2"] = 82;
        values[valuesById[83] = "PCAN_USBBUS3"] = 83;
        values[valuesById[84] = "PCAN_USBBUS4"] = 84;
        values[valuesById[85] = "PCAN_USBBUS5"] = 85;
        values[valuesById[86] = "PCAN_USBBUS6"] = 86;
        values[valuesById[87] = "PCAN_USBBUS7"] = 87;
        values[valuesById[88] = "PCAN_USBBUS8"] = 88;
        values[valuesById[1289] = "PCAN_USBBUS9"] = 1289;
        values[valuesById[1290] = "PCAN_USBBUS10"] = 1290;
        values[valuesById[1291] = "PCAN_USBBUS11"] = 1291;
        values[valuesById[1292] = "PCAN_USBBUS12"] = 1292;
        values[valuesById[1293] = "PCAN_USBBUS13"] = 1293;
        values[valuesById[1294] = "PCAN_USBBUS14"] = 1294;
        values[valuesById[1295] = "PCAN_USBBUS15"] = 1295;
        values[valuesById[1296] = "PCAN_USBBUS16"] = 1296;
        return values;
    })();

    tool_commands.Wait = (function() {

        /**
         * Properties of a Wait.
         * @memberof tool_commands
         * @interface IWait
         * @property {number} ms Wait ms
         */

        /**
         * Constructs a new Wait.
         * @memberof tool_commands
         * @classdesc Represents a Wait.
         * @implements IWait
         * @constructor
         * @param {tool_commands.IWait=} [properties] Properties to set
         */
        function Wait(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Wait ms.
         * @member {number} ms
         * @memberof tool_commands.Wait
         * @instance
         */
        Wait.prototype.ms = 0;

        /**
         * Creates a new Wait instance using the specified properties.
         * @function create
         * @memberof tool_commands.Wait
         * @static
         * @param {tool_commands.IWait=} [properties] Properties to set
         * @returns {tool_commands.Wait} Wait instance
         */
        Wait.create = function create(properties) {
            return new Wait(properties);
        };

        /**
         * Encodes the specified Wait message. Does not implicitly {@link tool_commands.Wait.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.Wait
         * @static
         * @param {tool_commands.IWait} message Wait message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Wait.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.ms);
            return writer;
        };

        /**
         * Encodes the specified Wait message, length delimited. Does not implicitly {@link tool_commands.Wait.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.Wait
         * @static
         * @param {tool_commands.IWait} message Wait message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Wait.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Wait message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.Wait
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.Wait} Wait
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Wait.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.Wait();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ms = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("ms"))
                throw $util.ProtocolError("missing required 'ms'", { instance: message });
            return message;
        };

        /**
         * Decodes a Wait message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.Wait
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.Wait} Wait
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Wait.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Wait message.
         * @function verify
         * @memberof tool_commands.Wait
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Wait.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.ms))
                return "ms: integer expected";
            return null;
        };

        /**
         * Creates a Wait message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.Wait
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.Wait} Wait
         */
        Wait.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.Wait)
                return object;
            var message = new $root.tool_commands.Wait();
            if (object.ms != null)
                message.ms = object.ms >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Wait message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.Wait
         * @static
         * @param {tool_commands.Wait} message Wait
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Wait.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.ms = 0;
            if (message.ms != null && message.hasOwnProperty("ms"))
                object.ms = message.ms;
            return object;
        };

        /**
         * Converts this Wait to JSON.
         * @function toJSON
         * @memberof tool_commands.Wait
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Wait.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Wait
         * @function getTypeUrl
         * @memberof tool_commands.Wait
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Wait.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.Wait";
        };

        return Wait;
    })();

    tool_commands.MyEmpty = (function() {

        /**
         * Properties of a MyEmpty.
         * @memberof tool_commands
         * @interface IMyEmpty
         */

        /**
         * Constructs a new MyEmpty.
         * @memberof tool_commands
         * @classdesc Represents a MyEmpty.
         * @implements IMyEmpty
         * @constructor
         * @param {tool_commands.IMyEmpty=} [properties] Properties to set
         */
        function MyEmpty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MyEmpty instance using the specified properties.
         * @function create
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {tool_commands.IMyEmpty=} [properties] Properties to set
         * @returns {tool_commands.MyEmpty} MyEmpty instance
         */
        MyEmpty.create = function create(properties) {
            return new MyEmpty(properties);
        };

        /**
         * Encodes the specified MyEmpty message. Does not implicitly {@link tool_commands.MyEmpty.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {tool_commands.IMyEmpty} message MyEmpty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MyEmpty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MyEmpty message, length delimited. Does not implicitly {@link tool_commands.MyEmpty.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {tool_commands.IMyEmpty} message MyEmpty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MyEmpty.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MyEmpty message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.MyEmpty} MyEmpty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MyEmpty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.MyEmpty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MyEmpty message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.MyEmpty} MyEmpty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MyEmpty.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MyEmpty message.
         * @function verify
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MyEmpty.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MyEmpty message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.MyEmpty} MyEmpty
         */
        MyEmpty.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.MyEmpty)
                return object;
            return new $root.tool_commands.MyEmpty();
        };

        /**
         * Creates a plain object from a MyEmpty message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {tool_commands.MyEmpty} message MyEmpty
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MyEmpty.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MyEmpty to JSON.
         * @function toJSON
         * @memberof tool_commands.MyEmpty
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MyEmpty.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MyEmpty
         * @function getTypeUrl
         * @memberof tool_commands.MyEmpty
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MyEmpty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.MyEmpty";
        };

        return MyEmpty;
    })();

    tool_commands.StartUpdateSessionResponse = (function() {

        /**
         * Properties of a StartUpdateSessionResponse.
         * @memberof tool_commands
         * @interface IStartUpdateSessionResponse
         * @property {number|Long} token StartUpdateSessionResponse token
         */

        /**
         * Constructs a new StartUpdateSessionResponse.
         * @memberof tool_commands
         * @classdesc Represents a StartUpdateSessionResponse.
         * @implements IStartUpdateSessionResponse
         * @constructor
         * @param {tool_commands.IStartUpdateSessionResponse=} [properties] Properties to set
         */
        function StartUpdateSessionResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StartUpdateSessionResponse token.
         * @member {number|Long} token
         * @memberof tool_commands.StartUpdateSessionResponse
         * @instance
         */
        StartUpdateSessionResponse.prototype.token = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new StartUpdateSessionResponse instance using the specified properties.
         * @function create
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {tool_commands.IStartUpdateSessionResponse=} [properties] Properties to set
         * @returns {tool_commands.StartUpdateSessionResponse} StartUpdateSessionResponse instance
         */
        StartUpdateSessionResponse.create = function create(properties) {
            return new StartUpdateSessionResponse(properties);
        };

        /**
         * Encodes the specified StartUpdateSessionResponse message. Does not implicitly {@link tool_commands.StartUpdateSessionResponse.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {tool_commands.IStartUpdateSessionResponse} message StartUpdateSessionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartUpdateSessionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.token);
            return writer;
        };

        /**
         * Encodes the specified StartUpdateSessionResponse message, length delimited. Does not implicitly {@link tool_commands.StartUpdateSessionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {tool_commands.IStartUpdateSessionResponse} message StartUpdateSessionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StartUpdateSessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StartUpdateSessionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.StartUpdateSessionResponse} StartUpdateSessionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartUpdateSessionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.StartUpdateSessionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("token"))
                throw $util.ProtocolError("missing required 'token'", { instance: message });
            return message;
        };

        /**
         * Decodes a StartUpdateSessionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.StartUpdateSessionResponse} StartUpdateSessionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StartUpdateSessionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StartUpdateSessionResponse message.
         * @function verify
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StartUpdateSessionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.token) && !(message.token && $util.isInteger(message.token.low) && $util.isInteger(message.token.high)))
                return "token: integer|Long expected";
            return null;
        };

        /**
         * Creates a StartUpdateSessionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.StartUpdateSessionResponse} StartUpdateSessionResponse
         */
        StartUpdateSessionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.StartUpdateSessionResponse)
                return object;
            var message = new $root.tool_commands.StartUpdateSessionResponse();
            if (object.token != null)
                if ($util.Long)
                    (message.token = $util.Long.fromValue(object.token)).unsigned = true;
                else if (typeof object.token === "string")
                    message.token = parseInt(object.token, 10);
                else if (typeof object.token === "number")
                    message.token = object.token;
                else if (typeof object.token === "object")
                    message.token = new $util.LongBits(object.token.low >>> 0, object.token.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a StartUpdateSessionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {tool_commands.StartUpdateSessionResponse} message StartUpdateSessionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StartUpdateSessionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.token = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.token = options.longs === String ? "0" : 0;
            if (message.token != null && message.hasOwnProperty("token"))
                if (typeof message.token === "number")
                    object.token = options.longs === String ? String(message.token) : message.token;
                else
                    object.token = options.longs === String ? $util.Long.prototype.toString.call(message.token) : options.longs === Number ? new $util.LongBits(message.token.low >>> 0, message.token.high >>> 0).toNumber(true) : message.token;
            return object;
        };

        /**
         * Converts this StartUpdateSessionResponse to JSON.
         * @function toJSON
         * @memberof tool_commands.StartUpdateSessionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StartUpdateSessionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StartUpdateSessionResponse
         * @function getTypeUrl
         * @memberof tool_commands.StartUpdateSessionResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StartUpdateSessionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.StartUpdateSessionResponse";
        };

        return StartUpdateSessionResponse;
    })();

    /**
     * VersionDataFormat enum.
     * @name tool_commands.VersionDataFormat
     * @enum {number}
     * @property {number} VER_NONE=0 VER_NONE value
     * @property {number} VER_ASCII=1 VER_ASCII value
     * @property {number} VER_FF=2 VER_FF value
     * @property {number} VER_HEX=3 VER_HEX value
     * @property {number} VER_IHUB=4 VER_IHUB value
     */
    tool_commands.VersionDataFormat = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VER_NONE"] = 0;
        values[valuesById[1] = "VER_ASCII"] = 1;
        values[valuesById[2] = "VER_FF"] = 2;
        values[valuesById[3] = "VER_HEX"] = 3;
        values[valuesById[4] = "VER_IHUB"] = 4;
        return values;
    })();

    tool_commands.VersionDIDInfo = (function() {

        /**
         * Properties of a VersionDIDInfo.
         * @memberof tool_commands
         * @interface IVersionDIDInfo
         * @property {number|null} [blDID] VersionDIDInfo blDID
         * @property {tool_commands.VersionDataFormat|null} [blFormat] VersionDIDInfo blFormat
         * @property {number|null} [appDID] VersionDIDInfo appDID
         * @property {tool_commands.VersionDataFormat|null} [appFormat] VersionDIDInfo appFormat
         */

        /**
         * Constructs a new VersionDIDInfo.
         * @memberof tool_commands
         * @classdesc Represents a VersionDIDInfo.
         * @implements IVersionDIDInfo
         * @constructor
         * @param {tool_commands.IVersionDIDInfo=} [properties] Properties to set
         */
        function VersionDIDInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionDIDInfo blDID.
         * @member {number} blDID
         * @memberof tool_commands.VersionDIDInfo
         * @instance
         */
        VersionDIDInfo.prototype.blDID = 61824;

        /**
         * VersionDIDInfo blFormat.
         * @member {tool_commands.VersionDataFormat} blFormat
         * @memberof tool_commands.VersionDIDInfo
         * @instance
         */
        VersionDIDInfo.prototype.blFormat = 2;

        /**
         * VersionDIDInfo appDID.
         * @member {number} appDID
         * @memberof tool_commands.VersionDIDInfo
         * @instance
         */
        VersionDIDInfo.prototype.appDID = 61825;

        /**
         * VersionDIDInfo appFormat.
         * @member {tool_commands.VersionDataFormat} appFormat
         * @memberof tool_commands.VersionDIDInfo
         * @instance
         */
        VersionDIDInfo.prototype.appFormat = 2;

        /**
         * Creates a new VersionDIDInfo instance using the specified properties.
         * @function create
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {tool_commands.IVersionDIDInfo=} [properties] Properties to set
         * @returns {tool_commands.VersionDIDInfo} VersionDIDInfo instance
         */
        VersionDIDInfo.create = function create(properties) {
            return new VersionDIDInfo(properties);
        };

        /**
         * Encodes the specified VersionDIDInfo message. Does not implicitly {@link tool_commands.VersionDIDInfo.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {tool_commands.IVersionDIDInfo} message VersionDIDInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionDIDInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blDID != null && Object.hasOwnProperty.call(message, "blDID"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.blDID);
            if (message.blFormat != null && Object.hasOwnProperty.call(message, "blFormat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.blFormat);
            if (message.appDID != null && Object.hasOwnProperty.call(message, "appDID"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.appDID);
            if (message.appFormat != null && Object.hasOwnProperty.call(message, "appFormat"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.appFormat);
            return writer;
        };

        /**
         * Encodes the specified VersionDIDInfo message, length delimited. Does not implicitly {@link tool_commands.VersionDIDInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {tool_commands.IVersionDIDInfo} message VersionDIDInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionDIDInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionDIDInfo message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.VersionDIDInfo} VersionDIDInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionDIDInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.VersionDIDInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.blDID = reader.uint32();
                        break;
                    }
                case 2: {
                        message.blFormat = reader.int32();
                        break;
                    }
                case 3: {
                        message.appDID = reader.uint32();
                        break;
                    }
                case 4: {
                        message.appFormat = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionDIDInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.VersionDIDInfo} VersionDIDInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionDIDInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionDIDInfo message.
         * @function verify
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionDIDInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.blDID != null && message.hasOwnProperty("blDID"))
                if (!$util.isInteger(message.blDID))
                    return "blDID: integer expected";
            if (message.blFormat != null && message.hasOwnProperty("blFormat"))
                switch (message.blFormat) {
                default:
                    return "blFormat: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.appDID != null && message.hasOwnProperty("appDID"))
                if (!$util.isInteger(message.appDID))
                    return "appDID: integer expected";
            if (message.appFormat != null && message.hasOwnProperty("appFormat"))
                switch (message.appFormat) {
                default:
                    return "appFormat: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a VersionDIDInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.VersionDIDInfo} VersionDIDInfo
         */
        VersionDIDInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.VersionDIDInfo)
                return object;
            var message = new $root.tool_commands.VersionDIDInfo();
            if (object.blDID != null)
                message.blDID = object.blDID >>> 0;
            switch (object.blFormat) {
            case "VER_NONE":
            case 0:
                message.blFormat = 0;
                break;
            case "VER_ASCII":
            case 1:
                message.blFormat = 1;
                break;
            default:
                if (typeof object.blFormat === "number") {
                    message.blFormat = object.blFormat;
                    break;
                }
                break;
            case "VER_FF":
            case 2:
                message.blFormat = 2;
                break;
            case "VER_HEX":
            case 3:
                message.blFormat = 3;
                break;
            case "VER_IHUB":
            case 4:
                message.blFormat = 4;
                break;
            }
            if (object.appDID != null)
                message.appDID = object.appDID >>> 0;
            switch (object.appFormat) {
            case "VER_NONE":
            case 0:
                message.appFormat = 0;
                break;
            case "VER_ASCII":
            case 1:
                message.appFormat = 1;
                break;
            default:
                if (typeof object.appFormat === "number") {
                    message.appFormat = object.appFormat;
                    break;
                }
                break;
            case "VER_FF":
            case 2:
                message.appFormat = 2;
                break;
            case "VER_HEX":
            case 3:
                message.appFormat = 3;
                break;
            case "VER_IHUB":
            case 4:
                message.appFormat = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a VersionDIDInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {tool_commands.VersionDIDInfo} message VersionDIDInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionDIDInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.blDID = 61824;
                object.blFormat = options.enums === String ? "VER_FF" : 2;
                object.appDID = 61825;
                object.appFormat = options.enums === String ? "VER_FF" : 2;
            }
            if (message.blDID != null && message.hasOwnProperty("blDID"))
                object.blDID = message.blDID;
            if (message.blFormat != null && message.hasOwnProperty("blFormat"))
                object.blFormat = options.enums === String ? $root.tool_commands.VersionDataFormat[message.blFormat] === undefined ? message.blFormat : $root.tool_commands.VersionDataFormat[message.blFormat] : message.blFormat;
            if (message.appDID != null && message.hasOwnProperty("appDID"))
                object.appDID = message.appDID;
            if (message.appFormat != null && message.hasOwnProperty("appFormat"))
                object.appFormat = options.enums === String ? $root.tool_commands.VersionDataFormat[message.appFormat] === undefined ? message.appFormat : $root.tool_commands.VersionDataFormat[message.appFormat] : message.appFormat;
            return object;
        };

        /**
         * Converts this VersionDIDInfo to JSON.
         * @function toJSON
         * @memberof tool_commands.VersionDIDInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionDIDInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionDIDInfo
         * @function getTypeUrl
         * @memberof tool_commands.VersionDIDInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionDIDInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.VersionDIDInfo";
        };

        return VersionDIDInfo;
    })();

    tool_commands.ExistingECU = (function() {

        /**
         * Properties of an ExistingECU.
         * @memberof tool_commands
         * @interface IExistingECU
         * @property {string} name ExistingECU name
         * @property {number|null} [id] ExistingECU id
         * @property {string|null} [revisionId] ExistingECU revisionId
         * @property {boolean|null} [isSelected] ExistingECU isSelected
         * @property {boolean|null} [hasValidPackage] ExistingECU hasValidPackage
         * @property {number|null} [linNode] ExistingECU linNode
         * @property {string|null} [packageId] ExistingECU packageId
         * @property {number|null} [udsRequestId] ExistingECU udsRequestId
         * @property {number|null} [udsResponseId] ExistingECU udsResponseId
         * @property {string|null} [revisionName] ExistingECU revisionName
         * @property {number|null} [flashOrder] ExistingECU flashOrder
         * @property {boolean|null} [supportDTCRead] ExistingECU supportDTCRead
         * @property {Array.<string>|null} [availablePckVer] ExistingECU availablePckVer
         * @property {boolean|null} [extendedCan] ExistingECU extendedCan
         * @property {tool_commands.IVersionDIDInfo|null} [versionDIDInfo] ExistingECU versionDIDInfo
         */

        /**
         * Constructs a new ExistingECU.
         * @memberof tool_commands
         * @classdesc Represents an ExistingECU.
         * @implements IExistingECU
         * @constructor
         * @param {tool_commands.IExistingECU=} [properties] Properties to set
         */
        function ExistingECU(properties) {
            this.availablePckVer = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExistingECU name.
         * @member {string} name
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.name = "";

        /**
         * ExistingECU id.
         * @member {number} id
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.id = 0;

        /**
         * ExistingECU revisionId.
         * @member {string} revisionId
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.revisionId = "";

        /**
         * ExistingECU isSelected.
         * @member {boolean} isSelected
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.isSelected = false;

        /**
         * ExistingECU hasValidPackage.
         * @member {boolean} hasValidPackage
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.hasValidPackage = false;

        /**
         * ExistingECU linNode.
         * @member {number} linNode
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.linNode = 0;

        /**
         * ExistingECU packageId.
         * @member {string} packageId
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.packageId = "";

        /**
         * ExistingECU udsRequestId.
         * @member {number} udsRequestId
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.udsRequestId = 0;

        /**
         * ExistingECU udsResponseId.
         * @member {number} udsResponseId
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.udsResponseId = 0;

        /**
         * ExistingECU revisionName.
         * @member {string} revisionName
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.revisionName = "";

        /**
         * ExistingECU flashOrder.
         * @member {number} flashOrder
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.flashOrder = 0;

        /**
         * ExistingECU supportDTCRead.
         * @member {boolean} supportDTCRead
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.supportDTCRead = false;

        /**
         * ExistingECU availablePckVer.
         * @member {Array.<string>} availablePckVer
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.availablePckVer = $util.emptyArray;

        /**
         * ExistingECU extendedCan.
         * @member {boolean} extendedCan
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.extendedCan = false;

        /**
         * ExistingECU versionDIDInfo.
         * @member {tool_commands.IVersionDIDInfo|null|undefined} versionDIDInfo
         * @memberof tool_commands.ExistingECU
         * @instance
         */
        ExistingECU.prototype.versionDIDInfo = null;

        /**
         * Creates a new ExistingECU instance using the specified properties.
         * @function create
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {tool_commands.IExistingECU=} [properties] Properties to set
         * @returns {tool_commands.ExistingECU} ExistingECU instance
         */
        ExistingECU.create = function create(properties) {
            return new ExistingECU(properties);
        };

        /**
         * Encodes the specified ExistingECU message. Does not implicitly {@link tool_commands.ExistingECU.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {tool_commands.IExistingECU} message ExistingECU message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExistingECU.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            if (message.revisionId != null && Object.hasOwnProperty.call(message, "revisionId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.revisionId);
            if (message.isSelected != null && Object.hasOwnProperty.call(message, "isSelected"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isSelected);
            if (message.hasValidPackage != null && Object.hasOwnProperty.call(message, "hasValidPackage"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.hasValidPackage);
            if (message.linNode != null && Object.hasOwnProperty.call(message, "linNode"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.linNode);
            if (message.packageId != null && Object.hasOwnProperty.call(message, "packageId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.packageId);
            if (message.udsRequestId != null && Object.hasOwnProperty.call(message, "udsRequestId"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.udsRequestId);
            if (message.udsResponseId != null && Object.hasOwnProperty.call(message, "udsResponseId"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.udsResponseId);
            if (message.revisionName != null && Object.hasOwnProperty.call(message, "revisionName"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.revisionName);
            if (message.flashOrder != null && Object.hasOwnProperty.call(message, "flashOrder"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.flashOrder);
            if (message.supportDTCRead != null && Object.hasOwnProperty.call(message, "supportDTCRead"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.supportDTCRead);
            if (message.availablePckVer != null && message.availablePckVer.length)
                for (var i = 0; i < message.availablePckVer.length; ++i)
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.availablePckVer[i]);
            if (message.extendedCan != null && Object.hasOwnProperty.call(message, "extendedCan"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.extendedCan);
            if (message.versionDIDInfo != null && Object.hasOwnProperty.call(message, "versionDIDInfo"))
                $root.tool_commands.VersionDIDInfo.encode(message.versionDIDInfo, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExistingECU message, length delimited. Does not implicitly {@link tool_commands.ExistingECU.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {tool_commands.IExistingECU} message ExistingECU message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExistingECU.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExistingECU message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ExistingECU} ExistingECU
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExistingECU.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ExistingECU();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.id = reader.uint32();
                        break;
                    }
                case 3: {
                        message.revisionId = reader.string();
                        break;
                    }
                case 4: {
                        message.isSelected = reader.bool();
                        break;
                    }
                case 5: {
                        message.hasValidPackage = reader.bool();
                        break;
                    }
                case 7: {
                        message.linNode = reader.uint32();
                        break;
                    }
                case 8: {
                        message.packageId = reader.string();
                        break;
                    }
                case 9: {
                        message.udsRequestId = reader.uint32();
                        break;
                    }
                case 10: {
                        message.udsResponseId = reader.uint32();
                        break;
                    }
                case 11: {
                        message.revisionName = reader.string();
                        break;
                    }
                case 12: {
                        message.flashOrder = reader.uint32();
                        break;
                    }
                case 13: {
                        message.supportDTCRead = reader.bool();
                        break;
                    }
                case 20: {
                        if (!(message.availablePckVer && message.availablePckVer.length))
                            message.availablePckVer = [];
                        message.availablePckVer.push(reader.string());
                        break;
                    }
                case 21: {
                        message.extendedCan = reader.bool();
                        break;
                    }
                case 22: {
                        message.versionDIDInfo = $root.tool_commands.VersionDIDInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes an ExistingECU message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ExistingECU} ExistingECU
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExistingECU.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExistingECU message.
         * @function verify
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExistingECU.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.revisionId != null && message.hasOwnProperty("revisionId"))
                if (!$util.isString(message.revisionId))
                    return "revisionId: string expected";
            if (message.isSelected != null && message.hasOwnProperty("isSelected"))
                if (typeof message.isSelected !== "boolean")
                    return "isSelected: boolean expected";
            if (message.hasValidPackage != null && message.hasOwnProperty("hasValidPackage"))
                if (typeof message.hasValidPackage !== "boolean")
                    return "hasValidPackage: boolean expected";
            if (message.linNode != null && message.hasOwnProperty("linNode"))
                if (!$util.isInteger(message.linNode))
                    return "linNode: integer expected";
            if (message.packageId != null && message.hasOwnProperty("packageId"))
                if (!$util.isString(message.packageId))
                    return "packageId: string expected";
            if (message.udsRequestId != null && message.hasOwnProperty("udsRequestId"))
                if (!$util.isInteger(message.udsRequestId))
                    return "udsRequestId: integer expected";
            if (message.udsResponseId != null && message.hasOwnProperty("udsResponseId"))
                if (!$util.isInteger(message.udsResponseId))
                    return "udsResponseId: integer expected";
            if (message.revisionName != null && message.hasOwnProperty("revisionName"))
                if (!$util.isString(message.revisionName))
                    return "revisionName: string expected";
            if (message.flashOrder != null && message.hasOwnProperty("flashOrder"))
                if (!$util.isInteger(message.flashOrder))
                    return "flashOrder: integer expected";
            if (message.supportDTCRead != null && message.hasOwnProperty("supportDTCRead"))
                if (typeof message.supportDTCRead !== "boolean")
                    return "supportDTCRead: boolean expected";
            if (message.availablePckVer != null && message.hasOwnProperty("availablePckVer")) {
                if (!Array.isArray(message.availablePckVer))
                    return "availablePckVer: array expected";
                for (var i = 0; i < message.availablePckVer.length; ++i)
                    if (!$util.isString(message.availablePckVer[i]))
                        return "availablePckVer: string[] expected";
            }
            if (message.extendedCan != null && message.hasOwnProperty("extendedCan"))
                if (typeof message.extendedCan !== "boolean")
                    return "extendedCan: boolean expected";
            if (message.versionDIDInfo != null && message.hasOwnProperty("versionDIDInfo")) {
                var error = $root.tool_commands.VersionDIDInfo.verify(message.versionDIDInfo);
                if (error)
                    return "versionDIDInfo." + error;
            }
            return null;
        };

        /**
         * Creates an ExistingECU message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ExistingECU} ExistingECU
         */
        ExistingECU.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ExistingECU)
                return object;
            var message = new $root.tool_commands.ExistingECU();
            if (object.name != null)
                message.name = String(object.name);
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.revisionId != null)
                message.revisionId = String(object.revisionId);
            if (object.isSelected != null)
                message.isSelected = Boolean(object.isSelected);
            if (object.hasValidPackage != null)
                message.hasValidPackage = Boolean(object.hasValidPackage);
            if (object.linNode != null)
                message.linNode = object.linNode >>> 0;
            if (object.packageId != null)
                message.packageId = String(object.packageId);
            if (object.udsRequestId != null)
                message.udsRequestId = object.udsRequestId >>> 0;
            if (object.udsResponseId != null)
                message.udsResponseId = object.udsResponseId >>> 0;
            if (object.revisionName != null)
                message.revisionName = String(object.revisionName);
            if (object.flashOrder != null)
                message.flashOrder = object.flashOrder >>> 0;
            if (object.supportDTCRead != null)
                message.supportDTCRead = Boolean(object.supportDTCRead);
            if (object.availablePckVer) {
                if (!Array.isArray(object.availablePckVer))
                    throw TypeError(".tool_commands.ExistingECU.availablePckVer: array expected");
                message.availablePckVer = [];
                for (var i = 0; i < object.availablePckVer.length; ++i)
                    message.availablePckVer[i] = String(object.availablePckVer[i]);
            }
            if (object.extendedCan != null)
                message.extendedCan = Boolean(object.extendedCan);
            if (object.versionDIDInfo != null) {
                if (typeof object.versionDIDInfo !== "object")
                    throw TypeError(".tool_commands.ExistingECU.versionDIDInfo: object expected");
                message.versionDIDInfo = $root.tool_commands.VersionDIDInfo.fromObject(object.versionDIDInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExistingECU message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {tool_commands.ExistingECU} message ExistingECU
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExistingECU.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.availablePckVer = [];
            if (options.defaults) {
                object.name = "";
                object.id = 0;
                object.revisionId = "";
                object.isSelected = false;
                object.hasValidPackage = false;
                object.linNode = 0;
                object.packageId = "";
                object.udsRequestId = 0;
                object.udsResponseId = 0;
                object.revisionName = "";
                object.flashOrder = 0;
                object.supportDTCRead = false;
                object.extendedCan = false;
                object.versionDIDInfo = null;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.revisionId != null && message.hasOwnProperty("revisionId"))
                object.revisionId = message.revisionId;
            if (message.isSelected != null && message.hasOwnProperty("isSelected"))
                object.isSelected = message.isSelected;
            if (message.hasValidPackage != null && message.hasOwnProperty("hasValidPackage"))
                object.hasValidPackage = message.hasValidPackage;
            if (message.linNode != null && message.hasOwnProperty("linNode"))
                object.linNode = message.linNode;
            if (message.packageId != null && message.hasOwnProperty("packageId"))
                object.packageId = message.packageId;
            if (message.udsRequestId != null && message.hasOwnProperty("udsRequestId"))
                object.udsRequestId = message.udsRequestId;
            if (message.udsResponseId != null && message.hasOwnProperty("udsResponseId"))
                object.udsResponseId = message.udsResponseId;
            if (message.revisionName != null && message.hasOwnProperty("revisionName"))
                object.revisionName = message.revisionName;
            if (message.flashOrder != null && message.hasOwnProperty("flashOrder"))
                object.flashOrder = message.flashOrder;
            if (message.supportDTCRead != null && message.hasOwnProperty("supportDTCRead"))
                object.supportDTCRead = message.supportDTCRead;
            if (message.availablePckVer && message.availablePckVer.length) {
                object.availablePckVer = [];
                for (var j = 0; j < message.availablePckVer.length; ++j)
                    object.availablePckVer[j] = message.availablePckVer[j];
            }
            if (message.extendedCan != null && message.hasOwnProperty("extendedCan"))
                object.extendedCan = message.extendedCan;
            if (message.versionDIDInfo != null && message.hasOwnProperty("versionDIDInfo"))
                object.versionDIDInfo = $root.tool_commands.VersionDIDInfo.toObject(message.versionDIDInfo, options);
            return object;
        };

        /**
         * Converts this ExistingECU to JSON.
         * @function toJSON
         * @memberof tool_commands.ExistingECU
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExistingECU.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExistingECU
         * @function getTypeUrl
         * @memberof tool_commands.ExistingECU
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExistingECU.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ExistingECU";
        };

        return ExistingECU;
    })();

    tool_commands.SelectedECUs = (function() {

        /**
         * Properties of a SelectedECUs.
         * @memberof tool_commands
         * @interface ISelectedECUs
         * @property {Array.<tool_commands.IExistingECU>|null} [ecus] SelectedECUs ecus
         * @property {string|null} [root] SelectedECUs root
         */

        /**
         * Constructs a new SelectedECUs.
         * @memberof tool_commands
         * @classdesc Represents a SelectedECUs.
         * @implements ISelectedECUs
         * @constructor
         * @param {tool_commands.ISelectedECUs=} [properties] Properties to set
         */
        function SelectedECUs(properties) {
            this.ecus = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SelectedECUs ecus.
         * @member {Array.<tool_commands.IExistingECU>} ecus
         * @memberof tool_commands.SelectedECUs
         * @instance
         */
        SelectedECUs.prototype.ecus = $util.emptyArray;

        /**
         * SelectedECUs root.
         * @member {string} root
         * @memberof tool_commands.SelectedECUs
         * @instance
         */
        SelectedECUs.prototype.root = "";

        /**
         * Creates a new SelectedECUs instance using the specified properties.
         * @function create
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {tool_commands.ISelectedECUs=} [properties] Properties to set
         * @returns {tool_commands.SelectedECUs} SelectedECUs instance
         */
        SelectedECUs.create = function create(properties) {
            return new SelectedECUs(properties);
        };

        /**
         * Encodes the specified SelectedECUs message. Does not implicitly {@link tool_commands.SelectedECUs.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {tool_commands.ISelectedECUs} message SelectedECUs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectedECUs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ecus != null && message.ecus.length)
                for (var i = 0; i < message.ecus.length; ++i)
                    $root.tool_commands.ExistingECU.encode(message.ecus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.root != null && Object.hasOwnProperty.call(message, "root"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.root);
            return writer;
        };

        /**
         * Encodes the specified SelectedECUs message, length delimited. Does not implicitly {@link tool_commands.SelectedECUs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {tool_commands.ISelectedECUs} message SelectedECUs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectedECUs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SelectedECUs message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SelectedECUs} SelectedECUs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectedECUs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SelectedECUs();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ecus && message.ecus.length))
                            message.ecus = [];
                        message.ecus.push($root.tool_commands.ExistingECU.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.root = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SelectedECUs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SelectedECUs} SelectedECUs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectedECUs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SelectedECUs message.
         * @function verify
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SelectedECUs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ecus != null && message.hasOwnProperty("ecus")) {
                if (!Array.isArray(message.ecus))
                    return "ecus: array expected";
                for (var i = 0; i < message.ecus.length; ++i) {
                    var error = $root.tool_commands.ExistingECU.verify(message.ecus[i]);
                    if (error)
                        return "ecus." + error;
                }
            }
            if (message.root != null && message.hasOwnProperty("root"))
                if (!$util.isString(message.root))
                    return "root: string expected";
            return null;
        };

        /**
         * Creates a SelectedECUs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SelectedECUs} SelectedECUs
         */
        SelectedECUs.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SelectedECUs)
                return object;
            var message = new $root.tool_commands.SelectedECUs();
            if (object.ecus) {
                if (!Array.isArray(object.ecus))
                    throw TypeError(".tool_commands.SelectedECUs.ecus: array expected");
                message.ecus = [];
                for (var i = 0; i < object.ecus.length; ++i) {
                    if (typeof object.ecus[i] !== "object")
                        throw TypeError(".tool_commands.SelectedECUs.ecus: object expected");
                    message.ecus[i] = $root.tool_commands.ExistingECU.fromObject(object.ecus[i]);
                }
            }
            if (object.root != null)
                message.root = String(object.root);
            return message;
        };

        /**
         * Creates a plain object from a SelectedECUs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {tool_commands.SelectedECUs} message SelectedECUs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SelectedECUs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ecus = [];
            if (options.defaults)
                object.root = "";
            if (message.ecus && message.ecus.length) {
                object.ecus = [];
                for (var j = 0; j < message.ecus.length; ++j)
                    object.ecus[j] = $root.tool_commands.ExistingECU.toObject(message.ecus[j], options);
            }
            if (message.root != null && message.hasOwnProperty("root"))
                object.root = message.root;
            return object;
        };

        /**
         * Converts this SelectedECUs to JSON.
         * @function toJSON
         * @memberof tool_commands.SelectedECUs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SelectedECUs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SelectedECUs
         * @function getTypeUrl
         * @memberof tool_commands.SelectedECUs
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SelectedECUs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SelectedECUs";
        };

        return SelectedECUs;
    })();

    tool_commands.ECUsOnBus = (function() {

        /**
         * Properties of a ECUsOnBus.
         * @memberof tool_commands
         * @interface IECUsOnBus
         * @property {string} name ECUsOnBus name
         * @property {number} busId ECUsOnBus busId
         * @property {Array.<tool_commands.IExistingECU>|null} [ecuList] ECUsOnBus ecuList
         */

        /**
         * Constructs a new ECUsOnBus.
         * @memberof tool_commands
         * @classdesc Represents a ECUsOnBus.
         * @implements IECUsOnBus
         * @constructor
         * @param {tool_commands.IECUsOnBus=} [properties] Properties to set
         */
        function ECUsOnBus(properties) {
            this.ecuList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ECUsOnBus name.
         * @member {string} name
         * @memberof tool_commands.ECUsOnBus
         * @instance
         */
        ECUsOnBus.prototype.name = "";

        /**
         * ECUsOnBus busId.
         * @member {number} busId
         * @memberof tool_commands.ECUsOnBus
         * @instance
         */
        ECUsOnBus.prototype.busId = 0;

        /**
         * ECUsOnBus ecuList.
         * @member {Array.<tool_commands.IExistingECU>} ecuList
         * @memberof tool_commands.ECUsOnBus
         * @instance
         */
        ECUsOnBus.prototype.ecuList = $util.emptyArray;

        /**
         * Creates a new ECUsOnBus instance using the specified properties.
         * @function create
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {tool_commands.IECUsOnBus=} [properties] Properties to set
         * @returns {tool_commands.ECUsOnBus} ECUsOnBus instance
         */
        ECUsOnBus.create = function create(properties) {
            return new ECUsOnBus(properties);
        };

        /**
         * Encodes the specified ECUsOnBus message. Does not implicitly {@link tool_commands.ECUsOnBus.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {tool_commands.IECUsOnBus} message ECUsOnBus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsOnBus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.busId);
            if (message.ecuList != null && message.ecuList.length)
                for (var i = 0; i < message.ecuList.length; ++i)
                    $root.tool_commands.ExistingECU.encode(message.ecuList[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ECUsOnBus message, length delimited. Does not implicitly {@link tool_commands.ECUsOnBus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {tool_commands.IECUsOnBus} message ECUsOnBus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsOnBus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ECUsOnBus message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ECUsOnBus} ECUsOnBus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsOnBus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ECUsOnBus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.busId = reader.uint32();
                        break;
                    }
                case 3: {
                        if (!(message.ecuList && message.ecuList.length))
                            message.ecuList = [];
                        message.ecuList.push($root.tool_commands.ExistingECU.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("busId"))
                throw $util.ProtocolError("missing required 'busId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ECUsOnBus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ECUsOnBus} ECUsOnBus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsOnBus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ECUsOnBus message.
         * @function verify
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ECUsOnBus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isInteger(message.busId))
                return "busId: integer expected";
            if (message.ecuList != null && message.hasOwnProperty("ecuList")) {
                if (!Array.isArray(message.ecuList))
                    return "ecuList: array expected";
                for (var i = 0; i < message.ecuList.length; ++i) {
                    var error = $root.tool_commands.ExistingECU.verify(message.ecuList[i]);
                    if (error)
                        return "ecuList." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ECUsOnBus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ECUsOnBus} ECUsOnBus
         */
        ECUsOnBus.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ECUsOnBus)
                return object;
            var message = new $root.tool_commands.ECUsOnBus();
            if (object.name != null)
                message.name = String(object.name);
            if (object.busId != null)
                message.busId = object.busId >>> 0;
            if (object.ecuList) {
                if (!Array.isArray(object.ecuList))
                    throw TypeError(".tool_commands.ECUsOnBus.ecuList: array expected");
                message.ecuList = [];
                for (var i = 0; i < object.ecuList.length; ++i) {
                    if (typeof object.ecuList[i] !== "object")
                        throw TypeError(".tool_commands.ECUsOnBus.ecuList: object expected");
                    message.ecuList[i] = $root.tool_commands.ExistingECU.fromObject(object.ecuList[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ECUsOnBus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {tool_commands.ECUsOnBus} message ECUsOnBus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ECUsOnBus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ecuList = [];
            if (options.defaults) {
                object.name = "";
                object.busId = 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.busId != null && message.hasOwnProperty("busId"))
                object.busId = message.busId;
            if (message.ecuList && message.ecuList.length) {
                object.ecuList = [];
                for (var j = 0; j < message.ecuList.length; ++j)
                    object.ecuList[j] = $root.tool_commands.ExistingECU.toObject(message.ecuList[j], options);
            }
            return object;
        };

        /**
         * Converts this ECUsOnBus to JSON.
         * @function toJSON
         * @memberof tool_commands.ECUsOnBus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ECUsOnBus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ECUsOnBus
         * @function getTypeUrl
         * @memberof tool_commands.ECUsOnBus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ECUsOnBus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ECUsOnBus";
        };

        return ECUsOnBus;
    })();

    tool_commands.ECUsInDomain = (function() {

        /**
         * Properties of a ECUsInDomain.
         * @memberof tool_commands
         * @interface IECUsInDomain
         * @property {string} name ECUsInDomain name
         * @property {Array.<tool_commands.IECUsOnBus>|null} [busList] ECUsInDomain busList
         * @property {string|null} [revisionName] ECUsInDomain revisionName
         */

        /**
         * Constructs a new ECUsInDomain.
         * @memberof tool_commands
         * @classdesc Represents a ECUsInDomain.
         * @implements IECUsInDomain
         * @constructor
         * @param {tool_commands.IECUsInDomain=} [properties] Properties to set
         */
        function ECUsInDomain(properties) {
            this.busList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ECUsInDomain name.
         * @member {string} name
         * @memberof tool_commands.ECUsInDomain
         * @instance
         */
        ECUsInDomain.prototype.name = "";

        /**
         * ECUsInDomain busList.
         * @member {Array.<tool_commands.IECUsOnBus>} busList
         * @memberof tool_commands.ECUsInDomain
         * @instance
         */
        ECUsInDomain.prototype.busList = $util.emptyArray;

        /**
         * ECUsInDomain revisionName.
         * @member {string} revisionName
         * @memberof tool_commands.ECUsInDomain
         * @instance
         */
        ECUsInDomain.prototype.revisionName = "";

        /**
         * Creates a new ECUsInDomain instance using the specified properties.
         * @function create
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {tool_commands.IECUsInDomain=} [properties] Properties to set
         * @returns {tool_commands.ECUsInDomain} ECUsInDomain instance
         */
        ECUsInDomain.create = function create(properties) {
            return new ECUsInDomain(properties);
        };

        /**
         * Encodes the specified ECUsInDomain message. Does not implicitly {@link tool_commands.ECUsInDomain.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {tool_commands.IECUsInDomain} message ECUsInDomain message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsInDomain.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.busList != null && message.busList.length)
                for (var i = 0; i < message.busList.length; ++i)
                    $root.tool_commands.ECUsOnBus.encode(message.busList[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.revisionName != null && Object.hasOwnProperty.call(message, "revisionName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.revisionName);
            return writer;
        };

        /**
         * Encodes the specified ECUsInDomain message, length delimited. Does not implicitly {@link tool_commands.ECUsInDomain.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {tool_commands.IECUsInDomain} message ECUsInDomain message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsInDomain.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ECUsInDomain message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ECUsInDomain} ECUsInDomain
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsInDomain.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ECUsInDomain();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.busList && message.busList.length))
                            message.busList = [];
                        message.busList.push($root.tool_commands.ECUsOnBus.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.revisionName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a ECUsInDomain message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ECUsInDomain} ECUsInDomain
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsInDomain.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ECUsInDomain message.
         * @function verify
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ECUsInDomain.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.busList != null && message.hasOwnProperty("busList")) {
                if (!Array.isArray(message.busList))
                    return "busList: array expected";
                for (var i = 0; i < message.busList.length; ++i) {
                    var error = $root.tool_commands.ECUsOnBus.verify(message.busList[i]);
                    if (error)
                        return "busList." + error;
                }
            }
            if (message.revisionName != null && message.hasOwnProperty("revisionName"))
                if (!$util.isString(message.revisionName))
                    return "revisionName: string expected";
            return null;
        };

        /**
         * Creates a ECUsInDomain message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ECUsInDomain} ECUsInDomain
         */
        ECUsInDomain.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ECUsInDomain)
                return object;
            var message = new $root.tool_commands.ECUsInDomain();
            if (object.name != null)
                message.name = String(object.name);
            if (object.busList) {
                if (!Array.isArray(object.busList))
                    throw TypeError(".tool_commands.ECUsInDomain.busList: array expected");
                message.busList = [];
                for (var i = 0; i < object.busList.length; ++i) {
                    if (typeof object.busList[i] !== "object")
                        throw TypeError(".tool_commands.ECUsInDomain.busList: object expected");
                    message.busList[i] = $root.tool_commands.ECUsOnBus.fromObject(object.busList[i]);
                }
            }
            if (object.revisionName != null)
                message.revisionName = String(object.revisionName);
            return message;
        };

        /**
         * Creates a plain object from a ECUsInDomain message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {tool_commands.ECUsInDomain} message ECUsInDomain
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ECUsInDomain.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.busList = [];
            if (options.defaults) {
                object.name = "";
                object.revisionName = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.busList && message.busList.length) {
                object.busList = [];
                for (var j = 0; j < message.busList.length; ++j)
                    object.busList[j] = $root.tool_commands.ECUsOnBus.toObject(message.busList[j], options);
            }
            if (message.revisionName != null && message.hasOwnProperty("revisionName"))
                object.revisionName = message.revisionName;
            return object;
        };

        /**
         * Converts this ECUsInDomain to JSON.
         * @function toJSON
         * @memberof tool_commands.ECUsInDomain
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ECUsInDomain.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ECUsInDomain
         * @function getTypeUrl
         * @memberof tool_commands.ECUsInDomain
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ECUsInDomain.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ECUsInDomain";
        };

        return ECUsInDomain;
    })();

    tool_commands.ECUsInVehicle = (function() {

        /**
         * Properties of a ECUsInVehicle.
         * @memberof tool_commands
         * @interface IECUsInVehicle
         * @property {string|null} [revision] ECUsInVehicle revision
         * @property {string|null} [release] ECUsInVehicle release
         * @property {Array.<tool_commands.IECUsInDomain>|null} [domains] ECUsInVehicle domains
         */

        /**
         * Constructs a new ECUsInVehicle.
         * @memberof tool_commands
         * @classdesc Represents a ECUsInVehicle.
         * @implements IECUsInVehicle
         * @constructor
         * @param {tool_commands.IECUsInVehicle=} [properties] Properties to set
         */
        function ECUsInVehicle(properties) {
            this.domains = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ECUsInVehicle revision.
         * @member {string} revision
         * @memberof tool_commands.ECUsInVehicle
         * @instance
         */
        ECUsInVehicle.prototype.revision = "";

        /**
         * ECUsInVehicle release.
         * @member {string} release
         * @memberof tool_commands.ECUsInVehicle
         * @instance
         */
        ECUsInVehicle.prototype.release = "";

        /**
         * ECUsInVehicle domains.
         * @member {Array.<tool_commands.IECUsInDomain>} domains
         * @memberof tool_commands.ECUsInVehicle
         * @instance
         */
        ECUsInVehicle.prototype.domains = $util.emptyArray;

        /**
         * Creates a new ECUsInVehicle instance using the specified properties.
         * @function create
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {tool_commands.IECUsInVehicle=} [properties] Properties to set
         * @returns {tool_commands.ECUsInVehicle} ECUsInVehicle instance
         */
        ECUsInVehicle.create = function create(properties) {
            return new ECUsInVehicle(properties);
        };

        /**
         * Encodes the specified ECUsInVehicle message. Does not implicitly {@link tool_commands.ECUsInVehicle.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {tool_commands.IECUsInVehicle} message ECUsInVehicle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsInVehicle.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.revision != null && Object.hasOwnProperty.call(message, "revision"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.revision);
            if (message.release != null && Object.hasOwnProperty.call(message, "release"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.release);
            if (message.domains != null && message.domains.length)
                for (var i = 0; i < message.domains.length; ++i)
                    $root.tool_commands.ECUsInDomain.encode(message.domains[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ECUsInVehicle message, length delimited. Does not implicitly {@link tool_commands.ECUsInVehicle.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {tool_commands.IECUsInVehicle} message ECUsInVehicle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUsInVehicle.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ECUsInVehicle message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ECUsInVehicle} ECUsInVehicle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsInVehicle.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ECUsInVehicle();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.revision = reader.string();
                        break;
                    }
                case 2: {
                        message.release = reader.string();
                        break;
                    }
                case 3: {
                        if (!(message.domains && message.domains.length))
                            message.domains = [];
                        message.domains.push($root.tool_commands.ECUsInDomain.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ECUsInVehicle message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ECUsInVehicle} ECUsInVehicle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUsInVehicle.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ECUsInVehicle message.
         * @function verify
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ECUsInVehicle.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.revision != null && message.hasOwnProperty("revision"))
                if (!$util.isString(message.revision))
                    return "revision: string expected";
            if (message.release != null && message.hasOwnProperty("release"))
                if (!$util.isString(message.release))
                    return "release: string expected";
            if (message.domains != null && message.hasOwnProperty("domains")) {
                if (!Array.isArray(message.domains))
                    return "domains: array expected";
                for (var i = 0; i < message.domains.length; ++i) {
                    var error = $root.tool_commands.ECUsInDomain.verify(message.domains[i]);
                    if (error)
                        return "domains." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ECUsInVehicle message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ECUsInVehicle} ECUsInVehicle
         */
        ECUsInVehicle.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ECUsInVehicle)
                return object;
            var message = new $root.tool_commands.ECUsInVehicle();
            if (object.revision != null)
                message.revision = String(object.revision);
            if (object.release != null)
                message.release = String(object.release);
            if (object.domains) {
                if (!Array.isArray(object.domains))
                    throw TypeError(".tool_commands.ECUsInVehicle.domains: array expected");
                message.domains = [];
                for (var i = 0; i < object.domains.length; ++i) {
                    if (typeof object.domains[i] !== "object")
                        throw TypeError(".tool_commands.ECUsInVehicle.domains: object expected");
                    message.domains[i] = $root.tool_commands.ECUsInDomain.fromObject(object.domains[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ECUsInVehicle message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {tool_commands.ECUsInVehicle} message ECUsInVehicle
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ECUsInVehicle.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.domains = [];
            if (options.defaults) {
                object.revision = "";
                object.release = "";
            }
            if (message.revision != null && message.hasOwnProperty("revision"))
                object.revision = message.revision;
            if (message.release != null && message.hasOwnProperty("release"))
                object.release = message.release;
            if (message.domains && message.domains.length) {
                object.domains = [];
                for (var j = 0; j < message.domains.length; ++j)
                    object.domains[j] = $root.tool_commands.ECUsInDomain.toObject(message.domains[j], options);
            }
            return object;
        };

        /**
         * Converts this ECUsInVehicle to JSON.
         * @function toJSON
         * @memberof tool_commands.ECUsInVehicle
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ECUsInVehicle.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ECUsInVehicle
         * @function getTypeUrl
         * @memberof tool_commands.ECUsInVehicle
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ECUsInVehicle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ECUsInVehicle";
        };

        return ECUsInVehicle;
    })();

    tool_commands.EC2data = (function() {

        /**
         * Properties of a EC2data.
         * @memberof tool_commands
         * @interface IEC2data
         * @property {string} name EC2data name
         * @property {Uint8Array} image EC2data image
         */

        /**
         * Constructs a new EC2data.
         * @memberof tool_commands
         * @classdesc Represents a EC2data.
         * @implements IEC2data
         * @constructor
         * @param {tool_commands.IEC2data=} [properties] Properties to set
         */
        function EC2data(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EC2data name.
         * @member {string} name
         * @memberof tool_commands.EC2data
         * @instance
         */
        EC2data.prototype.name = "";

        /**
         * EC2data image.
         * @member {Uint8Array} image
         * @memberof tool_commands.EC2data
         * @instance
         */
        EC2data.prototype.image = $util.newBuffer([]);

        /**
         * Creates a new EC2data instance using the specified properties.
         * @function create
         * @memberof tool_commands.EC2data
         * @static
         * @param {tool_commands.IEC2data=} [properties] Properties to set
         * @returns {tool_commands.EC2data} EC2data instance
         */
        EC2data.create = function create(properties) {
            return new EC2data(properties);
        };

        /**
         * Encodes the specified EC2data message. Does not implicitly {@link tool_commands.EC2data.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.EC2data
         * @static
         * @param {tool_commands.IEC2data} message EC2data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EC2data.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.image);
            return writer;
        };

        /**
         * Encodes the specified EC2data message, length delimited. Does not implicitly {@link tool_commands.EC2data.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.EC2data
         * @static
         * @param {tool_commands.IEC2data} message EC2data message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EC2data.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a EC2data message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.EC2data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.EC2data} EC2data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EC2data.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.EC2data();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.image = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("image"))
                throw $util.ProtocolError("missing required 'image'", { instance: message });
            return message;
        };

        /**
         * Decodes a EC2data message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.EC2data
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.EC2data} EC2data
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EC2data.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a EC2data message.
         * @function verify
         * @memberof tool_commands.EC2data
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EC2data.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!(message.image && typeof message.image.length === "number" || $util.isString(message.image)))
                return "image: buffer expected";
            return null;
        };

        /**
         * Creates a EC2data message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.EC2data
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.EC2data} EC2data
         */
        EC2data.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.EC2data)
                return object;
            var message = new $root.tool_commands.EC2data();
            if (object.name != null)
                message.name = String(object.name);
            if (object.image != null)
                if (typeof object.image === "string")
                    $util.base64.decode(object.image, message.image = $util.newBuffer($util.base64.length(object.image)), 0);
                else if (object.image.length >= 0)
                    message.image = object.image;
            return message;
        };

        /**
         * Creates a plain object from a EC2data message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.EC2data
         * @static
         * @param {tool_commands.EC2data} message EC2data
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EC2data.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                if (options.bytes === String)
                    object.image = "";
                else {
                    object.image = [];
                    if (options.bytes !== Array)
                        object.image = $util.newBuffer(object.image);
                }
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.image != null && message.hasOwnProperty("image"))
                object.image = options.bytes === String ? $util.base64.encode(message.image, 0, message.image.length) : options.bytes === Array ? Array.prototype.slice.call(message.image) : message.image;
            return object;
        };

        /**
         * Converts this EC2data to JSON.
         * @function toJSON
         * @memberof tool_commands.EC2data
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EC2data.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EC2data
         * @function getTypeUrl
         * @memberof tool_commands.EC2data
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EC2data.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.EC2data";
        };

        return EC2data;
    })();

    tool_commands.Settings = (function() {

        /**
         * Properties of a Settings.
         * @memberof tool_commands
         * @interface ISettings
         * @property {string} cloudURL Settings cloudURL
         * @property {string} localFolder Settings localFolder
         * @property {tool_commands.Transport} udsTransport Settings udsTransport
         * @property {string|null} [projectID] Settings projectID
         * @property {string|null} [authorization] Settings authorization
         * @property {string|null} [envId] Settings envId
         * @property {string|null} [url] Settings url
         * @property {tool_commands.PCAN_BaudRate|null} [pcanBitrate] Settings pcanBitrate
         * @property {tool_commands.PCAN_USB_Bus|null} [pcanBus] Settings pcanBus
         */

        /**
         * Constructs a new Settings.
         * @memberof tool_commands
         * @classdesc Represents a Settings.
         * @implements ISettings
         * @constructor
         * @param {tool_commands.ISettings=} [properties] Properties to set
         */
        function Settings(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Settings cloudURL.
         * @member {string} cloudURL
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.cloudURL = "https://api.galaxy.ff-tools.link/v1/";

        /**
         * Settings localFolder.
         * @member {string} localFolder
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.localFolder = "ff_production_tool_data/";

        /**
         * Settings udsTransport.
         * @member {tool_commands.Transport} udsTransport
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.udsTransport = 1;

        /**
         * Settings projectID.
         * @member {string} projectID
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.projectID = "et-object-table.prod";

        /**
         * Settings authorization.
         * @member {string} authorization
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.authorization = "ffgat_1682543575171-cke7.VF1E9KiIGNM38fZwQ46EtCVPf2k=";

        /**
         * Settings envId.
         * @member {string} envId
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.envId = "EEA_ce5b6eac-1121-4d26-9950-9357124866b2";

        /**
         * Settings url.
         * @member {string} url
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.url = "185.192.58.1";

        /**
         * Settings pcanBitrate.
         * @member {tool_commands.PCAN_BaudRate} pcanBitrate
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.pcanBitrate = 28;

        /**
         * Settings pcanBus.
         * @member {tool_commands.PCAN_USB_Bus} pcanBus
         * @memberof tool_commands.Settings
         * @instance
         */
        Settings.prototype.pcanBus = 81;

        /**
         * Creates a new Settings instance using the specified properties.
         * @function create
         * @memberof tool_commands.Settings
         * @static
         * @param {tool_commands.ISettings=} [properties] Properties to set
         * @returns {tool_commands.Settings} Settings instance
         */
        Settings.create = function create(properties) {
            return new Settings(properties);
        };

        /**
         * Encodes the specified Settings message. Does not implicitly {@link tool_commands.Settings.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.Settings
         * @static
         * @param {tool_commands.ISettings} message Settings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.cloudURL);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.localFolder);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.udsTransport);
            if (message.projectID != null && Object.hasOwnProperty.call(message, "projectID"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.projectID);
            if (message.authorization != null && Object.hasOwnProperty.call(message, "authorization"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.authorization);
            if (message.envId != null && Object.hasOwnProperty.call(message, "envId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.envId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.url);
            if (message.pcanBitrate != null && Object.hasOwnProperty.call(message, "pcanBitrate"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.pcanBitrate);
            if (message.pcanBus != null && Object.hasOwnProperty.call(message, "pcanBus"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.pcanBus);
            return writer;
        };

        /**
         * Encodes the specified Settings message, length delimited. Does not implicitly {@link tool_commands.Settings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.Settings
         * @static
         * @param {tool_commands.ISettings} message Settings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Settings message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.Settings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.Settings} Settings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.Settings();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cloudURL = reader.string();
                        break;
                    }
                case 2: {
                        message.localFolder = reader.string();
                        break;
                    }
                case 3: {
                        message.udsTransport = reader.int32();
                        break;
                    }
                case 4: {
                        message.projectID = reader.string();
                        break;
                    }
                case 5: {
                        message.authorization = reader.string();
                        break;
                    }
                case 6: {
                        message.envId = reader.string();
                        break;
                    }
                case 10: {
                        message.url = reader.string();
                        break;
                    }
                case 20: {
                        message.pcanBitrate = reader.int32();
                        break;
                    }
                case 21: {
                        message.pcanBus = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("cloudURL"))
                throw $util.ProtocolError("missing required 'cloudURL'", { instance: message });
            if (!message.hasOwnProperty("localFolder"))
                throw $util.ProtocolError("missing required 'localFolder'", { instance: message });
            if (!message.hasOwnProperty("udsTransport"))
                throw $util.ProtocolError("missing required 'udsTransport'", { instance: message });
            return message;
        };

        /**
         * Decodes a Settings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.Settings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.Settings} Settings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Settings message.
         * @function verify
         * @memberof tool_commands.Settings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Settings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.cloudURL))
                return "cloudURL: string expected";
            if (!$util.isString(message.localFolder))
                return "localFolder: string expected";
            switch (message.udsTransport) {
            default:
                return "udsTransport: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
            if (message.projectID != null && message.hasOwnProperty("projectID"))
                if (!$util.isString(message.projectID))
                    return "projectID: string expected";
            if (message.authorization != null && message.hasOwnProperty("authorization"))
                if (!$util.isString(message.authorization))
                    return "authorization: string expected";
            if (message.envId != null && message.hasOwnProperty("envId"))
                if (!$util.isString(message.envId))
                    return "envId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.pcanBitrate != null && message.hasOwnProperty("pcanBitrate"))
                switch (message.pcanBitrate) {
                default:
                    return "pcanBitrate: enum value expected";
                case 20:
                case 22:
                case 28:
                case 284:
                case 796:
                case 17199:
                case 49998:
                case 34091:
                case 18223:
                case 5140:
                case 35631:
                case 21295:
                case 26415:
                case 32639:
                    break;
                }
            if (message.pcanBus != null && message.hasOwnProperty("pcanBus"))
                switch (message.pcanBus) {
                default:
                    return "pcanBus: enum value expected";
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 88:
                case 1289:
                case 1290:
                case 1291:
                case 1292:
                case 1293:
                case 1294:
                case 1295:
                case 1296:
                    break;
                }
            return null;
        };

        /**
         * Creates a Settings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.Settings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.Settings} Settings
         */
        Settings.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.Settings)
                return object;
            var message = new $root.tool_commands.Settings();
            if (object.cloudURL != null)
                message.cloudURL = String(object.cloudURL);
            if (object.localFolder != null)
                message.localFolder = String(object.localFolder);
            switch (object.udsTransport) {
            case "CAN":
            case 0:
                message.udsTransport = 0;
                break;
            default:
                if (typeof object.udsTransport === "number") {
                    message.udsTransport = object.udsTransport;
                    break;
                }
                break;
            case "IP":
            case 1:
                message.udsTransport = 1;
                break;
            case "LIN":
            case 2:
                message.udsTransport = 2;
                break;
            case "SERIAL":
            case 3:
                message.udsTransport = 3;
                break;
            }
            if (object.projectID != null)
                message.projectID = String(object.projectID);
            if (object.authorization != null)
                message.authorization = String(object.authorization);
            if (object.envId != null)
                message.envId = String(object.envId);
            if (object.url != null)
                message.url = String(object.url);
            switch (object.pcanBitrate) {
            case "PCAN_BAUD_1M":
            case 20:
                message.pcanBitrate = 20;
                break;
            case "PCAN_BAUD_800K":
            case 22:
                message.pcanBitrate = 22;
                break;
            default:
                if (typeof object.pcanBitrate === "number") {
                    message.pcanBitrate = object.pcanBitrate;
                    break;
                }
                break;
            case "PCAN_BAUD_500K":
            case 28:
                message.pcanBitrate = 28;
                break;
            case "PCAN_BAUD_250K":
            case 284:
                message.pcanBitrate = 284;
                break;
            case "PCAN_BAUD_125K":
            case 796:
                message.pcanBitrate = 796;
                break;
            case "PCAN_BAUD_100K":
            case 17199:
                message.pcanBitrate = 17199;
                break;
            case "PCAN_BAUD_95K":
            case 49998:
                message.pcanBitrate = 49998;
                break;
            case "PCAN_BAUD_83K":
            case 34091:
                message.pcanBitrate = 34091;
                break;
            case "PCAN_BAUD_50K":
            case 18223:
                message.pcanBitrate = 18223;
                break;
            case "PCAN_BAUD_47K":
            case 5140:
                message.pcanBitrate = 5140;
                break;
            case "PCAN_BAUD_33K":
            case 35631:
                message.pcanBitrate = 35631;
                break;
            case "PCAN_BAUD_20K":
            case 21295:
                message.pcanBitrate = 21295;
                break;
            case "PCAN_BAUD_10K":
            case 26415:
                message.pcanBitrate = 26415;
                break;
            case "PCAN_BAUD_5K":
            case 32639:
                message.pcanBitrate = 32639;
                break;
            }
            switch (object.pcanBus) {
            default:
                if (typeof object.pcanBus === "number") {
                    message.pcanBus = object.pcanBus;
                    break;
                }
                break;
            case "PCAN_USBBUS1":
            case 81:
                message.pcanBus = 81;
                break;
            case "PCAN_USBBUS2":
            case 82:
                message.pcanBus = 82;
                break;
            case "PCAN_USBBUS3":
            case 83:
                message.pcanBus = 83;
                break;
            case "PCAN_USBBUS4":
            case 84:
                message.pcanBus = 84;
                break;
            case "PCAN_USBBUS5":
            case 85:
                message.pcanBus = 85;
                break;
            case "PCAN_USBBUS6":
            case 86:
                message.pcanBus = 86;
                break;
            case "PCAN_USBBUS7":
            case 87:
                message.pcanBus = 87;
                break;
            case "PCAN_USBBUS8":
            case 88:
                message.pcanBus = 88;
                break;
            case "PCAN_USBBUS9":
            case 1289:
                message.pcanBus = 1289;
                break;
            case "PCAN_USBBUS10":
            case 1290:
                message.pcanBus = 1290;
                break;
            case "PCAN_USBBUS11":
            case 1291:
                message.pcanBus = 1291;
                break;
            case "PCAN_USBBUS12":
            case 1292:
                message.pcanBus = 1292;
                break;
            case "PCAN_USBBUS13":
            case 1293:
                message.pcanBus = 1293;
                break;
            case "PCAN_USBBUS14":
            case 1294:
                message.pcanBus = 1294;
                break;
            case "PCAN_USBBUS15":
            case 1295:
                message.pcanBus = 1295;
                break;
            case "PCAN_USBBUS16":
            case 1296:
                message.pcanBus = 1296;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Settings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.Settings
         * @static
         * @param {tool_commands.Settings} message Settings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Settings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.cloudURL = "https://api.galaxy.ff-tools.link/v1/";
                object.localFolder = "ff_production_tool_data/";
                object.udsTransport = options.enums === String ? "IP" : 1;
                object.projectID = "et-object-table.prod";
                object.authorization = "ffgat_1682543575171-cke7.VF1E9KiIGNM38fZwQ46EtCVPf2k=";
                object.envId = "EEA_ce5b6eac-1121-4d26-9950-9357124866b2";
                object.url = "185.192.58.1";
                object.pcanBitrate = options.enums === String ? "PCAN_BAUD_500K" : 28;
                object.pcanBus = options.enums === String ? "PCAN_USBBUS1" : 81;
            }
            if (message.cloudURL != null && message.hasOwnProperty("cloudURL"))
                object.cloudURL = message.cloudURL;
            if (message.localFolder != null && message.hasOwnProperty("localFolder"))
                object.localFolder = message.localFolder;
            if (message.udsTransport != null && message.hasOwnProperty("udsTransport"))
                object.udsTransport = options.enums === String ? $root.tool_commands.Transport[message.udsTransport] === undefined ? message.udsTransport : $root.tool_commands.Transport[message.udsTransport] : message.udsTransport;
            if (message.projectID != null && message.hasOwnProperty("projectID"))
                object.projectID = message.projectID;
            if (message.authorization != null && message.hasOwnProperty("authorization"))
                object.authorization = message.authorization;
            if (message.envId != null && message.hasOwnProperty("envId"))
                object.envId = message.envId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.pcanBitrate != null && message.hasOwnProperty("pcanBitrate"))
                object.pcanBitrate = options.enums === String ? $root.tool_commands.PCAN_BaudRate[message.pcanBitrate] === undefined ? message.pcanBitrate : $root.tool_commands.PCAN_BaudRate[message.pcanBitrate] : message.pcanBitrate;
            if (message.pcanBus != null && message.hasOwnProperty("pcanBus"))
                object.pcanBus = options.enums === String ? $root.tool_commands.PCAN_USB_Bus[message.pcanBus] === undefined ? message.pcanBus : $root.tool_commands.PCAN_USB_Bus[message.pcanBus] : message.pcanBus;
            return object;
        };

        /**
         * Converts this Settings to JSON.
         * @function toJSON
         * @memberof tool_commands.Settings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Settings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Settings
         * @function getTypeUrl
         * @memberof tool_commands.Settings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Settings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.Settings";
        };

        return Settings;
    })();

    tool_commands.Model = (function() {

        /**
         * Properties of a Model.
         * @memberof tool_commands
         * @interface IModel
         * @property {string} name Model name
         * @property {string} id Model id
         * @property {Array.<string>|null} [revisions] Model revisions
         * @property {Array.<string>|null} [revisionNames] Model revisionNames
         */

        /**
         * Constructs a new Model.
         * @memberof tool_commands
         * @classdesc Represents a Model.
         * @implements IModel
         * @constructor
         * @param {tool_commands.IModel=} [properties] Properties to set
         */
        function Model(properties) {
            this.revisions = [];
            this.revisionNames = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Model name.
         * @member {string} name
         * @memberof tool_commands.Model
         * @instance
         */
        Model.prototype.name = "";

        /**
         * Model id.
         * @member {string} id
         * @memberof tool_commands.Model
         * @instance
         */
        Model.prototype.id = "";

        /**
         * Model revisions.
         * @member {Array.<string>} revisions
         * @memberof tool_commands.Model
         * @instance
         */
        Model.prototype.revisions = $util.emptyArray;

        /**
         * Model revisionNames.
         * @member {Array.<string>} revisionNames
         * @memberof tool_commands.Model
         * @instance
         */
        Model.prototype.revisionNames = $util.emptyArray;

        /**
         * Creates a new Model instance using the specified properties.
         * @function create
         * @memberof tool_commands.Model
         * @static
         * @param {tool_commands.IModel=} [properties] Properties to set
         * @returns {tool_commands.Model} Model instance
         */
        Model.create = function create(properties) {
            return new Model(properties);
        };

        /**
         * Encodes the specified Model message. Does not implicitly {@link tool_commands.Model.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.Model
         * @static
         * @param {tool_commands.IModel} message Model message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Model.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.revisions != null && message.revisions.length)
                for (var i = 0; i < message.revisions.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.revisions[i]);
            if (message.revisionNames != null && message.revisionNames.length)
                for (var i = 0; i < message.revisionNames.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.revisionNames[i]);
            return writer;
        };

        /**
         * Encodes the specified Model message, length delimited. Does not implicitly {@link tool_commands.Model.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.Model
         * @static
         * @param {tool_commands.IModel} message Model message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Model.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Model message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.Model
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.Model} Model
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Model.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.Model();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.id = reader.string();
                        break;
                    }
                case 3: {
                        if (!(message.revisions && message.revisions.length))
                            message.revisions = [];
                        message.revisions.push(reader.string());
                        break;
                    }
                case 4: {
                        if (!(message.revisionNames && message.revisionNames.length))
                            message.revisionNames = [];
                        message.revisionNames.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes a Model message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.Model
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.Model} Model
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Model.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Model message.
         * @function verify
         * @memberof tool_commands.Model
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Model.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.id))
                return "id: string expected";
            if (message.revisions != null && message.hasOwnProperty("revisions")) {
                if (!Array.isArray(message.revisions))
                    return "revisions: array expected";
                for (var i = 0; i < message.revisions.length; ++i)
                    if (!$util.isString(message.revisions[i]))
                        return "revisions: string[] expected";
            }
            if (message.revisionNames != null && message.hasOwnProperty("revisionNames")) {
                if (!Array.isArray(message.revisionNames))
                    return "revisionNames: array expected";
                for (var i = 0; i < message.revisionNames.length; ++i)
                    if (!$util.isString(message.revisionNames[i]))
                        return "revisionNames: string[] expected";
            }
            return null;
        };

        /**
         * Creates a Model message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.Model
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.Model} Model
         */
        Model.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.Model)
                return object;
            var message = new $root.tool_commands.Model();
            if (object.name != null)
                message.name = String(object.name);
            if (object.id != null)
                message.id = String(object.id);
            if (object.revisions) {
                if (!Array.isArray(object.revisions))
                    throw TypeError(".tool_commands.Model.revisions: array expected");
                message.revisions = [];
                for (var i = 0; i < object.revisions.length; ++i)
                    message.revisions[i] = String(object.revisions[i]);
            }
            if (object.revisionNames) {
                if (!Array.isArray(object.revisionNames))
                    throw TypeError(".tool_commands.Model.revisionNames: array expected");
                message.revisionNames = [];
                for (var i = 0; i < object.revisionNames.length; ++i)
                    message.revisionNames[i] = String(object.revisionNames[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Model message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.Model
         * @static
         * @param {tool_commands.Model} message Model
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Model.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.revisions = [];
                object.revisionNames = [];
            }
            if (options.defaults) {
                object.name = "";
                object.id = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.revisions && message.revisions.length) {
                object.revisions = [];
                for (var j = 0; j < message.revisions.length; ++j)
                    object.revisions[j] = message.revisions[j];
            }
            if (message.revisionNames && message.revisionNames.length) {
                object.revisionNames = [];
                for (var j = 0; j < message.revisionNames.length; ++j)
                    object.revisionNames[j] = message.revisionNames[j];
            }
            return object;
        };

        /**
         * Converts this Model to JSON.
         * @function toJSON
         * @memberof tool_commands.Model
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Model.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Model
         * @function getTypeUrl
         * @memberof tool_commands.Model
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Model.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.Model";
        };

        return Model;
    })();

    tool_commands.Models = (function() {

        /**
         * Properties of a Models.
         * @memberof tool_commands
         * @interface IModels
         * @property {Array.<tool_commands.IModel>|null} [objects] Models objects
         * @property {string|null} [selectedModel] Models selectedModel
         * @property {Array.<string>|null} [releases] Models releases
         * @property {string|null} [selectedRelease] Models selectedRelease
         */

        /**
         * Constructs a new Models.
         * @memberof tool_commands
         * @classdesc Represents a Models.
         * @implements IModels
         * @constructor
         * @param {tool_commands.IModels=} [properties] Properties to set
         */
        function Models(properties) {
            this.objects = [];
            this.releases = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Models objects.
         * @member {Array.<tool_commands.IModel>} objects
         * @memberof tool_commands.Models
         * @instance
         */
        Models.prototype.objects = $util.emptyArray;

        /**
         * Models selectedModel.
         * @member {string} selectedModel
         * @memberof tool_commands.Models
         * @instance
         */
        Models.prototype.selectedModel = "";

        /**
         * Models releases.
         * @member {Array.<string>} releases
         * @memberof tool_commands.Models
         * @instance
         */
        Models.prototype.releases = $util.emptyArray;

        /**
         * Models selectedRelease.
         * @member {string} selectedRelease
         * @memberof tool_commands.Models
         * @instance
         */
        Models.prototype.selectedRelease = "";

        /**
         * Creates a new Models instance using the specified properties.
         * @function create
         * @memberof tool_commands.Models
         * @static
         * @param {tool_commands.IModels=} [properties] Properties to set
         * @returns {tool_commands.Models} Models instance
         */
        Models.create = function create(properties) {
            return new Models(properties);
        };

        /**
         * Encodes the specified Models message. Does not implicitly {@link tool_commands.Models.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.Models
         * @static
         * @param {tool_commands.IModels} message Models message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Models.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objects != null && message.objects.length)
                for (var i = 0; i < message.objects.length; ++i)
                    $root.tool_commands.Model.encode(message.objects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.selectedModel != null && Object.hasOwnProperty.call(message, "selectedModel"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.selectedModel);
            if (message.releases != null && message.releases.length)
                for (var i = 0; i < message.releases.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.releases[i]);
            if (message.selectedRelease != null && Object.hasOwnProperty.call(message, "selectedRelease"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.selectedRelease);
            return writer;
        };

        /**
         * Encodes the specified Models message, length delimited. Does not implicitly {@link tool_commands.Models.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.Models
         * @static
         * @param {tool_commands.IModels} message Models message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Models.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Models message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.Models
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.Models} Models
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Models.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.Models();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.objects && message.objects.length))
                            message.objects = [];
                        message.objects.push($root.tool_commands.Model.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.selectedModel = reader.string();
                        break;
                    }
                case 3: {
                        if (!(message.releases && message.releases.length))
                            message.releases = [];
                        message.releases.push(reader.string());
                        break;
                    }
                case 4: {
                        message.selectedRelease = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Models message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.Models
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.Models} Models
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Models.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Models message.
         * @function verify
         * @memberof tool_commands.Models
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Models.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objects != null && message.hasOwnProperty("objects")) {
                if (!Array.isArray(message.objects))
                    return "objects: array expected";
                for (var i = 0; i < message.objects.length; ++i) {
                    var error = $root.tool_commands.Model.verify(message.objects[i]);
                    if (error)
                        return "objects." + error;
                }
            }
            if (message.selectedModel != null && message.hasOwnProperty("selectedModel"))
                if (!$util.isString(message.selectedModel))
                    return "selectedModel: string expected";
            if (message.releases != null && message.hasOwnProperty("releases")) {
                if (!Array.isArray(message.releases))
                    return "releases: array expected";
                for (var i = 0; i < message.releases.length; ++i)
                    if (!$util.isString(message.releases[i]))
                        return "releases: string[] expected";
            }
            if (message.selectedRelease != null && message.hasOwnProperty("selectedRelease"))
                if (!$util.isString(message.selectedRelease))
                    return "selectedRelease: string expected";
            return null;
        };

        /**
         * Creates a Models message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.Models
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.Models} Models
         */
        Models.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.Models)
                return object;
            var message = new $root.tool_commands.Models();
            if (object.objects) {
                if (!Array.isArray(object.objects))
                    throw TypeError(".tool_commands.Models.objects: array expected");
                message.objects = [];
                for (var i = 0; i < object.objects.length; ++i) {
                    if (typeof object.objects[i] !== "object")
                        throw TypeError(".tool_commands.Models.objects: object expected");
                    message.objects[i] = $root.tool_commands.Model.fromObject(object.objects[i]);
                }
            }
            if (object.selectedModel != null)
                message.selectedModel = String(object.selectedModel);
            if (object.releases) {
                if (!Array.isArray(object.releases))
                    throw TypeError(".tool_commands.Models.releases: array expected");
                message.releases = [];
                for (var i = 0; i < object.releases.length; ++i)
                    message.releases[i] = String(object.releases[i]);
            }
            if (object.selectedRelease != null)
                message.selectedRelease = String(object.selectedRelease);
            return message;
        };

        /**
         * Creates a plain object from a Models message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.Models
         * @static
         * @param {tool_commands.Models} message Models
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Models.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.objects = [];
                object.releases = [];
            }
            if (options.defaults) {
                object.selectedModel = "";
                object.selectedRelease = "";
            }
            if (message.objects && message.objects.length) {
                object.objects = [];
                for (var j = 0; j < message.objects.length; ++j)
                    object.objects[j] = $root.tool_commands.Model.toObject(message.objects[j], options);
            }
            if (message.selectedModel != null && message.hasOwnProperty("selectedModel"))
                object.selectedModel = message.selectedModel;
            if (message.releases && message.releases.length) {
                object.releases = [];
                for (var j = 0; j < message.releases.length; ++j)
                    object.releases[j] = message.releases[j];
            }
            if (message.selectedRelease != null && message.hasOwnProperty("selectedRelease"))
                object.selectedRelease = message.selectedRelease;
            return object;
        };

        /**
         * Converts this Models to JSON.
         * @function toJSON
         * @memberof tool_commands.Models
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Models.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Models
         * @function getTypeUrl
         * @memberof tool_commands.Models
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Models.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.Models";
        };

        return Models;
    })();

    tool_commands.RevisionDetails = (function() {

        /**
         * Properties of a RevisionDetails.
         * @memberof tool_commands
         * @interface IRevisionDetails
         * @property {string} name RevisionDetails name
         * @property {string} id RevisionDetails id
         */

        /**
         * Constructs a new RevisionDetails.
         * @memberof tool_commands
         * @classdesc Represents a RevisionDetails.
         * @implements IRevisionDetails
         * @constructor
         * @param {tool_commands.IRevisionDetails=} [properties] Properties to set
         */
        function RevisionDetails(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RevisionDetails name.
         * @member {string} name
         * @memberof tool_commands.RevisionDetails
         * @instance
         */
        RevisionDetails.prototype.name = "";

        /**
         * RevisionDetails id.
         * @member {string} id
         * @memberof tool_commands.RevisionDetails
         * @instance
         */
        RevisionDetails.prototype.id = "";

        /**
         * Creates a new RevisionDetails instance using the specified properties.
         * @function create
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {tool_commands.IRevisionDetails=} [properties] Properties to set
         * @returns {tool_commands.RevisionDetails} RevisionDetails instance
         */
        RevisionDetails.create = function create(properties) {
            return new RevisionDetails(properties);
        };

        /**
         * Encodes the specified RevisionDetails message. Does not implicitly {@link tool_commands.RevisionDetails.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {tool_commands.IRevisionDetails} message RevisionDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified RevisionDetails message, length delimited. Does not implicitly {@link tool_commands.RevisionDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {tool_commands.IRevisionDetails} message RevisionDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RevisionDetails message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.RevisionDetails} RevisionDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.RevisionDetails();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes a RevisionDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.RevisionDetails} RevisionDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RevisionDetails message.
         * @function verify
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RevisionDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (!$util.isString(message.id))
                return "id: string expected";
            return null;
        };

        /**
         * Creates a RevisionDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.RevisionDetails} RevisionDetails
         */
        RevisionDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.RevisionDetails)
                return object;
            var message = new $root.tool_commands.RevisionDetails();
            if (object.name != null)
                message.name = String(object.name);
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a RevisionDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {tool_commands.RevisionDetails} message RevisionDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RevisionDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.id = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this RevisionDetails to JSON.
         * @function toJSON
         * @memberof tool_commands.RevisionDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RevisionDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RevisionDetails
         * @function getTypeUrl
         * @memberof tool_commands.RevisionDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RevisionDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.RevisionDetails";
        };

        return RevisionDetails;
    })();

    tool_commands.RevisionsDetails = (function() {

        /**
         * Properties of a RevisionsDetails.
         * @memberof tool_commands
         * @interface IRevisionsDetails
         * @property {Array.<tool_commands.IRevisionDetails>|null} [objects] RevisionsDetails objects
         */

        /**
         * Constructs a new RevisionsDetails.
         * @memberof tool_commands
         * @classdesc Represents a RevisionsDetails.
         * @implements IRevisionsDetails
         * @constructor
         * @param {tool_commands.IRevisionsDetails=} [properties] Properties to set
         */
        function RevisionsDetails(properties) {
            this.objects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RevisionsDetails objects.
         * @member {Array.<tool_commands.IRevisionDetails>} objects
         * @memberof tool_commands.RevisionsDetails
         * @instance
         */
        RevisionsDetails.prototype.objects = $util.emptyArray;

        /**
         * Creates a new RevisionsDetails instance using the specified properties.
         * @function create
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {tool_commands.IRevisionsDetails=} [properties] Properties to set
         * @returns {tool_commands.RevisionsDetails} RevisionsDetails instance
         */
        RevisionsDetails.create = function create(properties) {
            return new RevisionsDetails(properties);
        };

        /**
         * Encodes the specified RevisionsDetails message. Does not implicitly {@link tool_commands.RevisionsDetails.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {tool_commands.IRevisionsDetails} message RevisionsDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionsDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objects != null && message.objects.length)
                for (var i = 0; i < message.objects.length; ++i)
                    $root.tool_commands.RevisionDetails.encode(message.objects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RevisionsDetails message, length delimited. Does not implicitly {@link tool_commands.RevisionsDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {tool_commands.IRevisionsDetails} message RevisionsDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RevisionsDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RevisionsDetails message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.RevisionsDetails} RevisionsDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionsDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.RevisionsDetails();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.objects && message.objects.length))
                            message.objects = [];
                        message.objects.push($root.tool_commands.RevisionDetails.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RevisionsDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.RevisionsDetails} RevisionsDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RevisionsDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RevisionsDetails message.
         * @function verify
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RevisionsDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objects != null && message.hasOwnProperty("objects")) {
                if (!Array.isArray(message.objects))
                    return "objects: array expected";
                for (var i = 0; i < message.objects.length; ++i) {
                    var error = $root.tool_commands.RevisionDetails.verify(message.objects[i]);
                    if (error)
                        return "objects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RevisionsDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.RevisionsDetails} RevisionsDetails
         */
        RevisionsDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.RevisionsDetails)
                return object;
            var message = new $root.tool_commands.RevisionsDetails();
            if (object.objects) {
                if (!Array.isArray(object.objects))
                    throw TypeError(".tool_commands.RevisionsDetails.objects: array expected");
                message.objects = [];
                for (var i = 0; i < object.objects.length; ++i) {
                    if (typeof object.objects[i] !== "object")
                        throw TypeError(".tool_commands.RevisionsDetails.objects: object expected");
                    message.objects[i] = $root.tool_commands.RevisionDetails.fromObject(object.objects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RevisionsDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {tool_commands.RevisionsDetails} message RevisionsDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RevisionsDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.objects = [];
            if (message.objects && message.objects.length) {
                object.objects = [];
                for (var j = 0; j < message.objects.length; ++j)
                    object.objects[j] = $root.tool_commands.RevisionDetails.toObject(message.objects[j], options);
            }
            return object;
        };

        /**
         * Converts this RevisionsDetails to JSON.
         * @function toJSON
         * @memberof tool_commands.RevisionsDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RevisionsDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RevisionsDetails
         * @function getTypeUrl
         * @memberof tool_commands.RevisionsDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RevisionsDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.RevisionsDetails";
        };

        return RevisionsDetails;
    })();

    tool_commands.ReleaseVersion = (function() {

        /**
         * Properties of a ReleaseVersion.
         * @memberof tool_commands
         * @interface IReleaseVersion
         * @property {string|null} [name] ReleaseVersion name
         */

        /**
         * Constructs a new ReleaseVersion.
         * @memberof tool_commands
         * @classdesc Represents a ReleaseVersion.
         * @implements IReleaseVersion
         * @constructor
         * @param {tool_commands.IReleaseVersion=} [properties] Properties to set
         */
        function ReleaseVersion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleaseVersion name.
         * @member {string} name
         * @memberof tool_commands.ReleaseVersion
         * @instance
         */
        ReleaseVersion.prototype.name = "";

        /**
         * Creates a new ReleaseVersion instance using the specified properties.
         * @function create
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {tool_commands.IReleaseVersion=} [properties] Properties to set
         * @returns {tool_commands.ReleaseVersion} ReleaseVersion instance
         */
        ReleaseVersion.create = function create(properties) {
            return new ReleaseVersion(properties);
        };

        /**
         * Encodes the specified ReleaseVersion message. Does not implicitly {@link tool_commands.ReleaseVersion.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {tool_commands.IReleaseVersion} message ReleaseVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ReleaseVersion message, length delimited. Does not implicitly {@link tool_commands.ReleaseVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {tool_commands.IReleaseVersion} message ReleaseVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleaseVersion message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ReleaseVersion} ReleaseVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ReleaseVersion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleaseVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ReleaseVersion} ReleaseVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleaseVersion message.
         * @function verify
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleaseVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a ReleaseVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ReleaseVersion} ReleaseVersion
         */
        ReleaseVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ReleaseVersion)
                return object;
            var message = new $root.tool_commands.ReleaseVersion();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a ReleaseVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {tool_commands.ReleaseVersion} message ReleaseVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleaseVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ReleaseVersion to JSON.
         * @function toJSON
         * @memberof tool_commands.ReleaseVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleaseVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReleaseVersion
         * @function getTypeUrl
         * @memberof tool_commands.ReleaseVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReleaseVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ReleaseVersion";
        };

        return ReleaseVersion;
    })();

    tool_commands.ReleaseVersions = (function() {

        /**
         * Properties of a ReleaseVersions.
         * @memberof tool_commands
         * @interface IReleaseVersions
         * @property {Array.<tool_commands.IReleaseVersion>|null} [releaseVersions] ReleaseVersions releaseVersions
         */

        /**
         * Constructs a new ReleaseVersions.
         * @memberof tool_commands
         * @classdesc Represents a ReleaseVersions.
         * @implements IReleaseVersions
         * @constructor
         * @param {tool_commands.IReleaseVersions=} [properties] Properties to set
         */
        function ReleaseVersions(properties) {
            this.releaseVersions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleaseVersions releaseVersions.
         * @member {Array.<tool_commands.IReleaseVersion>} releaseVersions
         * @memberof tool_commands.ReleaseVersions
         * @instance
         */
        ReleaseVersions.prototype.releaseVersions = $util.emptyArray;

        /**
         * Creates a new ReleaseVersions instance using the specified properties.
         * @function create
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {tool_commands.IReleaseVersions=} [properties] Properties to set
         * @returns {tool_commands.ReleaseVersions} ReleaseVersions instance
         */
        ReleaseVersions.create = function create(properties) {
            return new ReleaseVersions(properties);
        };

        /**
         * Encodes the specified ReleaseVersions message. Does not implicitly {@link tool_commands.ReleaseVersions.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {tool_commands.IReleaseVersions} message ReleaseVersions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseVersions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.releaseVersions != null && message.releaseVersions.length)
                for (var i = 0; i < message.releaseVersions.length; ++i)
                    $root.tool_commands.ReleaseVersion.encode(message.releaseVersions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReleaseVersions message, length delimited. Does not implicitly {@link tool_commands.ReleaseVersions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {tool_commands.IReleaseVersions} message ReleaseVersions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseVersions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleaseVersions message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ReleaseVersions} ReleaseVersions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseVersions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ReleaseVersions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.releaseVersions && message.releaseVersions.length))
                            message.releaseVersions = [];
                        message.releaseVersions.push($root.tool_commands.ReleaseVersion.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleaseVersions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ReleaseVersions} ReleaseVersions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseVersions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleaseVersions message.
         * @function verify
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleaseVersions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.releaseVersions != null && message.hasOwnProperty("releaseVersions")) {
                if (!Array.isArray(message.releaseVersions))
                    return "releaseVersions: array expected";
                for (var i = 0; i < message.releaseVersions.length; ++i) {
                    var error = $root.tool_commands.ReleaseVersion.verify(message.releaseVersions[i]);
                    if (error)
                        return "releaseVersions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ReleaseVersions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ReleaseVersions} ReleaseVersions
         */
        ReleaseVersions.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ReleaseVersions)
                return object;
            var message = new $root.tool_commands.ReleaseVersions();
            if (object.releaseVersions) {
                if (!Array.isArray(object.releaseVersions))
                    throw TypeError(".tool_commands.ReleaseVersions.releaseVersions: array expected");
                message.releaseVersions = [];
                for (var i = 0; i < object.releaseVersions.length; ++i) {
                    if (typeof object.releaseVersions[i] !== "object")
                        throw TypeError(".tool_commands.ReleaseVersions.releaseVersions: object expected");
                    message.releaseVersions[i] = $root.tool_commands.ReleaseVersion.fromObject(object.releaseVersions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ReleaseVersions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {tool_commands.ReleaseVersions} message ReleaseVersions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleaseVersions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.releaseVersions = [];
            if (message.releaseVersions && message.releaseVersions.length) {
                object.releaseVersions = [];
                for (var j = 0; j < message.releaseVersions.length; ++j)
                    object.releaseVersions[j] = $root.tool_commands.ReleaseVersion.toObject(message.releaseVersions[j], options);
            }
            return object;
        };

        /**
         * Converts this ReleaseVersions to JSON.
         * @function toJSON
         * @memberof tool_commands.ReleaseVersions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleaseVersions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReleaseVersions
         * @function getTypeUrl
         * @memberof tool_commands.ReleaseVersions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReleaseVersions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ReleaseVersions";
        };

        return ReleaseVersions;
    })();

    tool_commands.EcuListRequest = (function() {

        /**
         * Properties of an EcuListRequest.
         * @memberof tool_commands
         * @interface IEcuListRequest
         * @property {string} revision EcuListRequest revision
         * @property {string} release EcuListRequest release
         */

        /**
         * Constructs a new EcuListRequest.
         * @memberof tool_commands
         * @classdesc Represents an EcuListRequest.
         * @implements IEcuListRequest
         * @constructor
         * @param {tool_commands.IEcuListRequest=} [properties] Properties to set
         */
        function EcuListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EcuListRequest revision.
         * @member {string} revision
         * @memberof tool_commands.EcuListRequest
         * @instance
         */
        EcuListRequest.prototype.revision = "";

        /**
         * EcuListRequest release.
         * @member {string} release
         * @memberof tool_commands.EcuListRequest
         * @instance
         */
        EcuListRequest.prototype.release = "";

        /**
         * Creates a new EcuListRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {tool_commands.IEcuListRequest=} [properties] Properties to set
         * @returns {tool_commands.EcuListRequest} EcuListRequest instance
         */
        EcuListRequest.create = function create(properties) {
            return new EcuListRequest(properties);
        };

        /**
         * Encodes the specified EcuListRequest message. Does not implicitly {@link tool_commands.EcuListRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {tool_commands.IEcuListRequest} message EcuListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EcuListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.revision);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.release);
            return writer;
        };

        /**
         * Encodes the specified EcuListRequest message, length delimited. Does not implicitly {@link tool_commands.EcuListRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {tool_commands.IEcuListRequest} message EcuListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EcuListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EcuListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.EcuListRequest} EcuListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EcuListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.EcuListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.revision = reader.string();
                        break;
                    }
                case 2: {
                        message.release = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("revision"))
                throw $util.ProtocolError("missing required 'revision'", { instance: message });
            if (!message.hasOwnProperty("release"))
                throw $util.ProtocolError("missing required 'release'", { instance: message });
            return message;
        };

        /**
         * Decodes an EcuListRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.EcuListRequest} EcuListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EcuListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EcuListRequest message.
         * @function verify
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EcuListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.revision))
                return "revision: string expected";
            if (!$util.isString(message.release))
                return "release: string expected";
            return null;
        };

        /**
         * Creates an EcuListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.EcuListRequest} EcuListRequest
         */
        EcuListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.EcuListRequest)
                return object;
            var message = new $root.tool_commands.EcuListRequest();
            if (object.revision != null)
                message.revision = String(object.revision);
            if (object.release != null)
                message.release = String(object.release);
            return message;
        };

        /**
         * Creates a plain object from an EcuListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {tool_commands.EcuListRequest} message EcuListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EcuListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.revision = "";
                object.release = "";
            }
            if (message.revision != null && message.hasOwnProperty("revision"))
                object.revision = message.revision;
            if (message.release != null && message.hasOwnProperty("release"))
                object.release = message.release;
            return object;
        };

        /**
         * Converts this EcuListRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.EcuListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EcuListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EcuListRequest
         * @function getTypeUrl
         * @memberof tool_commands.EcuListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EcuListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.EcuListRequest";
        };

        return EcuListRequest;
    })();

    tool_commands.SpecificRevisionsRequest = (function() {

        /**
         * Properties of a SpecificRevisionsRequest.
         * @memberof tool_commands
         * @interface ISpecificRevisionsRequest
         * @property {Array.<string>|null} [itemRevisionIds] SpecificRevisionsRequest itemRevisionIds
         * @property {Array.<string>|null} [versions] SpecificRevisionsRequest versions
         */

        /**
         * Constructs a new SpecificRevisionsRequest.
         * @memberof tool_commands
         * @classdesc Represents a SpecificRevisionsRequest.
         * @implements ISpecificRevisionsRequest
         * @constructor
         * @param {tool_commands.ISpecificRevisionsRequest=} [properties] Properties to set
         */
        function SpecificRevisionsRequest(properties) {
            this.itemRevisionIds = [];
            this.versions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecificRevisionsRequest itemRevisionIds.
         * @member {Array.<string>} itemRevisionIds
         * @memberof tool_commands.SpecificRevisionsRequest
         * @instance
         */
        SpecificRevisionsRequest.prototype.itemRevisionIds = $util.emptyArray;

        /**
         * SpecificRevisionsRequest versions.
         * @member {Array.<string>} versions
         * @memberof tool_commands.SpecificRevisionsRequest
         * @instance
         */
        SpecificRevisionsRequest.prototype.versions = $util.emptyArray;

        /**
         * Creates a new SpecificRevisionsRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {tool_commands.ISpecificRevisionsRequest=} [properties] Properties to set
         * @returns {tool_commands.SpecificRevisionsRequest} SpecificRevisionsRequest instance
         */
        SpecificRevisionsRequest.create = function create(properties) {
            return new SpecificRevisionsRequest(properties);
        };

        /**
         * Encodes the specified SpecificRevisionsRequest message. Does not implicitly {@link tool_commands.SpecificRevisionsRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {tool_commands.ISpecificRevisionsRequest} message SpecificRevisionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevisionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemRevisionIds != null && message.itemRevisionIds.length)
                for (var i = 0; i < message.itemRevisionIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemRevisionIds[i]);
            if (message.versions != null && message.versions.length)
                for (var i = 0; i < message.versions.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.versions[i]);
            return writer;
        };

        /**
         * Encodes the specified SpecificRevisionsRequest message, length delimited. Does not implicitly {@link tool_commands.SpecificRevisionsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {tool_commands.ISpecificRevisionsRequest} message SpecificRevisionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevisionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpecificRevisionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SpecificRevisionsRequest} SpecificRevisionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevisionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SpecificRevisionsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.itemRevisionIds && message.itemRevisionIds.length))
                            message.itemRevisionIds = [];
                        message.itemRevisionIds.push(reader.string());
                        break;
                    }
                case 2: {
                        if (!(message.versions && message.versions.length))
                            message.versions = [];
                        message.versions.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpecificRevisionsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SpecificRevisionsRequest} SpecificRevisionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevisionsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpecificRevisionsRequest message.
         * @function verify
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpecificRevisionsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.itemRevisionIds != null && message.hasOwnProperty("itemRevisionIds")) {
                if (!Array.isArray(message.itemRevisionIds))
                    return "itemRevisionIds: array expected";
                for (var i = 0; i < message.itemRevisionIds.length; ++i)
                    if (!$util.isString(message.itemRevisionIds[i]))
                        return "itemRevisionIds: string[] expected";
            }
            if (message.versions != null && message.hasOwnProperty("versions")) {
                if (!Array.isArray(message.versions))
                    return "versions: array expected";
                for (var i = 0; i < message.versions.length; ++i)
                    if (!$util.isString(message.versions[i]))
                        return "versions: string[] expected";
            }
            return null;
        };

        /**
         * Creates a SpecificRevisionsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SpecificRevisionsRequest} SpecificRevisionsRequest
         */
        SpecificRevisionsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SpecificRevisionsRequest)
                return object;
            var message = new $root.tool_commands.SpecificRevisionsRequest();
            if (object.itemRevisionIds) {
                if (!Array.isArray(object.itemRevisionIds))
                    throw TypeError(".tool_commands.SpecificRevisionsRequest.itemRevisionIds: array expected");
                message.itemRevisionIds = [];
                for (var i = 0; i < object.itemRevisionIds.length; ++i)
                    message.itemRevisionIds[i] = String(object.itemRevisionIds[i]);
            }
            if (object.versions) {
                if (!Array.isArray(object.versions))
                    throw TypeError(".tool_commands.SpecificRevisionsRequest.versions: array expected");
                message.versions = [];
                for (var i = 0; i < object.versions.length; ++i)
                    message.versions[i] = String(object.versions[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a SpecificRevisionsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {tool_commands.SpecificRevisionsRequest} message SpecificRevisionsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpecificRevisionsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.itemRevisionIds = [];
                object.versions = [];
            }
            if (message.itemRevisionIds && message.itemRevisionIds.length) {
                object.itemRevisionIds = [];
                for (var j = 0; j < message.itemRevisionIds.length; ++j)
                    object.itemRevisionIds[j] = message.itemRevisionIds[j];
            }
            if (message.versions && message.versions.length) {
                object.versions = [];
                for (var j = 0; j < message.versions.length; ++j)
                    object.versions[j] = message.versions[j];
            }
            return object;
        };

        /**
         * Converts this SpecificRevisionsRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.SpecificRevisionsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpecificRevisionsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SpecificRevisionsRequest
         * @function getTypeUrl
         * @memberof tool_commands.SpecificRevisionsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SpecificRevisionsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SpecificRevisionsRequest";
        };

        return SpecificRevisionsRequest;
    })();

    tool_commands.ReleaseInfo = (function() {

        /**
         * Properties of a ReleaseInfo.
         * @memberof tool_commands
         * @interface IReleaseInfo
         * @property {string|null} [id] ReleaseInfo id
         */

        /**
         * Constructs a new ReleaseInfo.
         * @memberof tool_commands
         * @classdesc Represents a ReleaseInfo.
         * @implements IReleaseInfo
         * @constructor
         * @param {tool_commands.IReleaseInfo=} [properties] Properties to set
         */
        function ReleaseInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleaseInfo id.
         * @member {string} id
         * @memberof tool_commands.ReleaseInfo
         * @instance
         */
        ReleaseInfo.prototype.id = "";

        /**
         * Creates a new ReleaseInfo instance using the specified properties.
         * @function create
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {tool_commands.IReleaseInfo=} [properties] Properties to set
         * @returns {tool_commands.ReleaseInfo} ReleaseInfo instance
         */
        ReleaseInfo.create = function create(properties) {
            return new ReleaseInfo(properties);
        };

        /**
         * Encodes the specified ReleaseInfo message. Does not implicitly {@link tool_commands.ReleaseInfo.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {tool_commands.IReleaseInfo} message ReleaseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified ReleaseInfo message, length delimited. Does not implicitly {@link tool_commands.ReleaseInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {tool_commands.IReleaseInfo} message ReleaseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleaseInfo message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ReleaseInfo} ReleaseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ReleaseInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleaseInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ReleaseInfo} ReleaseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleaseInfo message.
         * @function verify
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleaseInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a ReleaseInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ReleaseInfo} ReleaseInfo
         */
        ReleaseInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ReleaseInfo)
                return object;
            var message = new $root.tool_commands.ReleaseInfo();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a ReleaseInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {tool_commands.ReleaseInfo} message ReleaseInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleaseInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this ReleaseInfo to JSON.
         * @function toJSON
         * @memberof tool_commands.ReleaseInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleaseInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReleaseInfo
         * @function getTypeUrl
         * @memberof tool_commands.ReleaseInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReleaseInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ReleaseInfo";
        };

        return ReleaseInfo;
    })();

    tool_commands.SpecificRevision = (function() {

        /**
         * Properties of a SpecificRevision.
         * @memberof tool_commands
         * @interface ISpecificRevision
         * @property {string|null} [itemRevisionId] SpecificRevision itemRevisionId
         * @property {tool_commands.IReleaseInfo|null} [releaseNum] SpecificRevision releaseNum
         */

        /**
         * Constructs a new SpecificRevision.
         * @memberof tool_commands
         * @classdesc Represents a SpecificRevision.
         * @implements ISpecificRevision
         * @constructor
         * @param {tool_commands.ISpecificRevision=} [properties] Properties to set
         */
        function SpecificRevision(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecificRevision itemRevisionId.
         * @member {string} itemRevisionId
         * @memberof tool_commands.SpecificRevision
         * @instance
         */
        SpecificRevision.prototype.itemRevisionId = "";

        /**
         * SpecificRevision releaseNum.
         * @member {tool_commands.IReleaseInfo|null|undefined} releaseNum
         * @memberof tool_commands.SpecificRevision
         * @instance
         */
        SpecificRevision.prototype.releaseNum = null;

        /**
         * Creates a new SpecificRevision instance using the specified properties.
         * @function create
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {tool_commands.ISpecificRevision=} [properties] Properties to set
         * @returns {tool_commands.SpecificRevision} SpecificRevision instance
         */
        SpecificRevision.create = function create(properties) {
            return new SpecificRevision(properties);
        };

        /**
         * Encodes the specified SpecificRevision message. Does not implicitly {@link tool_commands.SpecificRevision.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {tool_commands.ISpecificRevision} message SpecificRevision message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevision.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.itemRevisionId != null && Object.hasOwnProperty.call(message, "itemRevisionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemRevisionId);
            if (message.releaseNum != null && Object.hasOwnProperty.call(message, "releaseNum"))
                $root.tool_commands.ReleaseInfo.encode(message.releaseNum, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SpecificRevision message, length delimited. Does not implicitly {@link tool_commands.SpecificRevision.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {tool_commands.ISpecificRevision} message SpecificRevision message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevision.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpecificRevision message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SpecificRevision} SpecificRevision
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevision.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SpecificRevision();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.itemRevisionId = reader.string();
                        break;
                    }
                case 2: {
                        message.releaseNum = $root.tool_commands.ReleaseInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpecificRevision message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SpecificRevision} SpecificRevision
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevision.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpecificRevision message.
         * @function verify
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpecificRevision.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.itemRevisionId != null && message.hasOwnProperty("itemRevisionId"))
                if (!$util.isString(message.itemRevisionId))
                    return "itemRevisionId: string expected";
            if (message.releaseNum != null && message.hasOwnProperty("releaseNum")) {
                var error = $root.tool_commands.ReleaseInfo.verify(message.releaseNum);
                if (error)
                    return "releaseNum." + error;
            }
            return null;
        };

        /**
         * Creates a SpecificRevision message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SpecificRevision} SpecificRevision
         */
        SpecificRevision.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SpecificRevision)
                return object;
            var message = new $root.tool_commands.SpecificRevision();
            if (object.itemRevisionId != null)
                message.itemRevisionId = String(object.itemRevisionId);
            if (object.releaseNum != null) {
                if (typeof object.releaseNum !== "object")
                    throw TypeError(".tool_commands.SpecificRevision.releaseNum: object expected");
                message.releaseNum = $root.tool_commands.ReleaseInfo.fromObject(object.releaseNum);
            }
            return message;
        };

        /**
         * Creates a plain object from a SpecificRevision message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {tool_commands.SpecificRevision} message SpecificRevision
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpecificRevision.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.itemRevisionId = "";
                object.releaseNum = null;
            }
            if (message.itemRevisionId != null && message.hasOwnProperty("itemRevisionId"))
                object.itemRevisionId = message.itemRevisionId;
            if (message.releaseNum != null && message.hasOwnProperty("releaseNum"))
                object.releaseNum = $root.tool_commands.ReleaseInfo.toObject(message.releaseNum, options);
            return object;
        };

        /**
         * Converts this SpecificRevision to JSON.
         * @function toJSON
         * @memberof tool_commands.SpecificRevision
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpecificRevision.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SpecificRevision
         * @function getTypeUrl
         * @memberof tool_commands.SpecificRevision
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SpecificRevision.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SpecificRevision";
        };

        return SpecificRevision;
    })();

    tool_commands.SpecificRevisionsResponse = (function() {

        /**
         * Properties of a SpecificRevisionsResponse.
         * @memberof tool_commands
         * @interface ISpecificRevisionsResponse
         * @property {Array.<tool_commands.ISpecificRevision>|null} [revisions] SpecificRevisionsResponse revisions
         */

        /**
         * Constructs a new SpecificRevisionsResponse.
         * @memberof tool_commands
         * @classdesc Represents a SpecificRevisionsResponse.
         * @implements ISpecificRevisionsResponse
         * @constructor
         * @param {tool_commands.ISpecificRevisionsResponse=} [properties] Properties to set
         */
        function SpecificRevisionsResponse(properties) {
            this.revisions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpecificRevisionsResponse revisions.
         * @member {Array.<tool_commands.ISpecificRevision>} revisions
         * @memberof tool_commands.SpecificRevisionsResponse
         * @instance
         */
        SpecificRevisionsResponse.prototype.revisions = $util.emptyArray;

        /**
         * Creates a new SpecificRevisionsResponse instance using the specified properties.
         * @function create
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {tool_commands.ISpecificRevisionsResponse=} [properties] Properties to set
         * @returns {tool_commands.SpecificRevisionsResponse} SpecificRevisionsResponse instance
         */
        SpecificRevisionsResponse.create = function create(properties) {
            return new SpecificRevisionsResponse(properties);
        };

        /**
         * Encodes the specified SpecificRevisionsResponse message. Does not implicitly {@link tool_commands.SpecificRevisionsResponse.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {tool_commands.ISpecificRevisionsResponse} message SpecificRevisionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevisionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.revisions != null && message.revisions.length)
                for (var i = 0; i < message.revisions.length; ++i)
                    $root.tool_commands.SpecificRevision.encode(message.revisions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SpecificRevisionsResponse message, length delimited. Does not implicitly {@link tool_commands.SpecificRevisionsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {tool_commands.ISpecificRevisionsResponse} message SpecificRevisionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpecificRevisionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpecificRevisionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SpecificRevisionsResponse} SpecificRevisionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevisionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SpecificRevisionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.revisions && message.revisions.length))
                            message.revisions = [];
                        message.revisions.push($root.tool_commands.SpecificRevision.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpecificRevisionsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SpecificRevisionsResponse} SpecificRevisionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpecificRevisionsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpecificRevisionsResponse message.
         * @function verify
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpecificRevisionsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.revisions != null && message.hasOwnProperty("revisions")) {
                if (!Array.isArray(message.revisions))
                    return "revisions: array expected";
                for (var i = 0; i < message.revisions.length; ++i) {
                    var error = $root.tool_commands.SpecificRevision.verify(message.revisions[i]);
                    if (error)
                        return "revisions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SpecificRevisionsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SpecificRevisionsResponse} SpecificRevisionsResponse
         */
        SpecificRevisionsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SpecificRevisionsResponse)
                return object;
            var message = new $root.tool_commands.SpecificRevisionsResponse();
            if (object.revisions) {
                if (!Array.isArray(object.revisions))
                    throw TypeError(".tool_commands.SpecificRevisionsResponse.revisions: array expected");
                message.revisions = [];
                for (var i = 0; i < object.revisions.length; ++i) {
                    if (typeof object.revisions[i] !== "object")
                        throw TypeError(".tool_commands.SpecificRevisionsResponse.revisions: object expected");
                    message.revisions[i] = $root.tool_commands.SpecificRevision.fromObject(object.revisions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SpecificRevisionsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {tool_commands.SpecificRevisionsResponse} message SpecificRevisionsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpecificRevisionsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.revisions = [];
            if (message.revisions && message.revisions.length) {
                object.revisions = [];
                for (var j = 0; j < message.revisions.length; ++j)
                    object.revisions[j] = $root.tool_commands.SpecificRevision.toObject(message.revisions[j], options);
            }
            return object;
        };

        /**
         * Converts this SpecificRevisionsResponse to JSON.
         * @function toJSON
         * @memberof tool_commands.SpecificRevisionsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpecificRevisionsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SpecificRevisionsResponse
         * @function getTypeUrl
         * @memberof tool_commands.SpecificRevisionsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SpecificRevisionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SpecificRevisionsResponse";
        };

        return SpecificRevisionsResponse;
    })();

    tool_commands.DownlaodAddress = (function() {

        /**
         * Properties of a DownlaodAddress.
         * @memberof tool_commands
         * @interface IDownlaodAddress
         * @property {string|null} [url] DownlaodAddress url
         */

        /**
         * Constructs a new DownlaodAddress.
         * @memberof tool_commands
         * @classdesc Represents a DownlaodAddress.
         * @implements IDownlaodAddress
         * @constructor
         * @param {tool_commands.IDownlaodAddress=} [properties] Properties to set
         */
        function DownlaodAddress(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownlaodAddress url.
         * @member {string} url
         * @memberof tool_commands.DownlaodAddress
         * @instance
         */
        DownlaodAddress.prototype.url = "";

        /**
         * Creates a new DownlaodAddress instance using the specified properties.
         * @function create
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {tool_commands.IDownlaodAddress=} [properties] Properties to set
         * @returns {tool_commands.DownlaodAddress} DownlaodAddress instance
         */
        DownlaodAddress.create = function create(properties) {
            return new DownlaodAddress(properties);
        };

        /**
         * Encodes the specified DownlaodAddress message. Does not implicitly {@link tool_commands.DownlaodAddress.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {tool_commands.IDownlaodAddress} message DownlaodAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownlaodAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            return writer;
        };

        /**
         * Encodes the specified DownlaodAddress message, length delimited. Does not implicitly {@link tool_commands.DownlaodAddress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {tool_commands.IDownlaodAddress} message DownlaodAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownlaodAddress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DownlaodAddress message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.DownlaodAddress} DownlaodAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownlaodAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.DownlaodAddress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.url = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DownlaodAddress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.DownlaodAddress} DownlaodAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownlaodAddress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DownlaodAddress message.
         * @function verify
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DownlaodAddress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            return null;
        };

        /**
         * Creates a DownlaodAddress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.DownlaodAddress} DownlaodAddress
         */
        DownlaodAddress.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.DownlaodAddress)
                return object;
            var message = new $root.tool_commands.DownlaodAddress();
            if (object.url != null)
                message.url = String(object.url);
            return message;
        };

        /**
         * Creates a plain object from a DownlaodAddress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {tool_commands.DownlaodAddress} message DownlaodAddress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DownlaodAddress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.url = "";
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            return object;
        };

        /**
         * Converts this DownlaodAddress to JSON.
         * @function toJSON
         * @memberof tool_commands.DownlaodAddress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DownlaodAddress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DownlaodAddress
         * @function getTypeUrl
         * @memberof tool_commands.DownlaodAddress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownlaodAddress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.DownlaodAddress";
        };

        return DownlaodAddress;
    })();

    tool_commands.OtaManifest = (function() {

        /**
         * Properties of an OtaManifest.
         * @memberof tool_commands
         * @interface IOtaManifest
         * @property {string|null} [bucket] OtaManifest bucket
         * @property {string|null} [key] OtaManifest key
         * @property {string|null} [id] OtaManifest id
         * @property {number|Long|null} [size] OtaManifest size
         */

        /**
         * Constructs a new OtaManifest.
         * @memberof tool_commands
         * @classdesc Represents an OtaManifest.
         * @implements IOtaManifest
         * @constructor
         * @param {tool_commands.IOtaManifest=} [properties] Properties to set
         */
        function OtaManifest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtaManifest bucket.
         * @member {string} bucket
         * @memberof tool_commands.OtaManifest
         * @instance
         */
        OtaManifest.prototype.bucket = "";

        /**
         * OtaManifest key.
         * @member {string} key
         * @memberof tool_commands.OtaManifest
         * @instance
         */
        OtaManifest.prototype.key = "";

        /**
         * OtaManifest id.
         * @member {string} id
         * @memberof tool_commands.OtaManifest
         * @instance
         */
        OtaManifest.prototype.id = "";

        /**
         * OtaManifest size.
         * @member {number|Long} size
         * @memberof tool_commands.OtaManifest
         * @instance
         */
        OtaManifest.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new OtaManifest instance using the specified properties.
         * @function create
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {tool_commands.IOtaManifest=} [properties] Properties to set
         * @returns {tool_commands.OtaManifest} OtaManifest instance
         */
        OtaManifest.create = function create(properties) {
            return new OtaManifest(properties);
        };

        /**
         * Encodes the specified OtaManifest message. Does not implicitly {@link tool_commands.OtaManifest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {tool_commands.IOtaManifest} message OtaManifest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtaManifest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bucket != null && Object.hasOwnProperty.call(message, "bucket"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.bucket);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.size);
            return writer;
        };

        /**
         * Encodes the specified OtaManifest message, length delimited. Does not implicitly {@link tool_commands.OtaManifest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {tool_commands.IOtaManifest} message OtaManifest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtaManifest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OtaManifest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.OtaManifest} OtaManifest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtaManifest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.OtaManifest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bucket = reader.string();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                case 3: {
                        message.id = reader.string();
                        break;
                    }
                case 4: {
                        message.size = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OtaManifest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.OtaManifest} OtaManifest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtaManifest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OtaManifest message.
         * @function verify
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OtaManifest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                if (!$util.isString(message.bucket))
                    return "bucket: string expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            return null;
        };

        /**
         * Creates an OtaManifest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.OtaManifest} OtaManifest
         */
        OtaManifest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.OtaManifest)
                return object;
            var message = new $root.tool_commands.OtaManifest();
            if (object.bucket != null)
                message.bucket = String(object.bucket);
            if (object.key != null)
                message.key = String(object.key);
            if (object.id != null)
                message.id = String(object.id);
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = true;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an OtaManifest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {tool_commands.OtaManifest} message OtaManifest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OtaManifest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.bucket = "";
                object.key = "";
                object.id = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
            }
            if (message.bucket != null && message.hasOwnProperty("bucket"))
                object.bucket = message.bucket;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
            return object;
        };

        /**
         * Converts this OtaManifest to JSON.
         * @function toJSON
         * @memberof tool_commands.OtaManifest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OtaManifest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OtaManifest
         * @function getTypeUrl
         * @memberof tool_commands.OtaManifest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OtaManifest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.OtaManifest";
        };

        return OtaManifest;
    })();

    tool_commands.PackageManifest = (function() {

        /**
         * Properties of a PackageManifest.
         * @memberof tool_commands
         * @interface IPackageManifest
         * @property {string|null} [version] PackageManifest version
         * @property {tool_commands.IOtaManifest|null} [ota] PackageManifest ota
         */

        /**
         * Constructs a new PackageManifest.
         * @memberof tool_commands
         * @classdesc Represents a PackageManifest.
         * @implements IPackageManifest
         * @constructor
         * @param {tool_commands.IPackageManifest=} [properties] Properties to set
         */
        function PackageManifest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PackageManifest version.
         * @member {string} version
         * @memberof tool_commands.PackageManifest
         * @instance
         */
        PackageManifest.prototype.version = "";

        /**
         * PackageManifest ota.
         * @member {tool_commands.IOtaManifest|null|undefined} ota
         * @memberof tool_commands.PackageManifest
         * @instance
         */
        PackageManifest.prototype.ota = null;

        /**
         * Creates a new PackageManifest instance using the specified properties.
         * @function create
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {tool_commands.IPackageManifest=} [properties] Properties to set
         * @returns {tool_commands.PackageManifest} PackageManifest instance
         */
        PackageManifest.create = function create(properties) {
            return new PackageManifest(properties);
        };

        /**
         * Encodes the specified PackageManifest message. Does not implicitly {@link tool_commands.PackageManifest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {tool_commands.IPackageManifest} message PackageManifest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageManifest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.version);
            if (message.ota != null && Object.hasOwnProperty.call(message, "ota"))
                $root.tool_commands.OtaManifest.encode(message.ota, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PackageManifest message, length delimited. Does not implicitly {@link tool_commands.PackageManifest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {tool_commands.IPackageManifest} message PackageManifest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageManifest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PackageManifest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.PackageManifest} PackageManifest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageManifest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.PackageManifest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.version = reader.string();
                        break;
                    }
                case 2: {
                        message.ota = $root.tool_commands.OtaManifest.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PackageManifest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.PackageManifest} PackageManifest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageManifest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PackageManifest message.
         * @function verify
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PackageManifest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.ota != null && message.hasOwnProperty("ota")) {
                var error = $root.tool_commands.OtaManifest.verify(message.ota);
                if (error)
                    return "ota." + error;
            }
            return null;
        };

        /**
         * Creates a PackageManifest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.PackageManifest} PackageManifest
         */
        PackageManifest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.PackageManifest)
                return object;
            var message = new $root.tool_commands.PackageManifest();
            if (object.version != null)
                message.version = String(object.version);
            if (object.ota != null) {
                if (typeof object.ota !== "object")
                    throw TypeError(".tool_commands.PackageManifest.ota: object expected");
                message.ota = $root.tool_commands.OtaManifest.fromObject(object.ota);
            }
            return message;
        };

        /**
         * Creates a plain object from a PackageManifest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {tool_commands.PackageManifest} message PackageManifest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageManifest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.version = "";
                object.ota = null;
            }
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.ota != null && message.hasOwnProperty("ota"))
                object.ota = $root.tool_commands.OtaManifest.toObject(message.ota, options);
            return object;
        };

        /**
         * Converts this PackageManifest to JSON.
         * @function toJSON
         * @memberof tool_commands.PackageManifest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageManifest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageManifest
         * @function getTypeUrl
         * @memberof tool_commands.PackageManifest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageManifest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.PackageManifest";
        };

        return PackageManifest;
    })();

    tool_commands.PackageDetails = (function() {

        /**
         * Properties of a PackageDetails.
         * @memberof tool_commands
         * @interface IPackageDetails
         * @property {Array.<tool_commands.IPackageManifest>|null} [manifests] PackageDetails manifests
         */

        /**
         * Constructs a new PackageDetails.
         * @memberof tool_commands
         * @classdesc Represents a PackageDetails.
         * @implements IPackageDetails
         * @constructor
         * @param {tool_commands.IPackageDetails=} [properties] Properties to set
         */
        function PackageDetails(properties) {
            this.manifests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PackageDetails manifests.
         * @member {Array.<tool_commands.IPackageManifest>} manifests
         * @memberof tool_commands.PackageDetails
         * @instance
         */
        PackageDetails.prototype.manifests = $util.emptyArray;

        /**
         * Creates a new PackageDetails instance using the specified properties.
         * @function create
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {tool_commands.IPackageDetails=} [properties] Properties to set
         * @returns {tool_commands.PackageDetails} PackageDetails instance
         */
        PackageDetails.create = function create(properties) {
            return new PackageDetails(properties);
        };

        /**
         * Encodes the specified PackageDetails message. Does not implicitly {@link tool_commands.PackageDetails.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {tool_commands.IPackageDetails} message PackageDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.manifests != null && message.manifests.length)
                for (var i = 0; i < message.manifests.length; ++i)
                    $root.tool_commands.PackageManifest.encode(message.manifests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PackageDetails message, length delimited. Does not implicitly {@link tool_commands.PackageDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {tool_commands.IPackageDetails} message PackageDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PackageDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PackageDetails message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.PackageDetails} PackageDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.PackageDetails();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.manifests && message.manifests.length))
                            message.manifests = [];
                        message.manifests.push($root.tool_commands.PackageManifest.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PackageDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.PackageDetails} PackageDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PackageDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PackageDetails message.
         * @function verify
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PackageDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.manifests != null && message.hasOwnProperty("manifests")) {
                if (!Array.isArray(message.manifests))
                    return "manifests: array expected";
                for (var i = 0; i < message.manifests.length; ++i) {
                    var error = $root.tool_commands.PackageManifest.verify(message.manifests[i]);
                    if (error)
                        return "manifests." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PackageDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.PackageDetails} PackageDetails
         */
        PackageDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.PackageDetails)
                return object;
            var message = new $root.tool_commands.PackageDetails();
            if (object.manifests) {
                if (!Array.isArray(object.manifests))
                    throw TypeError(".tool_commands.PackageDetails.manifests: array expected");
                message.manifests = [];
                for (var i = 0; i < object.manifests.length; ++i) {
                    if (typeof object.manifests[i] !== "object")
                        throw TypeError(".tool_commands.PackageDetails.manifests: object expected");
                    message.manifests[i] = $root.tool_commands.PackageManifest.fromObject(object.manifests[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PackageDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {tool_commands.PackageDetails} message PackageDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PackageDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.manifests = [];
            if (message.manifests && message.manifests.length) {
                object.manifests = [];
                for (var j = 0; j < message.manifests.length; ++j)
                    object.manifests[j] = $root.tool_commands.PackageManifest.toObject(message.manifests[j], options);
            }
            return object;
        };

        /**
         * Converts this PackageDetails to JSON.
         * @function toJSON
         * @memberof tool_commands.PackageDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PackageDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PackageDetails
         * @function getTypeUrl
         * @memberof tool_commands.PackageDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PackageDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.PackageDetails";
        };

        return PackageDetails;
    })();

    tool_commands.VersionComparisonRequest = (function() {

        /**
         * Properties of a VersionComparisonRequest.
         * @memberof tool_commands
         * @interface IVersionComparisonRequest
         * @property {Array.<string>|null} [ecus] VersionComparisonRequest ecus
         * @property {string|null} [root] VersionComparisonRequest root
         */

        /**
         * Constructs a new VersionComparisonRequest.
         * @memberof tool_commands
         * @classdesc Represents a VersionComparisonRequest.
         * @implements IVersionComparisonRequest
         * @constructor
         * @param {tool_commands.IVersionComparisonRequest=} [properties] Properties to set
         */
        function VersionComparisonRequest(properties) {
            this.ecus = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionComparisonRequest ecus.
         * @member {Array.<string>} ecus
         * @memberof tool_commands.VersionComparisonRequest
         * @instance
         */
        VersionComparisonRequest.prototype.ecus = $util.emptyArray;

        /**
         * VersionComparisonRequest root.
         * @member {string} root
         * @memberof tool_commands.VersionComparisonRequest
         * @instance
         */
        VersionComparisonRequest.prototype.root = "";

        /**
         * Creates a new VersionComparisonRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {tool_commands.IVersionComparisonRequest=} [properties] Properties to set
         * @returns {tool_commands.VersionComparisonRequest} VersionComparisonRequest instance
         */
        VersionComparisonRequest.create = function create(properties) {
            return new VersionComparisonRequest(properties);
        };

        /**
         * Encodes the specified VersionComparisonRequest message. Does not implicitly {@link tool_commands.VersionComparisonRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {tool_commands.IVersionComparisonRequest} message VersionComparisonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ecus != null && message.ecus.length)
                for (var i = 0; i < message.ecus.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ecus[i]);
            if (message.root != null && Object.hasOwnProperty.call(message, "root"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.root);
            return writer;
        };

        /**
         * Encodes the specified VersionComparisonRequest message, length delimited. Does not implicitly {@link tool_commands.VersionComparisonRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {tool_commands.IVersionComparisonRequest} message VersionComparisonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionComparisonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.VersionComparisonRequest} VersionComparisonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.VersionComparisonRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ecus && message.ecus.length))
                            message.ecus = [];
                        message.ecus.push(reader.string());
                        break;
                    }
                case 2: {
                        message.root = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionComparisonRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.VersionComparisonRequest} VersionComparisonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionComparisonRequest message.
         * @function verify
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionComparisonRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ecus != null && message.hasOwnProperty("ecus")) {
                if (!Array.isArray(message.ecus))
                    return "ecus: array expected";
                for (var i = 0; i < message.ecus.length; ++i)
                    if (!$util.isString(message.ecus[i]))
                        return "ecus: string[] expected";
            }
            if (message.root != null && message.hasOwnProperty("root"))
                if (!$util.isString(message.root))
                    return "root: string expected";
            return null;
        };

        /**
         * Creates a VersionComparisonRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.VersionComparisonRequest} VersionComparisonRequest
         */
        VersionComparisonRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.VersionComparisonRequest)
                return object;
            var message = new $root.tool_commands.VersionComparisonRequest();
            if (object.ecus) {
                if (!Array.isArray(object.ecus))
                    throw TypeError(".tool_commands.VersionComparisonRequest.ecus: array expected");
                message.ecus = [];
                for (var i = 0; i < object.ecus.length; ++i)
                    message.ecus[i] = String(object.ecus[i]);
            }
            if (object.root != null)
                message.root = String(object.root);
            return message;
        };

        /**
         * Creates a plain object from a VersionComparisonRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {tool_commands.VersionComparisonRequest} message VersionComparisonRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionComparisonRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ecus = [];
            if (options.defaults)
                object.root = "";
            if (message.ecus && message.ecus.length) {
                object.ecus = [];
                for (var j = 0; j < message.ecus.length; ++j)
                    object.ecus[j] = message.ecus[j];
            }
            if (message.root != null && message.hasOwnProperty("root"))
                object.root = message.root;
            return object;
        };

        /**
         * Converts this VersionComparisonRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.VersionComparisonRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionComparisonRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionComparisonRequest
         * @function getTypeUrl
         * @memberof tool_commands.VersionComparisonRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionComparisonRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.VersionComparisonRequest";
        };

        return VersionComparisonRequest;
    })();

    /**
     * ComparedComponent enum.
     * @name tool_commands.ComparedComponent
     * @enum {number}
     * @property {number} BOOTLOADER=0 BOOTLOADER value
     * @property {number} UNKNOWN=1 UNKNOWN value
     * @property {number} APPLICATION=2 APPLICATION value
     */
    tool_commands.ComparedComponent = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BOOTLOADER"] = 0;
        values[valuesById[1] = "UNKNOWN"] = 1;
        values[valuesById[2] = "APPLICATION"] = 2;
        return values;
    })();

    tool_commands.ComponentComparisonResult = (function() {

        /**
         * Properties of a ComponentComparisonResult.
         * @memberof tool_commands
         * @interface IComponentComparisonResult
         * @property {tool_commands.ComparedComponent|null} [component] ComponentComparisonResult component
         * @property {string|null} [installedVersion] ComponentComparisonResult installedVersion
         * @property {string|null} [availableVersion] ComponentComparisonResult availableVersion
         * @property {boolean|null} [isComparable] ComponentComparisonResult isComparable
         */

        /**
         * Constructs a new ComponentComparisonResult.
         * @memberof tool_commands
         * @classdesc Represents a ComponentComparisonResult.
         * @implements IComponentComparisonResult
         * @constructor
         * @param {tool_commands.IComponentComparisonResult=} [properties] Properties to set
         */
        function ComponentComparisonResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComponentComparisonResult component.
         * @member {tool_commands.ComparedComponent} component
         * @memberof tool_commands.ComponentComparisonResult
         * @instance
         */
        ComponentComparisonResult.prototype.component = 1;

        /**
         * ComponentComparisonResult installedVersion.
         * @member {string} installedVersion
         * @memberof tool_commands.ComponentComparisonResult
         * @instance
         */
        ComponentComparisonResult.prototype.installedVersion = "n/a";

        /**
         * ComponentComparisonResult availableVersion.
         * @member {string} availableVersion
         * @memberof tool_commands.ComponentComparisonResult
         * @instance
         */
        ComponentComparisonResult.prototype.availableVersion = "n/a";

        /**
         * ComponentComparisonResult isComparable.
         * @member {boolean} isComparable
         * @memberof tool_commands.ComponentComparisonResult
         * @instance
         */
        ComponentComparisonResult.prototype.isComparable = true;

        /**
         * Creates a new ComponentComparisonResult instance using the specified properties.
         * @function create
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {tool_commands.IComponentComparisonResult=} [properties] Properties to set
         * @returns {tool_commands.ComponentComparisonResult} ComponentComparisonResult instance
         */
        ComponentComparisonResult.create = function create(properties) {
            return new ComponentComparisonResult(properties);
        };

        /**
         * Encodes the specified ComponentComparisonResult message. Does not implicitly {@link tool_commands.ComponentComparisonResult.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {tool_commands.IComponentComparisonResult} message ComponentComparisonResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComponentComparisonResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.component != null && Object.hasOwnProperty.call(message, "component"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.component);
            if (message.installedVersion != null && Object.hasOwnProperty.call(message, "installedVersion"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.installedVersion);
            if (message.availableVersion != null && Object.hasOwnProperty.call(message, "availableVersion"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.availableVersion);
            if (message.isComparable != null && Object.hasOwnProperty.call(message, "isComparable"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isComparable);
            return writer;
        };

        /**
         * Encodes the specified ComponentComparisonResult message, length delimited. Does not implicitly {@link tool_commands.ComponentComparisonResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {tool_commands.IComponentComparisonResult} message ComponentComparisonResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComponentComparisonResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComponentComparisonResult message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ComponentComparisonResult} ComponentComparisonResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComponentComparisonResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ComponentComparisonResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.component = reader.int32();
                        break;
                    }
                case 2: {
                        message.installedVersion = reader.string();
                        break;
                    }
                case 3: {
                        message.availableVersion = reader.string();
                        break;
                    }
                case 4: {
                        message.isComparable = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComponentComparisonResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ComponentComparisonResult} ComponentComparisonResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComponentComparisonResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComponentComparisonResult message.
         * @function verify
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComponentComparisonResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.component != null && message.hasOwnProperty("component"))
                switch (message.component) {
                default:
                    return "component: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.installedVersion != null && message.hasOwnProperty("installedVersion"))
                if (!$util.isString(message.installedVersion))
                    return "installedVersion: string expected";
            if (message.availableVersion != null && message.hasOwnProperty("availableVersion"))
                if (!$util.isString(message.availableVersion))
                    return "availableVersion: string expected";
            if (message.isComparable != null && message.hasOwnProperty("isComparable"))
                if (typeof message.isComparable !== "boolean")
                    return "isComparable: boolean expected";
            return null;
        };

        /**
         * Creates a ComponentComparisonResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ComponentComparisonResult} ComponentComparisonResult
         */
        ComponentComparisonResult.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ComponentComparisonResult)
                return object;
            var message = new $root.tool_commands.ComponentComparisonResult();
            switch (object.component) {
            case "BOOTLOADER":
            case 0:
                message.component = 0;
                break;
            default:
                if (typeof object.component === "number") {
                    message.component = object.component;
                    break;
                }
                break;
            case "UNKNOWN":
            case 1:
                message.component = 1;
                break;
            case "APPLICATION":
            case 2:
                message.component = 2;
                break;
            }
            if (object.installedVersion != null)
                message.installedVersion = String(object.installedVersion);
            if (object.availableVersion != null)
                message.availableVersion = String(object.availableVersion);
            if (object.isComparable != null)
                message.isComparable = Boolean(object.isComparable);
            return message;
        };

        /**
         * Creates a plain object from a ComponentComparisonResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {tool_commands.ComponentComparisonResult} message ComponentComparisonResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComponentComparisonResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.component = options.enums === String ? "UNKNOWN" : 1;
                object.installedVersion = "n/a";
                object.availableVersion = "n/a";
                object.isComparable = true;
            }
            if (message.component != null && message.hasOwnProperty("component"))
                object.component = options.enums === String ? $root.tool_commands.ComparedComponent[message.component] === undefined ? message.component : $root.tool_commands.ComparedComponent[message.component] : message.component;
            if (message.installedVersion != null && message.hasOwnProperty("installedVersion"))
                object.installedVersion = message.installedVersion;
            if (message.availableVersion != null && message.hasOwnProperty("availableVersion"))
                object.availableVersion = message.availableVersion;
            if (message.isComparable != null && message.hasOwnProperty("isComparable"))
                object.isComparable = message.isComparable;
            return object;
        };

        /**
         * Converts this ComponentComparisonResult to JSON.
         * @function toJSON
         * @memberof tool_commands.ComponentComparisonResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComponentComparisonResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComponentComparisonResult
         * @function getTypeUrl
         * @memberof tool_commands.ComponentComparisonResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComponentComparisonResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ComponentComparisonResult";
        };

        return ComponentComparisonResult;
    })();

    tool_commands.VersionComparisonEcuInfo = (function() {

        /**
         * Properties of a VersionComparisonEcuInfo.
         * @memberof tool_commands
         * @interface IVersionComparisonEcuInfo
         * @property {string} ecu VersionComparisonEcuInfo ecu
         * @property {Array.<tool_commands.IComponentComparisonResult>|null} [component] VersionComparisonEcuInfo component
         */

        /**
         * Constructs a new VersionComparisonEcuInfo.
         * @memberof tool_commands
         * @classdesc Represents a VersionComparisonEcuInfo.
         * @implements IVersionComparisonEcuInfo
         * @constructor
         * @param {tool_commands.IVersionComparisonEcuInfo=} [properties] Properties to set
         */
        function VersionComparisonEcuInfo(properties) {
            this.component = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionComparisonEcuInfo ecu.
         * @member {string} ecu
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @instance
         */
        VersionComparisonEcuInfo.prototype.ecu = "";

        /**
         * VersionComparisonEcuInfo component.
         * @member {Array.<tool_commands.IComponentComparisonResult>} component
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @instance
         */
        VersionComparisonEcuInfo.prototype.component = $util.emptyArray;

        /**
         * Creates a new VersionComparisonEcuInfo instance using the specified properties.
         * @function create
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {tool_commands.IVersionComparisonEcuInfo=} [properties] Properties to set
         * @returns {tool_commands.VersionComparisonEcuInfo} VersionComparisonEcuInfo instance
         */
        VersionComparisonEcuInfo.create = function create(properties) {
            return new VersionComparisonEcuInfo(properties);
        };

        /**
         * Encodes the specified VersionComparisonEcuInfo message. Does not implicitly {@link tool_commands.VersionComparisonEcuInfo.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {tool_commands.IVersionComparisonEcuInfo} message VersionComparisonEcuInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonEcuInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ecu);
            if (message.component != null && message.component.length)
                for (var i = 0; i < message.component.length; ++i)
                    $root.tool_commands.ComponentComparisonResult.encode(message.component[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified VersionComparisonEcuInfo message, length delimited. Does not implicitly {@link tool_commands.VersionComparisonEcuInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {tool_commands.IVersionComparisonEcuInfo} message VersionComparisonEcuInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonEcuInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionComparisonEcuInfo message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.VersionComparisonEcuInfo} VersionComparisonEcuInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonEcuInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.VersionComparisonEcuInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ecu = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.component && message.component.length))
                            message.component = [];
                        message.component.push($root.tool_commands.ComponentComparisonResult.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("ecu"))
                throw $util.ProtocolError("missing required 'ecu'", { instance: message });
            return message;
        };

        /**
         * Decodes a VersionComparisonEcuInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.VersionComparisonEcuInfo} VersionComparisonEcuInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonEcuInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionComparisonEcuInfo message.
         * @function verify
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionComparisonEcuInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.ecu))
                return "ecu: string expected";
            if (message.component != null && message.hasOwnProperty("component")) {
                if (!Array.isArray(message.component))
                    return "component: array expected";
                for (var i = 0; i < message.component.length; ++i) {
                    var error = $root.tool_commands.ComponentComparisonResult.verify(message.component[i]);
                    if (error)
                        return "component." + error;
                }
            }
            return null;
        };

        /**
         * Creates a VersionComparisonEcuInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.VersionComparisonEcuInfo} VersionComparisonEcuInfo
         */
        VersionComparisonEcuInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.VersionComparisonEcuInfo)
                return object;
            var message = new $root.tool_commands.VersionComparisonEcuInfo();
            if (object.ecu != null)
                message.ecu = String(object.ecu);
            if (object.component) {
                if (!Array.isArray(object.component))
                    throw TypeError(".tool_commands.VersionComparisonEcuInfo.component: array expected");
                message.component = [];
                for (var i = 0; i < object.component.length; ++i) {
                    if (typeof object.component[i] !== "object")
                        throw TypeError(".tool_commands.VersionComparisonEcuInfo.component: object expected");
                    message.component[i] = $root.tool_commands.ComponentComparisonResult.fromObject(object.component[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a VersionComparisonEcuInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {tool_commands.VersionComparisonEcuInfo} message VersionComparisonEcuInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionComparisonEcuInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.component = [];
            if (options.defaults)
                object.ecu = "";
            if (message.ecu != null && message.hasOwnProperty("ecu"))
                object.ecu = message.ecu;
            if (message.component && message.component.length) {
                object.component = [];
                for (var j = 0; j < message.component.length; ++j)
                    object.component[j] = $root.tool_commands.ComponentComparisonResult.toObject(message.component[j], options);
            }
            return object;
        };

        /**
         * Converts this VersionComparisonEcuInfo to JSON.
         * @function toJSON
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionComparisonEcuInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionComparisonEcuInfo
         * @function getTypeUrl
         * @memberof tool_commands.VersionComparisonEcuInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionComparisonEcuInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.VersionComparisonEcuInfo";
        };

        return VersionComparisonEcuInfo;
    })();

    tool_commands.VersionComparisonResponse = (function() {

        /**
         * Properties of a VersionComparisonResponse.
         * @memberof tool_commands
         * @interface IVersionComparisonResponse
         * @property {Array.<tool_commands.IVersionComparisonEcuInfo>|null} [comparisonResult] VersionComparisonResponse comparisonResult
         */

        /**
         * Constructs a new VersionComparisonResponse.
         * @memberof tool_commands
         * @classdesc Represents a VersionComparisonResponse.
         * @implements IVersionComparisonResponse
         * @constructor
         * @param {tool_commands.IVersionComparisonResponse=} [properties] Properties to set
         */
        function VersionComparisonResponse(properties) {
            this.comparisonResult = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionComparisonResponse comparisonResult.
         * @member {Array.<tool_commands.IVersionComparisonEcuInfo>} comparisonResult
         * @memberof tool_commands.VersionComparisonResponse
         * @instance
         */
        VersionComparisonResponse.prototype.comparisonResult = $util.emptyArray;

        /**
         * Creates a new VersionComparisonResponse instance using the specified properties.
         * @function create
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {tool_commands.IVersionComparisonResponse=} [properties] Properties to set
         * @returns {tool_commands.VersionComparisonResponse} VersionComparisonResponse instance
         */
        VersionComparisonResponse.create = function create(properties) {
            return new VersionComparisonResponse(properties);
        };

        /**
         * Encodes the specified VersionComparisonResponse message. Does not implicitly {@link tool_commands.VersionComparisonResponse.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {tool_commands.IVersionComparisonResponse} message VersionComparisonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.comparisonResult != null && message.comparisonResult.length)
                for (var i = 0; i < message.comparisonResult.length; ++i)
                    $root.tool_commands.VersionComparisonEcuInfo.encode(message.comparisonResult[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified VersionComparisonResponse message, length delimited. Does not implicitly {@link tool_commands.VersionComparisonResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {tool_commands.IVersionComparisonResponse} message VersionComparisonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionComparisonResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionComparisonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.VersionComparisonResponse} VersionComparisonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.VersionComparisonResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.comparisonResult && message.comparisonResult.length))
                            message.comparisonResult = [];
                        message.comparisonResult.push($root.tool_commands.VersionComparisonEcuInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionComparisonResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.VersionComparisonResponse} VersionComparisonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionComparisonResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionComparisonResponse message.
         * @function verify
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionComparisonResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.comparisonResult != null && message.hasOwnProperty("comparisonResult")) {
                if (!Array.isArray(message.comparisonResult))
                    return "comparisonResult: array expected";
                for (var i = 0; i < message.comparisonResult.length; ++i) {
                    var error = $root.tool_commands.VersionComparisonEcuInfo.verify(message.comparisonResult[i]);
                    if (error)
                        return "comparisonResult." + error;
                }
            }
            return null;
        };

        /**
         * Creates a VersionComparisonResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.VersionComparisonResponse} VersionComparisonResponse
         */
        VersionComparisonResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.VersionComparisonResponse)
                return object;
            var message = new $root.tool_commands.VersionComparisonResponse();
            if (object.comparisonResult) {
                if (!Array.isArray(object.comparisonResult))
                    throw TypeError(".tool_commands.VersionComparisonResponse.comparisonResult: array expected");
                message.comparisonResult = [];
                for (var i = 0; i < object.comparisonResult.length; ++i) {
                    if (typeof object.comparisonResult[i] !== "object")
                        throw TypeError(".tool_commands.VersionComparisonResponse.comparisonResult: object expected");
                    message.comparisonResult[i] = $root.tool_commands.VersionComparisonEcuInfo.fromObject(object.comparisonResult[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a VersionComparisonResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {tool_commands.VersionComparisonResponse} message VersionComparisonResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionComparisonResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.comparisonResult = [];
            if (message.comparisonResult && message.comparisonResult.length) {
                object.comparisonResult = [];
                for (var j = 0; j < message.comparisonResult.length; ++j)
                    object.comparisonResult[j] = $root.tool_commands.VersionComparisonEcuInfo.toObject(message.comparisonResult[j], options);
            }
            return object;
        };

        /**
         * Converts this VersionComparisonResponse to JSON.
         * @function toJSON
         * @memberof tool_commands.VersionComparisonResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionComparisonResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VersionComparisonResponse
         * @function getTypeUrl
         * @memberof tool_commands.VersionComparisonResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VersionComparisonResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.VersionComparisonResponse";
        };

        return VersionComparisonResponse;
    })();

    tool_commands.SecurityAccessRequest = (function() {

        /**
         * Properties of a SecurityAccessRequest.
         * @memberof tool_commands
         * @interface ISecurityAccessRequest
         * @property {string} name SecurityAccessRequest name
         * @property {number|null} [component] SecurityAccessRequest component
         * @property {number|null} [sessionControl] SecurityAccessRequest sessionControl
         */

        /**
         * Constructs a new SecurityAccessRequest.
         * @memberof tool_commands
         * @classdesc Represents a SecurityAccessRequest.
         * @implements ISecurityAccessRequest
         * @constructor
         * @param {tool_commands.ISecurityAccessRequest=} [properties] Properties to set
         */
        function SecurityAccessRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecurityAccessRequest name.
         * @member {string} name
         * @memberof tool_commands.SecurityAccessRequest
         * @instance
         */
        SecurityAccessRequest.prototype.name = "";

        /**
         * SecurityAccessRequest component.
         * @member {number} component
         * @memberof tool_commands.SecurityAccessRequest
         * @instance
         */
        SecurityAccessRequest.prototype.component = 0;

        /**
         * SecurityAccessRequest sessionControl.
         * @member {number} sessionControl
         * @memberof tool_commands.SecurityAccessRequest
         * @instance
         */
        SecurityAccessRequest.prototype.sessionControl = 2;

        /**
         * Creates a new SecurityAccessRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {tool_commands.ISecurityAccessRequest=} [properties] Properties to set
         * @returns {tool_commands.SecurityAccessRequest} SecurityAccessRequest instance
         */
        SecurityAccessRequest.create = function create(properties) {
            return new SecurityAccessRequest(properties);
        };

        /**
         * Encodes the specified SecurityAccessRequest message. Does not implicitly {@link tool_commands.SecurityAccessRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {tool_commands.ISecurityAccessRequest} message SecurityAccessRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecurityAccessRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.component != null && Object.hasOwnProperty.call(message, "component"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.component);
            if (message.sessionControl != null && Object.hasOwnProperty.call(message, "sessionControl"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.sessionControl);
            return writer;
        };

        /**
         * Encodes the specified SecurityAccessRequest message, length delimited. Does not implicitly {@link tool_commands.SecurityAccessRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {tool_commands.ISecurityAccessRequest} message SecurityAccessRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecurityAccessRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecurityAccessRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SecurityAccessRequest} SecurityAccessRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecurityAccessRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SecurityAccessRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.component = reader.uint32();
                        break;
                    }
                case 3: {
                        message.sessionControl = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a SecurityAccessRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SecurityAccessRequest} SecurityAccessRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecurityAccessRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecurityAccessRequest message.
         * @function verify
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecurityAccessRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.component != null && message.hasOwnProperty("component"))
                if (!$util.isInteger(message.component))
                    return "component: integer expected";
            if (message.sessionControl != null && message.hasOwnProperty("sessionControl"))
                if (!$util.isInteger(message.sessionControl))
                    return "sessionControl: integer expected";
            return null;
        };

        /**
         * Creates a SecurityAccessRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SecurityAccessRequest} SecurityAccessRequest
         */
        SecurityAccessRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SecurityAccessRequest)
                return object;
            var message = new $root.tool_commands.SecurityAccessRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.component != null)
                message.component = object.component >>> 0;
            if (object.sessionControl != null)
                message.sessionControl = object.sessionControl >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a SecurityAccessRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {tool_commands.SecurityAccessRequest} message SecurityAccessRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecurityAccessRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.component = 0;
                object.sessionControl = 2;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.component != null && message.hasOwnProperty("component"))
                object.component = message.component;
            if (message.sessionControl != null && message.hasOwnProperty("sessionControl"))
                object.sessionControl = message.sessionControl;
            return object;
        };

        /**
         * Converts this SecurityAccessRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.SecurityAccessRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecurityAccessRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecurityAccessRequest
         * @function getTypeUrl
         * @memberof tool_commands.SecurityAccessRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecurityAccessRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SecurityAccessRequest";
        };

        return SecurityAccessRequest;
    })();

    tool_commands.SecurityAccessResponse = (function() {

        /**
         * Properties of a SecurityAccessResponse.
         * @memberof tool_commands
         * @interface ISecurityAccessResponse
         * @property {Uint8Array|null} [seed] SecurityAccessResponse seed
         * @property {Uint8Array|null} [key] SecurityAccessResponse key
         * @property {boolean|null} [seedRejected] SecurityAccessResponse seedRejected
         * @property {number|null} [statusDetails] SecurityAccessResponse statusDetails
         * @property {boolean|null} [sessionSwitchFailed] SecurityAccessResponse sessionSwitchFailed
         */

        /**
         * Constructs a new SecurityAccessResponse.
         * @memberof tool_commands
         * @classdesc Represents a SecurityAccessResponse.
         * @implements ISecurityAccessResponse
         * @constructor
         * @param {tool_commands.ISecurityAccessResponse=} [properties] Properties to set
         */
        function SecurityAccessResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SecurityAccessResponse seed.
         * @member {Uint8Array} seed
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         */
        SecurityAccessResponse.prototype.seed = $util.newBuffer([]);

        /**
         * SecurityAccessResponse key.
         * @member {Uint8Array} key
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         */
        SecurityAccessResponse.prototype.key = $util.newBuffer([]);

        /**
         * SecurityAccessResponse seedRejected.
         * @member {boolean} seedRejected
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         */
        SecurityAccessResponse.prototype.seedRejected = false;

        /**
         * SecurityAccessResponse statusDetails.
         * @member {number} statusDetails
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         */
        SecurityAccessResponse.prototype.statusDetails = 0;

        /**
         * SecurityAccessResponse sessionSwitchFailed.
         * @member {boolean} sessionSwitchFailed
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         */
        SecurityAccessResponse.prototype.sessionSwitchFailed = false;

        /**
         * Creates a new SecurityAccessResponse instance using the specified properties.
         * @function create
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {tool_commands.ISecurityAccessResponse=} [properties] Properties to set
         * @returns {tool_commands.SecurityAccessResponse} SecurityAccessResponse instance
         */
        SecurityAccessResponse.create = function create(properties) {
            return new SecurityAccessResponse(properties);
        };

        /**
         * Encodes the specified SecurityAccessResponse message. Does not implicitly {@link tool_commands.SecurityAccessResponse.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {tool_commands.ISecurityAccessResponse} message SecurityAccessResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecurityAccessResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seed != null && Object.hasOwnProperty.call(message, "seed"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.seed);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.key);
            if (message.seedRejected != null && Object.hasOwnProperty.call(message, "seedRejected"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.seedRejected);
            if (message.statusDetails != null && Object.hasOwnProperty.call(message, "statusDetails"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.statusDetails);
            if (message.sessionSwitchFailed != null && Object.hasOwnProperty.call(message, "sessionSwitchFailed"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.sessionSwitchFailed);
            return writer;
        };

        /**
         * Encodes the specified SecurityAccessResponse message, length delimited. Does not implicitly {@link tool_commands.SecurityAccessResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {tool_commands.ISecurityAccessResponse} message SecurityAccessResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SecurityAccessResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SecurityAccessResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.SecurityAccessResponse} SecurityAccessResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecurityAccessResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.SecurityAccessResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.seed = reader.bytes();
                        break;
                    }
                case 2: {
                        message.key = reader.bytes();
                        break;
                    }
                case 3: {
                        message.seedRejected = reader.bool();
                        break;
                    }
                case 4: {
                        message.statusDetails = reader.uint32();
                        break;
                    }
                case 5: {
                        message.sessionSwitchFailed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SecurityAccessResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.SecurityAccessResponse} SecurityAccessResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SecurityAccessResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SecurityAccessResponse message.
         * @function verify
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SecurityAccessResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (!(message.seed && typeof message.seed.length === "number" || $util.isString(message.seed)))
                    return "seed: buffer expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!(message.key && typeof message.key.length === "number" || $util.isString(message.key)))
                    return "key: buffer expected";
            if (message.seedRejected != null && message.hasOwnProperty("seedRejected"))
                if (typeof message.seedRejected !== "boolean")
                    return "seedRejected: boolean expected";
            if (message.statusDetails != null && message.hasOwnProperty("statusDetails"))
                if (!$util.isInteger(message.statusDetails))
                    return "statusDetails: integer expected";
            if (message.sessionSwitchFailed != null && message.hasOwnProperty("sessionSwitchFailed"))
                if (typeof message.sessionSwitchFailed !== "boolean")
                    return "sessionSwitchFailed: boolean expected";
            return null;
        };

        /**
         * Creates a SecurityAccessResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.SecurityAccessResponse} SecurityAccessResponse
         */
        SecurityAccessResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.SecurityAccessResponse)
                return object;
            var message = new $root.tool_commands.SecurityAccessResponse();
            if (object.seed != null)
                if (typeof object.seed === "string")
                    $util.base64.decode(object.seed, message.seed = $util.newBuffer($util.base64.length(object.seed)), 0);
                else if (object.seed.length >= 0)
                    message.seed = object.seed;
            if (object.key != null)
                if (typeof object.key === "string")
                    $util.base64.decode(object.key, message.key = $util.newBuffer($util.base64.length(object.key)), 0);
                else if (object.key.length >= 0)
                    message.key = object.key;
            if (object.seedRejected != null)
                message.seedRejected = Boolean(object.seedRejected);
            if (object.statusDetails != null)
                message.statusDetails = object.statusDetails >>> 0;
            if (object.sessionSwitchFailed != null)
                message.sessionSwitchFailed = Boolean(object.sessionSwitchFailed);
            return message;
        };

        /**
         * Creates a plain object from a SecurityAccessResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {tool_commands.SecurityAccessResponse} message SecurityAccessResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SecurityAccessResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.seed = "";
                else {
                    object.seed = [];
                    if (options.bytes !== Array)
                        object.seed = $util.newBuffer(object.seed);
                }
                if (options.bytes === String)
                    object.key = "";
                else {
                    object.key = [];
                    if (options.bytes !== Array)
                        object.key = $util.newBuffer(object.key);
                }
                object.seedRejected = false;
                object.statusDetails = 0;
                object.sessionSwitchFailed = false;
            }
            if (message.seed != null && message.hasOwnProperty("seed"))
                object.seed = options.bytes === String ? $util.base64.encode(message.seed, 0, message.seed.length) : options.bytes === Array ? Array.prototype.slice.call(message.seed) : message.seed;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = options.bytes === String ? $util.base64.encode(message.key, 0, message.key.length) : options.bytes === Array ? Array.prototype.slice.call(message.key) : message.key;
            if (message.seedRejected != null && message.hasOwnProperty("seedRejected"))
                object.seedRejected = message.seedRejected;
            if (message.statusDetails != null && message.hasOwnProperty("statusDetails"))
                object.statusDetails = message.statusDetails;
            if (message.sessionSwitchFailed != null && message.hasOwnProperty("sessionSwitchFailed"))
                object.sessionSwitchFailed = message.sessionSwitchFailed;
            return object;
        };

        /**
         * Converts this SecurityAccessResponse to JSON.
         * @function toJSON
         * @memberof tool_commands.SecurityAccessResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SecurityAccessResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SecurityAccessResponse
         * @function getTypeUrl
         * @memberof tool_commands.SecurityAccessResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SecurityAccessResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.SecurityAccessResponse";
        };

        return SecurityAccessResponse;
    })();

    tool_commands.TesterPresentLoop = (function() {

        /**
         * Properties of a TesterPresentLoop.
         * @memberof tool_commands
         * @interface ITesterPresentLoop
         * @property {string} name TesterPresentLoop name
         * @property {number|null} [intervalMs] TesterPresentLoop intervalMs
         */

        /**
         * Constructs a new TesterPresentLoop.
         * @memberof tool_commands
         * @classdesc Represents a TesterPresentLoop.
         * @implements ITesterPresentLoop
         * @constructor
         * @param {tool_commands.ITesterPresentLoop=} [properties] Properties to set
         */
        function TesterPresentLoop(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TesterPresentLoop name.
         * @member {string} name
         * @memberof tool_commands.TesterPresentLoop
         * @instance
         */
        TesterPresentLoop.prototype.name = "";

        /**
         * TesterPresentLoop intervalMs.
         * @member {number} intervalMs
         * @memberof tool_commands.TesterPresentLoop
         * @instance
         */
        TesterPresentLoop.prototype.intervalMs = 0;

        /**
         * Creates a new TesterPresentLoop instance using the specified properties.
         * @function create
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {tool_commands.ITesterPresentLoop=} [properties] Properties to set
         * @returns {tool_commands.TesterPresentLoop} TesterPresentLoop instance
         */
        TesterPresentLoop.create = function create(properties) {
            return new TesterPresentLoop(properties);
        };

        /**
         * Encodes the specified TesterPresentLoop message. Does not implicitly {@link tool_commands.TesterPresentLoop.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {tool_commands.ITesterPresentLoop} message TesterPresentLoop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TesterPresentLoop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.intervalMs != null && Object.hasOwnProperty.call(message, "intervalMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.intervalMs);
            return writer;
        };

        /**
         * Encodes the specified TesterPresentLoop message, length delimited. Does not implicitly {@link tool_commands.TesterPresentLoop.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {tool_commands.ITesterPresentLoop} message TesterPresentLoop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TesterPresentLoop.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TesterPresentLoop message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.TesterPresentLoop} TesterPresentLoop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TesterPresentLoop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.TesterPresentLoop();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.intervalMs = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a TesterPresentLoop message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.TesterPresentLoop} TesterPresentLoop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TesterPresentLoop.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TesterPresentLoop message.
         * @function verify
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TesterPresentLoop.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.intervalMs != null && message.hasOwnProperty("intervalMs"))
                if (!$util.isInteger(message.intervalMs))
                    return "intervalMs: integer expected";
            return null;
        };

        /**
         * Creates a TesterPresentLoop message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.TesterPresentLoop} TesterPresentLoop
         */
        TesterPresentLoop.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.TesterPresentLoop)
                return object;
            var message = new $root.tool_commands.TesterPresentLoop();
            if (object.name != null)
                message.name = String(object.name);
            if (object.intervalMs != null)
                message.intervalMs = object.intervalMs >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a TesterPresentLoop message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {tool_commands.TesterPresentLoop} message TesterPresentLoop
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TesterPresentLoop.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.name = "";
                object.intervalMs = 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.intervalMs != null && message.hasOwnProperty("intervalMs"))
                object.intervalMs = message.intervalMs;
            return object;
        };

        /**
         * Converts this TesterPresentLoop to JSON.
         * @function toJSON
         * @memberof tool_commands.TesterPresentLoop
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TesterPresentLoop.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TesterPresentLoop
         * @function getTypeUrl
         * @memberof tool_commands.TesterPresentLoop
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TesterPresentLoop.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.TesterPresentLoop";
        };

        return TesterPresentLoop;
    })();

    tool_commands.CfrmEolCalibrationRequest = (function() {

        /**
         * Properties of a CfrmEolCalibrationRequest.
         * @memberof tool_commands
         * @interface ICfrmEolCalibrationRequest
         * @property {number|null} [mountPosition] CfrmEolCalibrationRequest mountPosition
         * @property {number|null} [targetPosition] CfrmEolCalibrationRequest targetPosition
         */

        /**
         * Constructs a new CfrmEolCalibrationRequest.
         * @memberof tool_commands
         * @classdesc Represents a CfrmEolCalibrationRequest.
         * @implements ICfrmEolCalibrationRequest
         * @constructor
         * @param {tool_commands.ICfrmEolCalibrationRequest=} [properties] Properties to set
         */
        function CfrmEolCalibrationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CfrmEolCalibrationRequest mountPosition.
         * @member {number} mountPosition
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @instance
         */
        CfrmEolCalibrationRequest.prototype.mountPosition = 0;

        /**
         * CfrmEolCalibrationRequest targetPosition.
         * @member {number} targetPosition
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @instance
         */
        CfrmEolCalibrationRequest.prototype.targetPosition = 0;

        /**
         * Creates a new CfrmEolCalibrationRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {tool_commands.ICfrmEolCalibrationRequest=} [properties] Properties to set
         * @returns {tool_commands.CfrmEolCalibrationRequest} CfrmEolCalibrationRequest instance
         */
        CfrmEolCalibrationRequest.create = function create(properties) {
            return new CfrmEolCalibrationRequest(properties);
        };

        /**
         * Encodes the specified CfrmEolCalibrationRequest message. Does not implicitly {@link tool_commands.CfrmEolCalibrationRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {tool_commands.ICfrmEolCalibrationRequest} message CfrmEolCalibrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CfrmEolCalibrationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mountPosition != null && Object.hasOwnProperty.call(message, "mountPosition"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.mountPosition);
            if (message.targetPosition != null && Object.hasOwnProperty.call(message, "targetPosition"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.targetPosition);
            return writer;
        };

        /**
         * Encodes the specified CfrmEolCalibrationRequest message, length delimited. Does not implicitly {@link tool_commands.CfrmEolCalibrationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {tool_commands.ICfrmEolCalibrationRequest} message CfrmEolCalibrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CfrmEolCalibrationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CfrmEolCalibrationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.CfrmEolCalibrationRequest} CfrmEolCalibrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CfrmEolCalibrationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.CfrmEolCalibrationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mountPosition = reader.float();
                        break;
                    }
                case 2: {
                        message.targetPosition = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CfrmEolCalibrationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.CfrmEolCalibrationRequest} CfrmEolCalibrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CfrmEolCalibrationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CfrmEolCalibrationRequest message.
         * @function verify
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CfrmEolCalibrationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mountPosition != null && message.hasOwnProperty("mountPosition"))
                if (typeof message.mountPosition !== "number")
                    return "mountPosition: number expected";
            if (message.targetPosition != null && message.hasOwnProperty("targetPosition"))
                if (typeof message.targetPosition !== "number")
                    return "targetPosition: number expected";
            return null;
        };

        /**
         * Creates a CfrmEolCalibrationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.CfrmEolCalibrationRequest} CfrmEolCalibrationRequest
         */
        CfrmEolCalibrationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.CfrmEolCalibrationRequest)
                return object;
            var message = new $root.tool_commands.CfrmEolCalibrationRequest();
            if (object.mountPosition != null)
                message.mountPosition = Number(object.mountPosition);
            if (object.targetPosition != null)
                message.targetPosition = Number(object.targetPosition);
            return message;
        };

        /**
         * Creates a plain object from a CfrmEolCalibrationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {tool_commands.CfrmEolCalibrationRequest} message CfrmEolCalibrationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CfrmEolCalibrationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.mountPosition = 0;
                object.targetPosition = 0;
            }
            if (message.mountPosition != null && message.hasOwnProperty("mountPosition"))
                object.mountPosition = options.json && !isFinite(message.mountPosition) ? String(message.mountPosition) : message.mountPosition;
            if (message.targetPosition != null && message.hasOwnProperty("targetPosition"))
                object.targetPosition = options.json && !isFinite(message.targetPosition) ? String(message.targetPosition) : message.targetPosition;
            return object;
        };

        /**
         * Converts this CfrmEolCalibrationRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CfrmEolCalibrationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CfrmEolCalibrationRequest
         * @function getTypeUrl
         * @memberof tool_commands.CfrmEolCalibrationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CfrmEolCalibrationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.CfrmEolCalibrationRequest";
        };

        return CfrmEolCalibrationRequest;
    })();

    /**
     * AutomationId enum.
     * @name tool_commands.AutomationId
     * @enum {number}
     * @property {number} CFRM_EOL_CALIBRATION=0 CFRM_EOL_CALIBRATION value
     * @property {number} SVC_EOL_CALIBRATION=1 SVC_EOL_CALIBRATION value
     * @property {number} SRS_VARIANT_CODING=2 SRS_VARIANT_CODING value
     * @property {number} SAS_CALIBRATION=3 SAS_CALIBRATION value
     * @property {number} TPMS_EOL=4 TPMS_EOL value
     * @property {number} SVC_WRITE_REGION_CODE=5 SVC_WRITE_REGION_CODE value
     * @property {number} SRSEDR_READ_6_DIDS=6 SRSEDR_READ_6_DIDS value
     * @property {number} SVCEDR_READ_6_DIDS=7 SVCEDR_READ_6_DIDS value
     * @property {number} SEAT_CALIBRATION=8 SEAT_CALIBRATION value
     * @property {number} SVC_AS_CALIBRATION=9 SVC_AS_CALIBRATION value
     * @property {number} ADC_CAMERA_CALIBRATION=10 ADC_CAMERA_CALIBRATION value
     * @property {number} REAR_SEAT_CALIBRATION=11 REAR_SEAT_CALIBRATION value
     * @property {number} MAX_AUTOMATION_ID=12 MAX_AUTOMATION_ID value
     */
    tool_commands.AutomationId = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CFRM_EOL_CALIBRATION"] = 0;
        values[valuesById[1] = "SVC_EOL_CALIBRATION"] = 1;
        values[valuesById[2] = "SRS_VARIANT_CODING"] = 2;
        values[valuesById[3] = "SAS_CALIBRATION"] = 3;
        values[valuesById[4] = "TPMS_EOL"] = 4;
        values[valuesById[5] = "SVC_WRITE_REGION_CODE"] = 5;
        values[valuesById[6] = "SRSEDR_READ_6_DIDS"] = 6;
        values[valuesById[7] = "SVCEDR_READ_6_DIDS"] = 7;
        values[valuesById[8] = "SEAT_CALIBRATION"] = 8;
        values[valuesById[9] = "SVC_AS_CALIBRATION"] = 9;
        values[valuesById[10] = "ADC_CAMERA_CALIBRATION"] = 10;
        values[valuesById[11] = "REAR_SEAT_CALIBRATION"] = 11;
        values[valuesById[12] = "MAX_AUTOMATION_ID"] = 12;
        return values;
    })();

    tool_commands.AutomationRR = (function() {

        /**
         * Properties of an AutomationRR.
         * @memberof tool_commands
         * @interface IAutomationRR
         * @property {tool_commands.AutomationId} id AutomationRR id
         * @property {Array.<number|Long>|null} [intParam] AutomationRR intParam
         * @property {Array.<number>|null} [floatParam] AutomationRR floatParam
         * @property {Array.<string>|null} [stringParam] AutomationRR stringParam
         * @property {Array.<Uint8Array>|null} [binParam] AutomationRR binParam
         */

        /**
         * Constructs a new AutomationRR.
         * @memberof tool_commands
         * @classdesc Represents an AutomationRR.
         * @implements IAutomationRR
         * @constructor
         * @param {tool_commands.IAutomationRR=} [properties] Properties to set
         */
        function AutomationRR(properties) {
            this.intParam = [];
            this.floatParam = [];
            this.stringParam = [];
            this.binParam = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AutomationRR id.
         * @member {tool_commands.AutomationId} id
         * @memberof tool_commands.AutomationRR
         * @instance
         */
        AutomationRR.prototype.id = 0;

        /**
         * AutomationRR intParam.
         * @member {Array.<number|Long>} intParam
         * @memberof tool_commands.AutomationRR
         * @instance
         */
        AutomationRR.prototype.intParam = $util.emptyArray;

        /**
         * AutomationRR floatParam.
         * @member {Array.<number>} floatParam
         * @memberof tool_commands.AutomationRR
         * @instance
         */
        AutomationRR.prototype.floatParam = $util.emptyArray;

        /**
         * AutomationRR stringParam.
         * @member {Array.<string>} stringParam
         * @memberof tool_commands.AutomationRR
         * @instance
         */
        AutomationRR.prototype.stringParam = $util.emptyArray;

        /**
         * AutomationRR binParam.
         * @member {Array.<Uint8Array>} binParam
         * @memberof tool_commands.AutomationRR
         * @instance
         */
        AutomationRR.prototype.binParam = $util.emptyArray;

        /**
         * Creates a new AutomationRR instance using the specified properties.
         * @function create
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {tool_commands.IAutomationRR=} [properties] Properties to set
         * @returns {tool_commands.AutomationRR} AutomationRR instance
         */
        AutomationRR.create = function create(properties) {
            return new AutomationRR(properties);
        };

        /**
         * Encodes the specified AutomationRR message. Does not implicitly {@link tool_commands.AutomationRR.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {tool_commands.IAutomationRR} message AutomationRR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutomationRR.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.intParam != null && message.intParam.length)
                for (var i = 0; i < message.intParam.length; ++i)
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.intParam[i]);
            if (message.floatParam != null && message.floatParam.length)
                for (var i = 0; i < message.floatParam.length; ++i)
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.floatParam[i]);
            if (message.stringParam != null && message.stringParam.length)
                for (var i = 0; i < message.stringParam.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.stringParam[i]);
            if (message.binParam != null && message.binParam.length)
                for (var i = 0; i < message.binParam.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.binParam[i]);
            return writer;
        };

        /**
         * Encodes the specified AutomationRR message, length delimited. Does not implicitly {@link tool_commands.AutomationRR.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {tool_commands.IAutomationRR} message AutomationRR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutomationRR.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AutomationRR message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.AutomationRR} AutomationRR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutomationRR.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.AutomationRR();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.intParam && message.intParam.length))
                            message.intParam = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.intParam.push(reader.int64());
                        } else
                            message.intParam.push(reader.int64());
                        break;
                    }
                case 3: {
                        if (!(message.floatParam && message.floatParam.length))
                            message.floatParam = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.floatParam.push(reader.float());
                        } else
                            message.floatParam.push(reader.float());
                        break;
                    }
                case 4: {
                        if (!(message.stringParam && message.stringParam.length))
                            message.stringParam = [];
                        message.stringParam.push(reader.string());
                        break;
                    }
                case 5: {
                        if (!(message.binParam && message.binParam.length))
                            message.binParam = [];
                        message.binParam.push(reader.bytes());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes an AutomationRR message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.AutomationRR} AutomationRR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutomationRR.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AutomationRR message.
         * @function verify
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AutomationRR.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.id) {
            default:
                return "id: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
                break;
            }
            if (message.intParam != null && message.hasOwnProperty("intParam")) {
                if (!Array.isArray(message.intParam))
                    return "intParam: array expected";
                for (var i = 0; i < message.intParam.length; ++i)
                    if (!$util.isInteger(message.intParam[i]) && !(message.intParam[i] && $util.isInteger(message.intParam[i].low) && $util.isInteger(message.intParam[i].high)))
                        return "intParam: integer|Long[] expected";
            }
            if (message.floatParam != null && message.hasOwnProperty("floatParam")) {
                if (!Array.isArray(message.floatParam))
                    return "floatParam: array expected";
                for (var i = 0; i < message.floatParam.length; ++i)
                    if (typeof message.floatParam[i] !== "number")
                        return "floatParam: number[] expected";
            }
            if (message.stringParam != null && message.hasOwnProperty("stringParam")) {
                if (!Array.isArray(message.stringParam))
                    return "stringParam: array expected";
                for (var i = 0; i < message.stringParam.length; ++i)
                    if (!$util.isString(message.stringParam[i]))
                        return "stringParam: string[] expected";
            }
            if (message.binParam != null && message.hasOwnProperty("binParam")) {
                if (!Array.isArray(message.binParam))
                    return "binParam: array expected";
                for (var i = 0; i < message.binParam.length; ++i)
                    if (!(message.binParam[i] && typeof message.binParam[i].length === "number" || $util.isString(message.binParam[i])))
                        return "binParam: buffer[] expected";
            }
            return null;
        };

        /**
         * Creates an AutomationRR message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.AutomationRR} AutomationRR
         */
        AutomationRR.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.AutomationRR)
                return object;
            var message = new $root.tool_commands.AutomationRR();
            switch (object.id) {
            default:
                if (typeof object.id === "number") {
                    message.id = object.id;
                    break;
                }
                break;
            case "CFRM_EOL_CALIBRATION":
            case 0:
                message.id = 0;
                break;
            case "SVC_EOL_CALIBRATION":
            case 1:
                message.id = 1;
                break;
            case "SRS_VARIANT_CODING":
            case 2:
                message.id = 2;
                break;
            case "SAS_CALIBRATION":
            case 3:
                message.id = 3;
                break;
            case "TPMS_EOL":
            case 4:
                message.id = 4;
                break;
            case "SVC_WRITE_REGION_CODE":
            case 5:
                message.id = 5;
                break;
            case "SRSEDR_READ_6_DIDS":
            case 6:
                message.id = 6;
                break;
            case "SVCEDR_READ_6_DIDS":
            case 7:
                message.id = 7;
                break;
            case "SEAT_CALIBRATION":
            case 8:
                message.id = 8;
                break;
            case "SVC_AS_CALIBRATION":
            case 9:
                message.id = 9;
                break;
            case "ADC_CAMERA_CALIBRATION":
            case 10:
                message.id = 10;
                break;
            case "REAR_SEAT_CALIBRATION":
            case 11:
                message.id = 11;
                break;
            case "MAX_AUTOMATION_ID":
            case 12:
                message.id = 12;
                break;
            }
            if (object.intParam) {
                if (!Array.isArray(object.intParam))
                    throw TypeError(".tool_commands.AutomationRR.intParam: array expected");
                message.intParam = [];
                for (var i = 0; i < object.intParam.length; ++i)
                    if ($util.Long)
                        (message.intParam[i] = $util.Long.fromValue(object.intParam[i])).unsigned = false;
                    else if (typeof object.intParam[i] === "string")
                        message.intParam[i] = parseInt(object.intParam[i], 10);
                    else if (typeof object.intParam[i] === "number")
                        message.intParam[i] = object.intParam[i];
                    else if (typeof object.intParam[i] === "object")
                        message.intParam[i] = new $util.LongBits(object.intParam[i].low >>> 0, object.intParam[i].high >>> 0).toNumber();
            }
            if (object.floatParam) {
                if (!Array.isArray(object.floatParam))
                    throw TypeError(".tool_commands.AutomationRR.floatParam: array expected");
                message.floatParam = [];
                for (var i = 0; i < object.floatParam.length; ++i)
                    message.floatParam[i] = Number(object.floatParam[i]);
            }
            if (object.stringParam) {
                if (!Array.isArray(object.stringParam))
                    throw TypeError(".tool_commands.AutomationRR.stringParam: array expected");
                message.stringParam = [];
                for (var i = 0; i < object.stringParam.length; ++i)
                    message.stringParam[i] = String(object.stringParam[i]);
            }
            if (object.binParam) {
                if (!Array.isArray(object.binParam))
                    throw TypeError(".tool_commands.AutomationRR.binParam: array expected");
                message.binParam = [];
                for (var i = 0; i < object.binParam.length; ++i)
                    if (typeof object.binParam[i] === "string")
                        $util.base64.decode(object.binParam[i], message.binParam[i] = $util.newBuffer($util.base64.length(object.binParam[i])), 0);
                    else if (object.binParam[i].length >= 0)
                        message.binParam[i] = object.binParam[i];
            }
            return message;
        };

        /**
         * Creates a plain object from an AutomationRR message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {tool_commands.AutomationRR} message AutomationRR
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AutomationRR.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.intParam = [];
                object.floatParam = [];
                object.stringParam = [];
                object.binParam = [];
            }
            if (options.defaults)
                object.id = options.enums === String ? "CFRM_EOL_CALIBRATION" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.enums === String ? $root.tool_commands.AutomationId[message.id] === undefined ? message.id : $root.tool_commands.AutomationId[message.id] : message.id;
            if (message.intParam && message.intParam.length) {
                object.intParam = [];
                for (var j = 0; j < message.intParam.length; ++j)
                    if (typeof message.intParam[j] === "number")
                        object.intParam[j] = options.longs === String ? String(message.intParam[j]) : message.intParam[j];
                    else
                        object.intParam[j] = options.longs === String ? $util.Long.prototype.toString.call(message.intParam[j]) : options.longs === Number ? new $util.LongBits(message.intParam[j].low >>> 0, message.intParam[j].high >>> 0).toNumber() : message.intParam[j];
            }
            if (message.floatParam && message.floatParam.length) {
                object.floatParam = [];
                for (var j = 0; j < message.floatParam.length; ++j)
                    object.floatParam[j] = options.json && !isFinite(message.floatParam[j]) ? String(message.floatParam[j]) : message.floatParam[j];
            }
            if (message.stringParam && message.stringParam.length) {
                object.stringParam = [];
                for (var j = 0; j < message.stringParam.length; ++j)
                    object.stringParam[j] = message.stringParam[j];
            }
            if (message.binParam && message.binParam.length) {
                object.binParam = [];
                for (var j = 0; j < message.binParam.length; ++j)
                    object.binParam[j] = options.bytes === String ? $util.base64.encode(message.binParam[j], 0, message.binParam[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.binParam[j]) : message.binParam[j];
            }
            return object;
        };

        /**
         * Converts this AutomationRR to JSON.
         * @function toJSON
         * @memberof tool_commands.AutomationRR
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AutomationRR.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AutomationRR
         * @function getTypeUrl
         * @memberof tool_commands.AutomationRR
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AutomationRR.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.AutomationRR";
        };

        return AutomationRR;
    })();

    tool_commands.ADBRequest = (function() {

        /**
         * Properties of a ADBRequest.
         * @memberof tool_commands
         * @interface IADBRequest
         * @property {string} commandLine ADBRequest commandLine
         * @property {string|null} [adbExecutable] ADBRequest adbExecutable
         * @property {string|null} [startupFolder] ADBRequest startupFolder
         * @property {string|null} [captureFile] ADBRequest captureFile
         */

        /**
         * Constructs a new ADBRequest.
         * @memberof tool_commands
         * @classdesc Represents a ADBRequest.
         * @implements IADBRequest
         * @constructor
         * @param {tool_commands.IADBRequest=} [properties] Properties to set
         */
        function ADBRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ADBRequest commandLine.
         * @member {string} commandLine
         * @memberof tool_commands.ADBRequest
         * @instance
         */
        ADBRequest.prototype.commandLine = "";

        /**
         * ADBRequest adbExecutable.
         * @member {string} adbExecutable
         * @memberof tool_commands.ADBRequest
         * @instance
         */
        ADBRequest.prototype.adbExecutable = "adb.exe";

        /**
         * ADBRequest startupFolder.
         * @member {string} startupFolder
         * @memberof tool_commands.ADBRequest
         * @instance
         */
        ADBRequest.prototype.startupFolder = "";

        /**
         * ADBRequest captureFile.
         * @member {string} captureFile
         * @memberof tool_commands.ADBRequest
         * @instance
         */
        ADBRequest.prototype.captureFile = "";

        /**
         * Creates a new ADBRequest instance using the specified properties.
         * @function create
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {tool_commands.IADBRequest=} [properties] Properties to set
         * @returns {tool_commands.ADBRequest} ADBRequest instance
         */
        ADBRequest.create = function create(properties) {
            return new ADBRequest(properties);
        };

        /**
         * Encodes the specified ADBRequest message. Does not implicitly {@link tool_commands.ADBRequest.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {tool_commands.IADBRequest} message ADBRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ADBRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.commandLine);
            if (message.adbExecutable != null && Object.hasOwnProperty.call(message, "adbExecutable"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.adbExecutable);
            if (message.startupFolder != null && Object.hasOwnProperty.call(message, "startupFolder"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startupFolder);
            if (message.captureFile != null && Object.hasOwnProperty.call(message, "captureFile"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.captureFile);
            return writer;
        };

        /**
         * Encodes the specified ADBRequest message, length delimited. Does not implicitly {@link tool_commands.ADBRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {tool_commands.IADBRequest} message ADBRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ADBRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ADBRequest message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ADBRequest} ADBRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ADBRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ADBRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commandLine = reader.string();
                        break;
                    }
                case 2: {
                        message.adbExecutable = reader.string();
                        break;
                    }
                case 3: {
                        message.startupFolder = reader.string();
                        break;
                    }
                case 4: {
                        message.captureFile = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("commandLine"))
                throw $util.ProtocolError("missing required 'commandLine'", { instance: message });
            return message;
        };

        /**
         * Decodes a ADBRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ADBRequest} ADBRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ADBRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ADBRequest message.
         * @function verify
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ADBRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.commandLine))
                return "commandLine: string expected";
            if (message.adbExecutable != null && message.hasOwnProperty("adbExecutable"))
                if (!$util.isString(message.adbExecutable))
                    return "adbExecutable: string expected";
            if (message.startupFolder != null && message.hasOwnProperty("startupFolder"))
                if (!$util.isString(message.startupFolder))
                    return "startupFolder: string expected";
            if (message.captureFile != null && message.hasOwnProperty("captureFile"))
                if (!$util.isString(message.captureFile))
                    return "captureFile: string expected";
            return null;
        };

        /**
         * Creates a ADBRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ADBRequest} ADBRequest
         */
        ADBRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ADBRequest)
                return object;
            var message = new $root.tool_commands.ADBRequest();
            if (object.commandLine != null)
                message.commandLine = String(object.commandLine);
            if (object.adbExecutable != null)
                message.adbExecutable = String(object.adbExecutable);
            if (object.startupFolder != null)
                message.startupFolder = String(object.startupFolder);
            if (object.captureFile != null)
                message.captureFile = String(object.captureFile);
            return message;
        };

        /**
         * Creates a plain object from a ADBRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {tool_commands.ADBRequest} message ADBRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ADBRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.commandLine = "";
                object.adbExecutable = "adb.exe";
                object.startupFolder = "";
                object.captureFile = "";
            }
            if (message.commandLine != null && message.hasOwnProperty("commandLine"))
                object.commandLine = message.commandLine;
            if (message.adbExecutable != null && message.hasOwnProperty("adbExecutable"))
                object.adbExecutable = message.adbExecutable;
            if (message.startupFolder != null && message.hasOwnProperty("startupFolder"))
                object.startupFolder = message.startupFolder;
            if (message.captureFile != null && message.hasOwnProperty("captureFile"))
                object.captureFile = message.captureFile;
            return object;
        };

        /**
         * Converts this ADBRequest to JSON.
         * @function toJSON
         * @memberof tool_commands.ADBRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ADBRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ADBRequest
         * @function getTypeUrl
         * @memberof tool_commands.ADBRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ADBRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ADBRequest";
        };

        return ADBRequest;
    })();

    tool_commands.ADBResponse = (function() {

        /**
         * Properties of a ADBResponse.
         * @memberof tool_commands
         * @interface IADBResponse
         * @property {string|null} [output] ADBResponse output
         * @property {number|null} [exitCode] ADBResponse exitCode
         */

        /**
         * Constructs a new ADBResponse.
         * @memberof tool_commands
         * @classdesc Represents a ADBResponse.
         * @implements IADBResponse
         * @constructor
         * @param {tool_commands.IADBResponse=} [properties] Properties to set
         */
        function ADBResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ADBResponse output.
         * @member {string} output
         * @memberof tool_commands.ADBResponse
         * @instance
         */
        ADBResponse.prototype.output = "";

        /**
         * ADBResponse exitCode.
         * @member {number} exitCode
         * @memberof tool_commands.ADBResponse
         * @instance
         */
        ADBResponse.prototype.exitCode = -1;

        /**
         * Creates a new ADBResponse instance using the specified properties.
         * @function create
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {tool_commands.IADBResponse=} [properties] Properties to set
         * @returns {tool_commands.ADBResponse} ADBResponse instance
         */
        ADBResponse.create = function create(properties) {
            return new ADBResponse(properties);
        };

        /**
         * Encodes the specified ADBResponse message. Does not implicitly {@link tool_commands.ADBResponse.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {tool_commands.IADBResponse} message ADBResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ADBResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.output);
            if (message.exitCode != null && Object.hasOwnProperty.call(message, "exitCode"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.exitCode);
            return writer;
        };

        /**
         * Encodes the specified ADBResponse message, length delimited. Does not implicitly {@link tool_commands.ADBResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {tool_commands.IADBResponse} message ADBResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ADBResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ADBResponse message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.ADBResponse} ADBResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ADBResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.ADBResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.output = reader.string();
                        break;
                    }
                case 2: {
                        message.exitCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ADBResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.ADBResponse} ADBResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ADBResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ADBResponse message.
         * @function verify
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ADBResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.output != null && message.hasOwnProperty("output"))
                if (!$util.isString(message.output))
                    return "output: string expected";
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                if (!$util.isInteger(message.exitCode))
                    return "exitCode: integer expected";
            return null;
        };

        /**
         * Creates a ADBResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.ADBResponse} ADBResponse
         */
        ADBResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.ADBResponse)
                return object;
            var message = new $root.tool_commands.ADBResponse();
            if (object.output != null)
                message.output = String(object.output);
            if (object.exitCode != null)
                message.exitCode = object.exitCode | 0;
            return message;
        };

        /**
         * Creates a plain object from a ADBResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {tool_commands.ADBResponse} message ADBResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ADBResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.output = "";
                object.exitCode = -1;
            }
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = message.output;
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                object.exitCode = message.exitCode;
            return object;
        };

        /**
         * Converts this ADBResponse to JSON.
         * @function toJSON
         * @memberof tool_commands.ADBResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ADBResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ADBResponse
         * @function getTypeUrl
         * @memberof tool_commands.ADBResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ADBResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.ADBResponse";
        };

        return ADBResponse;
    })();

    tool_commands.request = (function() {

        /**
         * Properties of a request.
         * @memberof tool_commands
         * @interface Irequest
         * @property {boolean|null} [preferCached] request preferCached
         * @property {tool_commands.IWait|null} [wait] request wait
         * @property {tool_commands.IMyEmpty|null} [getVersion] request getVersion
         * @property {tool_commands.ISelectedECUs|null} [startUpdateSession] request startUpdateSession
         * @property {progress_pb.IProgress|null} [getProgress] request getProgress
         * @property {tool_commands.IMyEmpty|null} [getModels] request getModels
         * @property {tool_commands.IEcuListRequest|null} [getECUList] request getECUList
         * @property {tool_commands.IMyEmpty|null} [getSelectedECUs] request getSelectedECUs
         * @property {tool_commands.IECUsInVehicle|null} [setSelectedECUs] request setSelectedECUs
         * @property {tool_commands.IVersionComparisonRequest|null} [startDownload] request startDownload
         * @property {tool_commands.IMyEmpty|null} [getSettings] request getSettings
         * @property {tool_commands.ISettings|null} [setSettings] request setSettings
         * @property {tool_commands.IVersionComparisonRequest|null} [versionComparisonReq] request versionComparisonReq
         * @property {tool_commands.IMyEmpty|null} [getVersionCompResult] request getVersionCompResult
         * @property {uds_client_pb.IUDSSequenceRequest|null} [udsSequenceRequest] request udsSequenceRequest
         * @property {tool_commands.IMyEmpty|null} [udsSequenceResult] request udsSequenceResult
         * @property {tool_commands.ISecurityAccessRequest|null} [securityAccess] request securityAccess
         * @property {uds_client_pb.IUDSRequest|null} [udsRequest] request udsRequest
         * @property {tool_commands.IADBRequest|null} [adbRequest] request adbRequest
         * @property {string|null} [getDiagnosticTree] request getDiagnosticTree
         * @property {tool_commands.ITesterPresentLoop|null} [loopTesterPresent] request loopTesterPresent
         * @property {tool_commands.ICfrmEolCalibrationRequest|null} [calibrateCfrmEol] request calibrateCfrmEol
         * @property {tool_commands.IMyEmpty|null} [getCalCfrmEolResult] request getCalCfrmEolResult
         * @property {tool_commands.IMyEmpty|null} [calibrateSvcEol] request calibrateSvcEol
         * @property {tool_commands.IMyEmpty|null} [getCalSvcEolResult] request getCalSvcEolResult
         * @property {tool_commands.IAutomationRR|null} [startAutomation] request startAutomation
         * @property {tool_commands.AutomationId|null} [getAutomationResult] request getAutomationResult
         */

        /**
         * Constructs a new request.
         * @memberof tool_commands
         * @classdesc Represents a request.
         * @implements Irequest
         * @constructor
         * @param {tool_commands.Irequest=} [properties] Properties to set
         */
        function request(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * request preferCached.
         * @member {boolean} preferCached
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.preferCached = false;

        /**
         * request wait.
         * @member {tool_commands.IWait|null|undefined} wait
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.wait = null;

        /**
         * request getVersion.
         * @member {tool_commands.IMyEmpty|null|undefined} getVersion
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getVersion = null;

        /**
         * request startUpdateSession.
         * @member {tool_commands.ISelectedECUs|null|undefined} startUpdateSession
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.startUpdateSession = null;

        /**
         * request getProgress.
         * @member {progress_pb.IProgress|null|undefined} getProgress
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getProgress = null;

        /**
         * request getModels.
         * @member {tool_commands.IMyEmpty|null|undefined} getModels
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getModels = null;

        /**
         * request getECUList.
         * @member {tool_commands.IEcuListRequest|null|undefined} getECUList
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getECUList = null;

        /**
         * request getSelectedECUs.
         * @member {tool_commands.IMyEmpty|null|undefined} getSelectedECUs
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getSelectedECUs = null;

        /**
         * request setSelectedECUs.
         * @member {tool_commands.IECUsInVehicle|null|undefined} setSelectedECUs
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.setSelectedECUs = null;

        /**
         * request startDownload.
         * @member {tool_commands.IVersionComparisonRequest|null|undefined} startDownload
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.startDownload = null;

        /**
         * request getSettings.
         * @member {tool_commands.IMyEmpty|null|undefined} getSettings
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getSettings = null;

        /**
         * request setSettings.
         * @member {tool_commands.ISettings|null|undefined} setSettings
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.setSettings = null;

        /**
         * request versionComparisonReq.
         * @member {tool_commands.IVersionComparisonRequest|null|undefined} versionComparisonReq
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.versionComparisonReq = null;

        /**
         * request getVersionCompResult.
         * @member {tool_commands.IMyEmpty|null|undefined} getVersionCompResult
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getVersionCompResult = null;

        /**
         * request udsSequenceRequest.
         * @member {uds_client_pb.IUDSSequenceRequest|null|undefined} udsSequenceRequest
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.udsSequenceRequest = null;

        /**
         * request udsSequenceResult.
         * @member {tool_commands.IMyEmpty|null|undefined} udsSequenceResult
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.udsSequenceResult = null;

        /**
         * request securityAccess.
         * @member {tool_commands.ISecurityAccessRequest|null|undefined} securityAccess
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.securityAccess = null;

        /**
         * request udsRequest.
         * @member {uds_client_pb.IUDSRequest|null|undefined} udsRequest
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.udsRequest = null;

        /**
         * request adbRequest.
         * @member {tool_commands.IADBRequest|null|undefined} adbRequest
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.adbRequest = null;

        /**
         * request getDiagnosticTree.
         * @member {string|null|undefined} getDiagnosticTree
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getDiagnosticTree = null;

        /**
         * request loopTesterPresent.
         * @member {tool_commands.ITesterPresentLoop|null|undefined} loopTesterPresent
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.loopTesterPresent = null;

        /**
         * request calibrateCfrmEol.
         * @member {tool_commands.ICfrmEolCalibrationRequest|null|undefined} calibrateCfrmEol
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.calibrateCfrmEol = null;

        /**
         * request getCalCfrmEolResult.
         * @member {tool_commands.IMyEmpty|null|undefined} getCalCfrmEolResult
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getCalCfrmEolResult = null;

        /**
         * request calibrateSvcEol.
         * @member {tool_commands.IMyEmpty|null|undefined} calibrateSvcEol
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.calibrateSvcEol = null;

        /**
         * request getCalSvcEolResult.
         * @member {tool_commands.IMyEmpty|null|undefined} getCalSvcEolResult
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getCalSvcEolResult = null;

        /**
         * request startAutomation.
         * @member {tool_commands.IAutomationRR|null|undefined} startAutomation
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.startAutomation = null;

        /**
         * request getAutomationResult.
         * @member {tool_commands.AutomationId|null|undefined} getAutomationResult
         * @memberof tool_commands.request
         * @instance
         */
        request.prototype.getAutomationResult = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * request currentRequest.
         * @member {"wait"|"getVersion"|"startUpdateSession"|"getProgress"|"getModels"|"getECUList"|"getSelectedECUs"|"setSelectedECUs"|"startDownload"|"getSettings"|"setSettings"|"versionComparisonReq"|"getVersionCompResult"|"udsSequenceRequest"|"udsSequenceResult"|"securityAccess"|"udsRequest"|"adbRequest"|"getDiagnosticTree"|"loopTesterPresent"|"calibrateCfrmEol"|"getCalCfrmEolResult"|"calibrateSvcEol"|"getCalSvcEolResult"|"startAutomation"|"getAutomationResult"|undefined} currentRequest
         * @memberof tool_commands.request
         * @instance
         */
        Object.defineProperty(request.prototype, "currentRequest", {
            get: $util.oneOfGetter($oneOfFields = ["wait", "getVersion", "startUpdateSession", "getProgress", "getModels", "getECUList", "getSelectedECUs", "setSelectedECUs", "startDownload", "getSettings", "setSettings", "versionComparisonReq", "getVersionCompResult", "udsSequenceRequest", "udsSequenceResult", "securityAccess", "udsRequest", "adbRequest", "getDiagnosticTree", "loopTesterPresent", "calibrateCfrmEol", "getCalCfrmEolResult", "calibrateSvcEol", "getCalSvcEolResult", "startAutomation", "getAutomationResult"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new request instance using the specified properties.
         * @function create
         * @memberof tool_commands.request
         * @static
         * @param {tool_commands.Irequest=} [properties] Properties to set
         * @returns {tool_commands.request} request instance
         */
        request.create = function create(properties) {
            return new request(properties);
        };

        /**
         * Encodes the specified request message. Does not implicitly {@link tool_commands.request.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.request
         * @static
         * @param {tool_commands.Irequest} message request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        request.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.preferCached != null && Object.hasOwnProperty.call(message, "preferCached"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.preferCached);
            if (message.wait != null && Object.hasOwnProperty.call(message, "wait"))
                $root.tool_commands.Wait.encode(message.wait, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.getVersion != null && Object.hasOwnProperty.call(message, "getVersion"))
                $root.tool_commands.MyEmpty.encode(message.getVersion, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.startUpdateSession != null && Object.hasOwnProperty.call(message, "startUpdateSession"))
                $root.tool_commands.SelectedECUs.encode(message.startUpdateSession, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.getProgress != null && Object.hasOwnProperty.call(message, "getProgress"))
                $root.progress_pb.Progress.encode(message.getProgress, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.getModels != null && Object.hasOwnProperty.call(message, "getModels"))
                $root.tool_commands.MyEmpty.encode(message.getModels, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.getECUList != null && Object.hasOwnProperty.call(message, "getECUList"))
                $root.tool_commands.EcuListRequest.encode(message.getECUList, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.getSelectedECUs != null && Object.hasOwnProperty.call(message, "getSelectedECUs"))
                $root.tool_commands.MyEmpty.encode(message.getSelectedECUs, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.setSelectedECUs != null && Object.hasOwnProperty.call(message, "setSelectedECUs"))
                $root.tool_commands.ECUsInVehicle.encode(message.setSelectedECUs, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.startDownload != null && Object.hasOwnProperty.call(message, "startDownload"))
                $root.tool_commands.VersionComparisonRequest.encode(message.startDownload, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.getSettings != null && Object.hasOwnProperty.call(message, "getSettings"))
                $root.tool_commands.MyEmpty.encode(message.getSettings, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.setSettings != null && Object.hasOwnProperty.call(message, "setSettings"))
                $root.tool_commands.Settings.encode(message.setSettings, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.versionComparisonReq != null && Object.hasOwnProperty.call(message, "versionComparisonReq"))
                $root.tool_commands.VersionComparisonRequest.encode(message.versionComparisonReq, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
            if (message.getVersionCompResult != null && Object.hasOwnProperty.call(message, "getVersionCompResult"))
                $root.tool_commands.MyEmpty.encode(message.getVersionCompResult, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
            if (message.udsSequenceRequest != null && Object.hasOwnProperty.call(message, "udsSequenceRequest"))
                $root.uds_client_pb.UDSSequenceRequest.encode(message.udsSequenceRequest, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
            if (message.udsSequenceResult != null && Object.hasOwnProperty.call(message, "udsSequenceResult"))
                $root.tool_commands.MyEmpty.encode(message.udsSequenceResult, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
            if (message.securityAccess != null && Object.hasOwnProperty.call(message, "securityAccess"))
                $root.tool_commands.SecurityAccessRequest.encode(message.securityAccess, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
            if (message.udsRequest != null && Object.hasOwnProperty.call(message, "udsRequest"))
                $root.uds_client_pb.UDSRequest.encode(message.udsRequest, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
            if (message.adbRequest != null && Object.hasOwnProperty.call(message, "adbRequest"))
                $root.tool_commands.ADBRequest.encode(message.adbRequest, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
            if (message.getDiagnosticTree != null && Object.hasOwnProperty.call(message, "getDiagnosticTree"))
                writer.uint32(/* id 70, wireType 2 =*/562).string(message.getDiagnosticTree);
            if (message.loopTesterPresent != null && Object.hasOwnProperty.call(message, "loopTesterPresent"))
                $root.tool_commands.TesterPresentLoop.encode(message.loopTesterPresent, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
            if (message.calibrateCfrmEol != null && Object.hasOwnProperty.call(message, "calibrateCfrmEol"))
                $root.tool_commands.CfrmEolCalibrationRequest.encode(message.calibrateCfrmEol, writer.uint32(/* id 80, wireType 2 =*/642).fork()).ldelim();
            if (message.getCalCfrmEolResult != null && Object.hasOwnProperty.call(message, "getCalCfrmEolResult"))
                $root.tool_commands.MyEmpty.encode(message.getCalCfrmEolResult, writer.uint32(/* id 81, wireType 2 =*/650).fork()).ldelim();
            if (message.calibrateSvcEol != null && Object.hasOwnProperty.call(message, "calibrateSvcEol"))
                $root.tool_commands.MyEmpty.encode(message.calibrateSvcEol, writer.uint32(/* id 82, wireType 2 =*/658).fork()).ldelim();
            if (message.getCalSvcEolResult != null && Object.hasOwnProperty.call(message, "getCalSvcEolResult"))
                $root.tool_commands.MyEmpty.encode(message.getCalSvcEolResult, writer.uint32(/* id 83, wireType 2 =*/666).fork()).ldelim();
            if (message.startAutomation != null && Object.hasOwnProperty.call(message, "startAutomation"))
                $root.tool_commands.AutomationRR.encode(message.startAutomation, writer.uint32(/* id 84, wireType 2 =*/674).fork()).ldelim();
            if (message.getAutomationResult != null && Object.hasOwnProperty.call(message, "getAutomationResult"))
                writer.uint32(/* id 85, wireType 0 =*/680).int32(message.getAutomationResult);
            return writer;
        };

        /**
         * Encodes the specified request message, length delimited. Does not implicitly {@link tool_commands.request.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.request
         * @static
         * @param {tool_commands.Irequest} message request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        request.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a request message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.request} request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        request.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.request();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.preferCached = reader.bool();
                        break;
                    }
                case 10: {
                        message.wait = $root.tool_commands.Wait.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.getVersion = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.startUpdateSession = $root.tool_commands.SelectedECUs.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.getProgress = $root.progress_pb.Progress.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.getModels = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.getECUList = $root.tool_commands.EcuListRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 44: {
                        message.getSelectedECUs = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 45: {
                        message.setSelectedECUs = $root.tool_commands.ECUsInVehicle.decode(reader, reader.uint32());
                        break;
                    }
                case 46: {
                        message.startDownload = $root.tool_commands.VersionComparisonRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 50: {
                        message.getSettings = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.setSettings = $root.tool_commands.Settings.decode(reader, reader.uint32());
                        break;
                    }
                case 60: {
                        message.versionComparisonReq = $root.tool_commands.VersionComparisonRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 61: {
                        message.getVersionCompResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 62: {
                        message.udsSequenceRequest = $root.uds_client_pb.UDSSequenceRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 63: {
                        message.udsSequenceResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 64: {
                        message.securityAccess = $root.tool_commands.SecurityAccessRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 66: {
                        message.udsRequest = $root.uds_client_pb.UDSRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 67: {
                        message.adbRequest = $root.tool_commands.ADBRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 70: {
                        message.getDiagnosticTree = reader.string();
                        break;
                    }
                case 71: {
                        message.loopTesterPresent = $root.tool_commands.TesterPresentLoop.decode(reader, reader.uint32());
                        break;
                    }
                case 80: {
                        message.calibrateCfrmEol = $root.tool_commands.CfrmEolCalibrationRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 81: {
                        message.getCalCfrmEolResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 82: {
                        message.calibrateSvcEol = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 83: {
                        message.getCalSvcEolResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 84: {
                        message.startAutomation = $root.tool_commands.AutomationRR.decode(reader, reader.uint32());
                        break;
                    }
                case 85: {
                        message.getAutomationResult = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a request message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.request} request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        request.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a request message.
         * @function verify
         * @memberof tool_commands.request
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        request.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.preferCached != null && message.hasOwnProperty("preferCached"))
                if (typeof message.preferCached !== "boolean")
                    return "preferCached: boolean expected";
            if (message.wait != null && message.hasOwnProperty("wait")) {
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.Wait.verify(message.wait);
                    if (error)
                        return "wait." + error;
                }
            }
            if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getVersion);
                    if (error)
                        return "getVersion." + error;
                }
            }
            if (message.startUpdateSession != null && message.hasOwnProperty("startUpdateSession")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.SelectedECUs.verify(message.startUpdateSession);
                    if (error)
                        return "startUpdateSession." + error;
                }
            }
            if (message.getProgress != null && message.hasOwnProperty("getProgress")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.progress_pb.Progress.verify(message.getProgress);
                    if (error)
                        return "getProgress." + error;
                }
            }
            if (message.getModels != null && message.hasOwnProperty("getModels")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getModels);
                    if (error)
                        return "getModels." + error;
                }
            }
            if (message.getECUList != null && message.hasOwnProperty("getECUList")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.EcuListRequest.verify(message.getECUList);
                    if (error)
                        return "getECUList." + error;
                }
            }
            if (message.getSelectedECUs != null && message.hasOwnProperty("getSelectedECUs")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getSelectedECUs);
                    if (error)
                        return "getSelectedECUs." + error;
                }
            }
            if (message.setSelectedECUs != null && message.hasOwnProperty("setSelectedECUs")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.ECUsInVehicle.verify(message.setSelectedECUs);
                    if (error)
                        return "setSelectedECUs." + error;
                }
            }
            if (message.startDownload != null && message.hasOwnProperty("startDownload")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.VersionComparisonRequest.verify(message.startDownload);
                    if (error)
                        return "startDownload." + error;
                }
            }
            if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getSettings);
                    if (error)
                        return "getSettings." + error;
                }
            }
            if (message.setSettings != null && message.hasOwnProperty("setSettings")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.Settings.verify(message.setSettings);
                    if (error)
                        return "setSettings." + error;
                }
            }
            if (message.versionComparisonReq != null && message.hasOwnProperty("versionComparisonReq")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.VersionComparisonRequest.verify(message.versionComparisonReq);
                    if (error)
                        return "versionComparisonReq." + error;
                }
            }
            if (message.getVersionCompResult != null && message.hasOwnProperty("getVersionCompResult")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getVersionCompResult);
                    if (error)
                        return "getVersionCompResult." + error;
                }
            }
            if (message.udsSequenceRequest != null && message.hasOwnProperty("udsSequenceRequest")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.uds_client_pb.UDSSequenceRequest.verify(message.udsSequenceRequest);
                    if (error)
                        return "udsSequenceRequest." + error;
                }
            }
            if (message.udsSequenceResult != null && message.hasOwnProperty("udsSequenceResult")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.udsSequenceResult);
                    if (error)
                        return "udsSequenceResult." + error;
                }
            }
            if (message.securityAccess != null && message.hasOwnProperty("securityAccess")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.SecurityAccessRequest.verify(message.securityAccess);
                    if (error)
                        return "securityAccess." + error;
                }
            }
            if (message.udsRequest != null && message.hasOwnProperty("udsRequest")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.uds_client_pb.UDSRequest.verify(message.udsRequest);
                    if (error)
                        return "udsRequest." + error;
                }
            }
            if (message.adbRequest != null && message.hasOwnProperty("adbRequest")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.ADBRequest.verify(message.adbRequest);
                    if (error)
                        return "adbRequest." + error;
                }
            }
            if (message.getDiagnosticTree != null && message.hasOwnProperty("getDiagnosticTree")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                if (!$util.isString(message.getDiagnosticTree))
                    return "getDiagnosticTree: string expected";
            }
            if (message.loopTesterPresent != null && message.hasOwnProperty("loopTesterPresent")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.TesterPresentLoop.verify(message.loopTesterPresent);
                    if (error)
                        return "loopTesterPresent." + error;
                }
            }
            if (message.calibrateCfrmEol != null && message.hasOwnProperty("calibrateCfrmEol")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.CfrmEolCalibrationRequest.verify(message.calibrateCfrmEol);
                    if (error)
                        return "calibrateCfrmEol." + error;
                }
            }
            if (message.getCalCfrmEolResult != null && message.hasOwnProperty("getCalCfrmEolResult")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getCalCfrmEolResult);
                    if (error)
                        return "getCalCfrmEolResult." + error;
                }
            }
            if (message.calibrateSvcEol != null && message.hasOwnProperty("calibrateSvcEol")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.calibrateSvcEol);
                    if (error)
                        return "calibrateSvcEol." + error;
                }
            }
            if (message.getCalSvcEolResult != null && message.hasOwnProperty("getCalSvcEolResult")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getCalSvcEolResult);
                    if (error)
                        return "getCalSvcEolResult." + error;
                }
            }
            if (message.startAutomation != null && message.hasOwnProperty("startAutomation")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                {
                    var error = $root.tool_commands.AutomationRR.verify(message.startAutomation);
                    if (error)
                        return "startAutomation." + error;
                }
            }
            if (message.getAutomationResult != null && message.hasOwnProperty("getAutomationResult")) {
                if (properties.currentRequest === 1)
                    return "currentRequest: multiple values";
                properties.currentRequest = 1;
                switch (message.getAutomationResult) {
                default:
                    return "getAutomationResult: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
            }
            return null;
        };

        /**
         * Creates a request message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.request
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.request} request
         */
        request.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.request)
                return object;
            var message = new $root.tool_commands.request();
            if (object.preferCached != null)
                message.preferCached = Boolean(object.preferCached);
            if (object.wait != null) {
                if (typeof object.wait !== "object")
                    throw TypeError(".tool_commands.request.wait: object expected");
                message.wait = $root.tool_commands.Wait.fromObject(object.wait);
            }
            if (object.getVersion != null) {
                if (typeof object.getVersion !== "object")
                    throw TypeError(".tool_commands.request.getVersion: object expected");
                message.getVersion = $root.tool_commands.MyEmpty.fromObject(object.getVersion);
            }
            if (object.startUpdateSession != null) {
                if (typeof object.startUpdateSession !== "object")
                    throw TypeError(".tool_commands.request.startUpdateSession: object expected");
                message.startUpdateSession = $root.tool_commands.SelectedECUs.fromObject(object.startUpdateSession);
            }
            if (object.getProgress != null) {
                if (typeof object.getProgress !== "object")
                    throw TypeError(".tool_commands.request.getProgress: object expected");
                message.getProgress = $root.progress_pb.Progress.fromObject(object.getProgress);
            }
            if (object.getModels != null) {
                if (typeof object.getModels !== "object")
                    throw TypeError(".tool_commands.request.getModels: object expected");
                message.getModels = $root.tool_commands.MyEmpty.fromObject(object.getModels);
            }
            if (object.getECUList != null) {
                if (typeof object.getECUList !== "object")
                    throw TypeError(".tool_commands.request.getECUList: object expected");
                message.getECUList = $root.tool_commands.EcuListRequest.fromObject(object.getECUList);
            }
            if (object.getSelectedECUs != null) {
                if (typeof object.getSelectedECUs !== "object")
                    throw TypeError(".tool_commands.request.getSelectedECUs: object expected");
                message.getSelectedECUs = $root.tool_commands.MyEmpty.fromObject(object.getSelectedECUs);
            }
            if (object.setSelectedECUs != null) {
                if (typeof object.setSelectedECUs !== "object")
                    throw TypeError(".tool_commands.request.setSelectedECUs: object expected");
                message.setSelectedECUs = $root.tool_commands.ECUsInVehicle.fromObject(object.setSelectedECUs);
            }
            if (object.startDownload != null) {
                if (typeof object.startDownload !== "object")
                    throw TypeError(".tool_commands.request.startDownload: object expected");
                message.startDownload = $root.tool_commands.VersionComparisonRequest.fromObject(object.startDownload);
            }
            if (object.getSettings != null) {
                if (typeof object.getSettings !== "object")
                    throw TypeError(".tool_commands.request.getSettings: object expected");
                message.getSettings = $root.tool_commands.MyEmpty.fromObject(object.getSettings);
            }
            if (object.setSettings != null) {
                if (typeof object.setSettings !== "object")
                    throw TypeError(".tool_commands.request.setSettings: object expected");
                message.setSettings = $root.tool_commands.Settings.fromObject(object.setSettings);
            }
            if (object.versionComparisonReq != null) {
                if (typeof object.versionComparisonReq !== "object")
                    throw TypeError(".tool_commands.request.versionComparisonReq: object expected");
                message.versionComparisonReq = $root.tool_commands.VersionComparisonRequest.fromObject(object.versionComparisonReq);
            }
            if (object.getVersionCompResult != null) {
                if (typeof object.getVersionCompResult !== "object")
                    throw TypeError(".tool_commands.request.getVersionCompResult: object expected");
                message.getVersionCompResult = $root.tool_commands.MyEmpty.fromObject(object.getVersionCompResult);
            }
            if (object.udsSequenceRequest != null) {
                if (typeof object.udsSequenceRequest !== "object")
                    throw TypeError(".tool_commands.request.udsSequenceRequest: object expected");
                message.udsSequenceRequest = $root.uds_client_pb.UDSSequenceRequest.fromObject(object.udsSequenceRequest);
            }
            if (object.udsSequenceResult != null) {
                if (typeof object.udsSequenceResult !== "object")
                    throw TypeError(".tool_commands.request.udsSequenceResult: object expected");
                message.udsSequenceResult = $root.tool_commands.MyEmpty.fromObject(object.udsSequenceResult);
            }
            if (object.securityAccess != null) {
                if (typeof object.securityAccess !== "object")
                    throw TypeError(".tool_commands.request.securityAccess: object expected");
                message.securityAccess = $root.tool_commands.SecurityAccessRequest.fromObject(object.securityAccess);
            }
            if (object.udsRequest != null) {
                if (typeof object.udsRequest !== "object")
                    throw TypeError(".tool_commands.request.udsRequest: object expected");
                message.udsRequest = $root.uds_client_pb.UDSRequest.fromObject(object.udsRequest);
            }
            if (object.adbRequest != null) {
                if (typeof object.adbRequest !== "object")
                    throw TypeError(".tool_commands.request.adbRequest: object expected");
                message.adbRequest = $root.tool_commands.ADBRequest.fromObject(object.adbRequest);
            }
            if (object.getDiagnosticTree != null)
                message.getDiagnosticTree = String(object.getDiagnosticTree);
            if (object.loopTesterPresent != null) {
                if (typeof object.loopTesterPresent !== "object")
                    throw TypeError(".tool_commands.request.loopTesterPresent: object expected");
                message.loopTesterPresent = $root.tool_commands.TesterPresentLoop.fromObject(object.loopTesterPresent);
            }
            if (object.calibrateCfrmEol != null) {
                if (typeof object.calibrateCfrmEol !== "object")
                    throw TypeError(".tool_commands.request.calibrateCfrmEol: object expected");
                message.calibrateCfrmEol = $root.tool_commands.CfrmEolCalibrationRequest.fromObject(object.calibrateCfrmEol);
            }
            if (object.getCalCfrmEolResult != null) {
                if (typeof object.getCalCfrmEolResult !== "object")
                    throw TypeError(".tool_commands.request.getCalCfrmEolResult: object expected");
                message.getCalCfrmEolResult = $root.tool_commands.MyEmpty.fromObject(object.getCalCfrmEolResult);
            }
            if (object.calibrateSvcEol != null) {
                if (typeof object.calibrateSvcEol !== "object")
                    throw TypeError(".tool_commands.request.calibrateSvcEol: object expected");
                message.calibrateSvcEol = $root.tool_commands.MyEmpty.fromObject(object.calibrateSvcEol);
            }
            if (object.getCalSvcEolResult != null) {
                if (typeof object.getCalSvcEolResult !== "object")
                    throw TypeError(".tool_commands.request.getCalSvcEolResult: object expected");
                message.getCalSvcEolResult = $root.tool_commands.MyEmpty.fromObject(object.getCalSvcEolResult);
            }
            if (object.startAutomation != null) {
                if (typeof object.startAutomation !== "object")
                    throw TypeError(".tool_commands.request.startAutomation: object expected");
                message.startAutomation = $root.tool_commands.AutomationRR.fromObject(object.startAutomation);
            }
            switch (object.getAutomationResult) {
            default:
                if (typeof object.getAutomationResult === "number") {
                    message.getAutomationResult = object.getAutomationResult;
                    break;
                }
                break;
            case "CFRM_EOL_CALIBRATION":
            case 0:
                message.getAutomationResult = 0;
                break;
            case "SVC_EOL_CALIBRATION":
            case 1:
                message.getAutomationResult = 1;
                break;
            case "SRS_VARIANT_CODING":
            case 2:
                message.getAutomationResult = 2;
                break;
            case "SAS_CALIBRATION":
            case 3:
                message.getAutomationResult = 3;
                break;
            case "TPMS_EOL":
            case 4:
                message.getAutomationResult = 4;
                break;
            case "SVC_WRITE_REGION_CODE":
            case 5:
                message.getAutomationResult = 5;
                break;
            case "SRSEDR_READ_6_DIDS":
            case 6:
                message.getAutomationResult = 6;
                break;
            case "SVCEDR_READ_6_DIDS":
            case 7:
                message.getAutomationResult = 7;
                break;
            case "SEAT_CALIBRATION":
            case 8:
                message.getAutomationResult = 8;
                break;
            case "SVC_AS_CALIBRATION":
            case 9:
                message.getAutomationResult = 9;
                break;
            case "ADC_CAMERA_CALIBRATION":
            case 10:
                message.getAutomationResult = 10;
                break;
            case "REAR_SEAT_CALIBRATION":
            case 11:
                message.getAutomationResult = 11;
                break;
            case "MAX_AUTOMATION_ID":
            case 12:
                message.getAutomationResult = 12;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a request message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.request
         * @static
         * @param {tool_commands.request} message request
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        request.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.preferCached = false;
            if (message.preferCached != null && message.hasOwnProperty("preferCached"))
                object.preferCached = message.preferCached;
            if (message.wait != null && message.hasOwnProperty("wait")) {
                object.wait = $root.tool_commands.Wait.toObject(message.wait, options);
                if (options.oneofs)
                    object.currentRequest = "wait";
            }
            if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
                object.getVersion = $root.tool_commands.MyEmpty.toObject(message.getVersion, options);
                if (options.oneofs)
                    object.currentRequest = "getVersion";
            }
            if (message.startUpdateSession != null && message.hasOwnProperty("startUpdateSession")) {
                object.startUpdateSession = $root.tool_commands.SelectedECUs.toObject(message.startUpdateSession, options);
                if (options.oneofs)
                    object.currentRequest = "startUpdateSession";
            }
            if (message.getProgress != null && message.hasOwnProperty("getProgress")) {
                object.getProgress = $root.progress_pb.Progress.toObject(message.getProgress, options);
                if (options.oneofs)
                    object.currentRequest = "getProgress";
            }
            if (message.getModels != null && message.hasOwnProperty("getModels")) {
                object.getModels = $root.tool_commands.MyEmpty.toObject(message.getModels, options);
                if (options.oneofs)
                    object.currentRequest = "getModels";
            }
            if (message.getECUList != null && message.hasOwnProperty("getECUList")) {
                object.getECUList = $root.tool_commands.EcuListRequest.toObject(message.getECUList, options);
                if (options.oneofs)
                    object.currentRequest = "getECUList";
            }
            if (message.getSelectedECUs != null && message.hasOwnProperty("getSelectedECUs")) {
                object.getSelectedECUs = $root.tool_commands.MyEmpty.toObject(message.getSelectedECUs, options);
                if (options.oneofs)
                    object.currentRequest = "getSelectedECUs";
            }
            if (message.setSelectedECUs != null && message.hasOwnProperty("setSelectedECUs")) {
                object.setSelectedECUs = $root.tool_commands.ECUsInVehicle.toObject(message.setSelectedECUs, options);
                if (options.oneofs)
                    object.currentRequest = "setSelectedECUs";
            }
            if (message.startDownload != null && message.hasOwnProperty("startDownload")) {
                object.startDownload = $root.tool_commands.VersionComparisonRequest.toObject(message.startDownload, options);
                if (options.oneofs)
                    object.currentRequest = "startDownload";
            }
            if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                object.getSettings = $root.tool_commands.MyEmpty.toObject(message.getSettings, options);
                if (options.oneofs)
                    object.currentRequest = "getSettings";
            }
            if (message.setSettings != null && message.hasOwnProperty("setSettings")) {
                object.setSettings = $root.tool_commands.Settings.toObject(message.setSettings, options);
                if (options.oneofs)
                    object.currentRequest = "setSettings";
            }
            if (message.versionComparisonReq != null && message.hasOwnProperty("versionComparisonReq")) {
                object.versionComparisonReq = $root.tool_commands.VersionComparisonRequest.toObject(message.versionComparisonReq, options);
                if (options.oneofs)
                    object.currentRequest = "versionComparisonReq";
            }
            if (message.getVersionCompResult != null && message.hasOwnProperty("getVersionCompResult")) {
                object.getVersionCompResult = $root.tool_commands.MyEmpty.toObject(message.getVersionCompResult, options);
                if (options.oneofs)
                    object.currentRequest = "getVersionCompResult";
            }
            if (message.udsSequenceRequest != null && message.hasOwnProperty("udsSequenceRequest")) {
                object.udsSequenceRequest = $root.uds_client_pb.UDSSequenceRequest.toObject(message.udsSequenceRequest, options);
                if (options.oneofs)
                    object.currentRequest = "udsSequenceRequest";
            }
            if (message.udsSequenceResult != null && message.hasOwnProperty("udsSequenceResult")) {
                object.udsSequenceResult = $root.tool_commands.MyEmpty.toObject(message.udsSequenceResult, options);
                if (options.oneofs)
                    object.currentRequest = "udsSequenceResult";
            }
            if (message.securityAccess != null && message.hasOwnProperty("securityAccess")) {
                object.securityAccess = $root.tool_commands.SecurityAccessRequest.toObject(message.securityAccess, options);
                if (options.oneofs)
                    object.currentRequest = "securityAccess";
            }
            if (message.udsRequest != null && message.hasOwnProperty("udsRequest")) {
                object.udsRequest = $root.uds_client_pb.UDSRequest.toObject(message.udsRequest, options);
                if (options.oneofs)
                    object.currentRequest = "udsRequest";
            }
            if (message.adbRequest != null && message.hasOwnProperty("adbRequest")) {
                object.adbRequest = $root.tool_commands.ADBRequest.toObject(message.adbRequest, options);
                if (options.oneofs)
                    object.currentRequest = "adbRequest";
            }
            if (message.getDiagnosticTree != null && message.hasOwnProperty("getDiagnosticTree")) {
                object.getDiagnosticTree = message.getDiagnosticTree;
                if (options.oneofs)
                    object.currentRequest = "getDiagnosticTree";
            }
            if (message.loopTesterPresent != null && message.hasOwnProperty("loopTesterPresent")) {
                object.loopTesterPresent = $root.tool_commands.TesterPresentLoop.toObject(message.loopTesterPresent, options);
                if (options.oneofs)
                    object.currentRequest = "loopTesterPresent";
            }
            if (message.calibrateCfrmEol != null && message.hasOwnProperty("calibrateCfrmEol")) {
                object.calibrateCfrmEol = $root.tool_commands.CfrmEolCalibrationRequest.toObject(message.calibrateCfrmEol, options);
                if (options.oneofs)
                    object.currentRequest = "calibrateCfrmEol";
            }
            if (message.getCalCfrmEolResult != null && message.hasOwnProperty("getCalCfrmEolResult")) {
                object.getCalCfrmEolResult = $root.tool_commands.MyEmpty.toObject(message.getCalCfrmEolResult, options);
                if (options.oneofs)
                    object.currentRequest = "getCalCfrmEolResult";
            }
            if (message.calibrateSvcEol != null && message.hasOwnProperty("calibrateSvcEol")) {
                object.calibrateSvcEol = $root.tool_commands.MyEmpty.toObject(message.calibrateSvcEol, options);
                if (options.oneofs)
                    object.currentRequest = "calibrateSvcEol";
            }
            if (message.getCalSvcEolResult != null && message.hasOwnProperty("getCalSvcEolResult")) {
                object.getCalSvcEolResult = $root.tool_commands.MyEmpty.toObject(message.getCalSvcEolResult, options);
                if (options.oneofs)
                    object.currentRequest = "getCalSvcEolResult";
            }
            if (message.startAutomation != null && message.hasOwnProperty("startAutomation")) {
                object.startAutomation = $root.tool_commands.AutomationRR.toObject(message.startAutomation, options);
                if (options.oneofs)
                    object.currentRequest = "startAutomation";
            }
            if (message.getAutomationResult != null && message.hasOwnProperty("getAutomationResult")) {
                object.getAutomationResult = options.enums === String ? $root.tool_commands.AutomationId[message.getAutomationResult] === undefined ? message.getAutomationResult : $root.tool_commands.AutomationId[message.getAutomationResult] : message.getAutomationResult;
                if (options.oneofs)
                    object.currentRequest = "getAutomationResult";
            }
            return object;
        };

        /**
         * Converts this request to JSON.
         * @function toJSON
         * @memberof tool_commands.request
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        request.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for request
         * @function getTypeUrl
         * @memberof tool_commands.request
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.request";
        };

        return request;
    })();

    tool_commands.requests = (function() {

        /**
         * Properties of a requests.
         * @memberof tool_commands
         * @interface Irequests
         * @property {Array.<tool_commands.Irequest>|null} [list] requests list
         */

        /**
         * Constructs a new requests.
         * @memberof tool_commands
         * @classdesc Represents a requests.
         * @implements Irequests
         * @constructor
         * @param {tool_commands.Irequests=} [properties] Properties to set
         */
        function requests(properties) {
            this.list = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * requests list.
         * @member {Array.<tool_commands.Irequest>} list
         * @memberof tool_commands.requests
         * @instance
         */
        requests.prototype.list = $util.emptyArray;

        /**
         * Creates a new requests instance using the specified properties.
         * @function create
         * @memberof tool_commands.requests
         * @static
         * @param {tool_commands.Irequests=} [properties] Properties to set
         * @returns {tool_commands.requests} requests instance
         */
        requests.create = function create(properties) {
            return new requests(properties);
        };

        /**
         * Encodes the specified requests message. Does not implicitly {@link tool_commands.requests.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.requests
         * @static
         * @param {tool_commands.Irequests} message requests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        requests.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.list != null && message.list.length)
                for (var i = 0; i < message.list.length; ++i)
                    $root.tool_commands.request.encode(message.list[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified requests message, length delimited. Does not implicitly {@link tool_commands.requests.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.requests
         * @static
         * @param {tool_commands.Irequests} message requests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        requests.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a requests message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.requests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.requests} requests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        requests.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.requests();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.list && message.list.length))
                            message.list = [];
                        message.list.push($root.tool_commands.request.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a requests message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.requests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.requests} requests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        requests.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a requests message.
         * @function verify
         * @memberof tool_commands.requests
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        requests.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.list != null && message.hasOwnProperty("list")) {
                if (!Array.isArray(message.list))
                    return "list: array expected";
                for (var i = 0; i < message.list.length; ++i) {
                    var error = $root.tool_commands.request.verify(message.list[i]);
                    if (error)
                        return "list." + error;
                }
            }
            return null;
        };

        /**
         * Creates a requests message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.requests
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.requests} requests
         */
        requests.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.requests)
                return object;
            var message = new $root.tool_commands.requests();
            if (object.list) {
                if (!Array.isArray(object.list))
                    throw TypeError(".tool_commands.requests.list: array expected");
                message.list = [];
                for (var i = 0; i < object.list.length; ++i) {
                    if (typeof object.list[i] !== "object")
                        throw TypeError(".tool_commands.requests.list: object expected");
                    message.list[i] = $root.tool_commands.request.fromObject(object.list[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a requests message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.requests
         * @static
         * @param {tool_commands.requests} message requests
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        requests.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.list = [];
            if (message.list && message.list.length) {
                object.list = [];
                for (var j = 0; j < message.list.length; ++j)
                    object.list[j] = $root.tool_commands.request.toObject(message.list[j], options);
            }
            return object;
        };

        /**
         * Converts this requests to JSON.
         * @function toJSON
         * @memberof tool_commands.requests
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        requests.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for requests
         * @function getTypeUrl
         * @memberof tool_commands.requests
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        requests.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.requests";
        };

        return requests;
    })();

    tool_commands.response = (function() {

        /**
         * Properties of a response.
         * @memberof tool_commands
         * @interface Iresponse
         * @property {tool_commands.Result} result response result
         * @property {string|null} [resultDescription] response resultDescription
         * @property {tool_commands.IMyEmpty|null} [wait] response wait
         * @property {tool_commands.IVersionInfo|null} [getVersion] response getVersion
         * @property {tool_commands.IStartUpdateSessionResponse|null} [startUpdateSession] response startUpdateSession
         * @property {progress_pb.IProgressResponses|null} [getProgress] response getProgress
         * @property {tool_commands.IModels|null} [getModels] response getModels
         * @property {tool_commands.IECUsInVehicle|null} [getECUList] response getECUList
         * @property {tool_commands.IECUsInVehicle|null} [getSelectedECUs] response getSelectedECUs
         * @property {tool_commands.IMyEmpty|null} [setSelectedECUs] response setSelectedECUs
         * @property {tool_commands.IMyEmpty|null} [startDownload] response startDownload
         * @property {tool_commands.ISettings|null} [getSettings] response getSettings
         * @property {tool_commands.IMyEmpty|null} [setSettings] response setSettings
         * @property {tool_commands.IMyEmpty|null} [versionComparisonReq] response versionComparisonReq
         * @property {tool_commands.IVersionComparisonResponse|null} [getVersionCompResult] response getVersionCompResult
         * @property {tool_commands.IMyEmpty|null} [udsSequenceRequest] response udsSequenceRequest
         * @property {uds_client_pb.IUDSSequenceResponse|null} [udsSequenceResult] response udsSequenceResult
         * @property {tool_commands.ISecurityAccessResponse|null} [securityAccess] response securityAccess
         * @property {uds_client_pb.IUDSRequest|null} [udsRequest] response udsRequest
         * @property {tool_commands.IADBResponse|null} [adbRequest] response adbRequest
         * @property {ecu_diagnostic_definitions.IDiagnosticTree|null} [getDiagnosticTree] response getDiagnosticTree
         * @property {tool_commands.IMyEmpty|null} [loopTesterPresent] response loopTesterPresent
         * @property {tool_commands.IMyEmpty|null} [calibrateCfrmEol] response calibrateCfrmEol
         * @property {tool_commands.IMyEmpty|null} [getCalCfrmEolResult] response getCalCfrmEolResult
         * @property {tool_commands.IMyEmpty|null} [calibrateSvcEol] response calibrateSvcEol
         * @property {tool_commands.IMyEmpty|null} [getCalSvcEolResult] response getCalSvcEolResult
         * @property {tool_commands.IMyEmpty|null} [startAutomation] response startAutomation
         * @property {tool_commands.IAutomationRR|null} [getAutomationResult] response getAutomationResult
         * @property {boolean|null} [invalidRequest] response invalidRequest
         */

        /**
         * Constructs a new response.
         * @memberof tool_commands
         * @classdesc Represents a response.
         * @implements Iresponse
         * @constructor
         * @param {tool_commands.Iresponse=} [properties] Properties to set
         */
        function response(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * response result.
         * @member {tool_commands.Result} result
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.result = 0;

        /**
         * response resultDescription.
         * @member {string} resultDescription
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.resultDescription = "";

        /**
         * response wait.
         * @member {tool_commands.IMyEmpty|null|undefined} wait
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.wait = null;

        /**
         * response getVersion.
         * @member {tool_commands.IVersionInfo|null|undefined} getVersion
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getVersion = null;

        /**
         * response startUpdateSession.
         * @member {tool_commands.IStartUpdateSessionResponse|null|undefined} startUpdateSession
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.startUpdateSession = null;

        /**
         * response getProgress.
         * @member {progress_pb.IProgressResponses|null|undefined} getProgress
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getProgress = null;

        /**
         * response getModels.
         * @member {tool_commands.IModels|null|undefined} getModels
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getModels = null;

        /**
         * response getECUList.
         * @member {tool_commands.IECUsInVehicle|null|undefined} getECUList
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getECUList = null;

        /**
         * response getSelectedECUs.
         * @member {tool_commands.IECUsInVehicle|null|undefined} getSelectedECUs
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getSelectedECUs = null;

        /**
         * response setSelectedECUs.
         * @member {tool_commands.IMyEmpty|null|undefined} setSelectedECUs
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.setSelectedECUs = null;

        /**
         * response startDownload.
         * @member {tool_commands.IMyEmpty|null|undefined} startDownload
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.startDownload = null;

        /**
         * response getSettings.
         * @member {tool_commands.ISettings|null|undefined} getSettings
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getSettings = null;

        /**
         * response setSettings.
         * @member {tool_commands.IMyEmpty|null|undefined} setSettings
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.setSettings = null;

        /**
         * response versionComparisonReq.
         * @member {tool_commands.IMyEmpty|null|undefined} versionComparisonReq
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.versionComparisonReq = null;

        /**
         * response getVersionCompResult.
         * @member {tool_commands.IVersionComparisonResponse|null|undefined} getVersionCompResult
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getVersionCompResult = null;

        /**
         * response udsSequenceRequest.
         * @member {tool_commands.IMyEmpty|null|undefined} udsSequenceRequest
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.udsSequenceRequest = null;

        /**
         * response udsSequenceResult.
         * @member {uds_client_pb.IUDSSequenceResponse|null|undefined} udsSequenceResult
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.udsSequenceResult = null;

        /**
         * response securityAccess.
         * @member {tool_commands.ISecurityAccessResponse|null|undefined} securityAccess
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.securityAccess = null;

        /**
         * response udsRequest.
         * @member {uds_client_pb.IUDSRequest|null|undefined} udsRequest
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.udsRequest = null;

        /**
         * response adbRequest.
         * @member {tool_commands.IADBResponse|null|undefined} adbRequest
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.adbRequest = null;

        /**
         * response getDiagnosticTree.
         * @member {ecu_diagnostic_definitions.IDiagnosticTree|null|undefined} getDiagnosticTree
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getDiagnosticTree = null;

        /**
         * response loopTesterPresent.
         * @member {tool_commands.IMyEmpty|null|undefined} loopTesterPresent
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.loopTesterPresent = null;

        /**
         * response calibrateCfrmEol.
         * @member {tool_commands.IMyEmpty|null|undefined} calibrateCfrmEol
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.calibrateCfrmEol = null;

        /**
         * response getCalCfrmEolResult.
         * @member {tool_commands.IMyEmpty|null|undefined} getCalCfrmEolResult
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getCalCfrmEolResult = null;

        /**
         * response calibrateSvcEol.
         * @member {tool_commands.IMyEmpty|null|undefined} calibrateSvcEol
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.calibrateSvcEol = null;

        /**
         * response getCalSvcEolResult.
         * @member {tool_commands.IMyEmpty|null|undefined} getCalSvcEolResult
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getCalSvcEolResult = null;

        /**
         * response startAutomation.
         * @member {tool_commands.IMyEmpty|null|undefined} startAutomation
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.startAutomation = null;

        /**
         * response getAutomationResult.
         * @member {tool_commands.IAutomationRR|null|undefined} getAutomationResult
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.getAutomationResult = null;

        /**
         * response invalidRequest.
         * @member {boolean|null|undefined} invalidRequest
         * @memberof tool_commands.response
         * @instance
         */
        response.prototype.invalidRequest = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * response currentResponse.
         * @member {"wait"|"getVersion"|"startUpdateSession"|"getProgress"|"getModels"|"getECUList"|"getSelectedECUs"|"setSelectedECUs"|"startDownload"|"getSettings"|"setSettings"|"versionComparisonReq"|"getVersionCompResult"|"udsSequenceRequest"|"udsSequenceResult"|"securityAccess"|"udsRequest"|"adbRequest"|"getDiagnosticTree"|"loopTesterPresent"|"calibrateCfrmEol"|"getCalCfrmEolResult"|"calibrateSvcEol"|"getCalSvcEolResult"|"startAutomation"|"getAutomationResult"|"invalidRequest"|undefined} currentResponse
         * @memberof tool_commands.response
         * @instance
         */
        Object.defineProperty(response.prototype, "currentResponse", {
            get: $util.oneOfGetter($oneOfFields = ["wait", "getVersion", "startUpdateSession", "getProgress", "getModels", "getECUList", "getSelectedECUs", "setSelectedECUs", "startDownload", "getSettings", "setSettings", "versionComparisonReq", "getVersionCompResult", "udsSequenceRequest", "udsSequenceResult", "securityAccess", "udsRequest", "adbRequest", "getDiagnosticTree", "loopTesterPresent", "calibrateCfrmEol", "getCalCfrmEolResult", "calibrateSvcEol", "getCalSvcEolResult", "startAutomation", "getAutomationResult", "invalidRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new response instance using the specified properties.
         * @function create
         * @memberof tool_commands.response
         * @static
         * @param {tool_commands.Iresponse=} [properties] Properties to set
         * @returns {tool_commands.response} response instance
         */
        response.create = function create(properties) {
            return new response(properties);
        };

        /**
         * Encodes the specified response message. Does not implicitly {@link tool_commands.response.verify|verify} messages.
         * @function encode
         * @memberof tool_commands.response
         * @static
         * @param {tool_commands.Iresponse} message response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        response.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.resultDescription != null && Object.hasOwnProperty.call(message, "resultDescription"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.resultDescription);
            if (message.wait != null && Object.hasOwnProperty.call(message, "wait"))
                $root.tool_commands.MyEmpty.encode(message.wait, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.getVersion != null && Object.hasOwnProperty.call(message, "getVersion"))
                $root.tool_commands.VersionInfo.encode(message.getVersion, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.startUpdateSession != null && Object.hasOwnProperty.call(message, "startUpdateSession"))
                $root.tool_commands.StartUpdateSessionResponse.encode(message.startUpdateSession, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.getProgress != null && Object.hasOwnProperty.call(message, "getProgress"))
                $root.progress_pb.ProgressResponses.encode(message.getProgress, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.getModels != null && Object.hasOwnProperty.call(message, "getModels"))
                $root.tool_commands.Models.encode(message.getModels, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.getECUList != null && Object.hasOwnProperty.call(message, "getECUList"))
                $root.tool_commands.ECUsInVehicle.encode(message.getECUList, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.getSelectedECUs != null && Object.hasOwnProperty.call(message, "getSelectedECUs"))
                $root.tool_commands.ECUsInVehicle.encode(message.getSelectedECUs, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.setSelectedECUs != null && Object.hasOwnProperty.call(message, "setSelectedECUs"))
                $root.tool_commands.MyEmpty.encode(message.setSelectedECUs, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.startDownload != null && Object.hasOwnProperty.call(message, "startDownload"))
                $root.tool_commands.MyEmpty.encode(message.startDownload, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.getSettings != null && Object.hasOwnProperty.call(message, "getSettings"))
                $root.tool_commands.Settings.encode(message.getSettings, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.setSettings != null && Object.hasOwnProperty.call(message, "setSettings"))
                $root.tool_commands.MyEmpty.encode(message.setSettings, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.versionComparisonReq != null && Object.hasOwnProperty.call(message, "versionComparisonReq"))
                $root.tool_commands.MyEmpty.encode(message.versionComparisonReq, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
            if (message.getVersionCompResult != null && Object.hasOwnProperty.call(message, "getVersionCompResult"))
                $root.tool_commands.VersionComparisonResponse.encode(message.getVersionCompResult, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
            if (message.udsSequenceRequest != null && Object.hasOwnProperty.call(message, "udsSequenceRequest"))
                $root.tool_commands.MyEmpty.encode(message.udsSequenceRequest, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
            if (message.udsSequenceResult != null && Object.hasOwnProperty.call(message, "udsSequenceResult"))
                $root.uds_client_pb.UDSSequenceResponse.encode(message.udsSequenceResult, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
            if (message.securityAccess != null && Object.hasOwnProperty.call(message, "securityAccess"))
                $root.tool_commands.SecurityAccessResponse.encode(message.securityAccess, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
            if (message.udsRequest != null && Object.hasOwnProperty.call(message, "udsRequest"))
                $root.uds_client_pb.UDSRequest.encode(message.udsRequest, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
            if (message.adbRequest != null && Object.hasOwnProperty.call(message, "adbRequest"))
                $root.tool_commands.ADBResponse.encode(message.adbRequest, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
            if (message.getDiagnosticTree != null && Object.hasOwnProperty.call(message, "getDiagnosticTree"))
                $root.ecu_diagnostic_definitions.DiagnosticTree.encode(message.getDiagnosticTree, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
            if (message.loopTesterPresent != null && Object.hasOwnProperty.call(message, "loopTesterPresent"))
                $root.tool_commands.MyEmpty.encode(message.loopTesterPresent, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
            if (message.calibrateCfrmEol != null && Object.hasOwnProperty.call(message, "calibrateCfrmEol"))
                $root.tool_commands.MyEmpty.encode(message.calibrateCfrmEol, writer.uint32(/* id 80, wireType 2 =*/642).fork()).ldelim();
            if (message.getCalCfrmEolResult != null && Object.hasOwnProperty.call(message, "getCalCfrmEolResult"))
                $root.tool_commands.MyEmpty.encode(message.getCalCfrmEolResult, writer.uint32(/* id 81, wireType 2 =*/650).fork()).ldelim();
            if (message.calibrateSvcEol != null && Object.hasOwnProperty.call(message, "calibrateSvcEol"))
                $root.tool_commands.MyEmpty.encode(message.calibrateSvcEol, writer.uint32(/* id 82, wireType 2 =*/658).fork()).ldelim();
            if (message.getCalSvcEolResult != null && Object.hasOwnProperty.call(message, "getCalSvcEolResult"))
                $root.tool_commands.MyEmpty.encode(message.getCalSvcEolResult, writer.uint32(/* id 83, wireType 2 =*/666).fork()).ldelim();
            if (message.startAutomation != null && Object.hasOwnProperty.call(message, "startAutomation"))
                $root.tool_commands.MyEmpty.encode(message.startAutomation, writer.uint32(/* id 84, wireType 2 =*/674).fork()).ldelim();
            if (message.getAutomationResult != null && Object.hasOwnProperty.call(message, "getAutomationResult"))
                $root.tool_commands.AutomationRR.encode(message.getAutomationResult, writer.uint32(/* id 85, wireType 2 =*/682).fork()).ldelim();
            if (message.invalidRequest != null && Object.hasOwnProperty.call(message, "invalidRequest"))
                writer.uint32(/* id 1000, wireType 0 =*/8000).bool(message.invalidRequest);
            return writer;
        };

        /**
         * Encodes the specified response message, length delimited. Does not implicitly {@link tool_commands.response.verify|verify} messages.
         * @function encodeDelimited
         * @memberof tool_commands.response
         * @static
         * @param {tool_commands.Iresponse} message response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        response.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a response message from the specified reader or buffer.
         * @function decode
         * @memberof tool_commands.response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {tool_commands.response} response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        response.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.tool_commands.response();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.resultDescription = reader.string();
                        break;
                    }
                case 10: {
                        message.wait = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.getVersion = $root.tool_commands.VersionInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.startUpdateSession = $root.tool_commands.StartUpdateSessionResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.getProgress = $root.progress_pb.ProgressResponses.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.getModels = $root.tool_commands.Models.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.getECUList = $root.tool_commands.ECUsInVehicle.decode(reader, reader.uint32());
                        break;
                    }
                case 44: {
                        message.getSelectedECUs = $root.tool_commands.ECUsInVehicle.decode(reader, reader.uint32());
                        break;
                    }
                case 45: {
                        message.setSelectedECUs = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 46: {
                        message.startDownload = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 50: {
                        message.getSettings = $root.tool_commands.Settings.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.setSettings = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 60: {
                        message.versionComparisonReq = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 61: {
                        message.getVersionCompResult = $root.tool_commands.VersionComparisonResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 62: {
                        message.udsSequenceRequest = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 63: {
                        message.udsSequenceResult = $root.uds_client_pb.UDSSequenceResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 64: {
                        message.securityAccess = $root.tool_commands.SecurityAccessResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 66: {
                        message.udsRequest = $root.uds_client_pb.UDSRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 67: {
                        message.adbRequest = $root.tool_commands.ADBResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 70: {
                        message.getDiagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.decode(reader, reader.uint32());
                        break;
                    }
                case 71: {
                        message.loopTesterPresent = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 80: {
                        message.calibrateCfrmEol = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 81: {
                        message.getCalCfrmEolResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 82: {
                        message.calibrateSvcEol = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 83: {
                        message.getCalSvcEolResult = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 84: {
                        message.startAutomation = $root.tool_commands.MyEmpty.decode(reader, reader.uint32());
                        break;
                    }
                case 85: {
                        message.getAutomationResult = $root.tool_commands.AutomationRR.decode(reader, reader.uint32());
                        break;
                    }
                case 1000: {
                        message.invalidRequest = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("result"))
                throw $util.ProtocolError("missing required 'result'", { instance: message });
            return message;
        };

        /**
         * Decodes a response message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof tool_commands.response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {tool_commands.response} response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        response.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a response message.
         * @function verify
         * @memberof tool_commands.response
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        response.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            switch (message.result) {
            default:
                return "result: enum value expected";
            case 0:
            case -1:
            case -2:
            case 1:
            case 2:
            case 3:
            case -3:
            case -4:
                break;
            }
            if (message.resultDescription != null && message.hasOwnProperty("resultDescription"))
                if (!$util.isString(message.resultDescription))
                    return "resultDescription: string expected";
            if (message.wait != null && message.hasOwnProperty("wait")) {
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.wait);
                    if (error)
                        return "wait." + error;
                }
            }
            if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.VersionInfo.verify(message.getVersion);
                    if (error)
                        return "getVersion." + error;
                }
            }
            if (message.startUpdateSession != null && message.hasOwnProperty("startUpdateSession")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.StartUpdateSessionResponse.verify(message.startUpdateSession);
                    if (error)
                        return "startUpdateSession." + error;
                }
            }
            if (message.getProgress != null && message.hasOwnProperty("getProgress")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.progress_pb.ProgressResponses.verify(message.getProgress);
                    if (error)
                        return "getProgress." + error;
                }
            }
            if (message.getModels != null && message.hasOwnProperty("getModels")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.Models.verify(message.getModels);
                    if (error)
                        return "getModels." + error;
                }
            }
            if (message.getECUList != null && message.hasOwnProperty("getECUList")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.ECUsInVehicle.verify(message.getECUList);
                    if (error)
                        return "getECUList." + error;
                }
            }
            if (message.getSelectedECUs != null && message.hasOwnProperty("getSelectedECUs")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.ECUsInVehicle.verify(message.getSelectedECUs);
                    if (error)
                        return "getSelectedECUs." + error;
                }
            }
            if (message.setSelectedECUs != null && message.hasOwnProperty("setSelectedECUs")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.setSelectedECUs);
                    if (error)
                        return "setSelectedECUs." + error;
                }
            }
            if (message.startDownload != null && message.hasOwnProperty("startDownload")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.startDownload);
                    if (error)
                        return "startDownload." + error;
                }
            }
            if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.Settings.verify(message.getSettings);
                    if (error)
                        return "getSettings." + error;
                }
            }
            if (message.setSettings != null && message.hasOwnProperty("setSettings")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.setSettings);
                    if (error)
                        return "setSettings." + error;
                }
            }
            if (message.versionComparisonReq != null && message.hasOwnProperty("versionComparisonReq")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.versionComparisonReq);
                    if (error)
                        return "versionComparisonReq." + error;
                }
            }
            if (message.getVersionCompResult != null && message.hasOwnProperty("getVersionCompResult")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.VersionComparisonResponse.verify(message.getVersionCompResult);
                    if (error)
                        return "getVersionCompResult." + error;
                }
            }
            if (message.udsSequenceRequest != null && message.hasOwnProperty("udsSequenceRequest")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.udsSequenceRequest);
                    if (error)
                        return "udsSequenceRequest." + error;
                }
            }
            if (message.udsSequenceResult != null && message.hasOwnProperty("udsSequenceResult")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.uds_client_pb.UDSSequenceResponse.verify(message.udsSequenceResult);
                    if (error)
                        return "udsSequenceResult." + error;
                }
            }
            if (message.securityAccess != null && message.hasOwnProperty("securityAccess")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.SecurityAccessResponse.verify(message.securityAccess);
                    if (error)
                        return "securityAccess." + error;
                }
            }
            if (message.udsRequest != null && message.hasOwnProperty("udsRequest")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.uds_client_pb.UDSRequest.verify(message.udsRequest);
                    if (error)
                        return "udsRequest." + error;
                }
            }
            if (message.adbRequest != null && message.hasOwnProperty("adbRequest")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.ADBResponse.verify(message.adbRequest);
                    if (error)
                        return "adbRequest." + error;
                }
            }
            if (message.getDiagnosticTree != null && message.hasOwnProperty("getDiagnosticTree")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.ecu_diagnostic_definitions.DiagnosticTree.verify(message.getDiagnosticTree);
                    if (error)
                        return "getDiagnosticTree." + error;
                }
            }
            if (message.loopTesterPresent != null && message.hasOwnProperty("loopTesterPresent")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.loopTesterPresent);
                    if (error)
                        return "loopTesterPresent." + error;
                }
            }
            if (message.calibrateCfrmEol != null && message.hasOwnProperty("calibrateCfrmEol")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.calibrateCfrmEol);
                    if (error)
                        return "calibrateCfrmEol." + error;
                }
            }
            if (message.getCalCfrmEolResult != null && message.hasOwnProperty("getCalCfrmEolResult")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getCalCfrmEolResult);
                    if (error)
                        return "getCalCfrmEolResult." + error;
                }
            }
            if (message.calibrateSvcEol != null && message.hasOwnProperty("calibrateSvcEol")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.calibrateSvcEol);
                    if (error)
                        return "calibrateSvcEol." + error;
                }
            }
            if (message.getCalSvcEolResult != null && message.hasOwnProperty("getCalSvcEolResult")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.getCalSvcEolResult);
                    if (error)
                        return "getCalSvcEolResult." + error;
                }
            }
            if (message.startAutomation != null && message.hasOwnProperty("startAutomation")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.MyEmpty.verify(message.startAutomation);
                    if (error)
                        return "startAutomation." + error;
                }
            }
            if (message.getAutomationResult != null && message.hasOwnProperty("getAutomationResult")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                {
                    var error = $root.tool_commands.AutomationRR.verify(message.getAutomationResult);
                    if (error)
                        return "getAutomationResult." + error;
                }
            }
            if (message.invalidRequest != null && message.hasOwnProperty("invalidRequest")) {
                if (properties.currentResponse === 1)
                    return "currentResponse: multiple values";
                properties.currentResponse = 1;
                if (typeof message.invalidRequest !== "boolean")
                    return "invalidRequest: boolean expected";
            }
            return null;
        };

        /**
         * Creates a response message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof tool_commands.response
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {tool_commands.response} response
         */
        response.fromObject = function fromObject(object) {
            if (object instanceof $root.tool_commands.response)
                return object;
            var message = new $root.tool_commands.response();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "OK":
            case 0:
                message.result = 0;
                break;
            case "ERROR":
            case -1:
                message.result = -1;
                break;
            case "FAIL":
            case -2:
                message.result = -2;
                break;
            case "BUSY":
            case 1:
                message.result = 1;
                break;
            case "CACHED":
            case 2:
                message.result = 2;
                break;
            case "RETRY":
            case 3:
                message.result = 3;
                break;
            case "INVALID_REQUEST":
            case -3:
                message.result = -3;
                break;
            case "INVALID_ARGUMENT":
            case -4:
                message.result = -4;
                break;
            }
            if (object.resultDescription != null)
                message.resultDescription = String(object.resultDescription);
            if (object.wait != null) {
                if (typeof object.wait !== "object")
                    throw TypeError(".tool_commands.response.wait: object expected");
                message.wait = $root.tool_commands.MyEmpty.fromObject(object.wait);
            }
            if (object.getVersion != null) {
                if (typeof object.getVersion !== "object")
                    throw TypeError(".tool_commands.response.getVersion: object expected");
                message.getVersion = $root.tool_commands.VersionInfo.fromObject(object.getVersion);
            }
            if (object.startUpdateSession != null) {
                if (typeof object.startUpdateSession !== "object")
                    throw TypeError(".tool_commands.response.startUpdateSession: object expected");
                message.startUpdateSession = $root.tool_commands.StartUpdateSessionResponse.fromObject(object.startUpdateSession);
            }
            if (object.getProgress != null) {
                if (typeof object.getProgress !== "object")
                    throw TypeError(".tool_commands.response.getProgress: object expected");
                message.getProgress = $root.progress_pb.ProgressResponses.fromObject(object.getProgress);
            }
            if (object.getModels != null) {
                if (typeof object.getModels !== "object")
                    throw TypeError(".tool_commands.response.getModels: object expected");
                message.getModels = $root.tool_commands.Models.fromObject(object.getModels);
            }
            if (object.getECUList != null) {
                if (typeof object.getECUList !== "object")
                    throw TypeError(".tool_commands.response.getECUList: object expected");
                message.getECUList = $root.tool_commands.ECUsInVehicle.fromObject(object.getECUList);
            }
            if (object.getSelectedECUs != null) {
                if (typeof object.getSelectedECUs !== "object")
                    throw TypeError(".tool_commands.response.getSelectedECUs: object expected");
                message.getSelectedECUs = $root.tool_commands.ECUsInVehicle.fromObject(object.getSelectedECUs);
            }
            if (object.setSelectedECUs != null) {
                if (typeof object.setSelectedECUs !== "object")
                    throw TypeError(".tool_commands.response.setSelectedECUs: object expected");
                message.setSelectedECUs = $root.tool_commands.MyEmpty.fromObject(object.setSelectedECUs);
            }
            if (object.startDownload != null) {
                if (typeof object.startDownload !== "object")
                    throw TypeError(".tool_commands.response.startDownload: object expected");
                message.startDownload = $root.tool_commands.MyEmpty.fromObject(object.startDownload);
            }
            if (object.getSettings != null) {
                if (typeof object.getSettings !== "object")
                    throw TypeError(".tool_commands.response.getSettings: object expected");
                message.getSettings = $root.tool_commands.Settings.fromObject(object.getSettings);
            }
            if (object.setSettings != null) {
                if (typeof object.setSettings !== "object")
                    throw TypeError(".tool_commands.response.setSettings: object expected");
                message.setSettings = $root.tool_commands.MyEmpty.fromObject(object.setSettings);
            }
            if (object.versionComparisonReq != null) {
                if (typeof object.versionComparisonReq !== "object")
                    throw TypeError(".tool_commands.response.versionComparisonReq: object expected");
                message.versionComparisonReq = $root.tool_commands.MyEmpty.fromObject(object.versionComparisonReq);
            }
            if (object.getVersionCompResult != null) {
                if (typeof object.getVersionCompResult !== "object")
                    throw TypeError(".tool_commands.response.getVersionCompResult: object expected");
                message.getVersionCompResult = $root.tool_commands.VersionComparisonResponse.fromObject(object.getVersionCompResult);
            }
            if (object.udsSequenceRequest != null) {
                if (typeof object.udsSequenceRequest !== "object")
                    throw TypeError(".tool_commands.response.udsSequenceRequest: object expected");
                message.udsSequenceRequest = $root.tool_commands.MyEmpty.fromObject(object.udsSequenceRequest);
            }
            if (object.udsSequenceResult != null) {
                if (typeof object.udsSequenceResult !== "object")
                    throw TypeError(".tool_commands.response.udsSequenceResult: object expected");
                message.udsSequenceResult = $root.uds_client_pb.UDSSequenceResponse.fromObject(object.udsSequenceResult);
            }
            if (object.securityAccess != null) {
                if (typeof object.securityAccess !== "object")
                    throw TypeError(".tool_commands.response.securityAccess: object expected");
                message.securityAccess = $root.tool_commands.SecurityAccessResponse.fromObject(object.securityAccess);
            }
            if (object.udsRequest != null) {
                if (typeof object.udsRequest !== "object")
                    throw TypeError(".tool_commands.response.udsRequest: object expected");
                message.udsRequest = $root.uds_client_pb.UDSRequest.fromObject(object.udsRequest);
            }
            if (object.adbRequest != null) {
                if (typeof object.adbRequest !== "object")
                    throw TypeError(".tool_commands.response.adbRequest: object expected");
                message.adbRequest = $root.tool_commands.ADBResponse.fromObject(object.adbRequest);
            }
            if (object.getDiagnosticTree != null) {
                if (typeof object.getDiagnosticTree !== "object")
                    throw TypeError(".tool_commands.response.getDiagnosticTree: object expected");
                message.getDiagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.fromObject(object.getDiagnosticTree);
            }
            if (object.loopTesterPresent != null) {
                if (typeof object.loopTesterPresent !== "object")
                    throw TypeError(".tool_commands.response.loopTesterPresent: object expected");
                message.loopTesterPresent = $root.tool_commands.MyEmpty.fromObject(object.loopTesterPresent);
            }
            if (object.calibrateCfrmEol != null) {
                if (typeof object.calibrateCfrmEol !== "object")
                    throw TypeError(".tool_commands.response.calibrateCfrmEol: object expected");
                message.calibrateCfrmEol = $root.tool_commands.MyEmpty.fromObject(object.calibrateCfrmEol);
            }
            if (object.getCalCfrmEolResult != null) {
                if (typeof object.getCalCfrmEolResult !== "object")
                    throw TypeError(".tool_commands.response.getCalCfrmEolResult: object expected");
                message.getCalCfrmEolResult = $root.tool_commands.MyEmpty.fromObject(object.getCalCfrmEolResult);
            }
            if (object.calibrateSvcEol != null) {
                if (typeof object.calibrateSvcEol !== "object")
                    throw TypeError(".tool_commands.response.calibrateSvcEol: object expected");
                message.calibrateSvcEol = $root.tool_commands.MyEmpty.fromObject(object.calibrateSvcEol);
            }
            if (object.getCalSvcEolResult != null) {
                if (typeof object.getCalSvcEolResult !== "object")
                    throw TypeError(".tool_commands.response.getCalSvcEolResult: object expected");
                message.getCalSvcEolResult = $root.tool_commands.MyEmpty.fromObject(object.getCalSvcEolResult);
            }
            if (object.startAutomation != null) {
                if (typeof object.startAutomation !== "object")
                    throw TypeError(".tool_commands.response.startAutomation: object expected");
                message.startAutomation = $root.tool_commands.MyEmpty.fromObject(object.startAutomation);
            }
            if (object.getAutomationResult != null) {
                if (typeof object.getAutomationResult !== "object")
                    throw TypeError(".tool_commands.response.getAutomationResult: object expected");
                message.getAutomationResult = $root.tool_commands.AutomationRR.fromObject(object.getAutomationResult);
            }
            if (object.invalidRequest != null)
                message.invalidRequest = Boolean(object.invalidRequest);
            return message;
        };

        /**
         * Creates a plain object from a response message. Also converts values to other types if specified.
         * @function toObject
         * @memberof tool_commands.response
         * @static
         * @param {tool_commands.response} message response
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        response.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "OK" : 0;
                object.resultDescription = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.tool_commands.Result[message.result] === undefined ? message.result : $root.tool_commands.Result[message.result] : message.result;
            if (message.resultDescription != null && message.hasOwnProperty("resultDescription"))
                object.resultDescription = message.resultDescription;
            if (message.wait != null && message.hasOwnProperty("wait")) {
                object.wait = $root.tool_commands.MyEmpty.toObject(message.wait, options);
                if (options.oneofs)
                    object.currentResponse = "wait";
            }
            if (message.getVersion != null && message.hasOwnProperty("getVersion")) {
                object.getVersion = $root.tool_commands.VersionInfo.toObject(message.getVersion, options);
                if (options.oneofs)
                    object.currentResponse = "getVersion";
            }
            if (message.startUpdateSession != null && message.hasOwnProperty("startUpdateSession")) {
                object.startUpdateSession = $root.tool_commands.StartUpdateSessionResponse.toObject(message.startUpdateSession, options);
                if (options.oneofs)
                    object.currentResponse = "startUpdateSession";
            }
            if (message.getProgress != null && message.hasOwnProperty("getProgress")) {
                object.getProgress = $root.progress_pb.ProgressResponses.toObject(message.getProgress, options);
                if (options.oneofs)
                    object.currentResponse = "getProgress";
            }
            if (message.getModels != null && message.hasOwnProperty("getModels")) {
                object.getModels = $root.tool_commands.Models.toObject(message.getModels, options);
                if (options.oneofs)
                    object.currentResponse = "getModels";
            }
            if (message.getECUList != null && message.hasOwnProperty("getECUList")) {
                object.getECUList = $root.tool_commands.ECUsInVehicle.toObject(message.getECUList, options);
                if (options.oneofs)
                    object.currentResponse = "getECUList";
            }
            if (message.getSelectedECUs != null && message.hasOwnProperty("getSelectedECUs")) {
                object.getSelectedECUs = $root.tool_commands.ECUsInVehicle.toObject(message.getSelectedECUs, options);
                if (options.oneofs)
                    object.currentResponse = "getSelectedECUs";
            }
            if (message.setSelectedECUs != null && message.hasOwnProperty("setSelectedECUs")) {
                object.setSelectedECUs = $root.tool_commands.MyEmpty.toObject(message.setSelectedECUs, options);
                if (options.oneofs)
                    object.currentResponse = "setSelectedECUs";
            }
            if (message.startDownload != null && message.hasOwnProperty("startDownload")) {
                object.startDownload = $root.tool_commands.MyEmpty.toObject(message.startDownload, options);
                if (options.oneofs)
                    object.currentResponse = "startDownload";
            }
            if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                object.getSettings = $root.tool_commands.Settings.toObject(message.getSettings, options);
                if (options.oneofs)
                    object.currentResponse = "getSettings";
            }
            if (message.setSettings != null && message.hasOwnProperty("setSettings")) {
                object.setSettings = $root.tool_commands.MyEmpty.toObject(message.setSettings, options);
                if (options.oneofs)
                    object.currentResponse = "setSettings";
            }
            if (message.versionComparisonReq != null && message.hasOwnProperty("versionComparisonReq")) {
                object.versionComparisonReq = $root.tool_commands.MyEmpty.toObject(message.versionComparisonReq, options);
                if (options.oneofs)
                    object.currentResponse = "versionComparisonReq";
            }
            if (message.getVersionCompResult != null && message.hasOwnProperty("getVersionCompResult")) {
                object.getVersionCompResult = $root.tool_commands.VersionComparisonResponse.toObject(message.getVersionCompResult, options);
                if (options.oneofs)
                    object.currentResponse = "getVersionCompResult";
            }
            if (message.udsSequenceRequest != null && message.hasOwnProperty("udsSequenceRequest")) {
                object.udsSequenceRequest = $root.tool_commands.MyEmpty.toObject(message.udsSequenceRequest, options);
                if (options.oneofs)
                    object.currentResponse = "udsSequenceRequest";
            }
            if (message.udsSequenceResult != null && message.hasOwnProperty("udsSequenceResult")) {
                object.udsSequenceResult = $root.uds_client_pb.UDSSequenceResponse.toObject(message.udsSequenceResult, options);
                if (options.oneofs)
                    object.currentResponse = "udsSequenceResult";
            }
            if (message.securityAccess != null && message.hasOwnProperty("securityAccess")) {
                object.securityAccess = $root.tool_commands.SecurityAccessResponse.toObject(message.securityAccess, options);
                if (options.oneofs)
                    object.currentResponse = "securityAccess";
            }
            if (message.udsRequest != null && message.hasOwnProperty("udsRequest")) {
                object.udsRequest = $root.uds_client_pb.UDSRequest.toObject(message.udsRequest, options);
                if (options.oneofs)
                    object.currentResponse = "udsRequest";
            }
            if (message.adbRequest != null && message.hasOwnProperty("adbRequest")) {
                object.adbRequest = $root.tool_commands.ADBResponse.toObject(message.adbRequest, options);
                if (options.oneofs)
                    object.currentResponse = "adbRequest";
            }
            if (message.getDiagnosticTree != null && message.hasOwnProperty("getDiagnosticTree")) {
                object.getDiagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.toObject(message.getDiagnosticTree, options);
                if (options.oneofs)
                    object.currentResponse = "getDiagnosticTree";
            }
            if (message.loopTesterPresent != null && message.hasOwnProperty("loopTesterPresent")) {
                object.loopTesterPresent = $root.tool_commands.MyEmpty.toObject(message.loopTesterPresent, options);
                if (options.oneofs)
                    object.currentResponse = "loopTesterPresent";
            }
            if (message.calibrateCfrmEol != null && message.hasOwnProperty("calibrateCfrmEol")) {
                object.calibrateCfrmEol = $root.tool_commands.MyEmpty.toObject(message.calibrateCfrmEol, options);
                if (options.oneofs)
                    object.currentResponse = "calibrateCfrmEol";
            }
            if (message.getCalCfrmEolResult != null && message.hasOwnProperty("getCalCfrmEolResult")) {
                object.getCalCfrmEolResult = $root.tool_commands.MyEmpty.toObject(message.getCalCfrmEolResult, options);
                if (options.oneofs)
                    object.currentResponse = "getCalCfrmEolResult";
            }
            if (message.calibrateSvcEol != null && message.hasOwnProperty("calibrateSvcEol")) {
                object.calibrateSvcEol = $root.tool_commands.MyEmpty.toObject(message.calibrateSvcEol, options);
                if (options.oneofs)
                    object.currentResponse = "calibrateSvcEol";
            }
            if (message.getCalSvcEolResult != null && message.hasOwnProperty("getCalSvcEolResult")) {
                object.getCalSvcEolResult = $root.tool_commands.MyEmpty.toObject(message.getCalSvcEolResult, options);
                if (options.oneofs)
                    object.currentResponse = "getCalSvcEolResult";
            }
            if (message.startAutomation != null && message.hasOwnProperty("startAutomation")) {
                object.startAutomation = $root.tool_commands.MyEmpty.toObject(message.startAutomation, options);
                if (options.oneofs)
                    object.currentResponse = "startAutomation";
            }
            if (message.getAutomationResult != null && message.hasOwnProperty("getAutomationResult")) {
                object.getAutomationResult = $root.tool_commands.AutomationRR.toObject(message.getAutomationResult, options);
                if (options.oneofs)
                    object.currentResponse = "getAutomationResult";
            }
            if (message.invalidRequest != null && message.hasOwnProperty("invalidRequest")) {
                object.invalidRequest = message.invalidRequest;
                if (options.oneofs)
                    object.currentResponse = "invalidRequest";
            }
            return object;
        };

        /**
         * Converts this response to JSON.
         * @function toJSON
         * @memberof tool_commands.response
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        response.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for response
         * @function getTypeUrl
         * @memberof tool_commands.response
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/tool_commands.response";
        };

        return response;
    })();

    return tool_commands;
})();

$root.progress_pb = (function() {

    /**
     * Namespace progress_pb.
     * @exports progress_pb
     * @namespace
     */
    var progress_pb = {};

    /**
     * ProgressStatus enum.
     * @name progress_pb.ProgressStatus
     * @enum {number}
     * @property {number} INFO=0 INFO value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} WARNING=2 WARNING value
     * @property {number} FAILURE=3 FAILURE value
     * @property {number} ACTION=4 ACTION value
     * @property {number} ABANDON=5 ABANDON value
     */
    progress_pb.ProgressStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INFO"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "WARNING"] = 2;
        values[valuesById[3] = "FAILURE"] = 3;
        values[valuesById[4] = "ACTION"] = 4;
        values[valuesById[5] = "ABANDON"] = 5;
        return values;
    })();

    progress_pb.Progress = (function() {

        /**
         * Properties of a Progress.
         * @memberof progress_pb
         * @interface IProgress
         * @property {number|Long} token Progress token
         */

        /**
         * Constructs a new Progress.
         * @memberof progress_pb
         * @classdesc Represents a Progress.
         * @implements IProgress
         * @constructor
         * @param {progress_pb.IProgress=} [properties] Properties to set
         */
        function Progress(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Progress token.
         * @member {number|Long} token
         * @memberof progress_pb.Progress
         * @instance
         */
        Progress.prototype.token = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Progress instance using the specified properties.
         * @function create
         * @memberof progress_pb.Progress
         * @static
         * @param {progress_pb.IProgress=} [properties] Properties to set
         * @returns {progress_pb.Progress} Progress instance
         */
        Progress.create = function create(properties) {
            return new Progress(properties);
        };

        /**
         * Encodes the specified Progress message. Does not implicitly {@link progress_pb.Progress.verify|verify} messages.
         * @function encode
         * @memberof progress_pb.Progress
         * @static
         * @param {progress_pb.IProgress} message Progress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Progress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.token);
            return writer;
        };

        /**
         * Encodes the specified Progress message, length delimited. Does not implicitly {@link progress_pb.Progress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof progress_pb.Progress
         * @static
         * @param {progress_pb.IProgress} message Progress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Progress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Progress message from the specified reader or buffer.
         * @function decode
         * @memberof progress_pb.Progress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {progress_pb.Progress} Progress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Progress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.progress_pb.Progress();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("token"))
                throw $util.ProtocolError("missing required 'token'", { instance: message });
            return message;
        };

        /**
         * Decodes a Progress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof progress_pb.Progress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {progress_pb.Progress} Progress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Progress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Progress message.
         * @function verify
         * @memberof progress_pb.Progress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Progress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.token) && !(message.token && $util.isInteger(message.token.low) && $util.isInteger(message.token.high)))
                return "token: integer|Long expected";
            return null;
        };

        /**
         * Creates a Progress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof progress_pb.Progress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {progress_pb.Progress} Progress
         */
        Progress.fromObject = function fromObject(object) {
            if (object instanceof $root.progress_pb.Progress)
                return object;
            var message = new $root.progress_pb.Progress();
            if (object.token != null)
                if ($util.Long)
                    (message.token = $util.Long.fromValue(object.token)).unsigned = true;
                else if (typeof object.token === "string")
                    message.token = parseInt(object.token, 10);
                else if (typeof object.token === "number")
                    message.token = object.token;
                else if (typeof object.token === "object")
                    message.token = new $util.LongBits(object.token.low >>> 0, object.token.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a Progress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof progress_pb.Progress
         * @static
         * @param {progress_pb.Progress} message Progress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Progress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.token = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.token = options.longs === String ? "0" : 0;
            if (message.token != null && message.hasOwnProperty("token"))
                if (typeof message.token === "number")
                    object.token = options.longs === String ? String(message.token) : message.token;
                else
                    object.token = options.longs === String ? $util.Long.prototype.toString.call(message.token) : options.longs === Number ? new $util.LongBits(message.token.low >>> 0, message.token.high >>> 0).toNumber(true) : message.token;
            return object;
        };

        /**
         * Converts this Progress to JSON.
         * @function toJSON
         * @memberof progress_pb.Progress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Progress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Progress
         * @function getTypeUrl
         * @memberof progress_pb.Progress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Progress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/progress_pb.Progress";
        };

        return Progress;
    })();

    progress_pb.ProgressResponse = (function() {

        /**
         * Properties of a ProgressResponse.
         * @memberof progress_pb
         * @interface IProgressResponse
         * @property {progress_pb.ProgressStatus|null} [status] ProgressResponse status
         * @property {string|null} [currentECU] ProgressResponse currentECU
         * @property {number|null} [curentECUPercentage] ProgressResponse curentECUPercentage
         * @property {number|null} [overallPercentage] ProgressResponse overallPercentage
         * @property {string|null} [msg] ProgressResponse msg
         * @property {boolean|null} [done] ProgressResponse done
         */

        /**
         * Constructs a new ProgressResponse.
         * @memberof progress_pb
         * @classdesc Represents a ProgressResponse.
         * @implements IProgressResponse
         * @constructor
         * @param {progress_pb.IProgressResponse=} [properties] Properties to set
         */
        function ProgressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProgressResponse status.
         * @member {progress_pb.ProgressStatus} status
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.status = 0;

        /**
         * ProgressResponse currentECU.
         * @member {string} currentECU
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.currentECU = "";

        /**
         * ProgressResponse curentECUPercentage.
         * @member {number} curentECUPercentage
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.curentECUPercentage = 0;

        /**
         * ProgressResponse overallPercentage.
         * @member {number} overallPercentage
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.overallPercentage = 0;

        /**
         * ProgressResponse msg.
         * @member {string} msg
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.msg = "";

        /**
         * ProgressResponse done.
         * @member {boolean} done
         * @memberof progress_pb.ProgressResponse
         * @instance
         */
        ProgressResponse.prototype.done = false;

        /**
         * Creates a new ProgressResponse instance using the specified properties.
         * @function create
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {progress_pb.IProgressResponse=} [properties] Properties to set
         * @returns {progress_pb.ProgressResponse} ProgressResponse instance
         */
        ProgressResponse.create = function create(properties) {
            return new ProgressResponse(properties);
        };

        /**
         * Encodes the specified ProgressResponse message. Does not implicitly {@link progress_pb.ProgressResponse.verify|verify} messages.
         * @function encode
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {progress_pb.IProgressResponse} message ProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProgressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.currentECU != null && Object.hasOwnProperty.call(message, "currentECU"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currentECU);
            if (message.curentECUPercentage != null && Object.hasOwnProperty.call(message, "curentECUPercentage"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.curentECUPercentage);
            if (message.overallPercentage != null && Object.hasOwnProperty.call(message, "overallPercentage"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.overallPercentage);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.msg);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.done);
            return writer;
        };

        /**
         * Encodes the specified ProgressResponse message, length delimited. Does not implicitly {@link progress_pb.ProgressResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {progress_pb.IProgressResponse} message ProgressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProgressResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProgressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {progress_pb.ProgressResponse} ProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProgressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.progress_pb.ProgressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.currentECU = reader.string();
                        break;
                    }
                case 3: {
                        message.curentECUPercentage = reader.float();
                        break;
                    }
                case 4: {
                        message.overallPercentage = reader.float();
                        break;
                    }
                case 5: {
                        message.msg = reader.string();
                        break;
                    }
                case 6: {
                        message.done = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProgressResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {progress_pb.ProgressResponse} ProgressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProgressResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProgressResponse message.
         * @function verify
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProgressResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.currentECU != null && message.hasOwnProperty("currentECU"))
                if (!$util.isString(message.currentECU))
                    return "currentECU: string expected";
            if (message.curentECUPercentage != null && message.hasOwnProperty("curentECUPercentage"))
                if (typeof message.curentECUPercentage !== "number")
                    return "curentECUPercentage: number expected";
            if (message.overallPercentage != null && message.hasOwnProperty("overallPercentage"))
                if (typeof message.overallPercentage !== "number")
                    return "overallPercentage: number expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            return null;
        };

        /**
         * Creates a ProgressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {progress_pb.ProgressResponse} ProgressResponse
         */
        ProgressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.progress_pb.ProgressResponse)
                return object;
            var message = new $root.progress_pb.ProgressResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "INFO":
            case 0:
                message.status = 0;
                break;
            case "SUCCESS":
            case 1:
                message.status = 1;
                break;
            case "WARNING":
            case 2:
                message.status = 2;
                break;
            case "FAILURE":
            case 3:
                message.status = 3;
                break;
            case "ACTION":
            case 4:
                message.status = 4;
                break;
            case "ABANDON":
            case 5:
                message.status = 5;
                break;
            }
            if (object.currentECU != null)
                message.currentECU = String(object.currentECU);
            if (object.curentECUPercentage != null)
                message.curentECUPercentage = Number(object.curentECUPercentage);
            if (object.overallPercentage != null)
                message.overallPercentage = Number(object.overallPercentage);
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.done != null)
                message.done = Boolean(object.done);
            return message;
        };

        /**
         * Creates a plain object from a ProgressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {progress_pb.ProgressResponse} message ProgressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProgressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "INFO" : 0;
                object.currentECU = "";
                object.curentECUPercentage = 0;
                object.overallPercentage = 0;
                object.msg = "";
                object.done = false;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.progress_pb.ProgressStatus[message.status] === undefined ? message.status : $root.progress_pb.ProgressStatus[message.status] : message.status;
            if (message.currentECU != null && message.hasOwnProperty("currentECU"))
                object.currentECU = message.currentECU;
            if (message.curentECUPercentage != null && message.hasOwnProperty("curentECUPercentage"))
                object.curentECUPercentage = options.json && !isFinite(message.curentECUPercentage) ? String(message.curentECUPercentage) : message.curentECUPercentage;
            if (message.overallPercentage != null && message.hasOwnProperty("overallPercentage"))
                object.overallPercentage = options.json && !isFinite(message.overallPercentage) ? String(message.overallPercentage) : message.overallPercentage;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            return object;
        };

        /**
         * Converts this ProgressResponse to JSON.
         * @function toJSON
         * @memberof progress_pb.ProgressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProgressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProgressResponse
         * @function getTypeUrl
         * @memberof progress_pb.ProgressResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProgressResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/progress_pb.ProgressResponse";
        };

        return ProgressResponse;
    })();

    progress_pb.ProgressResponses = (function() {

        /**
         * Properties of a ProgressResponses.
         * @memberof progress_pb
         * @interface IProgressResponses
         * @property {Array.<progress_pb.IProgressResponse>|null} [progress] ProgressResponses progress
         */

        /**
         * Constructs a new ProgressResponses.
         * @memberof progress_pb
         * @classdesc Represents a ProgressResponses.
         * @implements IProgressResponses
         * @constructor
         * @param {progress_pb.IProgressResponses=} [properties] Properties to set
         */
        function ProgressResponses(properties) {
            this.progress = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProgressResponses progress.
         * @member {Array.<progress_pb.IProgressResponse>} progress
         * @memberof progress_pb.ProgressResponses
         * @instance
         */
        ProgressResponses.prototype.progress = $util.emptyArray;

        /**
         * Creates a new ProgressResponses instance using the specified properties.
         * @function create
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {progress_pb.IProgressResponses=} [properties] Properties to set
         * @returns {progress_pb.ProgressResponses} ProgressResponses instance
         */
        ProgressResponses.create = function create(properties) {
            return new ProgressResponses(properties);
        };

        /**
         * Encodes the specified ProgressResponses message. Does not implicitly {@link progress_pb.ProgressResponses.verify|verify} messages.
         * @function encode
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {progress_pb.IProgressResponses} message ProgressResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProgressResponses.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.progress != null && message.progress.length)
                for (var i = 0; i < message.progress.length; ++i)
                    $root.progress_pb.ProgressResponse.encode(message.progress[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ProgressResponses message, length delimited. Does not implicitly {@link progress_pb.ProgressResponses.verify|verify} messages.
         * @function encodeDelimited
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {progress_pb.IProgressResponses} message ProgressResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProgressResponses.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProgressResponses message from the specified reader or buffer.
         * @function decode
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {progress_pb.ProgressResponses} ProgressResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProgressResponses.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.progress_pb.ProgressResponses();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.progress && message.progress.length))
                            message.progress = [];
                        message.progress.push($root.progress_pb.ProgressResponse.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProgressResponses message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {progress_pb.ProgressResponses} ProgressResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProgressResponses.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProgressResponses message.
         * @function verify
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProgressResponses.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.progress != null && message.hasOwnProperty("progress")) {
                if (!Array.isArray(message.progress))
                    return "progress: array expected";
                for (var i = 0; i < message.progress.length; ++i) {
                    var error = $root.progress_pb.ProgressResponse.verify(message.progress[i]);
                    if (error)
                        return "progress." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ProgressResponses message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {progress_pb.ProgressResponses} ProgressResponses
         */
        ProgressResponses.fromObject = function fromObject(object) {
            if (object instanceof $root.progress_pb.ProgressResponses)
                return object;
            var message = new $root.progress_pb.ProgressResponses();
            if (object.progress) {
                if (!Array.isArray(object.progress))
                    throw TypeError(".progress_pb.ProgressResponses.progress: array expected");
                message.progress = [];
                for (var i = 0; i < object.progress.length; ++i) {
                    if (typeof object.progress[i] !== "object")
                        throw TypeError(".progress_pb.ProgressResponses.progress: object expected");
                    message.progress[i] = $root.progress_pb.ProgressResponse.fromObject(object.progress[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ProgressResponses message. Also converts values to other types if specified.
         * @function toObject
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {progress_pb.ProgressResponses} message ProgressResponses
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProgressResponses.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.progress = [];
            if (message.progress && message.progress.length) {
                object.progress = [];
                for (var j = 0; j < message.progress.length; ++j)
                    object.progress[j] = $root.progress_pb.ProgressResponse.toObject(message.progress[j], options);
            }
            return object;
        };

        /**
         * Converts this ProgressResponses to JSON.
         * @function toJSON
         * @memberof progress_pb.ProgressResponses
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProgressResponses.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ProgressResponses
         * @function getTypeUrl
         * @memberof progress_pb.ProgressResponses
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProgressResponses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/progress_pb.ProgressResponses";
        };

        return ProgressResponses;
    })();

    return progress_pb;
})();

$root.ecu_diagnostic_definitions = (function() {

    /**
     * Namespace ecu_diagnostic_definitions.
     * @exports ecu_diagnostic_definitions
     * @namespace
     */
    var ecu_diagnostic_definitions = {};

    ecu_diagnostic_definitions.SubFunction = (function() {

        /**
         * Properties of a SubFunction.
         * @memberof ecu_diagnostic_definitions
         * @interface ISubFunction
         * @property {number} id SubFunction id
         * @property {string|null} [description] SubFunction description
         * @property {boolean|null} [supportedInDiag] SubFunction supportedInDiag
         * @property {boolean|null} [supportedInExtDiag] SubFunction supportedInExtDiag
         * @property {boolean|null} [supportedInProgramming] SubFunction supportedInProgramming
         */

        /**
         * Constructs a new SubFunction.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a SubFunction.
         * @implements ISubFunction
         * @constructor
         * @param {ecu_diagnostic_definitions.ISubFunction=} [properties] Properties to set
         */
        function SubFunction(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubFunction id.
         * @member {number} id
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         */
        SubFunction.prototype.id = 0;

        /**
         * SubFunction description.
         * @member {string} description
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         */
        SubFunction.prototype.description = "";

        /**
         * SubFunction supportedInDiag.
         * @member {boolean} supportedInDiag
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         */
        SubFunction.prototype.supportedInDiag = false;

        /**
         * SubFunction supportedInExtDiag.
         * @member {boolean} supportedInExtDiag
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         */
        SubFunction.prototype.supportedInExtDiag = false;

        /**
         * SubFunction supportedInProgramming.
         * @member {boolean} supportedInProgramming
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         */
        SubFunction.prototype.supportedInProgramming = false;

        /**
         * Creates a new SubFunction instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {ecu_diagnostic_definitions.ISubFunction=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.SubFunction} SubFunction instance
         */
        SubFunction.create = function create(properties) {
            return new SubFunction(properties);
        };

        /**
         * Encodes the specified SubFunction message. Does not implicitly {@link ecu_diagnostic_definitions.SubFunction.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {ecu_diagnostic_definitions.ISubFunction} message SubFunction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubFunction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.supportedInDiag != null && Object.hasOwnProperty.call(message, "supportedInDiag"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.supportedInDiag);
            if (message.supportedInExtDiag != null && Object.hasOwnProperty.call(message, "supportedInExtDiag"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.supportedInExtDiag);
            if (message.supportedInProgramming != null && Object.hasOwnProperty.call(message, "supportedInProgramming"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.supportedInProgramming);
            return writer;
        };

        /**
         * Encodes the specified SubFunction message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.SubFunction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {ecu_diagnostic_definitions.ISubFunction} message SubFunction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubFunction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubFunction message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.SubFunction} SubFunction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubFunction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.SubFunction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.supportedInDiag = reader.bool();
                        break;
                    }
                case 4: {
                        message.supportedInExtDiag = reader.bool();
                        break;
                    }
                case 5: {
                        message.supportedInProgramming = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            return message;
        };

        /**
         * Decodes a SubFunction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.SubFunction} SubFunction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubFunction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubFunction message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubFunction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id))
                return "id: integer expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.supportedInDiag != null && message.hasOwnProperty("supportedInDiag"))
                if (typeof message.supportedInDiag !== "boolean")
                    return "supportedInDiag: boolean expected";
            if (message.supportedInExtDiag != null && message.hasOwnProperty("supportedInExtDiag"))
                if (typeof message.supportedInExtDiag !== "boolean")
                    return "supportedInExtDiag: boolean expected";
            if (message.supportedInProgramming != null && message.hasOwnProperty("supportedInProgramming"))
                if (typeof message.supportedInProgramming !== "boolean")
                    return "supportedInProgramming: boolean expected";
            return null;
        };

        /**
         * Creates a SubFunction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.SubFunction} SubFunction
         */
        SubFunction.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.SubFunction)
                return object;
            var message = new $root.ecu_diagnostic_definitions.SubFunction();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.supportedInDiag != null)
                message.supportedInDiag = Boolean(object.supportedInDiag);
            if (object.supportedInExtDiag != null)
                message.supportedInExtDiag = Boolean(object.supportedInExtDiag);
            if (object.supportedInProgramming != null)
                message.supportedInProgramming = Boolean(object.supportedInProgramming);
            return message;
        };

        /**
         * Creates a plain object from a SubFunction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {ecu_diagnostic_definitions.SubFunction} message SubFunction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubFunction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.description = "";
                object.supportedInDiag = false;
                object.supportedInExtDiag = false;
                object.supportedInProgramming = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.supportedInDiag != null && message.hasOwnProperty("supportedInDiag"))
                object.supportedInDiag = message.supportedInDiag;
            if (message.supportedInExtDiag != null && message.hasOwnProperty("supportedInExtDiag"))
                object.supportedInExtDiag = message.supportedInExtDiag;
            if (message.supportedInProgramming != null && message.hasOwnProperty("supportedInProgramming"))
                object.supportedInProgramming = message.supportedInProgramming;
            return object;
        };

        /**
         * Converts this SubFunction to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubFunction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SubFunction
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.SubFunction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SubFunction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.SubFunction";
        };

        return SubFunction;
    })();

    ecu_diagnostic_definitions.UDSService = (function() {

        /**
         * Properties of a UDSService.
         * @memberof ecu_diagnostic_definitions
         * @interface IUDSService
         * @property {number} id UDSService id
         * @property {string} description UDSService description
         * @property {Array.<ecu_diagnostic_definitions.ISubFunction>|null} [subFunction] UDSService subFunction
         */

        /**
         * Constructs a new UDSService.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a UDSService.
         * @implements IUDSService
         * @constructor
         * @param {ecu_diagnostic_definitions.IUDSService=} [properties] Properties to set
         */
        function UDSService(properties) {
            this.subFunction = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSService id.
         * @member {number} id
         * @memberof ecu_diagnostic_definitions.UDSService
         * @instance
         */
        UDSService.prototype.id = 0;

        /**
         * UDSService description.
         * @member {string} description
         * @memberof ecu_diagnostic_definitions.UDSService
         * @instance
         */
        UDSService.prototype.description = "";

        /**
         * UDSService subFunction.
         * @member {Array.<ecu_diagnostic_definitions.ISubFunction>} subFunction
         * @memberof ecu_diagnostic_definitions.UDSService
         * @instance
         */
        UDSService.prototype.subFunction = $util.emptyArray;

        /**
         * Creates a new UDSService instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {ecu_diagnostic_definitions.IUDSService=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.UDSService} UDSService instance
         */
        UDSService.create = function create(properties) {
            return new UDSService(properties);
        };

        /**
         * Encodes the specified UDSService message. Does not implicitly {@link ecu_diagnostic_definitions.UDSService.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {ecu_diagnostic_definitions.IUDSService} message UDSService message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSService.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.subFunction != null && message.subFunction.length)
                for (var i = 0; i < message.subFunction.length; ++i)
                    $root.ecu_diagnostic_definitions.SubFunction.encode(message.subFunction[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDSService message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.UDSService.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {ecu_diagnostic_definitions.IUDSService} message UDSService message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSService.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSService message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.UDSService} UDSService
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSService.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.UDSService();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        if (!(message.subFunction && message.subFunction.length))
                            message.subFunction = [];
                        message.subFunction.push($root.ecu_diagnostic_definitions.SubFunction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("description"))
                throw $util.ProtocolError("missing required 'description'", { instance: message });
            return message;
        };

        /**
         * Decodes a UDSService message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.UDSService} UDSService
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSService.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSService message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSService.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id))
                return "id: integer expected";
            if (!$util.isString(message.description))
                return "description: string expected";
            if (message.subFunction != null && message.hasOwnProperty("subFunction")) {
                if (!Array.isArray(message.subFunction))
                    return "subFunction: array expected";
                for (var i = 0; i < message.subFunction.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.SubFunction.verify(message.subFunction[i]);
                    if (error)
                        return "subFunction." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UDSService message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.UDSService} UDSService
         */
        UDSService.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.UDSService)
                return object;
            var message = new $root.ecu_diagnostic_definitions.UDSService();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.subFunction) {
                if (!Array.isArray(object.subFunction))
                    throw TypeError(".ecu_diagnostic_definitions.UDSService.subFunction: array expected");
                message.subFunction = [];
                for (var i = 0; i < object.subFunction.length; ++i) {
                    if (typeof object.subFunction[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.UDSService.subFunction: object expected");
                    message.subFunction[i] = $root.ecu_diagnostic_definitions.SubFunction.fromObject(object.subFunction[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UDSService message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {ecu_diagnostic_definitions.UDSService} message UDSService
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSService.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.subFunction = [];
            if (options.defaults) {
                object.id = 0;
                object.description = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.subFunction && message.subFunction.length) {
                object.subFunction = [];
                for (var j = 0; j < message.subFunction.length; ++j)
                    object.subFunction[j] = $root.ecu_diagnostic_definitions.SubFunction.toObject(message.subFunction[j], options);
            }
            return object;
        };

        /**
         * Converts this UDSService to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.UDSService
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSService.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSService
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.UDSService
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSService.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.UDSService";
        };

        return UDSService;
    })();

    ecu_diagnostic_definitions.DID = (function() {

        /**
         * Properties of a DID.
         * @memberof ecu_diagnostic_definitions
         * @interface IDID
         * @property {number} id DID id
         * @property {string} description DID description
         * @property {number|null} [size] DID size
         * @property {string|null} [format] DID format
         * @property {boolean|null} [accessInDiag] DID accessInDiag
         * @property {boolean|null} [accessInExtDiag] DID accessInExtDiag
         * @property {boolean|null} [accessInProgramming] DID accessInProgramming
         */

        /**
         * Constructs a new DID.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a DID.
         * @implements IDID
         * @constructor
         * @param {ecu_diagnostic_definitions.IDID=} [properties] Properties to set
         */
        function DID(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DID id.
         * @member {number} id
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.id = 0;

        /**
         * DID description.
         * @member {string} description
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.description = "";

        /**
         * DID size.
         * @member {number} size
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.size = 0;

        /**
         * DID format.
         * @member {string} format
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.format = "";

        /**
         * DID accessInDiag.
         * @member {boolean} accessInDiag
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.accessInDiag = false;

        /**
         * DID accessInExtDiag.
         * @member {boolean} accessInExtDiag
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.accessInExtDiag = false;

        /**
         * DID accessInProgramming.
         * @member {boolean} accessInProgramming
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         */
        DID.prototype.accessInProgramming = false;

        /**
         * Creates a new DID instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {ecu_diagnostic_definitions.IDID=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.DID} DID instance
         */
        DID.create = function create(properties) {
            return new DID(properties);
        };

        /**
         * Encodes the specified DID message. Does not implicitly {@link ecu_diagnostic_definitions.DID.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {ecu_diagnostic_definitions.IDID} message DID message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DID.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.size);
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.format);
            if (message.accessInDiag != null && Object.hasOwnProperty.call(message, "accessInDiag"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.accessInDiag);
            if (message.accessInExtDiag != null && Object.hasOwnProperty.call(message, "accessInExtDiag"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.accessInExtDiag);
            if (message.accessInProgramming != null && Object.hasOwnProperty.call(message, "accessInProgramming"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.accessInProgramming);
            return writer;
        };

        /**
         * Encodes the specified DID message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.DID.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {ecu_diagnostic_definitions.IDID} message DID message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DID.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DID message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.DID} DID
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DID.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.DID();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.size = reader.uint32();
                        break;
                    }
                case 4: {
                        message.format = reader.string();
                        break;
                    }
                case 5: {
                        message.accessInDiag = reader.bool();
                        break;
                    }
                case 6: {
                        message.accessInExtDiag = reader.bool();
                        break;
                    }
                case 7: {
                        message.accessInProgramming = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("description"))
                throw $util.ProtocolError("missing required 'description'", { instance: message });
            return message;
        };

        /**
         * Decodes a DID message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.DID} DID
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DID.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DID message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DID.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id))
                return "id: integer expected";
            if (!$util.isString(message.description))
                return "description: string expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size))
                    return "size: integer expected";
            if (message.format != null && message.hasOwnProperty("format"))
                if (!$util.isString(message.format))
                    return "format: string expected";
            if (message.accessInDiag != null && message.hasOwnProperty("accessInDiag"))
                if (typeof message.accessInDiag !== "boolean")
                    return "accessInDiag: boolean expected";
            if (message.accessInExtDiag != null && message.hasOwnProperty("accessInExtDiag"))
                if (typeof message.accessInExtDiag !== "boolean")
                    return "accessInExtDiag: boolean expected";
            if (message.accessInProgramming != null && message.hasOwnProperty("accessInProgramming"))
                if (typeof message.accessInProgramming !== "boolean")
                    return "accessInProgramming: boolean expected";
            return null;
        };

        /**
         * Creates a DID message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.DID} DID
         */
        DID.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.DID)
                return object;
            var message = new $root.ecu_diagnostic_definitions.DID();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.size != null)
                message.size = object.size >>> 0;
            if (object.format != null)
                message.format = String(object.format);
            if (object.accessInDiag != null)
                message.accessInDiag = Boolean(object.accessInDiag);
            if (object.accessInExtDiag != null)
                message.accessInExtDiag = Boolean(object.accessInExtDiag);
            if (object.accessInProgramming != null)
                message.accessInProgramming = Boolean(object.accessInProgramming);
            return message;
        };

        /**
         * Creates a plain object from a DID message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {ecu_diagnostic_definitions.DID} message DID
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DID.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.description = "";
                object.size = 0;
                object.format = "";
                object.accessInDiag = false;
                object.accessInExtDiag = false;
                object.accessInProgramming = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = message.size;
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = message.format;
            if (message.accessInDiag != null && message.hasOwnProperty("accessInDiag"))
                object.accessInDiag = message.accessInDiag;
            if (message.accessInExtDiag != null && message.hasOwnProperty("accessInExtDiag"))
                object.accessInExtDiag = message.accessInExtDiag;
            if (message.accessInProgramming != null && message.hasOwnProperty("accessInProgramming"))
                object.accessInProgramming = message.accessInProgramming;
            return object;
        };

        /**
         * Converts this DID to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.DID
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DID.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DID
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.DID
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.DID";
        };

        return DID;
    })();

    ecu_diagnostic_definitions.DTC = (function() {

        /**
         * Properties of a DTC.
         * @memberof ecu_diagnostic_definitions
         * @interface IDTC
         * @property {number} id DTC id
         * @property {string} description DTC description
         * @property {string|null} [saeCode] DTC saeCode
         * @property {string|null} [display] DTC display
         */

        /**
         * Constructs a new DTC.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a DTC.
         * @implements IDTC
         * @constructor
         * @param {ecu_diagnostic_definitions.IDTC=} [properties] Properties to set
         */
        function DTC(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DTC id.
         * @member {number} id
         * @memberof ecu_diagnostic_definitions.DTC
         * @instance
         */
        DTC.prototype.id = 0;

        /**
         * DTC description.
         * @member {string} description
         * @memberof ecu_diagnostic_definitions.DTC
         * @instance
         */
        DTC.prototype.description = "";

        /**
         * DTC saeCode.
         * @member {string} saeCode
         * @memberof ecu_diagnostic_definitions.DTC
         * @instance
         */
        DTC.prototype.saeCode = "";

        /**
         * DTC display.
         * @member {string} display
         * @memberof ecu_diagnostic_definitions.DTC
         * @instance
         */
        DTC.prototype.display = "";

        /**
         * Creates a new DTC instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {ecu_diagnostic_definitions.IDTC=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.DTC} DTC instance
         */
        DTC.create = function create(properties) {
            return new DTC(properties);
        };

        /**
         * Encodes the specified DTC message. Does not implicitly {@link ecu_diagnostic_definitions.DTC.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {ecu_diagnostic_definitions.IDTC} message DTC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTC.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.saeCode != null && Object.hasOwnProperty.call(message, "saeCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.saeCode);
            if (message.display != null && Object.hasOwnProperty.call(message, "display"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.display);
            return writer;
        };

        /**
         * Encodes the specified DTC message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.DTC.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {ecu_diagnostic_definitions.IDTC} message DTC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTC.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DTC message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.DTC} DTC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTC.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.DTC();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.saeCode = reader.string();
                        break;
                    }
                case 4: {
                        message.display = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("description"))
                throw $util.ProtocolError("missing required 'description'", { instance: message });
            return message;
        };

        /**
         * Decodes a DTC message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.DTC} DTC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTC.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DTC message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DTC.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id))
                return "id: integer expected";
            if (!$util.isString(message.description))
                return "description: string expected";
            if (message.saeCode != null && message.hasOwnProperty("saeCode"))
                if (!$util.isString(message.saeCode))
                    return "saeCode: string expected";
            if (message.display != null && message.hasOwnProperty("display"))
                if (!$util.isString(message.display))
                    return "display: string expected";
            return null;
        };

        /**
         * Creates a DTC message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.DTC} DTC
         */
        DTC.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.DTC)
                return object;
            var message = new $root.ecu_diagnostic_definitions.DTC();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.saeCode != null)
                message.saeCode = String(object.saeCode);
            if (object.display != null)
                message.display = String(object.display);
            return message;
        };

        /**
         * Creates a plain object from a DTC message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {ecu_diagnostic_definitions.DTC} message DTC
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DTC.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.description = "";
                object.saeCode = "";
                object.display = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.saeCode != null && message.hasOwnProperty("saeCode"))
                object.saeCode = message.saeCode;
            if (message.display != null && message.hasOwnProperty("display"))
                object.display = message.display;
            return object;
        };

        /**
         * Converts this DTC to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.DTC
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DTC.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DTC
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.DTC
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DTC.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.DTC";
        };

        return DTC;
    })();

    ecu_diagnostic_definitions.RoutineControlType = (function() {

        /**
         * Properties of a RoutineControlType.
         * @memberof ecu_diagnostic_definitions
         * @interface IRoutineControlType
         * @property {string|null} [paramDescription] RoutineControlType paramDescription
         * @property {string|null} [statusDescription] RoutineControlType statusDescription
         */

        /**
         * Constructs a new RoutineControlType.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a RoutineControlType.
         * @implements IRoutineControlType
         * @constructor
         * @param {ecu_diagnostic_definitions.IRoutineControlType=} [properties] Properties to set
         */
        function RoutineControlType(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoutineControlType paramDescription.
         * @member {string} paramDescription
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @instance
         */
        RoutineControlType.prototype.paramDescription = "";

        /**
         * RoutineControlType statusDescription.
         * @member {string} statusDescription
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @instance
         */
        RoutineControlType.prototype.statusDescription = "";

        /**
         * Creates a new RoutineControlType instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {ecu_diagnostic_definitions.IRoutineControlType=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.RoutineControlType} RoutineControlType instance
         */
        RoutineControlType.create = function create(properties) {
            return new RoutineControlType(properties);
        };

        /**
         * Encodes the specified RoutineControlType message. Does not implicitly {@link ecu_diagnostic_definitions.RoutineControlType.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {ecu_diagnostic_definitions.IRoutineControlType} message RoutineControlType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoutineControlType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paramDescription != null && Object.hasOwnProperty.call(message, "paramDescription"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paramDescription);
            if (message.statusDescription != null && Object.hasOwnProperty.call(message, "statusDescription"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.statusDescription);
            return writer;
        };

        /**
         * Encodes the specified RoutineControlType message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.RoutineControlType.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {ecu_diagnostic_definitions.IRoutineControlType} message RoutineControlType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoutineControlType.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoutineControlType message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.RoutineControlType} RoutineControlType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoutineControlType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.RoutineControlType();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paramDescription = reader.string();
                        break;
                    }
                case 2: {
                        message.statusDescription = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoutineControlType message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.RoutineControlType} RoutineControlType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoutineControlType.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoutineControlType message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoutineControlType.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paramDescription != null && message.hasOwnProperty("paramDescription"))
                if (!$util.isString(message.paramDescription))
                    return "paramDescription: string expected";
            if (message.statusDescription != null && message.hasOwnProperty("statusDescription"))
                if (!$util.isString(message.statusDescription))
                    return "statusDescription: string expected";
            return null;
        };

        /**
         * Creates a RoutineControlType message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.RoutineControlType} RoutineControlType
         */
        RoutineControlType.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.RoutineControlType)
                return object;
            var message = new $root.ecu_diagnostic_definitions.RoutineControlType();
            if (object.paramDescription != null)
                message.paramDescription = String(object.paramDescription);
            if (object.statusDescription != null)
                message.statusDescription = String(object.statusDescription);
            return message;
        };

        /**
         * Creates a plain object from a RoutineControlType message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {ecu_diagnostic_definitions.RoutineControlType} message RoutineControlType
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoutineControlType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.paramDescription = "";
                object.statusDescription = "";
            }
            if (message.paramDescription != null && message.hasOwnProperty("paramDescription"))
                object.paramDescription = message.paramDescription;
            if (message.statusDescription != null && message.hasOwnProperty("statusDescription"))
                object.statusDescription = message.statusDescription;
            return object;
        };

        /**
         * Converts this RoutineControlType to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoutineControlType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RoutineControlType
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.RoutineControlType
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RoutineControlType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.RoutineControlType";
        };

        return RoutineControlType;
    })();

    ecu_diagnostic_definitions.Routine = (function() {

        /**
         * Properties of a Routine.
         * @memberof ecu_diagnostic_definitions
         * @interface IRoutine
         * @property {number} id Routine id
         * @property {string} description Routine description
         * @property {boolean|null} [supportedInDiag] Routine supportedInDiag
         * @property {boolean|null} [supportedInExtDiag] Routine supportedInExtDiag
         * @property {boolean|null} [supportedInProgramming] Routine supportedInProgramming
         * @property {ecu_diagnostic_definitions.IRoutineControlType} start Routine start
         * @property {ecu_diagnostic_definitions.IRoutineControlType|null} [stop] Routine stop
         * @property {ecu_diagnostic_definitions.IRoutineControlType|null} [reqResult] Routine reqResult
         */

        /**
         * Constructs a new Routine.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a Routine.
         * @implements IRoutine
         * @constructor
         * @param {ecu_diagnostic_definitions.IRoutine=} [properties] Properties to set
         */
        function Routine(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Routine id.
         * @member {number} id
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.id = 0;

        /**
         * Routine description.
         * @member {string} description
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.description = "";

        /**
         * Routine supportedInDiag.
         * @member {boolean} supportedInDiag
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.supportedInDiag = false;

        /**
         * Routine supportedInExtDiag.
         * @member {boolean} supportedInExtDiag
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.supportedInExtDiag = false;

        /**
         * Routine supportedInProgramming.
         * @member {boolean} supportedInProgramming
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.supportedInProgramming = false;

        /**
         * Routine start.
         * @member {ecu_diagnostic_definitions.IRoutineControlType} start
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.start = null;

        /**
         * Routine stop.
         * @member {ecu_diagnostic_definitions.IRoutineControlType|null|undefined} stop
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.stop = null;

        /**
         * Routine reqResult.
         * @member {ecu_diagnostic_definitions.IRoutineControlType|null|undefined} reqResult
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         */
        Routine.prototype.reqResult = null;

        /**
         * Creates a new Routine instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {ecu_diagnostic_definitions.IRoutine=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.Routine} Routine instance
         */
        Routine.create = function create(properties) {
            return new Routine(properties);
        };

        /**
         * Encodes the specified Routine message. Does not implicitly {@link ecu_diagnostic_definitions.Routine.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {ecu_diagnostic_definitions.IRoutine} message Routine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Routine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.supportedInDiag != null && Object.hasOwnProperty.call(message, "supportedInDiag"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.supportedInDiag);
            if (message.supportedInExtDiag != null && Object.hasOwnProperty.call(message, "supportedInExtDiag"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.supportedInExtDiag);
            $root.ecu_diagnostic_definitions.RoutineControlType.encode(message.start, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.stop != null && Object.hasOwnProperty.call(message, "stop"))
                $root.ecu_diagnostic_definitions.RoutineControlType.encode(message.stop, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.reqResult != null && Object.hasOwnProperty.call(message, "reqResult"))
                $root.ecu_diagnostic_definitions.RoutineControlType.encode(message.reqResult, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.supportedInProgramming != null && Object.hasOwnProperty.call(message, "supportedInProgramming"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.supportedInProgramming);
            return writer;
        };

        /**
         * Encodes the specified Routine message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.Routine.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {ecu_diagnostic_definitions.IRoutine} message Routine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Routine.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Routine message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.Routine} Routine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Routine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.Routine();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.supportedInDiag = reader.bool();
                        break;
                    }
                case 4: {
                        message.supportedInExtDiag = reader.bool();
                        break;
                    }
                case 8: {
                        message.supportedInProgramming = reader.bool();
                        break;
                    }
                case 5: {
                        message.start = $root.ecu_diagnostic_definitions.RoutineControlType.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.stop = $root.ecu_diagnostic_definitions.RoutineControlType.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.reqResult = $root.ecu_diagnostic_definitions.RoutineControlType.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("id"))
                throw $util.ProtocolError("missing required 'id'", { instance: message });
            if (!message.hasOwnProperty("description"))
                throw $util.ProtocolError("missing required 'description'", { instance: message });
            if (!message.hasOwnProperty("start"))
                throw $util.ProtocolError("missing required 'start'", { instance: message });
            return message;
        };

        /**
         * Decodes a Routine message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.Routine} Routine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Routine.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Routine message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Routine.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.id))
                return "id: integer expected";
            if (!$util.isString(message.description))
                return "description: string expected";
            if (message.supportedInDiag != null && message.hasOwnProperty("supportedInDiag"))
                if (typeof message.supportedInDiag !== "boolean")
                    return "supportedInDiag: boolean expected";
            if (message.supportedInExtDiag != null && message.hasOwnProperty("supportedInExtDiag"))
                if (typeof message.supportedInExtDiag !== "boolean")
                    return "supportedInExtDiag: boolean expected";
            if (message.supportedInProgramming != null && message.hasOwnProperty("supportedInProgramming"))
                if (typeof message.supportedInProgramming !== "boolean")
                    return "supportedInProgramming: boolean expected";
            {
                var error = $root.ecu_diagnostic_definitions.RoutineControlType.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.stop != null && message.hasOwnProperty("stop")) {
                var error = $root.ecu_diagnostic_definitions.RoutineControlType.verify(message.stop);
                if (error)
                    return "stop." + error;
            }
            if (message.reqResult != null && message.hasOwnProperty("reqResult")) {
                var error = $root.ecu_diagnostic_definitions.RoutineControlType.verify(message.reqResult);
                if (error)
                    return "reqResult." + error;
            }
            return null;
        };

        /**
         * Creates a Routine message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.Routine} Routine
         */
        Routine.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.Routine)
                return object;
            var message = new $root.ecu_diagnostic_definitions.Routine();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.supportedInDiag != null)
                message.supportedInDiag = Boolean(object.supportedInDiag);
            if (object.supportedInExtDiag != null)
                message.supportedInExtDiag = Boolean(object.supportedInExtDiag);
            if (object.supportedInProgramming != null)
                message.supportedInProgramming = Boolean(object.supportedInProgramming);
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".ecu_diagnostic_definitions.Routine.start: object expected");
                message.start = $root.ecu_diagnostic_definitions.RoutineControlType.fromObject(object.start);
            }
            if (object.stop != null) {
                if (typeof object.stop !== "object")
                    throw TypeError(".ecu_diagnostic_definitions.Routine.stop: object expected");
                message.stop = $root.ecu_diagnostic_definitions.RoutineControlType.fromObject(object.stop);
            }
            if (object.reqResult != null) {
                if (typeof object.reqResult !== "object")
                    throw TypeError(".ecu_diagnostic_definitions.Routine.reqResult: object expected");
                message.reqResult = $root.ecu_diagnostic_definitions.RoutineControlType.fromObject(object.reqResult);
            }
            return message;
        };

        /**
         * Creates a plain object from a Routine message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {ecu_diagnostic_definitions.Routine} message Routine
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Routine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.description = "";
                object.supportedInDiag = false;
                object.supportedInExtDiag = false;
                object.start = null;
                object.stop = null;
                object.reqResult = null;
                object.supportedInProgramming = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.supportedInDiag != null && message.hasOwnProperty("supportedInDiag"))
                object.supportedInDiag = message.supportedInDiag;
            if (message.supportedInExtDiag != null && message.hasOwnProperty("supportedInExtDiag"))
                object.supportedInExtDiag = message.supportedInExtDiag;
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.ecu_diagnostic_definitions.RoutineControlType.toObject(message.start, options);
            if (message.stop != null && message.hasOwnProperty("stop"))
                object.stop = $root.ecu_diagnostic_definitions.RoutineControlType.toObject(message.stop, options);
            if (message.reqResult != null && message.hasOwnProperty("reqResult"))
                object.reqResult = $root.ecu_diagnostic_definitions.RoutineControlType.toObject(message.reqResult, options);
            if (message.supportedInProgramming != null && message.hasOwnProperty("supportedInProgramming"))
                object.supportedInProgramming = message.supportedInProgramming;
            return object;
        };

        /**
         * Converts this Routine to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.Routine
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Routine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Routine
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.Routine
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Routine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.Routine";
        };

        return Routine;
    })();

    ecu_diagnostic_definitions.ComponentIdInfo = (function() {

        /**
         * Properties of a ComponentIdInfo.
         * @memberof ecu_diagnostic_definitions
         * @interface IComponentIdInfo
         * @property {string|null} [componentType] ComponentIdInfo componentType
         * @property {number|null} [componentNumber] ComponentIdInfo componentNumber
         */

        /**
         * Constructs a new ComponentIdInfo.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a ComponentIdInfo.
         * @implements IComponentIdInfo
         * @constructor
         * @param {ecu_diagnostic_definitions.IComponentIdInfo=} [properties] Properties to set
         */
        function ComponentIdInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ComponentIdInfo componentType.
         * @member {string} componentType
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @instance
         */
        ComponentIdInfo.prototype.componentType = "";

        /**
         * ComponentIdInfo componentNumber.
         * @member {number} componentNumber
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @instance
         */
        ComponentIdInfo.prototype.componentNumber = 0;

        /**
         * Creates a new ComponentIdInfo instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {ecu_diagnostic_definitions.IComponentIdInfo=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.ComponentIdInfo} ComponentIdInfo instance
         */
        ComponentIdInfo.create = function create(properties) {
            return new ComponentIdInfo(properties);
        };

        /**
         * Encodes the specified ComponentIdInfo message. Does not implicitly {@link ecu_diagnostic_definitions.ComponentIdInfo.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {ecu_diagnostic_definitions.IComponentIdInfo} message ComponentIdInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComponentIdInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.componentType != null && Object.hasOwnProperty.call(message, "componentType"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.componentType);
            if (message.componentNumber != null && Object.hasOwnProperty.call(message, "componentNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.componentNumber);
            return writer;
        };

        /**
         * Encodes the specified ComponentIdInfo message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.ComponentIdInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {ecu_diagnostic_definitions.IComponentIdInfo} message ComponentIdInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ComponentIdInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ComponentIdInfo message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.ComponentIdInfo} ComponentIdInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComponentIdInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.ComponentIdInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.componentType = reader.string();
                        break;
                    }
                case 2: {
                        message.componentNumber = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ComponentIdInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.ComponentIdInfo} ComponentIdInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ComponentIdInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ComponentIdInfo message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ComponentIdInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.componentType != null && message.hasOwnProperty("componentType"))
                if (!$util.isString(message.componentType))
                    return "componentType: string expected";
            if (message.componentNumber != null && message.hasOwnProperty("componentNumber"))
                if (!$util.isInteger(message.componentNumber))
                    return "componentNumber: integer expected";
            return null;
        };

        /**
         * Creates a ComponentIdInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.ComponentIdInfo} ComponentIdInfo
         */
        ComponentIdInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.ComponentIdInfo)
                return object;
            var message = new $root.ecu_diagnostic_definitions.ComponentIdInfo();
            if (object.componentType != null)
                message.componentType = String(object.componentType);
            if (object.componentNumber != null)
                message.componentNumber = object.componentNumber >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ComponentIdInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {ecu_diagnostic_definitions.ComponentIdInfo} message ComponentIdInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ComponentIdInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.componentType = "";
                object.componentNumber = 0;
            }
            if (message.componentType != null && message.hasOwnProperty("componentType"))
                object.componentType = message.componentType;
            if (message.componentNumber != null && message.hasOwnProperty("componentNumber"))
                object.componentNumber = message.componentNumber;
            return object;
        };

        /**
         * Converts this ComponentIdInfo to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ComponentIdInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ComponentIdInfo
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.ComponentIdInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ComponentIdInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.ComponentIdInfo";
        };

        return ComponentIdInfo;
    })();

    ecu_diagnostic_definitions.CloudSoftwares = (function() {

        /**
         * Properties of a CloudSoftwares.
         * @memberof ecu_diagnostic_definitions
         * @interface ICloudSoftwares
         * @property {Array.<ecu_diagnostic_definitions.IComponentIdInfo>|null} [softwares] CloudSoftwares softwares
         */

        /**
         * Constructs a new CloudSoftwares.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a CloudSoftwares.
         * @implements ICloudSoftwares
         * @constructor
         * @param {ecu_diagnostic_definitions.ICloudSoftwares=} [properties] Properties to set
         */
        function CloudSoftwares(properties) {
            this.softwares = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloudSoftwares softwares.
         * @member {Array.<ecu_diagnostic_definitions.IComponentIdInfo>} softwares
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @instance
         */
        CloudSoftwares.prototype.softwares = $util.emptyArray;

        /**
         * Creates a new CloudSoftwares instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {ecu_diagnostic_definitions.ICloudSoftwares=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.CloudSoftwares} CloudSoftwares instance
         */
        CloudSoftwares.create = function create(properties) {
            return new CloudSoftwares(properties);
        };

        /**
         * Encodes the specified CloudSoftwares message. Does not implicitly {@link ecu_diagnostic_definitions.CloudSoftwares.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {ecu_diagnostic_definitions.ICloudSoftwares} message CloudSoftwares message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudSoftwares.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.softwares != null && message.softwares.length)
                for (var i = 0; i < message.softwares.length; ++i)
                    $root.ecu_diagnostic_definitions.ComponentIdInfo.encode(message.softwares[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CloudSoftwares message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.CloudSoftwares.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {ecu_diagnostic_definitions.ICloudSoftwares} message CloudSoftwares message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudSoftwares.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloudSoftwares message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.CloudSoftwares} CloudSoftwares
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudSoftwares.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.CloudSoftwares();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.softwares && message.softwares.length))
                            message.softwares = [];
                        message.softwares.push($root.ecu_diagnostic_definitions.ComponentIdInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloudSoftwares message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.CloudSoftwares} CloudSoftwares
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudSoftwares.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloudSoftwares message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloudSoftwares.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.softwares != null && message.hasOwnProperty("softwares")) {
                if (!Array.isArray(message.softwares))
                    return "softwares: array expected";
                for (var i = 0; i < message.softwares.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.ComponentIdInfo.verify(message.softwares[i]);
                    if (error)
                        return "softwares." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CloudSoftwares message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.CloudSoftwares} CloudSoftwares
         */
        CloudSoftwares.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.CloudSoftwares)
                return object;
            var message = new $root.ecu_diagnostic_definitions.CloudSoftwares();
            if (object.softwares) {
                if (!Array.isArray(object.softwares))
                    throw TypeError(".ecu_diagnostic_definitions.CloudSoftwares.softwares: array expected");
                message.softwares = [];
                for (var i = 0; i < object.softwares.length; ++i) {
                    if (typeof object.softwares[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.CloudSoftwares.softwares: object expected");
                    message.softwares[i] = $root.ecu_diagnostic_definitions.ComponentIdInfo.fromObject(object.softwares[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CloudSoftwares message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {ecu_diagnostic_definitions.CloudSoftwares} message CloudSoftwares
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloudSoftwares.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.softwares = [];
            if (message.softwares && message.softwares.length) {
                object.softwares = [];
                for (var j = 0; j < message.softwares.length; ++j)
                    object.softwares[j] = $root.ecu_diagnostic_definitions.ComponentIdInfo.toObject(message.softwares[j], options);
            }
            return object;
        };

        /**
         * Converts this CloudSoftwares to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloudSoftwares.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CloudSoftwares
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.CloudSoftwares
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CloudSoftwares.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.CloudSoftwares";
        };

        return CloudSoftwares;
    })();

    ecu_diagnostic_definitions.CloudComponents = (function() {

        /**
         * Properties of a CloudComponents.
         * @memberof ecu_diagnostic_definitions
         * @interface ICloudComponents
         * @property {Array.<ecu_diagnostic_definitions.ICloudSoftwares>|null} [objects] CloudComponents objects
         */

        /**
         * Constructs a new CloudComponents.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a CloudComponents.
         * @implements ICloudComponents
         * @constructor
         * @param {ecu_diagnostic_definitions.ICloudComponents=} [properties] Properties to set
         */
        function CloudComponents(properties) {
            this.objects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloudComponents objects.
         * @member {Array.<ecu_diagnostic_definitions.ICloudSoftwares>} objects
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @instance
         */
        CloudComponents.prototype.objects = $util.emptyArray;

        /**
         * Creates a new CloudComponents instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {ecu_diagnostic_definitions.ICloudComponents=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.CloudComponents} CloudComponents instance
         */
        CloudComponents.create = function create(properties) {
            return new CloudComponents(properties);
        };

        /**
         * Encodes the specified CloudComponents message. Does not implicitly {@link ecu_diagnostic_definitions.CloudComponents.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {ecu_diagnostic_definitions.ICloudComponents} message CloudComponents message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudComponents.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objects != null && message.objects.length)
                for (var i = 0; i < message.objects.length; ++i)
                    $root.ecu_diagnostic_definitions.CloudSoftwares.encode(message.objects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CloudComponents message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.CloudComponents.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {ecu_diagnostic_definitions.ICloudComponents} message CloudComponents message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudComponents.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloudComponents message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.CloudComponents} CloudComponents
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudComponents.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.CloudComponents();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.objects && message.objects.length))
                            message.objects = [];
                        message.objects.push($root.ecu_diagnostic_definitions.CloudSoftwares.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloudComponents message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.CloudComponents} CloudComponents
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudComponents.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloudComponents message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloudComponents.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objects != null && message.hasOwnProperty("objects")) {
                if (!Array.isArray(message.objects))
                    return "objects: array expected";
                for (var i = 0; i < message.objects.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.CloudSoftwares.verify(message.objects[i]);
                    if (error)
                        return "objects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CloudComponents message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.CloudComponents} CloudComponents
         */
        CloudComponents.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.CloudComponents)
                return object;
            var message = new $root.ecu_diagnostic_definitions.CloudComponents();
            if (object.objects) {
                if (!Array.isArray(object.objects))
                    throw TypeError(".ecu_diagnostic_definitions.CloudComponents.objects: array expected");
                message.objects = [];
                for (var i = 0; i < object.objects.length; ++i) {
                    if (typeof object.objects[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.CloudComponents.objects: object expected");
                    message.objects[i] = $root.ecu_diagnostic_definitions.CloudSoftwares.fromObject(object.objects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CloudComponents message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {ecu_diagnostic_definitions.CloudComponents} message CloudComponents
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloudComponents.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.objects = [];
            if (message.objects && message.objects.length) {
                object.objects = [];
                for (var j = 0; j < message.objects.length; ++j)
                    object.objects[j] = $root.ecu_diagnostic_definitions.CloudSoftwares.toObject(message.objects[j], options);
            }
            return object;
        };

        /**
         * Converts this CloudComponents to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloudComponents.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CloudComponents
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.CloudComponents
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CloudComponents.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.CloudComponents";
        };

        return CloudComponents;
    })();

    ecu_diagnostic_definitions.DiagnosticTree = (function() {

        /**
         * Properties of a DiagnosticTree.
         * @memberof ecu_diagnostic_definitions
         * @interface IDiagnosticTree
         * @property {Array.<ecu_diagnostic_definitions.IUDSService>|null} [services] DiagnosticTree services
         * @property {Array.<ecu_diagnostic_definitions.IDID>|null} [readDids] DiagnosticTree readDids
         * @property {Array.<ecu_diagnostic_definitions.IDID>|null} [writeDids] DiagnosticTree writeDids
         * @property {Array.<ecu_diagnostic_definitions.IDTC>|null} [dtcList] DiagnosticTree dtcList
         * @property {Array.<ecu_diagnostic_definitions.IRoutine>|null} [routines] DiagnosticTree routines
         * @property {Array.<ecu_diagnostic_definitions.IComponentIdInfo>|null} [components] DiagnosticTree components
         */

        /**
         * Constructs a new DiagnosticTree.
         * @memberof ecu_diagnostic_definitions
         * @classdesc Represents a DiagnosticTree.
         * @implements IDiagnosticTree
         * @constructor
         * @param {ecu_diagnostic_definitions.IDiagnosticTree=} [properties] Properties to set
         */
        function DiagnosticTree(properties) {
            this.services = [];
            this.readDids = [];
            this.writeDids = [];
            this.dtcList = [];
            this.routines = [];
            this.components = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticTree services.
         * @member {Array.<ecu_diagnostic_definitions.IUDSService>} services
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.services = $util.emptyArray;

        /**
         * DiagnosticTree readDids.
         * @member {Array.<ecu_diagnostic_definitions.IDID>} readDids
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.readDids = $util.emptyArray;

        /**
         * DiagnosticTree writeDids.
         * @member {Array.<ecu_diagnostic_definitions.IDID>} writeDids
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.writeDids = $util.emptyArray;

        /**
         * DiagnosticTree dtcList.
         * @member {Array.<ecu_diagnostic_definitions.IDTC>} dtcList
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.dtcList = $util.emptyArray;

        /**
         * DiagnosticTree routines.
         * @member {Array.<ecu_diagnostic_definitions.IRoutine>} routines
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.routines = $util.emptyArray;

        /**
         * DiagnosticTree components.
         * @member {Array.<ecu_diagnostic_definitions.IComponentIdInfo>} components
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         */
        DiagnosticTree.prototype.components = $util.emptyArray;

        /**
         * Creates a new DiagnosticTree instance using the specified properties.
         * @function create
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {ecu_diagnostic_definitions.IDiagnosticTree=} [properties] Properties to set
         * @returns {ecu_diagnostic_definitions.DiagnosticTree} DiagnosticTree instance
         */
        DiagnosticTree.create = function create(properties) {
            return new DiagnosticTree(properties);
        };

        /**
         * Encodes the specified DiagnosticTree message. Does not implicitly {@link ecu_diagnostic_definitions.DiagnosticTree.verify|verify} messages.
         * @function encode
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {ecu_diagnostic_definitions.IDiagnosticTree} message DiagnosticTree message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticTree.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.services != null && message.services.length)
                for (var i = 0; i < message.services.length; ++i)
                    $root.ecu_diagnostic_definitions.UDSService.encode(message.services[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.readDids != null && message.readDids.length)
                for (var i = 0; i < message.readDids.length; ++i)
                    $root.ecu_diagnostic_definitions.DID.encode(message.readDids[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.writeDids != null && message.writeDids.length)
                for (var i = 0; i < message.writeDids.length; ++i)
                    $root.ecu_diagnostic_definitions.DID.encode(message.writeDids[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.dtcList != null && message.dtcList.length)
                for (var i = 0; i < message.dtcList.length; ++i)
                    $root.ecu_diagnostic_definitions.DTC.encode(message.dtcList[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.routines != null && message.routines.length)
                for (var i = 0; i < message.routines.length; ++i)
                    $root.ecu_diagnostic_definitions.Routine.encode(message.routines[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.components != null && message.components.length)
                for (var i = 0; i < message.components.length; ++i)
                    $root.ecu_diagnostic_definitions.ComponentIdInfo.encode(message.components[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DiagnosticTree message, length delimited. Does not implicitly {@link ecu_diagnostic_definitions.DiagnosticTree.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {ecu_diagnostic_definitions.IDiagnosticTree} message DiagnosticTree message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticTree.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticTree message from the specified reader or buffer.
         * @function decode
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ecu_diagnostic_definitions.DiagnosticTree} DiagnosticTree
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticTree.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ecu_diagnostic_definitions.DiagnosticTree();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.services && message.services.length))
                            message.services = [];
                        message.services.push($root.ecu_diagnostic_definitions.UDSService.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.readDids && message.readDids.length))
                            message.readDids = [];
                        message.readDids.push($root.ecu_diagnostic_definitions.DID.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.writeDids && message.writeDids.length))
                            message.writeDids = [];
                        message.writeDids.push($root.ecu_diagnostic_definitions.DID.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.dtcList && message.dtcList.length))
                            message.dtcList = [];
                        message.dtcList.push($root.ecu_diagnostic_definitions.DTC.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.routines && message.routines.length))
                            message.routines = [];
                        message.routines.push($root.ecu_diagnostic_definitions.Routine.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (!(message.components && message.components.length))
                            message.components = [];
                        message.components.push($root.ecu_diagnostic_definitions.ComponentIdInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticTree message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ecu_diagnostic_definitions.DiagnosticTree} DiagnosticTree
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticTree.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticTree message.
         * @function verify
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticTree.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.services != null && message.hasOwnProperty("services")) {
                if (!Array.isArray(message.services))
                    return "services: array expected";
                for (var i = 0; i < message.services.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.UDSService.verify(message.services[i]);
                    if (error)
                        return "services." + error;
                }
            }
            if (message.readDids != null && message.hasOwnProperty("readDids")) {
                if (!Array.isArray(message.readDids))
                    return "readDids: array expected";
                for (var i = 0; i < message.readDids.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.DID.verify(message.readDids[i]);
                    if (error)
                        return "readDids." + error;
                }
            }
            if (message.writeDids != null && message.hasOwnProperty("writeDids")) {
                if (!Array.isArray(message.writeDids))
                    return "writeDids: array expected";
                for (var i = 0; i < message.writeDids.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.DID.verify(message.writeDids[i]);
                    if (error)
                        return "writeDids." + error;
                }
            }
            if (message.dtcList != null && message.hasOwnProperty("dtcList")) {
                if (!Array.isArray(message.dtcList))
                    return "dtcList: array expected";
                for (var i = 0; i < message.dtcList.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.DTC.verify(message.dtcList[i]);
                    if (error)
                        return "dtcList." + error;
                }
            }
            if (message.routines != null && message.hasOwnProperty("routines")) {
                if (!Array.isArray(message.routines))
                    return "routines: array expected";
                for (var i = 0; i < message.routines.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.Routine.verify(message.routines[i]);
                    if (error)
                        return "routines." + error;
                }
            }
            if (message.components != null && message.hasOwnProperty("components")) {
                if (!Array.isArray(message.components))
                    return "components: array expected";
                for (var i = 0; i < message.components.length; ++i) {
                    var error = $root.ecu_diagnostic_definitions.ComponentIdInfo.verify(message.components[i]);
                    if (error)
                        return "components." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DiagnosticTree message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ecu_diagnostic_definitions.DiagnosticTree} DiagnosticTree
         */
        DiagnosticTree.fromObject = function fromObject(object) {
            if (object instanceof $root.ecu_diagnostic_definitions.DiagnosticTree)
                return object;
            var message = new $root.ecu_diagnostic_definitions.DiagnosticTree();
            if (object.services) {
                if (!Array.isArray(object.services))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.services: array expected");
                message.services = [];
                for (var i = 0; i < object.services.length; ++i) {
                    if (typeof object.services[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.services: object expected");
                    message.services[i] = $root.ecu_diagnostic_definitions.UDSService.fromObject(object.services[i]);
                }
            }
            if (object.readDids) {
                if (!Array.isArray(object.readDids))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.readDids: array expected");
                message.readDids = [];
                for (var i = 0; i < object.readDids.length; ++i) {
                    if (typeof object.readDids[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.readDids: object expected");
                    message.readDids[i] = $root.ecu_diagnostic_definitions.DID.fromObject(object.readDids[i]);
                }
            }
            if (object.writeDids) {
                if (!Array.isArray(object.writeDids))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.writeDids: array expected");
                message.writeDids = [];
                for (var i = 0; i < object.writeDids.length; ++i) {
                    if (typeof object.writeDids[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.writeDids: object expected");
                    message.writeDids[i] = $root.ecu_diagnostic_definitions.DID.fromObject(object.writeDids[i]);
                }
            }
            if (object.dtcList) {
                if (!Array.isArray(object.dtcList))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.dtcList: array expected");
                message.dtcList = [];
                for (var i = 0; i < object.dtcList.length; ++i) {
                    if (typeof object.dtcList[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.dtcList: object expected");
                    message.dtcList[i] = $root.ecu_diagnostic_definitions.DTC.fromObject(object.dtcList[i]);
                }
            }
            if (object.routines) {
                if (!Array.isArray(object.routines))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.routines: array expected");
                message.routines = [];
                for (var i = 0; i < object.routines.length; ++i) {
                    if (typeof object.routines[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.routines: object expected");
                    message.routines[i] = $root.ecu_diagnostic_definitions.Routine.fromObject(object.routines[i]);
                }
            }
            if (object.components) {
                if (!Array.isArray(object.components))
                    throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.components: array expected");
                message.components = [];
                for (var i = 0; i < object.components.length; ++i) {
                    if (typeof object.components[i] !== "object")
                        throw TypeError(".ecu_diagnostic_definitions.DiagnosticTree.components: object expected");
                    message.components[i] = $root.ecu_diagnostic_definitions.ComponentIdInfo.fromObject(object.components[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticTree message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {ecu_diagnostic_definitions.DiagnosticTree} message DiagnosticTree
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticTree.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.services = [];
                object.readDids = [];
                object.writeDids = [];
                object.dtcList = [];
                object.routines = [];
                object.components = [];
            }
            if (message.services && message.services.length) {
                object.services = [];
                for (var j = 0; j < message.services.length; ++j)
                    object.services[j] = $root.ecu_diagnostic_definitions.UDSService.toObject(message.services[j], options);
            }
            if (message.readDids && message.readDids.length) {
                object.readDids = [];
                for (var j = 0; j < message.readDids.length; ++j)
                    object.readDids[j] = $root.ecu_diagnostic_definitions.DID.toObject(message.readDids[j], options);
            }
            if (message.writeDids && message.writeDids.length) {
                object.writeDids = [];
                for (var j = 0; j < message.writeDids.length; ++j)
                    object.writeDids[j] = $root.ecu_diagnostic_definitions.DID.toObject(message.writeDids[j], options);
            }
            if (message.dtcList && message.dtcList.length) {
                object.dtcList = [];
                for (var j = 0; j < message.dtcList.length; ++j)
                    object.dtcList[j] = $root.ecu_diagnostic_definitions.DTC.toObject(message.dtcList[j], options);
            }
            if (message.routines && message.routines.length) {
                object.routines = [];
                for (var j = 0; j < message.routines.length; ++j)
                    object.routines[j] = $root.ecu_diagnostic_definitions.Routine.toObject(message.routines[j], options);
            }
            if (message.components && message.components.length) {
                object.components = [];
                for (var j = 0; j < message.components.length; ++j)
                    object.components[j] = $root.ecu_diagnostic_definitions.ComponentIdInfo.toObject(message.components[j], options);
            }
            return object;
        };

        /**
         * Converts this DiagnosticTree to JSON.
         * @function toJSON
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticTree.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticTree
         * @function getTypeUrl
         * @memberof ecu_diagnostic_definitions.DiagnosticTree
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticTree.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ecu_diagnostic_definitions.DiagnosticTree";
        };

        return DiagnosticTree;
    })();

    return ecu_diagnostic_definitions;
})();

$root.uds_client_pb = (function() {

    /**
     * Namespace uds_client_pb.
     * @exports uds_client_pb
     * @namespace
     */
    var uds_client_pb = {};

    uds_client_pb.DTCInfoRequest = (function() {

        /**
         * Properties of a DTCInfoRequest.
         * @memberof uds_client_pb
         * @interface IDTCInfoRequest
         * @property {number|null} [dtc] DTCInfoRequest dtc
         * @property {number|null} [functionalGroup] DTCInfoRequest functionalGroup
         * @property {number|null} [statusMask] DTCInfoRequest statusMask
         * @property {number|null} [severityMask] DTCInfoRequest severityMask
         * @property {number|null} [recordNumber] DTCInfoRequest recordNumber
         * @property {number|null} [memorySelection] DTCInfoRequest memorySelection
         */

        /**
         * Constructs a new DTCInfoRequest.
         * @memberof uds_client_pb
         * @classdesc Represents a DTCInfoRequest.
         * @implements IDTCInfoRequest
         * @constructor
         * @param {uds_client_pb.IDTCInfoRequest=} [properties] Properties to set
         */
        function DTCInfoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DTCInfoRequest dtc.
         * @member {number} dtc
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.dtc = 0;

        /**
         * DTCInfoRequest functionalGroup.
         * @member {number} functionalGroup
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.functionalGroup = 0;

        /**
         * DTCInfoRequest statusMask.
         * @member {number} statusMask
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.statusMask = 0;

        /**
         * DTCInfoRequest severityMask.
         * @member {number} severityMask
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.severityMask = 0;

        /**
         * DTCInfoRequest recordNumber.
         * @member {number} recordNumber
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.recordNumber = 0;

        /**
         * DTCInfoRequest memorySelection.
         * @member {number} memorySelection
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         */
        DTCInfoRequest.prototype.memorySelection = 0;

        /**
         * Creates a new DTCInfoRequest instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {uds_client_pb.IDTCInfoRequest=} [properties] Properties to set
         * @returns {uds_client_pb.DTCInfoRequest} DTCInfoRequest instance
         */
        DTCInfoRequest.create = function create(properties) {
            return new DTCInfoRequest(properties);
        };

        /**
         * Encodes the specified DTCInfoRequest message. Does not implicitly {@link uds_client_pb.DTCInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {uds_client_pb.IDTCInfoRequest} message DTCInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dtc != null && Object.hasOwnProperty.call(message, "dtc"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.dtc);
            if (message.functionalGroup != null && Object.hasOwnProperty.call(message, "functionalGroup"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.functionalGroup);
            if (message.statusMask != null && Object.hasOwnProperty.call(message, "statusMask"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.statusMask);
            if (message.severityMask != null && Object.hasOwnProperty.call(message, "severityMask"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.severityMask);
            if (message.recordNumber != null && Object.hasOwnProperty.call(message, "recordNumber"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.recordNumber);
            if (message.memorySelection != null && Object.hasOwnProperty.call(message, "memorySelection"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.memorySelection);
            return writer;
        };

        /**
         * Encodes the specified DTCInfoRequest message, length delimited. Does not implicitly {@link uds_client_pb.DTCInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {uds_client_pb.IDTCInfoRequest} message DTCInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DTCInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.DTCInfoRequest} DTCInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.DTCInfoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dtc = reader.uint32();
                        break;
                    }
                case 2: {
                        message.functionalGroup = reader.uint32();
                        break;
                    }
                case 3: {
                        message.statusMask = reader.uint32();
                        break;
                    }
                case 4: {
                        message.severityMask = reader.uint32();
                        break;
                    }
                case 5: {
                        message.recordNumber = reader.uint32();
                        break;
                    }
                case 6: {
                        message.memorySelection = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DTCInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.DTCInfoRequest} DTCInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DTCInfoRequest message.
         * @function verify
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DTCInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dtc != null && message.hasOwnProperty("dtc"))
                if (!$util.isInteger(message.dtc))
                    return "dtc: integer expected";
            if (message.functionalGroup != null && message.hasOwnProperty("functionalGroup"))
                if (!$util.isInteger(message.functionalGroup))
                    return "functionalGroup: integer expected";
            if (message.statusMask != null && message.hasOwnProperty("statusMask"))
                if (!$util.isInteger(message.statusMask))
                    return "statusMask: integer expected";
            if (message.severityMask != null && message.hasOwnProperty("severityMask"))
                if (!$util.isInteger(message.severityMask))
                    return "severityMask: integer expected";
            if (message.recordNumber != null && message.hasOwnProperty("recordNumber"))
                if (!$util.isInteger(message.recordNumber))
                    return "recordNumber: integer expected";
            if (message.memorySelection != null && message.hasOwnProperty("memorySelection"))
                if (!$util.isInteger(message.memorySelection))
                    return "memorySelection: integer expected";
            return null;
        };

        /**
         * Creates a DTCInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.DTCInfoRequest} DTCInfoRequest
         */
        DTCInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.DTCInfoRequest)
                return object;
            var message = new $root.uds_client_pb.DTCInfoRequest();
            if (object.dtc != null)
                message.dtc = object.dtc >>> 0;
            if (object.functionalGroup != null)
                message.functionalGroup = object.functionalGroup >>> 0;
            if (object.statusMask != null)
                message.statusMask = object.statusMask >>> 0;
            if (object.severityMask != null)
                message.severityMask = object.severityMask >>> 0;
            if (object.recordNumber != null)
                message.recordNumber = object.recordNumber >>> 0;
            if (object.memorySelection != null)
                message.memorySelection = object.memorySelection >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a DTCInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {uds_client_pb.DTCInfoRequest} message DTCInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DTCInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.dtc = 0;
                object.functionalGroup = 0;
                object.statusMask = 0;
                object.severityMask = 0;
                object.recordNumber = 0;
                object.memorySelection = 0;
            }
            if (message.dtc != null && message.hasOwnProperty("dtc"))
                object.dtc = message.dtc;
            if (message.functionalGroup != null && message.hasOwnProperty("functionalGroup"))
                object.functionalGroup = message.functionalGroup;
            if (message.statusMask != null && message.hasOwnProperty("statusMask"))
                object.statusMask = message.statusMask;
            if (message.severityMask != null && message.hasOwnProperty("severityMask"))
                object.severityMask = message.severityMask;
            if (message.recordNumber != null && message.hasOwnProperty("recordNumber"))
                object.recordNumber = message.recordNumber;
            if (message.memorySelection != null && message.hasOwnProperty("memorySelection"))
                object.memorySelection = message.memorySelection;
            return object;
        };

        /**
         * Converts this DTCInfoRequest to JSON.
         * @function toJSON
         * @memberof uds_client_pb.DTCInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DTCInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DTCInfoRequest
         * @function getTypeUrl
         * @memberof uds_client_pb.DTCInfoRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DTCInfoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.DTCInfoRequest";
        };

        return DTCInfoRequest;
    })();

    uds_client_pb.DTCInfoRecord = (function() {

        /**
         * Properties of a DTCInfoRecord.
         * @memberof uds_client_pb
         * @interface IDTCInfoRecord
         * @property {number|null} [dtc] DTCInfoRecord dtc
         * @property {number|null} [status] DTCInfoRecord status
         * @property {number|null} [ssRecordNumber] DTCInfoRecord ssRecordNumber
         * @property {number|null} [severity] DTCInfoRecord severity
         * @property {number|null} [functionalUnit] DTCInfoRecord functionalUnit
         * @property {number|null} [faultCounter] DTCInfoRecord faultCounter
         */

        /**
         * Constructs a new DTCInfoRecord.
         * @memberof uds_client_pb
         * @classdesc Represents a DTCInfoRecord.
         * @implements IDTCInfoRecord
         * @constructor
         * @param {uds_client_pb.IDTCInfoRecord=} [properties] Properties to set
         */
        function DTCInfoRecord(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DTCInfoRecord dtc.
         * @member {number} dtc
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.dtc = 0;

        /**
         * DTCInfoRecord status.
         * @member {number} status
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.status = 0;

        /**
         * DTCInfoRecord ssRecordNumber.
         * @member {number} ssRecordNumber
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.ssRecordNumber = 0;

        /**
         * DTCInfoRecord severity.
         * @member {number} severity
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.severity = 0;

        /**
         * DTCInfoRecord functionalUnit.
         * @member {number} functionalUnit
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.functionalUnit = 0;

        /**
         * DTCInfoRecord faultCounter.
         * @member {number} faultCounter
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         */
        DTCInfoRecord.prototype.faultCounter = 0;

        /**
         * Creates a new DTCInfoRecord instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {uds_client_pb.IDTCInfoRecord=} [properties] Properties to set
         * @returns {uds_client_pb.DTCInfoRecord} DTCInfoRecord instance
         */
        DTCInfoRecord.create = function create(properties) {
            return new DTCInfoRecord(properties);
        };

        /**
         * Encodes the specified DTCInfoRecord message. Does not implicitly {@link uds_client_pb.DTCInfoRecord.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {uds_client_pb.IDTCInfoRecord} message DTCInfoRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoRecord.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dtc != null && Object.hasOwnProperty.call(message, "dtc"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.dtc);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.status);
            if (message.ssRecordNumber != null && Object.hasOwnProperty.call(message, "ssRecordNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.ssRecordNumber);
            if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.severity);
            if (message.functionalUnit != null && Object.hasOwnProperty.call(message, "functionalUnit"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.functionalUnit);
            if (message.faultCounter != null && Object.hasOwnProperty.call(message, "faultCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.faultCounter);
            return writer;
        };

        /**
         * Encodes the specified DTCInfoRecord message, length delimited. Does not implicitly {@link uds_client_pb.DTCInfoRecord.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {uds_client_pb.IDTCInfoRecord} message DTCInfoRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoRecord.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DTCInfoRecord message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.DTCInfoRecord} DTCInfoRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoRecord.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.DTCInfoRecord();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dtc = reader.uint32();
                        break;
                    }
                case 2: {
                        message.status = reader.uint32();
                        break;
                    }
                case 3: {
                        message.ssRecordNumber = reader.uint32();
                        break;
                    }
                case 4: {
                        message.severity = reader.uint32();
                        break;
                    }
                case 5: {
                        message.functionalUnit = reader.uint32();
                        break;
                    }
                case 6: {
                        message.faultCounter = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DTCInfoRecord message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.DTCInfoRecord} DTCInfoRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoRecord.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DTCInfoRecord message.
         * @function verify
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DTCInfoRecord.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dtc != null && message.hasOwnProperty("dtc"))
                if (!$util.isInteger(message.dtc))
                    return "dtc: integer expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isInteger(message.status))
                    return "status: integer expected";
            if (message.ssRecordNumber != null && message.hasOwnProperty("ssRecordNumber"))
                if (!$util.isInteger(message.ssRecordNumber))
                    return "ssRecordNumber: integer expected";
            if (message.severity != null && message.hasOwnProperty("severity"))
                if (!$util.isInteger(message.severity))
                    return "severity: integer expected";
            if (message.functionalUnit != null && message.hasOwnProperty("functionalUnit"))
                if (!$util.isInteger(message.functionalUnit))
                    return "functionalUnit: integer expected";
            if (message.faultCounter != null && message.hasOwnProperty("faultCounter"))
                if (!$util.isInteger(message.faultCounter))
                    return "faultCounter: integer expected";
            return null;
        };

        /**
         * Creates a DTCInfoRecord message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.DTCInfoRecord} DTCInfoRecord
         */
        DTCInfoRecord.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.DTCInfoRecord)
                return object;
            var message = new $root.uds_client_pb.DTCInfoRecord();
            if (object.dtc != null)
                message.dtc = object.dtc >>> 0;
            if (object.status != null)
                message.status = object.status >>> 0;
            if (object.ssRecordNumber != null)
                message.ssRecordNumber = object.ssRecordNumber >>> 0;
            if (object.severity != null)
                message.severity = object.severity >>> 0;
            if (object.functionalUnit != null)
                message.functionalUnit = object.functionalUnit >>> 0;
            if (object.faultCounter != null)
                message.faultCounter = object.faultCounter >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a DTCInfoRecord message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {uds_client_pb.DTCInfoRecord} message DTCInfoRecord
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DTCInfoRecord.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.dtc = 0;
                object.status = 0;
                object.ssRecordNumber = 0;
                object.severity = 0;
                object.functionalUnit = 0;
                object.faultCounter = 0;
            }
            if (message.dtc != null && message.hasOwnProperty("dtc"))
                object.dtc = message.dtc;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.ssRecordNumber != null && message.hasOwnProperty("ssRecordNumber"))
                object.ssRecordNumber = message.ssRecordNumber;
            if (message.severity != null && message.hasOwnProperty("severity"))
                object.severity = message.severity;
            if (message.functionalUnit != null && message.hasOwnProperty("functionalUnit"))
                object.functionalUnit = message.functionalUnit;
            if (message.faultCounter != null && message.hasOwnProperty("faultCounter"))
                object.faultCounter = message.faultCounter;
            return object;
        };

        /**
         * Converts this DTCInfoRecord to JSON.
         * @function toJSON
         * @memberof uds_client_pb.DTCInfoRecord
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DTCInfoRecord.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DTCInfoRecord
         * @function getTypeUrl
         * @memberof uds_client_pb.DTCInfoRecord
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DTCInfoRecord.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.DTCInfoRecord";
        };

        return DTCInfoRecord;
    })();

    uds_client_pb.DTCInfoResponse = (function() {

        /**
         * Properties of a DTCInfoResponse.
         * @memberof uds_client_pb
         * @interface IDTCInfoResponse
         * @property {number|null} [functionalGroup] DTCInfoResponse functionalGroup
         * @property {number|null} [formatId] DTCInfoResponse formatId
         * @property {number|null} [memorySelection] DTCInfoResponse memorySelection
         * @property {number|null} [statusAvailability] DTCInfoResponse statusAvailability
         * @property {number|null} [recordNumber] DTCInfoResponse recordNumber
         * @property {Array.<uds_client_pb.IDTCInfoRecord>|null} [infoRecords] DTCInfoResponse infoRecords
         * @property {number|null} [dtcCount] DTCInfoResponse dtcCount
         */

        /**
         * Constructs a new DTCInfoResponse.
         * @memberof uds_client_pb
         * @classdesc Represents a DTCInfoResponse.
         * @implements IDTCInfoResponse
         * @constructor
         * @param {uds_client_pb.IDTCInfoResponse=} [properties] Properties to set
         */
        function DTCInfoResponse(properties) {
            this.infoRecords = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DTCInfoResponse functionalGroup.
         * @member {number} functionalGroup
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.functionalGroup = 0;

        /**
         * DTCInfoResponse formatId.
         * @member {number} formatId
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.formatId = 0;

        /**
         * DTCInfoResponse memorySelection.
         * @member {number} memorySelection
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.memorySelection = 0;

        /**
         * DTCInfoResponse statusAvailability.
         * @member {number} statusAvailability
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.statusAvailability = 0;

        /**
         * DTCInfoResponse recordNumber.
         * @member {number} recordNumber
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.recordNumber = 0;

        /**
         * DTCInfoResponse infoRecords.
         * @member {Array.<uds_client_pb.IDTCInfoRecord>} infoRecords
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.infoRecords = $util.emptyArray;

        /**
         * DTCInfoResponse dtcCount.
         * @member {number} dtcCount
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         */
        DTCInfoResponse.prototype.dtcCount = 0;

        /**
         * Creates a new DTCInfoResponse instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {uds_client_pb.IDTCInfoResponse=} [properties] Properties to set
         * @returns {uds_client_pb.DTCInfoResponse} DTCInfoResponse instance
         */
        DTCInfoResponse.create = function create(properties) {
            return new DTCInfoResponse(properties);
        };

        /**
         * Encodes the specified DTCInfoResponse message. Does not implicitly {@link uds_client_pb.DTCInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {uds_client_pb.IDTCInfoResponse} message DTCInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.functionalGroup != null && Object.hasOwnProperty.call(message, "functionalGroup"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.functionalGroup);
            if (message.formatId != null && Object.hasOwnProperty.call(message, "formatId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.formatId);
            if (message.memorySelection != null && Object.hasOwnProperty.call(message, "memorySelection"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.memorySelection);
            if (message.statusAvailability != null && Object.hasOwnProperty.call(message, "statusAvailability"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.statusAvailability);
            if (message.recordNumber != null && Object.hasOwnProperty.call(message, "recordNumber"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.recordNumber);
            if (message.infoRecords != null && message.infoRecords.length)
                for (var i = 0; i < message.infoRecords.length; ++i)
                    $root.uds_client_pb.DTCInfoRecord.encode(message.infoRecords[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.dtcCount != null && Object.hasOwnProperty.call(message, "dtcCount"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.dtcCount);
            return writer;
        };

        /**
         * Encodes the specified DTCInfoResponse message, length delimited. Does not implicitly {@link uds_client_pb.DTCInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {uds_client_pb.IDTCInfoResponse} message DTCInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DTCInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DTCInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.DTCInfoResponse} DTCInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.DTCInfoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.functionalGroup = reader.uint32();
                        break;
                    }
                case 2: {
                        message.formatId = reader.uint32();
                        break;
                    }
                case 3: {
                        message.memorySelection = reader.uint32();
                        break;
                    }
                case 4: {
                        message.statusAvailability = reader.uint32();
                        break;
                    }
                case 5: {
                        message.recordNumber = reader.uint32();
                        break;
                    }
                case 6: {
                        if (!(message.infoRecords && message.infoRecords.length))
                            message.infoRecords = [];
                        message.infoRecords.push($root.uds_client_pb.DTCInfoRecord.decode(reader, reader.uint32()));
                        break;
                    }
                case 7: {
                        message.dtcCount = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DTCInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.DTCInfoResponse} DTCInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DTCInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DTCInfoResponse message.
         * @function verify
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DTCInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.functionalGroup != null && message.hasOwnProperty("functionalGroup"))
                if (!$util.isInteger(message.functionalGroup))
                    return "functionalGroup: integer expected";
            if (message.formatId != null && message.hasOwnProperty("formatId"))
                if (!$util.isInteger(message.formatId))
                    return "formatId: integer expected";
            if (message.memorySelection != null && message.hasOwnProperty("memorySelection"))
                if (!$util.isInteger(message.memorySelection))
                    return "memorySelection: integer expected";
            if (message.statusAvailability != null && message.hasOwnProperty("statusAvailability"))
                if (!$util.isInteger(message.statusAvailability))
                    return "statusAvailability: integer expected";
            if (message.recordNumber != null && message.hasOwnProperty("recordNumber"))
                if (!$util.isInteger(message.recordNumber))
                    return "recordNumber: integer expected";
            if (message.infoRecords != null && message.hasOwnProperty("infoRecords")) {
                if (!Array.isArray(message.infoRecords))
                    return "infoRecords: array expected";
                for (var i = 0; i < message.infoRecords.length; ++i) {
                    var error = $root.uds_client_pb.DTCInfoRecord.verify(message.infoRecords[i]);
                    if (error)
                        return "infoRecords." + error;
                }
            }
            if (message.dtcCount != null && message.hasOwnProperty("dtcCount"))
                if (!$util.isInteger(message.dtcCount))
                    return "dtcCount: integer expected";
            return null;
        };

        /**
         * Creates a DTCInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.DTCInfoResponse} DTCInfoResponse
         */
        DTCInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.DTCInfoResponse)
                return object;
            var message = new $root.uds_client_pb.DTCInfoResponse();
            if (object.functionalGroup != null)
                message.functionalGroup = object.functionalGroup >>> 0;
            if (object.formatId != null)
                message.formatId = object.formatId >>> 0;
            if (object.memorySelection != null)
                message.memorySelection = object.memorySelection >>> 0;
            if (object.statusAvailability != null)
                message.statusAvailability = object.statusAvailability >>> 0;
            if (object.recordNumber != null)
                message.recordNumber = object.recordNumber >>> 0;
            if (object.infoRecords) {
                if (!Array.isArray(object.infoRecords))
                    throw TypeError(".uds_client_pb.DTCInfoResponse.infoRecords: array expected");
                message.infoRecords = [];
                for (var i = 0; i < object.infoRecords.length; ++i) {
                    if (typeof object.infoRecords[i] !== "object")
                        throw TypeError(".uds_client_pb.DTCInfoResponse.infoRecords: object expected");
                    message.infoRecords[i] = $root.uds_client_pb.DTCInfoRecord.fromObject(object.infoRecords[i]);
                }
            }
            if (object.dtcCount != null)
                message.dtcCount = object.dtcCount >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a DTCInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {uds_client_pb.DTCInfoResponse} message DTCInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DTCInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infoRecords = [];
            if (options.defaults) {
                object.functionalGroup = 0;
                object.formatId = 0;
                object.memorySelection = 0;
                object.statusAvailability = 0;
                object.recordNumber = 0;
                object.dtcCount = 0;
            }
            if (message.functionalGroup != null && message.hasOwnProperty("functionalGroup"))
                object.functionalGroup = message.functionalGroup;
            if (message.formatId != null && message.hasOwnProperty("formatId"))
                object.formatId = message.formatId;
            if (message.memorySelection != null && message.hasOwnProperty("memorySelection"))
                object.memorySelection = message.memorySelection;
            if (message.statusAvailability != null && message.hasOwnProperty("statusAvailability"))
                object.statusAvailability = message.statusAvailability;
            if (message.recordNumber != null && message.hasOwnProperty("recordNumber"))
                object.recordNumber = message.recordNumber;
            if (message.infoRecords && message.infoRecords.length) {
                object.infoRecords = [];
                for (var j = 0; j < message.infoRecords.length; ++j)
                    object.infoRecords[j] = $root.uds_client_pb.DTCInfoRecord.toObject(message.infoRecords[j], options);
            }
            if (message.dtcCount != null && message.hasOwnProperty("dtcCount"))
                object.dtcCount = message.dtcCount;
            return object;
        };

        /**
         * Converts this DTCInfoResponse to JSON.
         * @function toJSON
         * @memberof uds_client_pb.DTCInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DTCInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DTCInfoResponse
         * @function getTypeUrl
         * @memberof uds_client_pb.DTCInfoResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DTCInfoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.DTCInfoResponse";
        };

        return DTCInfoResponse;
    })();

    uds_client_pb.udsRR = (function() {

        /**
         * Properties of an udsRR.
         * @memberof uds_client_pb
         * @interface IudsRR
         * @property {number} sid udsRR sid
         * @property {number|null} [subFn] udsRR subFn
         * @property {number|null} [id] udsRR id
         * @property {number|Long|null} [start] udsRR start
         * @property {number|Long|null} [size] udsRR size
         * @property {Uint8Array|null} [data] udsRR data
         * @property {uds_client_pb.IDTCInfoRequest|null} [dtcInfoRequest] udsRR dtcInfoRequest
         * @property {uds_client_pb.IDTCInfoResponse|null} [dtcInfoResponse] udsRR dtcInfoResponse
         * @property {ecu_diagnostic_definitions.IDiagnosticTree|null} [diagnosticTree] udsRR diagnosticTree
         * @property {boolean|null} [stopOnFailure] udsRR stopOnFailure
         */

        /**
         * Constructs a new udsRR.
         * @memberof uds_client_pb
         * @classdesc Represents an udsRR.
         * @implements IudsRR
         * @constructor
         * @param {uds_client_pb.IudsRR=} [properties] Properties to set
         */
        function udsRR(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * udsRR sid.
         * @member {number} sid
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.sid = 0;

        /**
         * udsRR subFn.
         * @member {number} subFn
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.subFn = 0;

        /**
         * udsRR id.
         * @member {number} id
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.id = 0;

        /**
         * udsRR start.
         * @member {number|Long} start
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.start = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * udsRR size.
         * @member {number|Long} size
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * udsRR data.
         * @member {Uint8Array} data
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.data = $util.newBuffer([]);

        /**
         * udsRR dtcInfoRequest.
         * @member {uds_client_pb.IDTCInfoRequest|null|undefined} dtcInfoRequest
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.dtcInfoRequest = null;

        /**
         * udsRR dtcInfoResponse.
         * @member {uds_client_pb.IDTCInfoResponse|null|undefined} dtcInfoResponse
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.dtcInfoResponse = null;

        /**
         * udsRR diagnosticTree.
         * @member {ecu_diagnostic_definitions.IDiagnosticTree|null|undefined} diagnosticTree
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.diagnosticTree = null;

        /**
         * udsRR stopOnFailure.
         * @member {boolean} stopOnFailure
         * @memberof uds_client_pb.udsRR
         * @instance
         */
        udsRR.prototype.stopOnFailure = false;

        /**
         * Creates a new udsRR instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {uds_client_pb.IudsRR=} [properties] Properties to set
         * @returns {uds_client_pb.udsRR} udsRR instance
         */
        udsRR.create = function create(properties) {
            return new udsRR(properties);
        };

        /**
         * Encodes the specified udsRR message. Does not implicitly {@link uds_client_pb.udsRR.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {uds_client_pb.IudsRR} message udsRR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        udsRR.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sid);
            if (message.subFn != null && Object.hasOwnProperty.call(message, "subFn"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.subFn);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.id);
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.start);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.size);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.data);
            if (message.dtcInfoRequest != null && Object.hasOwnProperty.call(message, "dtcInfoRequest"))
                $root.uds_client_pb.DTCInfoRequest.encode(message.dtcInfoRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dtcInfoResponse != null && Object.hasOwnProperty.call(message, "dtcInfoResponse"))
                $root.uds_client_pb.DTCInfoResponse.encode(message.dtcInfoResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.diagnosticTree != null && Object.hasOwnProperty.call(message, "diagnosticTree"))
                $root.ecu_diagnostic_definitions.DiagnosticTree.encode(message.diagnosticTree, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.stopOnFailure != null && Object.hasOwnProperty.call(message, "stopOnFailure"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.stopOnFailure);
            return writer;
        };

        /**
         * Encodes the specified udsRR message, length delimited. Does not implicitly {@link uds_client_pb.udsRR.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {uds_client_pb.IudsRR} message udsRR message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        udsRR.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an udsRR message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.udsRR} udsRR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        udsRR.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.udsRR();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sid = reader.uint32();
                        break;
                    }
                case 2: {
                        message.subFn = reader.uint32();
                        break;
                    }
                case 3: {
                        message.id = reader.uint32();
                        break;
                    }
                case 4: {
                        message.start = reader.uint64();
                        break;
                    }
                case 5: {
                        message.size = reader.uint64();
                        break;
                    }
                case 6: {
                        message.data = reader.bytes();
                        break;
                    }
                case 7: {
                        message.dtcInfoRequest = $root.uds_client_pb.DTCInfoRequest.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.dtcInfoResponse = $root.uds_client_pb.DTCInfoResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.diagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.stopOnFailure = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sid"))
                throw $util.ProtocolError("missing required 'sid'", { instance: message });
            return message;
        };

        /**
         * Decodes an udsRR message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.udsRR} udsRR
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        udsRR.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an udsRR message.
         * @function verify
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        udsRR.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.sid))
                return "sid: integer expected";
            if (message.subFn != null && message.hasOwnProperty("subFn"))
                if (!$util.isInteger(message.subFn))
                    return "subFn: integer expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.start != null && message.hasOwnProperty("start"))
                if (!$util.isInteger(message.start) && !(message.start && $util.isInteger(message.start.low) && $util.isInteger(message.start.high)))
                    return "start: integer|Long expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            if (message.dtcInfoRequest != null && message.hasOwnProperty("dtcInfoRequest")) {
                var error = $root.uds_client_pb.DTCInfoRequest.verify(message.dtcInfoRequest);
                if (error)
                    return "dtcInfoRequest." + error;
            }
            if (message.dtcInfoResponse != null && message.hasOwnProperty("dtcInfoResponse")) {
                var error = $root.uds_client_pb.DTCInfoResponse.verify(message.dtcInfoResponse);
                if (error)
                    return "dtcInfoResponse." + error;
            }
            if (message.diagnosticTree != null && message.hasOwnProperty("diagnosticTree")) {
                var error = $root.ecu_diagnostic_definitions.DiagnosticTree.verify(message.diagnosticTree);
                if (error)
                    return "diagnosticTree." + error;
            }
            if (message.stopOnFailure != null && message.hasOwnProperty("stopOnFailure"))
                if (typeof message.stopOnFailure !== "boolean")
                    return "stopOnFailure: boolean expected";
            return null;
        };

        /**
         * Creates an udsRR message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.udsRR} udsRR
         */
        udsRR.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.udsRR)
                return object;
            var message = new $root.uds_client_pb.udsRR();
            if (object.sid != null)
                message.sid = object.sid >>> 0;
            if (object.subFn != null)
                message.subFn = object.subFn >>> 0;
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.start != null)
                if ($util.Long)
                    (message.start = $util.Long.fromValue(object.start)).unsigned = true;
                else if (typeof object.start === "string")
                    message.start = parseInt(object.start, 10);
                else if (typeof object.start === "number")
                    message.start = object.start;
                else if (typeof object.start === "object")
                    message.start = new $util.LongBits(object.start.low >>> 0, object.start.high >>> 0).toNumber(true);
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = true;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            if (object.dtcInfoRequest != null) {
                if (typeof object.dtcInfoRequest !== "object")
                    throw TypeError(".uds_client_pb.udsRR.dtcInfoRequest: object expected");
                message.dtcInfoRequest = $root.uds_client_pb.DTCInfoRequest.fromObject(object.dtcInfoRequest);
            }
            if (object.dtcInfoResponse != null) {
                if (typeof object.dtcInfoResponse !== "object")
                    throw TypeError(".uds_client_pb.udsRR.dtcInfoResponse: object expected");
                message.dtcInfoResponse = $root.uds_client_pb.DTCInfoResponse.fromObject(object.dtcInfoResponse);
            }
            if (object.diagnosticTree != null) {
                if (typeof object.diagnosticTree !== "object")
                    throw TypeError(".uds_client_pb.udsRR.diagnosticTree: object expected");
                message.diagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.fromObject(object.diagnosticTree);
            }
            if (object.stopOnFailure != null)
                message.stopOnFailure = Boolean(object.stopOnFailure);
            return message;
        };

        /**
         * Creates a plain object from an udsRR message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {uds_client_pb.udsRR} message udsRR
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        udsRR.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sid = 0;
                object.subFn = 0;
                object.id = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.start = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.start = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
                object.dtcInfoRequest = null;
                object.dtcInfoResponse = null;
                object.diagnosticTree = null;
                object.stopOnFailure = false;
            }
            if (message.sid != null && message.hasOwnProperty("sid"))
                object.sid = message.sid;
            if (message.subFn != null && message.hasOwnProperty("subFn"))
                object.subFn = message.subFn;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.start != null && message.hasOwnProperty("start"))
                if (typeof message.start === "number")
                    object.start = options.longs === String ? String(message.start) : message.start;
                else
                    object.start = options.longs === String ? $util.Long.prototype.toString.call(message.start) : options.longs === Number ? new $util.LongBits(message.start.low >>> 0, message.start.high >>> 0).toNumber(true) : message.start;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            if (message.dtcInfoRequest != null && message.hasOwnProperty("dtcInfoRequest"))
                object.dtcInfoRequest = $root.uds_client_pb.DTCInfoRequest.toObject(message.dtcInfoRequest, options);
            if (message.dtcInfoResponse != null && message.hasOwnProperty("dtcInfoResponse"))
                object.dtcInfoResponse = $root.uds_client_pb.DTCInfoResponse.toObject(message.dtcInfoResponse, options);
            if (message.diagnosticTree != null && message.hasOwnProperty("diagnosticTree"))
                object.diagnosticTree = $root.ecu_diagnostic_definitions.DiagnosticTree.toObject(message.diagnosticTree, options);
            if (message.stopOnFailure != null && message.hasOwnProperty("stopOnFailure"))
                object.stopOnFailure = message.stopOnFailure;
            return object;
        };

        /**
         * Converts this udsRR to JSON.
         * @function toJSON
         * @memberof uds_client_pb.udsRR
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        udsRR.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for udsRR
         * @function getTypeUrl
         * @memberof uds_client_pb.udsRR
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        udsRR.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.udsRR";
        };

        return udsRR;
    })();

    uds_client_pb.UDSRequest = (function() {

        /**
         * Properties of a UDSRequest.
         * @memberof uds_client_pb
         * @interface IUDSRequest
         * @property {string} ecu UDSRequest ecu
         * @property {uds_client_pb.IudsRR} rr UDSRequest rr
         * @property {boolean|null} [keep] UDSRequest keep
         * @property {number|Long|null} [handle] UDSRequest handle
         */

        /**
         * Constructs a new UDSRequest.
         * @memberof uds_client_pb
         * @classdesc Represents a UDSRequest.
         * @implements IUDSRequest
         * @constructor
         * @param {uds_client_pb.IUDSRequest=} [properties] Properties to set
         */
        function UDSRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSRequest ecu.
         * @member {string} ecu
         * @memberof uds_client_pb.UDSRequest
         * @instance
         */
        UDSRequest.prototype.ecu = "";

        /**
         * UDSRequest rr.
         * @member {uds_client_pb.IudsRR} rr
         * @memberof uds_client_pb.UDSRequest
         * @instance
         */
        UDSRequest.prototype.rr = null;

        /**
         * UDSRequest keep.
         * @member {boolean} keep
         * @memberof uds_client_pb.UDSRequest
         * @instance
         */
        UDSRequest.prototype.keep = false;

        /**
         * UDSRequest handle.
         * @member {number|Long} handle
         * @memberof uds_client_pb.UDSRequest
         * @instance
         */
        UDSRequest.prototype.handle = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new UDSRequest instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {uds_client_pb.IUDSRequest=} [properties] Properties to set
         * @returns {uds_client_pb.UDSRequest} UDSRequest instance
         */
        UDSRequest.create = function create(properties) {
            return new UDSRequest(properties);
        };

        /**
         * Encodes the specified UDSRequest message. Does not implicitly {@link uds_client_pb.UDSRequest.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {uds_client_pb.IUDSRequest} message UDSRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ecu);
            $root.uds_client_pb.udsRR.encode(message.rr, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.keep != null && Object.hasOwnProperty.call(message, "keep"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.keep);
            if (message.handle != null && Object.hasOwnProperty.call(message, "handle"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.handle);
            return writer;
        };

        /**
         * Encodes the specified UDSRequest message, length delimited. Does not implicitly {@link uds_client_pb.UDSRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {uds_client_pb.IUDSRequest} message UDSRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSRequest message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.UDSRequest} UDSRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.UDSRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ecu = reader.string();
                        break;
                    }
                case 2: {
                        message.rr = $root.uds_client_pb.udsRR.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.keep = reader.bool();
                        break;
                    }
                case 4: {
                        message.handle = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("ecu"))
                throw $util.ProtocolError("missing required 'ecu'", { instance: message });
            if (!message.hasOwnProperty("rr"))
                throw $util.ProtocolError("missing required 'rr'", { instance: message });
            return message;
        };

        /**
         * Decodes a UDSRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.UDSRequest} UDSRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSRequest message.
         * @function verify
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.ecu))
                return "ecu: string expected";
            {
                var error = $root.uds_client_pb.udsRR.verify(message.rr);
                if (error)
                    return "rr." + error;
            }
            if (message.keep != null && message.hasOwnProperty("keep"))
                if (typeof message.keep !== "boolean")
                    return "keep: boolean expected";
            if (message.handle != null && message.hasOwnProperty("handle"))
                if (!$util.isInteger(message.handle) && !(message.handle && $util.isInteger(message.handle.low) && $util.isInteger(message.handle.high)))
                    return "handle: integer|Long expected";
            return null;
        };

        /**
         * Creates a UDSRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.UDSRequest} UDSRequest
         */
        UDSRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.UDSRequest)
                return object;
            var message = new $root.uds_client_pb.UDSRequest();
            if (object.ecu != null)
                message.ecu = String(object.ecu);
            if (object.rr != null) {
                if (typeof object.rr !== "object")
                    throw TypeError(".uds_client_pb.UDSRequest.rr: object expected");
                message.rr = $root.uds_client_pb.udsRR.fromObject(object.rr);
            }
            if (object.keep != null)
                message.keep = Boolean(object.keep);
            if (object.handle != null)
                if ($util.Long)
                    (message.handle = $util.Long.fromValue(object.handle)).unsigned = true;
                else if (typeof object.handle === "string")
                    message.handle = parseInt(object.handle, 10);
                else if (typeof object.handle === "number")
                    message.handle = object.handle;
                else if (typeof object.handle === "object")
                    message.handle = new $util.LongBits(object.handle.low >>> 0, object.handle.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a UDSRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {uds_client_pb.UDSRequest} message UDSRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.ecu = "";
                object.rr = null;
                object.keep = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.handle = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.handle = options.longs === String ? "0" : 0;
            }
            if (message.ecu != null && message.hasOwnProperty("ecu"))
                object.ecu = message.ecu;
            if (message.rr != null && message.hasOwnProperty("rr"))
                object.rr = $root.uds_client_pb.udsRR.toObject(message.rr, options);
            if (message.keep != null && message.hasOwnProperty("keep"))
                object.keep = message.keep;
            if (message.handle != null && message.hasOwnProperty("handle"))
                if (typeof message.handle === "number")
                    object.handle = options.longs === String ? String(message.handle) : message.handle;
                else
                    object.handle = options.longs === String ? $util.Long.prototype.toString.call(message.handle) : options.longs === Number ? new $util.LongBits(message.handle.low >>> 0, message.handle.high >>> 0).toNumber(true) : message.handle;
            return object;
        };

        /**
         * Converts this UDSRequest to JSON.
         * @function toJSON
         * @memberof uds_client_pb.UDSRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSRequest
         * @function getTypeUrl
         * @memberof uds_client_pb.UDSRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.UDSRequest";
        };

        return UDSRequest;
    })();

    uds_client_pb.UDSPackageRequest = (function() {

        /**
         * Properties of a UDSPackageRequest.
         * @memberof uds_client_pb
         * @interface IUDSPackageRequest
         * @property {Array.<string>|null} [ecus] UDSPackageRequest ecus
         * @property {Array.<uds_client_pb.IudsRR>|null} [requests] UDSPackageRequest requests
         */

        /**
         * Constructs a new UDSPackageRequest.
         * @memberof uds_client_pb
         * @classdesc Represents a UDSPackageRequest.
         * @implements IUDSPackageRequest
         * @constructor
         * @param {uds_client_pb.IUDSPackageRequest=} [properties] Properties to set
         */
        function UDSPackageRequest(properties) {
            this.ecus = [];
            this.requests = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSPackageRequest ecus.
         * @member {Array.<string>} ecus
         * @memberof uds_client_pb.UDSPackageRequest
         * @instance
         */
        UDSPackageRequest.prototype.ecus = $util.emptyArray;

        /**
         * UDSPackageRequest requests.
         * @member {Array.<uds_client_pb.IudsRR>} requests
         * @memberof uds_client_pb.UDSPackageRequest
         * @instance
         */
        UDSPackageRequest.prototype.requests = $util.emptyArray;

        /**
         * Creates a new UDSPackageRequest instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {uds_client_pb.IUDSPackageRequest=} [properties] Properties to set
         * @returns {uds_client_pb.UDSPackageRequest} UDSPackageRequest instance
         */
        UDSPackageRequest.create = function create(properties) {
            return new UDSPackageRequest(properties);
        };

        /**
         * Encodes the specified UDSPackageRequest message. Does not implicitly {@link uds_client_pb.UDSPackageRequest.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {uds_client_pb.IUDSPackageRequest} message UDSPackageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSPackageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ecus != null && message.ecus.length)
                for (var i = 0; i < message.ecus.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ecus[i]);
            if (message.requests != null && message.requests.length)
                for (var i = 0; i < message.requests.length; ++i)
                    $root.uds_client_pb.udsRR.encode(message.requests[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDSPackageRequest message, length delimited. Does not implicitly {@link uds_client_pb.UDSPackageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {uds_client_pb.IUDSPackageRequest} message UDSPackageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSPackageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSPackageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.UDSPackageRequest} UDSPackageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSPackageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.UDSPackageRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ecus && message.ecus.length))
                            message.ecus = [];
                        message.ecus.push(reader.string());
                        break;
                    }
                case 2: {
                        if (!(message.requests && message.requests.length))
                            message.requests = [];
                        message.requests.push($root.uds_client_pb.udsRR.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDSPackageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.UDSPackageRequest} UDSPackageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSPackageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSPackageRequest message.
         * @function verify
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSPackageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ecus != null && message.hasOwnProperty("ecus")) {
                if (!Array.isArray(message.ecus))
                    return "ecus: array expected";
                for (var i = 0; i < message.ecus.length; ++i)
                    if (!$util.isString(message.ecus[i]))
                        return "ecus: string[] expected";
            }
            if (message.requests != null && message.hasOwnProperty("requests")) {
                if (!Array.isArray(message.requests))
                    return "requests: array expected";
                for (var i = 0; i < message.requests.length; ++i) {
                    var error = $root.uds_client_pb.udsRR.verify(message.requests[i]);
                    if (error)
                        return "requests." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UDSPackageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.UDSPackageRequest} UDSPackageRequest
         */
        UDSPackageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.UDSPackageRequest)
                return object;
            var message = new $root.uds_client_pb.UDSPackageRequest();
            if (object.ecus) {
                if (!Array.isArray(object.ecus))
                    throw TypeError(".uds_client_pb.UDSPackageRequest.ecus: array expected");
                message.ecus = [];
                for (var i = 0; i < object.ecus.length; ++i)
                    message.ecus[i] = String(object.ecus[i]);
            }
            if (object.requests) {
                if (!Array.isArray(object.requests))
                    throw TypeError(".uds_client_pb.UDSPackageRequest.requests: array expected");
                message.requests = [];
                for (var i = 0; i < object.requests.length; ++i) {
                    if (typeof object.requests[i] !== "object")
                        throw TypeError(".uds_client_pb.UDSPackageRequest.requests: object expected");
                    message.requests[i] = $root.uds_client_pb.udsRR.fromObject(object.requests[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UDSPackageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {uds_client_pb.UDSPackageRequest} message UDSPackageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSPackageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.ecus = [];
                object.requests = [];
            }
            if (message.ecus && message.ecus.length) {
                object.ecus = [];
                for (var j = 0; j < message.ecus.length; ++j)
                    object.ecus[j] = message.ecus[j];
            }
            if (message.requests && message.requests.length) {
                object.requests = [];
                for (var j = 0; j < message.requests.length; ++j)
                    object.requests[j] = $root.uds_client_pb.udsRR.toObject(message.requests[j], options);
            }
            return object;
        };

        /**
         * Converts this UDSPackageRequest to JSON.
         * @function toJSON
         * @memberof uds_client_pb.UDSPackageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSPackageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSPackageRequest
         * @function getTypeUrl
         * @memberof uds_client_pb.UDSPackageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSPackageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.UDSPackageRequest";
        };

        return UDSPackageRequest;
    })();

    uds_client_pb.UDSSequenceRequest = (function() {

        /**
         * Properties of a UDSSequenceRequest.
         * @memberof uds_client_pb
         * @interface IUDSSequenceRequest
         * @property {Array.<uds_client_pb.IUDSPackageRequest>|null} [packages] UDSSequenceRequest packages
         */

        /**
         * Constructs a new UDSSequenceRequest.
         * @memberof uds_client_pb
         * @classdesc Represents a UDSSequenceRequest.
         * @implements IUDSSequenceRequest
         * @constructor
         * @param {uds_client_pb.IUDSSequenceRequest=} [properties] Properties to set
         */
        function UDSSequenceRequest(properties) {
            this.packages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSSequenceRequest packages.
         * @member {Array.<uds_client_pb.IUDSPackageRequest>} packages
         * @memberof uds_client_pb.UDSSequenceRequest
         * @instance
         */
        UDSSequenceRequest.prototype.packages = $util.emptyArray;

        /**
         * Creates a new UDSSequenceRequest instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {uds_client_pb.IUDSSequenceRequest=} [properties] Properties to set
         * @returns {uds_client_pb.UDSSequenceRequest} UDSSequenceRequest instance
         */
        UDSSequenceRequest.create = function create(properties) {
            return new UDSSequenceRequest(properties);
        };

        /**
         * Encodes the specified UDSSequenceRequest message. Does not implicitly {@link uds_client_pb.UDSSequenceRequest.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {uds_client_pb.IUDSSequenceRequest} message UDSSequenceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSSequenceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.packages != null && message.packages.length)
                for (var i = 0; i < message.packages.length; ++i)
                    $root.uds_client_pb.UDSPackageRequest.encode(message.packages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDSSequenceRequest message, length delimited. Does not implicitly {@link uds_client_pb.UDSSequenceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {uds_client_pb.IUDSSequenceRequest} message UDSSequenceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSSequenceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSSequenceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.UDSSequenceRequest} UDSSequenceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSSequenceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.UDSSequenceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.packages && message.packages.length))
                            message.packages = [];
                        message.packages.push($root.uds_client_pb.UDSPackageRequest.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDSSequenceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.UDSSequenceRequest} UDSSequenceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSSequenceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSSequenceRequest message.
         * @function verify
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSSequenceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.packages != null && message.hasOwnProperty("packages")) {
                if (!Array.isArray(message.packages))
                    return "packages: array expected";
                for (var i = 0; i < message.packages.length; ++i) {
                    var error = $root.uds_client_pb.UDSPackageRequest.verify(message.packages[i]);
                    if (error)
                        return "packages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UDSSequenceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.UDSSequenceRequest} UDSSequenceRequest
         */
        UDSSequenceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.UDSSequenceRequest)
                return object;
            var message = new $root.uds_client_pb.UDSSequenceRequest();
            if (object.packages) {
                if (!Array.isArray(object.packages))
                    throw TypeError(".uds_client_pb.UDSSequenceRequest.packages: array expected");
                message.packages = [];
                for (var i = 0; i < object.packages.length; ++i) {
                    if (typeof object.packages[i] !== "object")
                        throw TypeError(".uds_client_pb.UDSSequenceRequest.packages: object expected");
                    message.packages[i] = $root.uds_client_pb.UDSPackageRequest.fromObject(object.packages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UDSSequenceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {uds_client_pb.UDSSequenceRequest} message UDSSequenceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSSequenceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.packages = [];
            if (message.packages && message.packages.length) {
                object.packages = [];
                for (var j = 0; j < message.packages.length; ++j)
                    object.packages[j] = $root.uds_client_pb.UDSPackageRequest.toObject(message.packages[j], options);
            }
            return object;
        };

        /**
         * Converts this UDSSequenceRequest to JSON.
         * @function toJSON
         * @memberof uds_client_pb.UDSSequenceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSSequenceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSSequenceRequest
         * @function getTypeUrl
         * @memberof uds_client_pb.UDSSequenceRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSSequenceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.UDSSequenceRequest";
        };

        return UDSSequenceRequest;
    })();

    uds_client_pb.ECUResponses = (function() {

        /**
         * Properties of a ECUResponses.
         * @memberof uds_client_pb
         * @interface IECUResponses
         * @property {string} ecu ECUResponses ecu
         * @property {Array.<uds_client_pb.IudsRR>|null} [responses] ECUResponses responses
         */

        /**
         * Constructs a new ECUResponses.
         * @memberof uds_client_pb
         * @classdesc Represents a ECUResponses.
         * @implements IECUResponses
         * @constructor
         * @param {uds_client_pb.IECUResponses=} [properties] Properties to set
         */
        function ECUResponses(properties) {
            this.responses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ECUResponses ecu.
         * @member {string} ecu
         * @memberof uds_client_pb.ECUResponses
         * @instance
         */
        ECUResponses.prototype.ecu = "";

        /**
         * ECUResponses responses.
         * @member {Array.<uds_client_pb.IudsRR>} responses
         * @memberof uds_client_pb.ECUResponses
         * @instance
         */
        ECUResponses.prototype.responses = $util.emptyArray;

        /**
         * Creates a new ECUResponses instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {uds_client_pb.IECUResponses=} [properties] Properties to set
         * @returns {uds_client_pb.ECUResponses} ECUResponses instance
         */
        ECUResponses.create = function create(properties) {
            return new ECUResponses(properties);
        };

        /**
         * Encodes the specified ECUResponses message. Does not implicitly {@link uds_client_pb.ECUResponses.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {uds_client_pb.IECUResponses} message ECUResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUResponses.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ecu);
            if (message.responses != null && message.responses.length)
                for (var i = 0; i < message.responses.length; ++i)
                    $root.uds_client_pb.udsRR.encode(message.responses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ECUResponses message, length delimited. Does not implicitly {@link uds_client_pb.ECUResponses.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {uds_client_pb.IECUResponses} message ECUResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ECUResponses.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ECUResponses message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.ECUResponses} ECUResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUResponses.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.ECUResponses();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ecu = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.responses && message.responses.length))
                            message.responses = [];
                        message.responses.push($root.uds_client_pb.udsRR.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("ecu"))
                throw $util.ProtocolError("missing required 'ecu'", { instance: message });
            return message;
        };

        /**
         * Decodes a ECUResponses message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.ECUResponses} ECUResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ECUResponses.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ECUResponses message.
         * @function verify
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ECUResponses.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.ecu))
                return "ecu: string expected";
            if (message.responses != null && message.hasOwnProperty("responses")) {
                if (!Array.isArray(message.responses))
                    return "responses: array expected";
                for (var i = 0; i < message.responses.length; ++i) {
                    var error = $root.uds_client_pb.udsRR.verify(message.responses[i]);
                    if (error)
                        return "responses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ECUResponses message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.ECUResponses} ECUResponses
         */
        ECUResponses.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.ECUResponses)
                return object;
            var message = new $root.uds_client_pb.ECUResponses();
            if (object.ecu != null)
                message.ecu = String(object.ecu);
            if (object.responses) {
                if (!Array.isArray(object.responses))
                    throw TypeError(".uds_client_pb.ECUResponses.responses: array expected");
                message.responses = [];
                for (var i = 0; i < object.responses.length; ++i) {
                    if (typeof object.responses[i] !== "object")
                        throw TypeError(".uds_client_pb.ECUResponses.responses: object expected");
                    message.responses[i] = $root.uds_client_pb.udsRR.fromObject(object.responses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ECUResponses message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {uds_client_pb.ECUResponses} message ECUResponses
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ECUResponses.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.responses = [];
            if (options.defaults)
                object.ecu = "";
            if (message.ecu != null && message.hasOwnProperty("ecu"))
                object.ecu = message.ecu;
            if (message.responses && message.responses.length) {
                object.responses = [];
                for (var j = 0; j < message.responses.length; ++j)
                    object.responses[j] = $root.uds_client_pb.udsRR.toObject(message.responses[j], options);
            }
            return object;
        };

        /**
         * Converts this ECUResponses to JSON.
         * @function toJSON
         * @memberof uds_client_pb.ECUResponses
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ECUResponses.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ECUResponses
         * @function getTypeUrl
         * @memberof uds_client_pb.ECUResponses
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ECUResponses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.ECUResponses";
        };

        return ECUResponses;
    })();

    uds_client_pb.UDSPackageResponse = (function() {

        /**
         * Properties of a UDSPackageResponse.
         * @memberof uds_client_pb
         * @interface IUDSPackageResponse
         * @property {Array.<uds_client_pb.IECUResponses>|null} [ecuResponses] UDSPackageResponse ecuResponses
         */

        /**
         * Constructs a new UDSPackageResponse.
         * @memberof uds_client_pb
         * @classdesc Represents a UDSPackageResponse.
         * @implements IUDSPackageResponse
         * @constructor
         * @param {uds_client_pb.IUDSPackageResponse=} [properties] Properties to set
         */
        function UDSPackageResponse(properties) {
            this.ecuResponses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSPackageResponse ecuResponses.
         * @member {Array.<uds_client_pb.IECUResponses>} ecuResponses
         * @memberof uds_client_pb.UDSPackageResponse
         * @instance
         */
        UDSPackageResponse.prototype.ecuResponses = $util.emptyArray;

        /**
         * Creates a new UDSPackageResponse instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {uds_client_pb.IUDSPackageResponse=} [properties] Properties to set
         * @returns {uds_client_pb.UDSPackageResponse} UDSPackageResponse instance
         */
        UDSPackageResponse.create = function create(properties) {
            return new UDSPackageResponse(properties);
        };

        /**
         * Encodes the specified UDSPackageResponse message. Does not implicitly {@link uds_client_pb.UDSPackageResponse.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {uds_client_pb.IUDSPackageResponse} message UDSPackageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSPackageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ecuResponses != null && message.ecuResponses.length)
                for (var i = 0; i < message.ecuResponses.length; ++i)
                    $root.uds_client_pb.ECUResponses.encode(message.ecuResponses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDSPackageResponse message, length delimited. Does not implicitly {@link uds_client_pb.UDSPackageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {uds_client_pb.IUDSPackageResponse} message UDSPackageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSPackageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSPackageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.UDSPackageResponse} UDSPackageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSPackageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.UDSPackageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ecuResponses && message.ecuResponses.length))
                            message.ecuResponses = [];
                        message.ecuResponses.push($root.uds_client_pb.ECUResponses.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDSPackageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.UDSPackageResponse} UDSPackageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSPackageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSPackageResponse message.
         * @function verify
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSPackageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ecuResponses != null && message.hasOwnProperty("ecuResponses")) {
                if (!Array.isArray(message.ecuResponses))
                    return "ecuResponses: array expected";
                for (var i = 0; i < message.ecuResponses.length; ++i) {
                    var error = $root.uds_client_pb.ECUResponses.verify(message.ecuResponses[i]);
                    if (error)
                        return "ecuResponses." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UDSPackageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.UDSPackageResponse} UDSPackageResponse
         */
        UDSPackageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.UDSPackageResponse)
                return object;
            var message = new $root.uds_client_pb.UDSPackageResponse();
            if (object.ecuResponses) {
                if (!Array.isArray(object.ecuResponses))
                    throw TypeError(".uds_client_pb.UDSPackageResponse.ecuResponses: array expected");
                message.ecuResponses = [];
                for (var i = 0; i < object.ecuResponses.length; ++i) {
                    if (typeof object.ecuResponses[i] !== "object")
                        throw TypeError(".uds_client_pb.UDSPackageResponse.ecuResponses: object expected");
                    message.ecuResponses[i] = $root.uds_client_pb.ECUResponses.fromObject(object.ecuResponses[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UDSPackageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {uds_client_pb.UDSPackageResponse} message UDSPackageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSPackageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ecuResponses = [];
            if (message.ecuResponses && message.ecuResponses.length) {
                object.ecuResponses = [];
                for (var j = 0; j < message.ecuResponses.length; ++j)
                    object.ecuResponses[j] = $root.uds_client_pb.ECUResponses.toObject(message.ecuResponses[j], options);
            }
            return object;
        };

        /**
         * Converts this UDSPackageResponse to JSON.
         * @function toJSON
         * @memberof uds_client_pb.UDSPackageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSPackageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSPackageResponse
         * @function getTypeUrl
         * @memberof uds_client_pb.UDSPackageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSPackageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.UDSPackageResponse";
        };

        return UDSPackageResponse;
    })();

    uds_client_pb.UDSSequenceResponse = (function() {

        /**
         * Properties of a UDSSequenceResponse.
         * @memberof uds_client_pb
         * @interface IUDSSequenceResponse
         * @property {Array.<uds_client_pb.IUDSPackageResponse>|null} [packages] UDSSequenceResponse packages
         */

        /**
         * Constructs a new UDSSequenceResponse.
         * @memberof uds_client_pb
         * @classdesc Represents a UDSSequenceResponse.
         * @implements IUDSSequenceResponse
         * @constructor
         * @param {uds_client_pb.IUDSSequenceResponse=} [properties] Properties to set
         */
        function UDSSequenceResponse(properties) {
            this.packages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UDSSequenceResponse packages.
         * @member {Array.<uds_client_pb.IUDSPackageResponse>} packages
         * @memberof uds_client_pb.UDSSequenceResponse
         * @instance
         */
        UDSSequenceResponse.prototype.packages = $util.emptyArray;

        /**
         * Creates a new UDSSequenceResponse instance using the specified properties.
         * @function create
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {uds_client_pb.IUDSSequenceResponse=} [properties] Properties to set
         * @returns {uds_client_pb.UDSSequenceResponse} UDSSequenceResponse instance
         */
        UDSSequenceResponse.create = function create(properties) {
            return new UDSSequenceResponse(properties);
        };

        /**
         * Encodes the specified UDSSequenceResponse message. Does not implicitly {@link uds_client_pb.UDSSequenceResponse.verify|verify} messages.
         * @function encode
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {uds_client_pb.IUDSSequenceResponse} message UDSSequenceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSSequenceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.packages != null && message.packages.length)
                for (var i = 0; i < message.packages.length; ++i)
                    $root.uds_client_pb.UDSPackageResponse.encode(message.packages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UDSSequenceResponse message, length delimited. Does not implicitly {@link uds_client_pb.UDSSequenceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {uds_client_pb.IUDSSequenceResponse} message UDSSequenceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UDSSequenceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UDSSequenceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uds_client_pb.UDSSequenceResponse} UDSSequenceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSSequenceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.uds_client_pb.UDSSequenceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.packages && message.packages.length))
                            message.packages = [];
                        message.packages.push($root.uds_client_pb.UDSPackageResponse.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UDSSequenceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uds_client_pb.UDSSequenceResponse} UDSSequenceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UDSSequenceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UDSSequenceResponse message.
         * @function verify
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UDSSequenceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.packages != null && message.hasOwnProperty("packages")) {
                if (!Array.isArray(message.packages))
                    return "packages: array expected";
                for (var i = 0; i < message.packages.length; ++i) {
                    var error = $root.uds_client_pb.UDSPackageResponse.verify(message.packages[i]);
                    if (error)
                        return "packages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a UDSSequenceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uds_client_pb.UDSSequenceResponse} UDSSequenceResponse
         */
        UDSSequenceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.uds_client_pb.UDSSequenceResponse)
                return object;
            var message = new $root.uds_client_pb.UDSSequenceResponse();
            if (object.packages) {
                if (!Array.isArray(object.packages))
                    throw TypeError(".uds_client_pb.UDSSequenceResponse.packages: array expected");
                message.packages = [];
                for (var i = 0; i < object.packages.length; ++i) {
                    if (typeof object.packages[i] !== "object")
                        throw TypeError(".uds_client_pb.UDSSequenceResponse.packages: object expected");
                    message.packages[i] = $root.uds_client_pb.UDSPackageResponse.fromObject(object.packages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a UDSSequenceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {uds_client_pb.UDSSequenceResponse} message UDSSequenceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UDSSequenceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.packages = [];
            if (message.packages && message.packages.length) {
                object.packages = [];
                for (var j = 0; j < message.packages.length; ++j)
                    object.packages[j] = $root.uds_client_pb.UDSPackageResponse.toObject(message.packages[j], options);
            }
            return object;
        };

        /**
         * Converts this UDSSequenceResponse to JSON.
         * @function toJSON
         * @memberof uds_client_pb.UDSSequenceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UDSSequenceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UDSSequenceResponse
         * @function getTypeUrl
         * @memberof uds_client_pb.UDSSequenceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UDSSequenceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uds_client_pb.UDSSequenceResponse";
        };

        return UDSSequenceResponse;
    })();

    return uds_client_pb;
})();

module.exports = $root;
