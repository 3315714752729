import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  const { companyLogo } = props;
  if (companyLogo) {
    const href = 'data:image/png;base64,' + companyLogo;
    return (
      <svg
        width="42"
        height="42"
        viewBox="0 0 42 42"
        xmlns="http://www.w3.org/2000/svg"
      >
        <image id="image0" width="42" height="42" x="0" y="0" href={href} />
      </svg>
    );
  }

  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <image
        id="image0"
        width="42"
        height="42"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWX
        MAABJ0AAASdAHeZh94AAAOnklEQVR4Xu2dCXRU1RnH75t9QsKipEWoBaJWRTwulABxKUeqzb5pwIjHKgZJ
        pKwSEIiobLKpEEQxbBWRoCxZQay1ihBJIFptqy0iiodWUUAgk8zMm8nM6/++zBJmXpL3Zn1p35zj0QP33e
        X/3e/+3ne/b0ZClI+igKKAooCigKKAooCigKKAooCigKKAooCigKKAooCigKKAooCigKKAokBUFHDaWS37
        7so11tqnt7ee+mtS+0mwB9Yuall797+ttQtei8rk/h8HNW8aV9c0VceZZsZyzSUDOceZE1dRHdg/r1rWNM
        3AmWbHc01TNJx1T/HO7qaPqrtN2Foxe1vr36qTVD37EWLsQ5w/fUu4pu8H0nVwP307lFFrCaMzEgZ/b6//
        433Wqnk7utMau5VBLOVFtfZDZeOZ2L6E4zhC7BaiTrjtDNP36k+o6No7JxdTQ3D4c4bB0jQ6Yv9w3ThL5Z
        xu4yndxiDW8qK99votacQQB7EZGMNMVPFXnTYUvDVE3avfBWoQdb/r/mksrLmG6dHXydmthFGpCW3fenA9
        PGXuW93BU7qFQawVxW/YPy5PZWLjeWNwbAthYi6zGx4pv1Xde8DZ9kLDKF8ZHto6itEY4CkwCvUUfSyx12
        3Mw3G3W+5GwVaT98e8cezh1r/XjKTHlNsYqv5DvzIW7L5VFdfX1NHsnebzvcxLb7rAG0UL43BOwpl+JLrR
        06oNuSuz5LpqWXsIdvRWagxVXDyvH2eDZ/S4zG4s2DWsM2PQtqqYPhcNE3cnor2HKUzczyjoMwF62XqKbD
        0EAK9sbSzPogzgP9jpqr4Jpw0Tym9R499id7jj9L8SLK9mnuDM5+EpRsI5HXg/biaaOwr3GrOXp4vtJ1Lt
        ZOkhAHglAM4bwwvwhLOGxyqGSjGGC/RfGydVU9C3McUL+jSAviZSQosdR3YGAcC3AuBZPgB3AuA3q3v3Py
        d2YQKgHyEA+nQci/sD6TNcz8jqyALAD4IZt/sA/KQL4OeDFaET0O8H6FOC7T8Uz8vGQ7BTX6PG8AG4AwC/
        GQAP2hjtQD9CAPTJAL0sji9ZeAgAXgGAZ/sA/AwAfjOY8V0odl77PgD6qwH64wKgf9uQtSwN3MI1QHQ+mu
        gM6x0VAN9jq9+S7WEGXm1V8VefNkysuB7MuBCO+dHgEUa5xlKWc5xrOdcWp7RF9Ckso9qLMVPDMa6YPqN6
        ZAHgmwHwHIEIfFi4jOEWxRXRjxQAfQqOz324K4vK6RGVQakoAPj7YMZoH4AfA8CHdxX0idlpYtsA9HGI6J
        sEIvp9AH2a2H5C1S4qHoIduIUawwfgFgA8MZLGcIHehIh+lADoUwH6iF9IRtxDLOWF1a2NOzJ8AH4KAE+U
        GvSFalfSfsCUXwH0xy4FvYlox8zaYkhdMCGUY3XWV0QN4mi5oGuZ0Ztl4q9suyhsA/h3APhQMCMkr7bBCM
        cbpSznmAf0Djs/z9il30VMp4geWeoevW1Mrz78XRJnuUiv0GkEPkoOxqCGBOi/xNV9EqPrQaincM1nifra
        334YjJFl/6zjx+ODzJvvrzdvGV/vuHj6cjlOuPVE3Z3Wmvm72HeWrZfj/JQ5/S8owO5f+oJ5Y95nlq2/P+
        Bej/P8f/q1rL7ruGlef852aMP87rbOlnWp9c3zr+SQo98RrTglIM1sDVsnm2b14UzFl3O0XMdaPf912lHL
        qlGnTE/04kxP9kOpTl+O/cvqFQENEMaHbEe2F7Ysup5rXngtx35Q+rx7KMu2R/ebpsdwprlXcHQNuLovD4
        dRQg51W2P5o2xF8UsEySBG34MwuJJwnKgbSRfGnf36FwS5bgbVILQixLZ/cTF7ePPsMOorqevWk0dHsVWz
        X3E2nwPULxBb9byZDouJf8NynjnxS4IrFlpm5MrR389WztkVaqOE1CC2o9sLrJvyNxKVBhPXEA6vjQTXdP
        rclePpogyTqpMI/fNWGxJFuEbT6Ilt57Tl7HvPr5GkXBga27/Yn2VZe9dHBBlFereFBbhH4f/DULAzkdHH
        2j1JLi3m/v7qXLZidkUopxMyg9gaXi9kd8/YwPQZwGfleNHxDq8fu/YRzcDhR+ikNYNHHDaM35hJVCreWG
        3Zu57E9u7Kqex7L5SGcmFS+rJ/8U4WW15YSXSxvAfwaV6Hjeiyls9XG+NaaV/quPhmV47e5q77cuXosxDR
        h8xTQhLw2I9sK7S+OfkVgvd33jN4D1ATff76XO2NGX47CEdDoqV0TAPRx6C9rk0AaxPR37dmni5pwnNSxA
        y2LeZyG+ZyyDsX6G810blMwVxe8u0fweMgRPRfI05hfHL01cjRB13NErSHAOCP88ZA7ZPnmIIH6PLX3ydk
        DN5TBg0/giMgnb9pxU50ewpbOXspQP9CsCKLfd7++ds5lrIsGINuJLoxYAynk+jSnl0oZAzeU/pdd7KDHD
        2qWebuCZYpQRkEAC8AwNfxnkGPKcoM/KPPeX6i7saMTktttEN+txcelElsZu/x1Qb6GZEAPTwjyfpGwR5q
        AM8xBc/Q3V28SD9m5tOdGRVGOYGIXihHnwPQV4ndEELtAj6yAPCJ1s0PlHmY4TKGsbD6Tk1C0kGxk4Iwwy
        1l2Ud4mMIg7uNLl/bMGv2YJ6aL7UdKOwpw66Y8LzMc8AzKjJwVf9CPmrBObF+4uu+Jq/uLAlf3tfqcFZmB
        ZB4D8hAAvAgA9xrDDfC80olSjOE6vo4C9BkCoJ8G0If8+ALAMwUBnlzyjBRj0LmjGK8JoB+Oq3vOB/TpbP
        X8gN6+JHtIJwDPAzN2id1dvu3gKb8GXI8KgH4WznNPgBZo//Q5GmdgjI8EAD4VY6wNtG+AfrCrGM8X9DXI
        0WdJ8RRJHkIj8A4AHpQxXJ7SCNCnCoB+FUC/LFCx3M8B4NkAOIzhB/BFwRjDBfpvAPqrBKruM9jqeZVS5i
        7aIAD4RD4C9wf4JAA8YM9oP1mA/m2APk0A9HMA+hlSFta+LTxjJABeIQDwxQD4gkD7bf8cQP9NB6DPRIa0
        Vuzbl6gjCwCfBICvDxbgYhcOAYcB9I0CoF8F0BeL7Ye2CxXAxY7ZCej3AfTpXR1fXXoIAP44AO41hhfghV
        IBLnZRiFM+7gD0swD65WL7AcDTAPBdfhF4csmzUgEudsx2oPetuk8F6Cu78pROPQQALwIzXhaIwINmhpgF
        8q/EpWOOCIB+Bs791Z314X8b0HkELmY+Utq4QE8jer+qe4A+oyNP6dBDAPA2Y/hH4OOCeZuSsih4ylGAPk
        MA9C8C9As76gsAT5cagUuZl5i2lCkAfQJAj1fiS6vuAfqqjjxF0CAA+AQAvM0zLo3AiwDwiJbGAPS1AH2W
        AOifAuin+opDvQoAfwsA1/pE4Au7isDFCC2ljQv0iQLFeBmI6PcJ9eV3ZNk+fvNh64ZxW5jLXLe23gh8NJ
        jhyf5JmVgo2kLoEQB9/SWgR6GEIX99kTbxQT73DYBnIAKv9jDDG4FPBjNeDsU8AulDsOr+4vdEn7nkNf09
        Tz7cvk8/D7EffLWE6YkvV7a/Qs8rLYqmMeiEcXw1APTZ7oged/vIWTDEcbKBry50/PDlYOu2RysEAL4gms
        agc3N9vY5G9Dzo6YeJ6UNav/jTOF8D+xlEM+SeGoJ6KZoxo99g1ee/OlY3/AFZVF9ob0ipMj5WNZKzXEDY
        zSIjGUc0N+Xwc+PM5/rjBwTUNDnG39rS6/zsFdP1d01fFMiuDvUzyAk1Gh+rTECSi/96Hj2CdXdMmiNqHP
        bAuhLrzum19s/3ZYt6IMKNbJ/uechWt/FJmlhyD+1s+Ulv3nBvQ9MULUfz+Xg9ll2+ns619csP7sbcFyK2
        mxlh2aIzXOupT2/AKyf/2yfd8dNlYCjXReEHZwY4fjg2WGB+9EUlal+4kateYZ2X/bOq/OYFCVzzUwM5y/
        ZJte7BcGQdbndkPRPWSYSpc1F3WWEaO6Bu+Sv0tbhC18bgVQXFEs1nSMycxqEgOTEvH/YPptcA+IfTnaOf
        hog+asUTgSywWx1ZiMCz+Ct0GrDS2i765mvsRct2WvAaeYbpeQVHnN5qFuTo1yCiXxyIMNF6ptsYRPgKvY
        nokksWqn9+7Un15YN+NDy4KVsgop+PiH5WtASWOm63OLI6uUKfiqDvkkwfNRwi+sORzNFLFb2z9rL3EFcO
        fI9ABE5z4H5pV0T09YjoczvI0b8YSvHC0ZesPYTf7aVjDgeSA6dlPni2TuDqfg5AL8ugkRpYth7iAjiM4Z
        cDXyImBw5P+QhX97kCV/fLAXrRSa5weEFnfcrSQ/jkUll2gwAHnkMKd54UkcCfXOumsbvbkmy0bhf3XMh6
        ogZrJo482R1hsjMIBEzHFXqNwBX6NAgYUEzB1++WZR8SMPBKGFg2X4eQ3ZEFgKcgB+7NZ7ir0JNLlgRqDL
        pIHF91AP29AqAvxiVkRIu7u/Ju2XiIf6GcJwfeZf68q0W6/96/0t1TdT8dXIr6d1Rk4yEAeCoicFQt+gH8
        ua6KGcQaw+MpBTtzBEC/GqCXRd4k6h7Ce0ZZ9tFQAFysccCpewF6lAf5gd4v0BTbZ6jaRdUgECYFAN8nAP
        CwC4ONcDs2wsFIbgQxRotaHAKAJwPgXmN4Ab5IKAIXsxgpbQD6QwD9OAHQzwXol0jpK5Rto+Ih2J23IIr+
        RCACj/h1OebyG8zlA4GIPqiK+ECNFHEPAcBTAHAYww/gi6ORu4CnHEBEny8A+lLUFswNVNhAn4uohzisLY
        x5wUAn/7XpS78ttQQBWkmgiwjFc+DZ/QB9uQf0tKaLbSIxS77X4Ju4eD+OzCeiHsJwDi2tdSV8NSS9wmCJ
        Lm/NlGgbg0qtHZK8wzjlvTv4udEyHc5jg4hu2siYvd0oqABfR/8vOE34mQq8+8vu907w0xpF7X5aQ1ZRfN
        iM5bQ0qZxWE36jQvkoCigKKAooCigKKAooCigKKAooCigKKAooCigKKAooCigKKAooCigKKAooCigKKAoo
        CigKRFWB/wLAAV0InDOqfQAAAABJRU5ErkJggg=="
      />
    </svg>
  );
})``;

Logo.defaultProps = {
  variant: 'primary',
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary']),
};
