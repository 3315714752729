import axios from './base';

const prefix = '/v1/vehicle';

class VehicleApi {
  async updateInstance(id: string, request: object) {
    return axios
      .post(`${prefix}/${id}/updateInstance`, request)
      .then((response) => response);
  }

  async deleteInstance(id: string) {
    return axios
      .delete(`${prefix}/${id}/deleteInstance`)
      .then((response) => response);
  }

  async createActivity(vehicleInstanceId: string, newActivity: object) {
    return axios
      .post(`${prefix}/${vehicleInstanceId}/createActivity`, newActivity)
      .then((response) => response);
  }

  async deleteActivity(id: string) {
    return axios
      .delete(`${prefix}/${id}/deleteActivity`)
      .then((response) => response);
  }

  async getECUList(id: string) {
    return axios
      .get(`${prefix}/${id}/getECUList`)
      .then((response) => response);
  }

}

export const vehicleApi = new VehicleApi();