// TODO: rename to common.ys or so
import { filter, uniq, uniqBy } from 'lodash';
import { isInNativeMode } from 'src/utils/native';
import { AVAILABLE_SCREENS as AvailableScreens } from './constants';

const ObjectTypePrefix = {
  Item: 'I',
  VehicleItem: 'VI',
  DomainItem: 'DI',
  ECUItem: 'EI',
  ECUItemDetails: 'EID',
  ECUAddress: 'EA',
  LibraryItem: 'LI',
  ItemRevision: 'IR',
  VehicleItemRevision: 'VIR',
  DomainItemRevision: 'DIR',
  ECUItemRevision: 'EIR',
  LibraryItemRevision: 'LIR',
  RevisionRule: 'RR',
  RevisionRuleEntry: 'RRE',
  VariantRule: 'VR',
  VariantValue: 'VV',
  VariantCondition: 'VC',
  VariantOption: 'VO',
  BOMViewRevision: 'BVR',
  BOMLine: 'BL',
  Occurrence: 'OC',
  HardwareRevision: 'HWR',
  SoftwareRevision: 'SWR',
  SoftwareAddress: 'SWA',
  SoftwareListType: 'SWLT',
  Software: 'SW',
  JenkinsJob: 'JJ',
  Package: 'P',
  PackageVersion: 'PV',
  PackageImage: 'PI',
  PackageManifest: 'PM',
  PackageAssembly: 'PA',
  Dataset: 'DS',
  ImageDataset: 'IDS',
  ReleaseStatus: 'RS',
  EEArch: 'EEA',
  ReleaseVersion: 'RA',
  VehicleNode: 'VN',
  DomainNode: 'DN',
  ECUNode: 'EN',
  LibraryNode: 'LN',
  TemplateNode: 'TN',
  DID: 'DID',
  DTC: 'DTC',
  DIDNumber: 'DIDN',
  DIDFormat: 'DIDF',
  ROUTINES: 'ROUT',
  UDS: 'UDS',
  User: 'U',
  Group: 'G',
  Role: 'R',
  Notification: 'N',
  Subscription: 'S',
  Key: 'K',
  App: 'A',
  VehicleInstance: 'VTI',
  VehicleActivity: 'VTA',
  VehicleECUStatus: 'VES',
  AccessToken: 'FFGAT',
  OTAPackage: 'OTA',
  SystemSetting: 'SS',
  TenantSetting: 'TS',
  UserSetting: 'US',
};

const jiraComponentsNameToId = {
  admin: '17302',
  vehicle: '17202',
  vendor: '17301',
  tracker: '17300',
  gateway: '17400',
};

export function stringToColor(inputString: string) {
  let hash = 0;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to a 6-digit hex color code
  const color = ((hash & 0x00ffffff) | 0x1000000).toString(16).slice(1);

  return `#${color}`;
}

export const getReportIssueUrl = () => {
  const isFlash = false; // getActiveScreen()?.value === 'flash';
  const pid = isFlash ? '18300' : '18001';
  return (
    'https://jira.faradayfuture.com/secure/CreateIssueDetails!init.jspa?' +
    `pid=${pid}&issuetype=4${isFlash ? '' : '&components=' + getJiraComponentFromLocation()
    }`
  );
};

export const getJiraComponentFromLocation = () => {
  return (
    jiraComponentsNameToId?.[getActiveScreen()?.value || 'tracker'] || '17300'
  ); // tracker is by default
};

export const ObjectTypePrefixToRequestParam = {
  VehicleNode: 'vehicles',
  DomainNode: 'domains',
  ECUNode: 'ecus',
  LibraryNode: 'libraries',
  ReleaseVersion: 'releaseVersions',
  TemplateNode: 'templates',
  DIDFormat: 'didFormats',
  DIDNumber: 'didNumbers',
};

export const openExternalURLCrossplatform = (url: string) => {
  if (isInNativeMode()) {
    (window as any)?.nativeUtils?.openExternal(url);
  } else {
    window.open(url, '_blank');
  }
};

export const runNFCReaderFlasher = (
  masterKey: string,
  ecuId: number,
  newVin: string,
) => {
  if (isInNativeMode()) {
    (window as any)?.nativeUtils?.runNFCReaderFlasher(masterKey, ecuId, newVin);
  }
};

export const runNFCCardFlasher = (
  masterKey: string,
  program: number,
  newVin: string,
  oldVin: string,
) => {
  if (isInNativeMode()) {
    (window as any)?.nativeUtils?.runNFCCardFlasher(
      masterKey,
      program,
      newVin,
      oldVin,
    );
  }
};

export const sortByVersionNumber = (versions: any[], field = 'name') => {
  return [...versions].sort((a: any, b: any) => {
    const aName2 = parseInt(a[field].split('_')?.[1] || a);
    const bName2 = parseInt(b[field].split('_')?.[1] || b);
    if (aName2 > bName2) {
      return -1;
    }
    if (aName2 < bName2) {
      return 1;
    }
    return 0;
  });
};

export const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxy'.replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
};

export const createObjectId = (type?: string) => {
  const prefix = ObjectTypePrefix[type] || type;
  const uniqToken = Math.random().toString(36).slice(-4);
  const id = `${prefix}_${Date.now()}-${uniqToken}`;
  return id;
};

export const createResourceId = () => {
  const arr = new Uint8Array(12);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (v) => v.toString(16).padStart(2, '0')).join('');
};

export const createShortUniqueId = (len: number) => {
  const arr = new Uint8Array(Math.round(len / 2));
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (v) => v.toString(16).padStart(2, '0'))
    .join('')
    .substring(0, len);
};

export const getEnv = () => {
  console.log('running on: ', process.env.deployment);
  if (process.env.deployment === 'prod') {
    return {
      env: 'prod',
      version: process.env.version,
      apiHost: 'https://api.galaxy.ff-tools.link',
      projectId: 'et-object-table.prod',
      vaultId: 'et-object-table.prod',
      dbId: 'et-object-table.prod',
    };
  } else {
    return {
      env: 'dev',
      version: process.env.version,
      apiHost: 'https://api.dev.galaxy.ff-tools.link',
      projectId: 'et-object-table.dev',
      vaultId: 'et-object-table.dev',
      dbId: 'et-object-table.dev',
    };
  }
};

export const getActiveScreen = () => {
  // Possible values: gateway, tracker, vendor, flash, mlops
  let screen = window?.location?.pathname?.split('/')[2] || '';
  if (screen === 'apps') {
    screen = window?.location?.pathname?.split('/')[3] || '';
  }
  return getScreen(screen);
};

export const getScreen = (screenId) => {
  let screens = getScreens(getActiveUser());
  let screen = screens.filter((v) => v.value === screenId);
  return screen[0] || screens[0] || [];
};

export const getActiveUser = () => {
  let user = null;

  try {
    const storedData = window.localStorage.getItem('activeUser');
    if (storedData) {
      user = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return user;
};

export const updateActiveUser = (user: any) => {
  try {
    localStorage.setItem('activeUser', JSON.stringify(user));
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }
};

const getScreensPerRole = (roleAction: string) => {
  const screensPerRole = {
    'screen:admin': filter(AvailableScreens, (i) => i !== null), // filter not empty
    'screen:tracker': [
      ,
      {
        label: 'ECU Tracker',
        value: 'tracker',
        priority: 3,
      },
    ],
    'screen:vehicle': [
      {
        label: 'Vehicle Systems',
        value: 'vehicle',
        priority: 4,
      },
    ],
    'screen:flash': [
      {
        label: 'Flash Station',
        value: 'flash',
        priority: 5,
      },
    ],
    'screen:vendor': [
      {
        label: 'Vendor Portal',
        value: 'vendor',
        priority: 6,
      },
    ],
    'screen:mlops': [
      {
        label: 'ML Ops',
        value: 'mlops',
        priority: 7,
      },
    ],
    'screen:askgalaxy': [
      {
        label: 'Ask Galaxy',
        value: 'askgalaxy',
        priority: 8,
      },
    ],
    'screen:predictor': [
      {
        label: 'Predictive Maintenance',
        value: 'predictor',
        priority: 9,
      },
    ],
    'screen:dem': [
      {
        label: 'Diagnostic Events Manager',
        value: 'dem',
        priority: 10,
      },
    ],
  };
  return screensPerRole[roleAction?.split?.(':')?.splice?.(0, 2)?.join?.(':')];
};

export const getScreens = (user: any) => {
  if (!user) {
    return [];
  }

  let screens = [];
  let role = user?.defaultRole;

  screens.push({
    label: 'Gateway',
    value: 'gateway',
    priority: 1,
  });

  // Show screens only fro default role
  const roleActions = role?.actions || [];
  for (let roleAction of roleActions) {
    screens.push(...(getScreensPerRole(roleAction) || []));
  }

  // TODO: comment if no need to show screens from all roles
  for (let otherRoleAction of uniq(
    user?.groups
      ?.map((g) => g?.roles)
      ?.flat()
      ?.map((r) => r?.actions)
      ?.flat() || [],
  )) {
    screens.push(...(getScreensPerRole(otherRoleAction as string) || []));
  }

  screens = uniqBy(screens, 'value');
  screens = screens.filter(function (element) {
    return element !== undefined;
  });

  screens.sort(function (screenA, screenB) {
    return screenA.priority - screenB.priority;
  });

  screens = uniqBy(screens, 'value');

  return screens;
};

export const programNFC = (activityValue: any) => {
  if (activityValue) {
    if (activityValue.needFlashReaderBPillar) {
      runNFCReaderFlasher(
        activityValue.nfcMasterKey,
        activityValue.readerProgramBPillar,
        activityValue.newVINBPillar,
      );
    }
    if (activityValue.needFlashCardBPillar) {
      runNFCCardFlasher(
        activityValue.nfcMasterKey,
        activityValue.cardProgramBPillar,
        activityValue.newVINBPillar,
        activityValue.oldVINBPillar,
      );
    }

    if (activityValue.needFlashReaderCConsole) {
      runNFCReaderFlasher(
        activityValue.nfcMasterKey,
        activityValue.readerProgramCConsole,
        activityValue.newVINCConsole,
      );
    }
    if (activityValue.needFlashCardCConsole) {
      runNFCCardFlasher(
        activityValue.nfcMasterKey,
        activityValue.cardProgramCConsole,
        activityValue.newVINCConsole,
        activityValue.oldVINCConsole,
      );
    }
  }
};
