import moment from 'moment';
import {
  Package,
  PackageVersion,
} from 'src/tools/tracker/models/package-models';
import { User } from 'src/tools/tracker/models/user';
import { createObjectId } from './utils';

const getActiveUser = (): User => {
  const activeUser: User = JSON.parse(localStorage.getItem('activeUser'));
  return activeUser;
};

export const preparePackage = (pkg: Package) => {
  const activeUser = getActiveUser();
  const newPackage: Package = {
    id: pkg.id || createObjectId('Package'),
    type: 'Package',
    name: pkg.name,
    createdAt: pkg.createdAt || moment().toISOString(),
    createdBy: pkg.createdBy || activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    level: pkg.level,
    group: pkg.group,
    builtVersion: pkg.builtVersion,
    images: pkg.images || [],
    internalVersions: pkg.internalVersions || [],
    versions: pkg.versions || [],
  };

  return newPackage;
};

export const preparePackageVersion = (packageVersion: PackageVersion) => {
  const activeUser = getActiveUser();
  const newVersion: PackageVersion = {
    id: packageVersion.id || createObjectId('PackageVersion'),
    type: 'PackageVersion',
    name: packageVersion.name || `00_${Math.round(Math.random() * 100) + 44}`,
    createdAt: packageVersion.createdAt || moment().toISOString(),
    createdBy: packageVersion.createdBy || activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    promoted: packageVersion.promoted,
    promotedBy: activeUser.email,
    promotedAt: moment().toISOString(),
    notes: '',
  };

  return newVersion;
};
