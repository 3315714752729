//import { DomainItem, ECUItem, Item, LibraryItem, VehicleItem } from "src/models/objects-item";
import moment from 'moment';
import {
  Dataset,
  ECUAddress,
  HardwareRevision,
  Item,
  ItemRevision,
  JenkinsJob,
  Software,
  SoftwareAddress,
} from 'src/tools/tracker/models/object-models';
import {
  BOMLine,
  Occurrence,
  VariantRule,
  VariantValue,
} from 'src/tools/tracker/models/structure-models';
import { User } from 'src/tools/tracker/models/user';
import { createObjectId, createShortUniqueId } from './utils';

const DEFAULT_NEW_SEP = '_';

const getNextNumber = (rows: string[]): number => {
  const numbers = rows.map(
    (r: string) => parseInt(r.split(DEFAULT_NEW_SEP)?.[1] || r) || 0,
  );
  return numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
};

const getActiveUser = (): User => {
  const activeUser: User = JSON.parse(localStorage.getItem('activeUser'));
  return activeUser;
};

export const prepareItemAndItemRevision = (
  type: string,
  item: Item,
  itemRev: ItemRevision,
) => {
  const newItem = {};
  const newItemRev = {};
  let keysOfItemProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'baseType',
    'level',
    'group',
    'otaRequired',
    'owner',
    'notes',
    'revisions',
  ];
  let keysOfItemRevProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'baseType',
    'itemId',
    'revision',
    'enabled',
    'sinceVersion',
    'endVersion',
    'pNumber',
  ];
  switch (type) {
    case 'Vehicle':
      keysOfItemProps = [...keysOfItemProps, 'model', 'year'];
      break;
    case 'Domain':
      break;
    case 'ECU':
      keysOfItemProps = [
        ...keysOfItemProps,
        'masterECU',
        'longName',
        'source',
        'usage',
        'needsUDSFlashing',
        'diagnosticLevel',
        'bus',
        'daily',
        'supportDTCRead',
        'supportDIDSpec',
        'extendedCan',
        'supportUDS',
        'vendor',
        'supportSWBLRead',
        'supportSWAppRead',
        'supportSWCalRead',
        'supportHWVersionRead',
        'supportHWPartNumberRead',
      ];
      break;
    case 'Library':
      break;
    default:
      break;
  }
  if (item) {
    for (const prop of keysOfItemProps) {
      newItem[prop] = (<any>item)[prop];
    }
  }

  if (itemRev) {
    for (const prop of keysOfItemRevProps) {
      newItemRev[prop] = (<any>itemRev)[prop];
    }
  }
  const activeUser = getActiveUser();

  if (newItem['usage'] === 'Daily') {
    newItem['name'] = `${newItem['group']}-DAILY`;
  } else if (newItem['usage'] === 'Delta') {
    newItem['name'] = `${newItem['group']}-DELTA`;
  }

  newItem['createdAt'] = item.createdAt || moment().toISOString();
  newItem['createdBy'] = item.createdBy || activeUser.email;
  newItem['modifiedAt'] = moment().toISOString();
  newItem['modifiedBy'] = activeUser.email;
  newItem['type'] = `${type}Item`;
  newItem['id'] = item.id || createObjectId(newItem['type']);

  newItemRev['name'] = `${newItem['name']}_${newItemRev['revision']}`;
  newItemRev['itemId'] = newItem['id'];
  newItemRev['createdAt'] = itemRev.createdAt || moment().toISOString();
  newItemRev['createdBy'] = itemRev.createdBy || activeUser.email;
  newItemRev['modifiedAt'] = moment().toISOString();
  newItemRev['modifiedBy'] = activeUser.email;
  newItemRev['type'] = `${type}ItemRevision`;
  newItemRev['id'] = itemRev.id || createObjectId(newItemRev['type']);

  return { item: newItem, itemRevision: newItemRev };
};

export const prepareItem = (type: string, item: Item) => {
  const newItem = {};
  let keysOfItemProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'baseType',
    'level',
    'group',
    'otaRequired',
    'owner',
    'notes',
    'revisions',
  ];
  switch (type) {
    case 'Vehicle':
      keysOfItemProps = [...keysOfItemProps, 'model', 'year'];
      break;
    case 'Domain':
      break;
    case 'ECU':
      keysOfItemProps = [
        ...keysOfItemProps,
        'masterECU',
        'longName',
        'source',
        'usage',
        'needsUDSFlashing',
        'diagnosticLevel',
        'bus',
        'daily',
        'supportDTCRead',
        'supportDIDSpec',
        'extendedCan',
        'supportUDS',
        'hardwareRevisions',
        'softwareAddresses',
        'softwareListTypes',
        'softwares',
        'vendor',
        'supportSWBLRead',
        'swBLDIDNumber',
        'swBLDIDFormat',
        'supportSWAppRead',
        'swAppDIDNumber',
        'swAppDIDFormat',
        'supportSWCalRead',
        'swCalDIDNumber',
        'swCalDIDFormat',
        'supportHWVersionRead',
        'hwVerDIDNumber',
        'hwVerDIDFormat',
        'supportHWPartNumberRead',
        'hwPnDIDNumber',
        'hwPnDIDFormat',
        'gateway',
      ];
      break;
    case 'Library':
      break;
    default:
      break;
  }
  if (item) {
    for (const prop of keysOfItemProps) {
      newItem[prop] = (<any>item)[prop];
    }
  }
  const activeUser = getActiveUser();

  // if (newItem['daily']) {
  //   newItem['name'] = `${newItem['group']}-DAILY`;
  // } else {
  //   newItem['name'] = newItem['group'];
  // }

  newItem['createdAt'] = item.createdAt || moment().toISOString();
  newItem['createdBy'] = item.createdBy || activeUser.email;
  newItem['modifiedAt'] = moment().toISOString();
  newItem['modifiedBy'] = activeUser.email;
  newItem['type'] = `${type}Item`;
  newItem['id'] = item.id || createObjectId(newItem['type']);

  return newItem;
};

export const prepareItemRevision = (itemRev: ItemRevision) => {
  const newItemRev = {};
  let keysOfItemRevProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'baseType',
    'itemId',
    'revision',
    'hardwareRevision',
    'enabled',
    'sinceVersion',
    'endVersion',
    'pNumber',
    'ecuAddresses',
  ];
  if (itemRev) {
    for (const prop of keysOfItemRevProps) {
      newItemRev[prop] = (<any>itemRev)[prop];
    }
  }
  const activeUser = getActiveUser();
  const itemName = itemRev.name.substring(0, itemRev.name.indexOf('_'));
  newItemRev['id'] = itemRev.id || createObjectId(newItemRev['type']);
  newItemRev['name'] = `${itemName}_${newItemRev['revision']}`;
  newItemRev['createdAt'] = itemRev.createdAt || moment().toISOString();
  newItemRev['createdBy'] = itemRev.createdBy || activeUser.email;
  newItemRev['modifiedAt'] = moment().toISOString();
  newItemRev['modifiedBy'] = activeUser.email;

  return newItemRev;
};

export const prepareSoftwareAddress = (
  ecuItemId: string,
  object: SoftwareAddress,
  existingRows: SoftwareAddress[],
) => {
  const newObject = {};
  let keysOfProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'ecuId',
    'busId',
    'busName',
    'idOnLocalBus',
    'udsRequestId',
    'udsResponseId',
    'ecuItemId',
  ];

  if (!object) {
    object = {
      id: '',
      name: `New_${getNextNumber(
        existingRows?.map((r) => r?.name || '0') || [],
      )}`,
      type: 'SoftwareAddress',
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      ecuId: '',
      busId: '',
      busName: '',
      idOnLocalBus: '',
      udsRequestId: '',
      udsResponseId: '',
      ecuItemId: ecuItemId,
    };
  } else {
    // Duplicate object
    newObject['name'] = `${object?.name || 'New'}_Copy`;
  }

  for (const prop of keysOfProps) {
    if (!newObject[prop]) {
      newObject[prop] = (<any>object)[prop];
    }
  }

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;
  newObject['releasedItemRevs'] = [];

  return newObject;
};

export const prepareHardwareRevision = (
  ecuItemId: string,
  object: HardwareRevision,
  existingRows: HardwareRevision[],
) => {
  const newObject = {};
  let keysOfProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'hardwareRevision',
    'edcs',
    'pNumber',
    'ecuItemId',
  ];

  if (!object) {
    object = {
      id: '',
      name: `New_${getNextNumber(
        existingRows?.map((r) => r?.name || '0') || [],
      )}`,
      type: 'HardwareRevision',
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      hardwareRevision: '',
      edcs: '',
      pNumber: '',
      ecuItemId: ecuItemId,
    };
  } else {
    // Duplicate object
    newObject['name'] = `${object?.name || 'New'}_Copy`;
  }

  for (const prop of keysOfProps) {
    if (!newObject[prop]) {
      newObject[prop] = (<any>object)[prop];
    }
  }

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;
  newObject['releasedItemRevs'] = [];

  return newObject;
};

export const prepareSoftware = (
  ecuItemId: string,
  object: Software,
  existingRows: Software[],
  source: string,
) => {
  const newObject = {};
  let keysOfProps = [
    'built',
    'componentNumber',
    'componentType',
    'compressionMethod',
    'description',
    'ecuItemId',
    'filePath',
    'flashingLevel',
    'gitBranch',
    'gitProject',
    'jiraTickets',
    'name',
    'owner',
    'packageType',
    'pNumber',
    'programmingBlock',
    'softwareType',
    'startString',
    'type',
    'version',
  ];

  if (!object) {
    object = {
      id: '',
      name: `New_${getNextNumber(
        existingRows?.map((r) => r?.name || '0') || [],
      )}`,
      type: 'Software',
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      built: false,
      alwaysOTA: false,
      startString: '',
      version: '',
      supplierVersion: '',
      compressionMethod: '',
      componentType: '',
      componentNumber: 0,
      flashingLevel: '',
      filePath: '',
      owner: '',
      pNumber: '',
      programmingBlock: '',
      description: '',
      packageType: '',
      ecuItemId: ecuItemId,
      jiraTickets: [],
      softwareType: source?.toLowerCase() == 'in-house' ? 'Code' : 'Image',
    };
    if (object.softwareType === 'Code') {
      object.built = true;
      object.version = 'Auto';
    }
  } else {
    newObject['name'] = `${object?.name || 'New'}_Copy`;
    newObject['filePath'] =
      source?.toLowerCase() == 'in-house' ? object.filePath : '';
  }

  for (const prop of keysOfProps) {
    if (!newObject[prop]) {
      newObject[prop] = (<any>object)[prop];
    }
  }

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;
  newObject['releasedItemRevs'] = [];

  return newObject;
};

export const prepareJenkinsJob = (
  ecuItemId: string,
  // make it optional when we need to create a new entry
  jenkinsJobObject?: JenkinsJob,
  existingRows?: JenkinsJob[],
) => {
  const activeUser = getActiveUser();
  // Those props will be in the resulted
  // object anyway, either it's a duplicate
  // or a brand new object. So let's
  // create it in the beginning.
  // Also, assume only JenkinsJob type
  // here, so doee not make sense to copy it from
  // exsiting job
  const newProps = {
    id: createObjectId('JenkinsJob'),
    name: `New_${getNextNumber(
      existingRows?.map((r) => r?.name || '0') || [],
    )}`,
    type: 'JenkinsJob',
    createdAt: moment().toISOString(),
    createdBy: activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
  };

  let newObject: JenkinsJob = null;
  if (jenkinsJobObject) {
    // Duplicate object
    newObject = {
      ...jenkinsJobObject,
      ...newProps,
      name: jenkinsJobObject?.name
        ? `${jenkinsJobObject?.name}_Copy`
        : newProps.name,
    };
  } else {
    newObject = {
      ...newProps,
      job: '',
      ecuItemId: ecuItemId,
    };
  }

  return newObject;
};

export const prepareSoftwareListType = (ecuItemId: string, parentPath: []) => {
  let name = `New_${createShortUniqueId(6)}`;
  let path = [];
  if (parentPath) {
    parentPath.forEach((element) => {
      path.push(element);
    });
  }

  path.push(name);

  let object = {
    id: createObjectId('SoftwareListType'),
    name: name,
    software: path.length === 3 ? name : '', // assign name to software only when it's software level node
    type: 'SoftwareListType',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    path: path,
    flashOrder: 0,
    version: '',
    tool: '',
    compatibleBl: '',
    resetAfterUpdate: false,
    notes: '',
    softwareId: path.length === 3 ? 'None' : '',
    ecuItemId: ecuItemId,
  };

  const newObject = {};
  let keysOfProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'path',
    'flashOrder',
    'version',
    'tool',
    'compatibleBl',
    'resetAfterUpdate',
    'notes',
    'software',
    'softwareId',
    'ecuItemId',
  ];
  for (const prop of keysOfProps) {
    newObject[prop] = (<any>object)[prop];
  }

  const activeUser = getActiveUser();
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;
  newObject['releasedItemRevs'] = [];

  return newObject;
};

export const prepareECUAddress = (
  ecuItemRevId: string,
  object: ECUAddress,
  existingRows: ECUAddress[],
) => {
  const newObject = {};
  let keysOfProps = [
    'id',
    'name',
    'type',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    'softwareAddress',
    'softwareListType',
    'ecuItemRevId',
    'hardwareRevision',
  ];

  if (!object) {
    object = {
      id: '',
      name: `New_${getNextNumber(
        existingRows?.map((r) => r?.name || '0') || [],
      )}`,
      type: 'ECUAddress',
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      softwareAddress: '',
      softwareListType: '',
      ecuItemRevId: ecuItemRevId,
      hardwareRevision: '',
    };
  } else {
    // Duplicate object
    newObject['name'] = `${object?.name || 'New'}_Copy`;
  }

  for (const prop of keysOfProps) {
    if (!newObject[prop]) {
      newObject[prop] = (<any>object)[prop];
    }
  }

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;

  return newObject;
};

export const prepareBOMWindow = (itemRev: string, bomData: any) => {
  let newObject = {
    id: '',
    type: 'BOMWindow',
    topItemRevision: itemRev,
    revisionRule: null,
    variantRules: [],
    topLine: bomData.topLine,
  };

  newObject['id'] = createObjectId(newObject['type']);

  return newObject;
};

export const prepareBOMLine = (
  itemOrItemRev: ItemRevision,
  parentBOMLine: BOMLine,
  occ: Occurrence,
) => {
  let path = [...parentBOMLine.path];
  path.push(itemOrItemRev.name);

  let newObject = {
    ...itemOrItemRev, // TODO: need to check which attribute should be assigned
    type: 'BOMLine',
    name: itemOrItemRev.name,
    itemType: itemOrItemRev.type,
    itemRevision: itemOrItemRev,
    precise: true,
    configured: true,
    occurrence: occ,
    revisionEffectivity: '00_42-00_43',
    variantConditions: [
      {
        id: '001',
        operator: '=',
        option: 'engine',
        value: '1300',
        condition: 'if',
      },
    ],
    path: path,
    childLines: [],
  };

  newObject['id'] = createObjectId(newObject['type']);

  return newObject;
};

export const prepareVariantOption = (existingRows: any[]) => {
  let newObject = {
    id: '',
    name: `New_${getNextNumber(
      existingRows?.map((r) => r?.name || '0') || [],
    )}`,
    type: 'VariantOption',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    optionType: 'Fixed Word',
    allowedValues: '',
    defaultValue: '',
    description: '',
    visibility: true,
  };

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;

  return newObject;
};

export const prepareVariantCondition = (occId: string, existingRows: any[]) => {
  let newObject = {
    id: '',
    name: `New_${getNextNumber(
      existingRows?.map((r) => r?.name || '0') || [],
    )}`,
    type: 'VariantCondition',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    optionName: '',
    optionId: '',
    operator: 'equal',
    value: '',
    condition: 'if',
    occurrenceId: occId,
  };

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;

  return newObject;
};

export const prepareVariantValue = (
  ruleId: string,
  existingRows: VariantValue[],
) => {
  let newObject: VariantValue = {
    id: '',
    variantRuleId: ruleId,
    name: `New_${getNextNumber(
      existingRows?.map((r) => r?.name || '0') || [],
    )}`,
    type: 'VariantValue',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    optionName: '',
    optionId: '',
    value: '',
  };

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;

  return newObject;
};

export const prepareVariantRule = (existingRows: VariantRule[]) => {
  let newObject: VariantRule = {
    id: '',
    name: `New_${getNextNumber(
      existingRows?.map((r) => r?.name || '0') || [],
    )}`,
    type: 'VariantRule',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: '',
    variantValues: [],
  };

  const activeUser = getActiveUser();
  newObject['id'] = createObjectId(newObject['type']);
  newObject['createdAt'] = moment().toISOString();
  newObject['createdBy'] = activeUser.email;
  newObject['modifiedAt'] = moment().toISOString();
  newObject['modifiedBy'] = activeUser.email;

  return newObject;
};

export const prepareDataset = (bucket, key, name) => {
  const activeUser = getActiveUser();
  let newObject: Dataset = {
    id: createObjectId('Dataset'),
    name: `${name}`,
    type: 'Dataset',
    createdAt: moment().toISOString(),
    createdBy: activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    bucket: bucket,
    key: key,
  };

  return newObject;
};

export const prepareTypedNode = (
  nodeType: string,
  name: string,
  desc: string,
) => {
  const activeUser = getActiveUser();
  const newObject = {
    id: createObjectId(nodeType),
    type: nodeType,
    name: name,
    desc: desc,
    createdBy: activeUser.email,
    createdAt: new Date().toISOString(),
    modifiedBy: activeUser.email,
  };

  switch (nodeType) {
    case 'ReleaseVersion':
      newObject['startTime'] = new Date().toISOString();
      newObject['endTime'] = new Date().toISOString();
      break;

    case 'ECUNode':
      newObject['dtcs'] = [];
      newObject['status'] = 'Enabled';
      break;

    case 'DIDNumber':
    case 'DIDFormat':
      newObject['startTime'] = new Date().toISOString();
      newObject['endTime'] = new Date().toISOString();
      break;

    default:
      newObject['status'] = 'Enabled';
  }

  return newObject;
};

export const prepareTemplateNode = (
  name: string,
  level: string,
  path: string[],
) => {
  const activeUser = getActiveUser();
  let newObject = {
    id: createObjectId('TemplateNode'),
    type: 'TemplateNode',
    name: name,
    level: level,
    path: path,
    required: true,
    createdBy: activeUser.email,
    createdAt: new Date().toISOString(),
    modifiedBy: activeUser.email,
  };

  if (level === 'Vehicle') {
    newObject['status'] = 'Enabled';
  }

  if (level === 'ECU') {
    newObject['flashOrder'] = 0;
  }

  return newObject;
};
