import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      //Flash Station - UDS codes
      UDS_REQUEST_RESULT_SUCCESS: 'UDS Request Success',
      UDS_REQUEST_RESULT_BAD_SERVER: 'Bad Server',
      UDS_REQUEST_RESULT_BAD_PARAMETERS: 'Bad Parameters',
      UDS_REQUEST_RESULT_ERROR: 'UDS Request Error',
      UDS_REQUEST_RESULT_RETRY_LATER: 'Retry later',
      UDS_REQUEST_NEGATIVE_RESPONSE: 'Negative Response',
      UDS_REQUEST_BUSY_RESPONSE: 'Busy Response',
      UDS_REQUEST_NO_RESPONSE: 'No Response',

      UDS_RES_CODE_POSITIVE: 'Positive',
      UDS_RES_CODE_GENERAL_REJECT: 'General Reject',
      UDS_RES_CODE_SERVICE_NOT_SUPPORTED: 'Service Not Supported',
      UDS_RES_CODE_SUB_FUNCTION_NOT_SUPPORTED: 'Sub Function Not Supported',
      UDS_RES_CODE_INCORRECT_MESSAGE_LENGTH: 'Incorrect Message Length',
      UDS_RES_CODE_RESPONSE_TOO_LONG: 'Response Too Long',
      UDS_RES_CODE_BUSY_REPEAT_REQUEST: 'Busy Repeat Request',
      UDS_RES_CODE_CONDITIONS_NOT_CORRECT: 'Conditions Not Correct',
      UDS_RES_CODE_REQUEST_SEQUENCE_ERROR: 'Request Sequence Error',
      UDS_RES_CODE_NO_RESPONSE_FROM_SUBNET: 'No Response From Subnet',
      UDS_RES_CODE_FAILURE_PREVENTS_EXECUTION: 'Failure Prevents Execution',
      UDS_RES_CODE_REQUEST_OUT_OF_RANGE: 'Request Out Of Range',
      UDS_RES_CODE_SECURITY_ACCESS_DENIED: 'Security Access Denied',
      UDS_RES_CODE_INVALID_KEY: 'Invalid Key',
      UDS_RES_CODE_EXCEED_NUMBER_OF_ATTEMPTS: 'Exceed Number Of Attempts',
      UDS_RES_CODE_REQUIRED_TIME_DELAY_NOT_EXPIRED:
        'Required Time Delay Not Expired',
      UDS_RES_CODE_UPLOAD_DOWNLOAD_NOT_ACCEPTED: 'Upload Download Not Accepted',
      UDS_RES_CODE_TRANSFER_DATA_SUSPENDED: 'Transfer Data Suspended',
      UDS_RES_CODE_GENERAL_PROGRAMMING_FAILURE: 'General Programming Failure',
      UDS_RES_CODE_WRONG_BLOCK_SEQUENCE_COUNTER: 'Wrong Block Sequence Counter',
      UDS_RES_CODE_REQUEST_CORRECTLY_RCVD_RESP_PENDING:
        'Request Correctly Rcvd Resp Pending',
      UDS_RES_CODE_SUB_FUNCTION_NOT_SUPPORTED_IN_SESSION:
        'Sub Function Not Supported In Session',
      UDS_RES_CODE_SERVICE_NOT_SUPPORTED_IN_SESSION:
        'Service Not Supported In Session',
      UDS_RES_CODE_RPM_TOO_HIGH: 'Rpm Too High',
      UDS_RES_CODE_RPM_TOO_LOW: 'Rpm Too Low',
      UDS_RES_CODE_ENGINE_IS_RUNNING: 'Engine Is Running',
      UDS_RES_CODE_ENGINE_IS_NOT_RUNNING: 'Engine Is Not Running',
      UDS_RES_CODE_ENGINE_RUN_TIME_TOO_LOW: 'Engine Run Time Too Low',
      UDS_RES_CODE_TEMPERATURE_TOO_HIGH: 'Temperature Too High',
      UDS_RES_CODE_TEMPERATURE_TOO_LOW: 'Temperature Too Low',
      UDS_RES_CODE_VEHICLE_SPEED_TOO_HIGH: 'Vehicle Speed Too High',
      UDS_RES_CODE_VEHICLE_SPEED_TOO_LOW: 'Vehicle Speed Too Low',
      UDS_RES_CODE_THROTTLE_TOO_HIGH: 'Throttle Too High',
      UDS_RES_CODE_THROTTLE_TOO_LOW: 'Throttle Too Low',
      UDS_RES_CODE_TRANSMISSION_RANGE_NOT_IN_NEUTRAL:
        'Transmission Range Not In Neutral',
      UDS_RES_CODE_TRANSMISSION_RANGE_NOT_IN_GEAR:
        'Transmission Range Not In Gear',
      UDS_RES_CODE_BRAKE_SWITCH_NOT_CLOSED: 'Brake Switch Not Closed',
      UDS_RES_CODE_SHIFTER_LEVER_NOT_IN_PARK: 'Shifter Lever Not In Park',
      UDS_RES_CODE_TORQUE_CONVERTOR_CLUTCH_LOCKED:
        'Torque Convertor Clutch Locked',
      UDS_RES_CODE_VOLTAGE_TOO_HIGH: 'Voltage Too High',
      UDS_RES_CODE_VOLTAGE_TOO_LOW: 'Voltage Too Low',
      UDS_RES_CODE_INIT: 'Init',
    },
  },
  cn: {
    translation: {
      'Language changed': '语言切换成功',

      //Flash Station - UDS codes
      UDS_REQUEST_RESULT_SUCCESS: 'UDS请求成功',
      UDS_REQUEST_RESULT_BAD_SERVER: '服务器响应失败',
      UDS_REQUEST_RESULT_BAD_PARAMETERS: 'UDS请求参数无效',
      UDS_REQUEST_RESULT_ERROR: 'UDS请求错误',
      UDS_REQUEST_RESULT_RETRY_LATER: '稍后重试',
      UDS_REQUEST_NEGATIVE_RESPONSE: 'UDS请求异常响应',
      UDS_REQUEST_BUSY_RESPONSE: 'UDS请求忙响应',
      UDS_REQUEST_NO_RESPONSE: 'UDS请求无响应',

      UDS_RES_CODE_POSITIVE: '阳性',
      UDS_RES_CODE_GENERAL_REJECT: '一般拒绝',
      UDS_RES_CODE_SERVICE_NOT_SUPPORTED: '服务不支持',
      UDS_RES_CODE_SUB_FUNCTION_NOT_SUPPORTED: '不支持子功能',
      UDS_RES_CODE_INCORRECT_MESSAGE_LENGTH: '消息长度不正确',
      UDS_RES_CODE_RESPONSE_TOO_LONG: '响应太长',
      UDS_RES_CODE_BUSY_REPEAT_REQUEST: '忙重复请求',
      UDS_RES_CODE_CONDITIONS_NOT_CORRECT: '条件不正确',
      UDS_RES_CODE_REQUEST_SEQUENCE_ERROR: '请求序列错误',
      UDS_RES_CODE_NO_RESPONSE_FROM_SUBNET: '没有来自子网的响应',
      UDS_RES_CODE_FAILURE_PREVENTS_EXECUTION: '失败阻止执行',
      UDS_RES_CODE_REQUEST_OUT_OF_RANGE: '请求超出范围',
      UDS_RES_CODE_SECURITY_ACCESS_DENIED: '安全访问被拒绝',
      UDS_RES_CODE_INVALID_KEY: '无效密钥',
      UDS_RES_CODE_EXCEED_NUMBER_OF_ATTEMPTS: '超过尝试次数',
      UDS_RES_CODE_REQUIRED_TIME_DELAY_NOT_EXPIRED: '所需时间延迟未过期',
      UDS_RES_CODE_UPLOAD_DOWNLOAD_NOT_ACCEPTED: '上传下载不接受',
      UDS_RES_CODE_TRANSFER_DATA_SUSPENDED: '传输数据暂停',
      UDS_RES_CODE_GENERAL_PROGRAMMING_FAILURE: '一般编程失败',
      UDS_RES_CODE_WRONG_BLOCK_SEQUENCE_COUNTER: '错误的块序列计数器',
      UDS_RES_CODE_REQUEST_CORRECTLY_RCVD_RESP_PENDING:
        '请求正确收到响应待定',
      UDS_RES_CODE_SUB_FUNCTION_NOT_SUPPORTED_IN_SESSION: '会话中不支持子功能',
      UDS_RES_CODE_SERVICE_NOT_SUPPORTED_IN_SESSION: '会话中不支持服务',
      UDS_RES_CODE_RPM_TOO_HIGH: '转速太高',
      UDS_RES_CODE_RPM_TOO_LOW: '转速太低',
      UDS_RES_CODE_ENGINE_IS_RUNNING: '引擎正在运行',
      UDS_RES_CODE_ENGINE_IS_NOT_RUNNING: '引擎没有运行',
      UDS_RES_CODE_ENGINE_RUN_TIME_TOO_LOW: '引擎运行时间太低',
      UDS_RES_CODE_TEMPERATURE_TOO_HIGH: '温度太高',
      UDS_RES_CODE_TEMPERATURE_TOO_LOW: '温度太低',
      UDS_RES_CODE_VEHICLE_SPEED_TOO_HIGH: '车速太高',
      UDS_RES_CODE_VEHICLE_SPEED_TOO_LOW: '车速太低',
      UDS_RES_CODE_THROTTLE_TOO_HIGH: '油门太高',
      UDS_RES_CODE_THROTTLE_TOO_LOW: '油门太低',
      UDS_RES_CODE_TRANSMISSION_RANGE_NOT_IN_NEUTRAL: '传输范围不在中性范围内',
      UDS_RES_CODE_TRANSMISSION_RANGE_NOT_IN_GEAR: '变速箱不在档位',
      UDS_RES_CODE_BRAKE_SWITCH_NOT_CLOSED: '刹车开关未闭合',
      UDS_RES_CODE_SHIFTER_LEVER_NOT_IN_PARK: '变速杆不在公园',
      UDS_RES_CODE_TORQUE_CONVERTOR_CLUTCH_LOCKED: '变矩器离合器锁定',
      UDS_RES_CODE_VOLTAGE_TOO_HIGH: '电压太高',
      UDS_RES_CODE_VOLTAGE_TOO_LOW: '电压太低',
      UDS_RES_CODE_INIT: '初始化',
    },
  },
  de: {
    translation: {
      'Language changed': 'Sprache geändert',
      'Faraday Future': 'Faraday Future',
      'Your tier': 'Ihre Stufe',
      General: 'Allgemein',
      Overview: 'Überblick',
      Analytics: 'Analytik',
      Finance: 'Finanzen',
      Logistics: 'Logistik',
      Account: 'Konto',
      Management: 'Verwaltung',
      Customers: 'Kunden',
      List: 'Aufführen',
      Details: 'Einzelheiten',
      Edit: 'Bearbeiten',
      Products: 'Produkte',
      Create: 'Schaffen',
      Orders: 'Aufträge',
      Invoices: 'Rechnungen',
      Platforms: 'Plattform',
      'Job Listings': 'Stellenangebote',
      Browse: 'Durchsuche',
      'Social Media': 'Sozialen Medien',
      Profile: 'Profil',
      Feed: 'Füttern',
      Blog: 'Blog',
      'Post List': 'Beitragsliste',
      'Post Details': 'Beitragsdetails',
      'Post Create': 'Beitrag erstellen',
      Apps: 'Apps',
      Kanban: 'Kanban',
      Mail: 'E-Mail',
      Chat: 'Plaudern',
      Calendar: 'Kalender',
      Pages: 'Seiten',
      Auth: 'Authentifizierung',
      Register: 'Registrieren',
      Login: 'Anmeldung',
      Pricing: 'Preisgestaltung',
      Checkout: 'Auschecken',
      Contact: 'Kontakt',
      Error: 'Fehler',
      'Need Help?': 'Brauchen Sie Hilfe?',
      'Check our docs': 'Überprüfen Sie unsere Dokumente',
      Documentation: 'Dokumentation',
    },
  },
  es: {
    translation: {
      'Language changed': 'Se ha cambiado el idioma.',
      'Faraday Future': 'Faraday Future',
      'Your tier': 'Tu nivel',
      General: 'General',
      Overview: 'Visión general',
      Analytics: 'Analítica',
      Finance: 'Finanzas',
      Logistics: 'Logística',
      Account: 'Cuenta',
      Management: 'Gestión',
      Customers: 'Clientes',
      List: 'Lista',
      Details: 'Detalles',
      Edit: 'Editar',
      Products: 'Productos',
      Create: 'Crear',
      Orders: 'Pedidos',
      Invoices: 'Facturas',
      Platforms: 'Plataforma',
      'Job Listings': 'Listado de trabajos',
      Browse: 'Buscar',
      'Social Media': 'Redes sociales',
      Profile: 'Perfil',
      Feed: 'Fuente social',
      Blog: 'Blog',
      'Post List': 'Lista de articulos',
      'Post Details': 'Detalles del articulo',
      'Post Create': 'Create articulo',
      Apps: 'Aplicaciones',
      Kanban: 'Kanban',
      Mail: 'Correo',
      Chat: 'Chat',
      Calendar: 'Calendario',
      Pages: 'Páginas',
      Auth: 'Autenticación',
      Register: 'Registrarse',
      Login: 'Acceso',
      Pricing: 'Precios',
      Checkout: 'Pago',
      Contact: 'Contacto',
      Error: 'Error',
      'Need Help?': '¿Necesitas ayuda?',
      'Check our docs': 'Consulte nuestros documentos',
      Documentation: 'Documentación',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
