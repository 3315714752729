import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

import { useDispatch } from 'src/store';
import { setAccount } from 'src/slices/common-settings-slice';

const initialSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: 'light',
  packageStatusVersion: '',
  workspace: 'tracker',
  selectedReleaseLevel: '',
  selectedReleaseVersion: '',
  selectedReleaseGroup: '',
  objectListSearch: {},
  vehicleListSearch: {},
  defaultInheritanceModel: null,
  initInheritanceModel: null,
  account: {},
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: 'ltr',
        responsiveFontSizes: true,
        theme: 'light',
        packageStatusVersion: '',
        workspace: 'tracker',
        selectedReleaseLevel: '',
        selectedReleaseVersion: '',
        selectedReleaseGroup: '',
        objectListSearch: {},
        vehicleListSearch: {},
        defaultInheritanceModel: null,
        initInheritanceModel: null,
        account: {},
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: (newSettings) => {},
  clearSession: () => {},
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);

      // Save account in store in order to easy use
      // in placse out of React components
      dispatch(setAccount(restoredSettings.account));
    }
  }, []);

  const saveSettings = (updatedSettings) => {
    // So it's possible to save just one settings
    // and dont think about the existing ones
    const newSettings = {
      ...settings,
      ...updatedSettings,
    };
    setSettings(newSettings);
    storeSettings(newSettings);

    dispatch(setAccount(newSettings.account));
  };

  const clearSession = () => {
    saveSettings({
      defaultInheritanceModel: null,
      initInheritanceModel: null,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        clearSession,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;
