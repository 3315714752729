import { createSlice } from '@reduxjs/toolkit';

import { Notification } from 'src/tools/tracker/models/notification';
import { Subscription } from 'src/tools/tracker/models/subscription';

import { notificationApi } from 'src/apis/notification-api';
import { getActiveUser } from 'src/utils/utils';

const initialState: {
  notifications: Notification[];
  subscriptions: Subscription[];
} = {
  notifications: [],
  subscriptions: [],
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setSubscriptions(state, action) {
      const idsToReplace = action.payload.map((i) => i.id);
      state.subscriptions = [
        ...state.subscriptions.filter((i) => !idsToReplace.includes(i.id)),
        ...action.payload,
      ];
    },
    setNotifications(state, action) {
      const idsToReplace = action.payload.map((i) => i.id);
      state.notifications = [
        ...state.notifications.filter((i) => !idsToReplace.includes(i.id)),
        ...action.payload,
      ];
    },
    markRead(state, action) {
      const notificationId = action.payload.notificationId;
      const flag = action.payload.flag;

      const objToUpdateIndex = state.notifications.findIndex(
        (n) => n.id === notificationId,
      );
      state.notifications = [
        ...state.notifications.slice(0, objToUpdateIndex),
        {
          ...state.notifications[objToUpdateIndex],
          read: flag,
        },
        ...state.notifications.slice(objToUpdateIndex + 1),
      ];
    },
    markAllRead(state) {
      // Change flag "read" to true for all notifications
      state.notifications = state.notifications.map((n) => ({
        ...n,
        read: true,
      }));
    },
  },
});

export const { reducer } = slice;

export const getUnreadNotifications = () => async (dispatch: Function) => {
  const activeUser = getActiveUser();
  const response = await notificationApi.getUnreadNotifications(activeUser);
  if (!response || response.status !== 200) {
    return;
  }
  dispatch(slice.actions.setNotifications(response?.data?.objects || []));
};

export const markReadAllNotifications = () => async (dispatch: Function) => {
  // Change local store first for better
  // user experience, so it happens immediately
  dispatch(slice.actions.markAllRead());

  const activeUser = getActiveUser();
  const response = await notificationApi.markAllNotificationsRead(
    activeUser.id,
  );
  if (response.status !== 200) {
    return;
  }
};

export const markReadNotification =
  (notificationId: string, flag: boolean) => async (dispatch: Function) => {
    const activeUser = getActiveUser();
    const response = await notificationApi.markNotificationRead(
      activeUser,
      notificationId,
      flag,
    );
    if (response.status !== 200) {
      return;
    }
    dispatch(slice.actions.markRead({ notificationId, flag }));
  };

export const getSubscriptions =
  (notificationType?: string, group?: string, level?: string) =>
  async (dispatch: Function) => {
    const activeUser = getActiveUser();
    const response = await notificationApi.getSubscriptions(
      activeUser,
      notificationType,
      group,
      level,
    );
    if (response.status !== 200) {
      return;
    }
    dispatch(slice.actions.setSubscriptions(response?.data?.objects || []));
  };
