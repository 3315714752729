import { createSlice } from '@reduxjs/toolkit';
import { objectApi } from 'src/apis/object-api';
import { structureApi } from 'src/apis/structure-api';
import { showErrorAlert } from 'src/slices/common-settings-slice';
import { ItemRevision } from 'src/tools/tracker/models/item-revision';
import {
  BOMLine,
  BOMWindow,
  VariantCondition,
  VariantOption,
  VariantRule,
  VariantValue,
} from 'src/tools/tracker/models/structure-models';
import { prepareBOMLine, prepareBOMWindow } from 'src/utils/convert-object';
import { showTalkToServer } from 'src/utils/toast-message';

const initialState: {
  topItemRevision: ItemRevision;
  bomWindow: BOMWindow;
  selectedBOMLine: BOMLine;
  isSidebarOpen: boolean;
  variantOptions: VariantOption[];
  variantConditions: VariantCondition[];
  variantRules: VariantRule[];
} = {
  topItemRevision: null,
  bomWindow: null,
  selectedBOMLine: null,
  isSidebarOpen: true,
  variantOptions: [],
  variantConditions: [],
  variantRules: [],
};

const getVariantConditions = (bomLine) => {
  let conditions = [];
  if (bomLine?.occurrence?.variantConditions) {
    conditions = [...bomLine?.occurrence?.variantConditions];
  }

  bomLine?.childLines?.forEach((line) => {
    let childConditions = getVariantConditions(line);
    conditions = [...conditions, ...childConditions];
  });

  return conditions;
};

const slice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    setTopItemRevision(state, action) {
      state.topItemRevision = action.payload.itemRev;
    },
    setBOMWindow(state, action) {
      state.bomWindow = action.payload.bomWindow;

      // In fact, there is no variant condition on top line
      let variantConditions = getVariantConditions(state.bomWindow.topLine);
      //slice.actions.setVariantConditions(variantConditions);
      state.variantConditions = [...variantConditions];
    },
    addBOMLine(state, action) {
      action.payload.bomLine.parentLineId = action.payload.parentBOMLine.id;

      // Vehicle
      if (state.bomWindow.topLine.id === action.payload.parentBOMLine.id) {
        state.bomWindow = {
          ...state.bomWindow,
          topLine: {
            ...state.bomWindow.topLine,
            childLines: [
              ...(state.bomWindow.topLine?.childLines || []),
              action.payload.bomLine,
            ],
          },
        };
      } else {
        // Domain
        let domainLineIndex = state.bomWindow.topLine.childLines.findIndex(
          (o: BOMLine) => o.id == action.payload.parentBOMLine.id,
        );
        let domainLine = state.bomWindow.topLine.childLines.find(
          (o: BOMLine) => o.id == action.payload.parentBOMLine.id,
        );

        if (domainLineIndex < 0) {
          domainLineIndex = 0;
        }
        state.bomWindow = {
          ...state.bomWindow,
          topLine: {
            ...state.bomWindow.topLine, // Vehicle
            childLines: [
              ...state.bomWindow.topLine.childLines.slice(0, domainLineIndex), // Domain
              {
                ...domainLine,
                childLines: [
                  ...(domainLine.childLines || []),
                  action.payload.bomLine,
                ],
              },
              ...state.bomWindow.topLine.childLines.slice(domainLineIndex + 1),
            ],
          },
        };
      }
    },
    removeBOMLine(state, action) {
      let parentLineId = action.payload.bomLine.parentLineId;

      // Vehicle
      if (state.bomWindow.topLine.id === parentLineId) {
        state.bomWindow = {
          ...state.bomWindow,
          topLine: {
            ...state.bomWindow.topLine,
            childLines: [
              ...state.bomWindow.topLine.childLines.filter(
                (o) => o.id != action.payload.bomLine.id,
              ),
            ],
          },
        };
      } else {
        // Domain
        let domainLineIndex = state.bomWindow.topLine.childLines.findIndex(
          (o: BOMLine) => o.id == action.payload.bomLine.parentLineId,
        );
        let domainLine = state.bomWindow.topLine.childLines.find(
          (o: BOMLine) => o.id == action.payload.bomLine.parentLineId,
        );

        if (domainLineIndex < 0) {
          domainLineIndex = 0;
        }
        state.bomWindow = {
          ...state.bomWindow,
          topLine: {
            ...state.bomWindow.topLine, // Vehicle
            childLines: [
              ...state.bomWindow.topLine.childLines.slice(0, domainLineIndex), // Domain
              {
                ...domainLine,
                childLines: [
                  ...(domainLine.childLines || []).filter(
                    (o) => o.id != action.payload.bomLine.id,
                  ),
                ],
              },
              ...state.bomWindow.topLine.childLines.slice(domainLineIndex + 1),
            ],
          },
        };
      }
    },
    setSelectedBOMLine(state, action) {
      // unselect when click again
      if (
        state.selectedBOMLine &&
        state.selectedBOMLine.id === action.payload.id
      ) {
        state.selectedBOMLine = null;
      } else {
        state.selectedBOMLine = action.payload;
      }
    },
    openSidebar(state) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
    setVariantOptions(state, action) {
      // override the entire list with input variant options because we only keep options from
      // top item rev
      state.variantOptions = [...action.payload];
    },
    addVariantOption(state, action) {
      const objectToAdd = {
        ...action.payload.objectToBeAdded,
      };

      state.variantOptions = [...state.variantOptions, objectToAdd];
    },
    deleteVariantOption(state, action) {
      state.variantOptions = [
        ...state.variantOptions.filter((o) => o.id != action.payload.objectId),
      ];
    },
    updateVariantOption(state, action) {
      let objectToUpdate = state.variantOptions.find(
        (o) => o.id === action.payload.objectId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;
      const objectIndex = state.variantOptions.findIndex(
        (o) => o.id === action.payload.objectId,
      );

      state.variantOptions = [
        ...state.variantOptions.slice(0, objectIndex),
        objectToUpdate,
        ...state.variantOptions.slice(objectIndex + 1),
      ];
    },
    setVariantConditions(state, action) {
      state.variantConditions = [...action.payload];
    },
    addVariantCondition(state, action) {
      const objectToAdd = {
        ...action.payload.objectToBeAdded,
      };
      state.variantConditions = [...state.variantConditions, objectToAdd];
    },
    deleteVariantCondition(state, action) {
      state.variantConditions = [
        ...state.variantConditions.filter(
          (o) => o.id != action.payload.objectId,
        ),
      ];
    },
    updateVariantCondition(state, action) {
      //occId, objectId, objectToUpdate
      let objectToUpdate = state.variantConditions.find(
        (o) => o.id === action.payload.objectId,
      );
      const variantConditionObject = action.payload.objectToUpdate;
      if (variantConditionObject) {
        delete variantConditionObject.id;
        for (const key of Object.keys(variantConditionObject)) {
          objectToUpdate[key] = variantConditionObject[key];
        }
      }
      const objectIndex = state.variantConditions.findIndex(
        (o) => o.id === action.payload.objectId,
      );

      state.variantConditions = [
        ...state.variantConditions.slice(0, objectIndex),
        objectToUpdate,
        ...state.variantConditions.slice(objectIndex + 1),
      ];
    },
    setVariantRules(state, action) {
      state.variantRules = [...action.payload];
    },
    addVariantRule(state, action) {
      state.variantRules = [...state.variantRules, action.payload];
    },
    deleteVariantRule(state, action) {
      state.variantRules = [
        ...state.variantRules.filter((o) => o.id !== action.payload),
      ];
    },
    updateVariantRule(state, action) {
      const indexToChange = state.variantRules.findIndex(
        (o) => o.id === action.payload.ruleId,
      );
      let objectToUpdate = state.variantRules.find(
        (o) => o.id === action.payload.ruleId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;

      state.variantRules = [
        ...state.variantRules.slice(0, indexToChange),
        objectToUpdate,
        ...state.variantRules.slice(indexToChange + 1),
      ];
    },
    addVariantValue(state, action) {
      //variantRuleId, objectToBeAdded
      const variantRule = state.variantRules.find(
        (vr) => vr.id === action.payload.variantRuleId,
      );
      const variantRuleIndex = state.variantRules.findIndex(
        (vr) => vr.id === action.payload.variantRuleId,
      );

      if (!variantRule.variantValues) {
        variantRule.variantValues = [];
      }

      state.variantRules.splice(variantRuleIndex, 1, {
        ...variantRule,
        variantValues: [
          ...variantRule.variantValues,
          action.payload.objectToBeAdded,
        ],
      });
    },
    deleteVariantValue(state, action) {
      //variantRuleId, objectId
      const variantRuleIndex = state.variantRules.findIndex(
        (vr) => vr.id === action.payload.variantRuleId,
      );
      state.variantRules.splice(variantRuleIndex, 1, {
        ...state.variantRules[variantRuleIndex],
        variantValues: [
          ...state.variantRules[variantRuleIndex].variantValues.filter(
            (v) => v.id !== action.payload.objectId,
          ),
        ],
      });
    },
    updateVariantValue(state, action) {
      //variantRuleId, objectId, objectToUpdate
      const variantRule = state.variantRules.find(
        (vr) => vr.id === action.payload.variantRuleId,
      );
      const variantRuleIndex = state.variantRules.findIndex(
        (vr) => vr.id === action.payload.variantRuleId,
      );

      let objectToUpdate = variantRule.variantValues.find(
        (o) => o.id === action.payload.objectId,
      );
      const variantValueObject = action.payload.objectToUpdate;
      if (variantValueObject) {
        delete variantValueObject.id;
        for (const key of Object.keys(variantValueObject)) {
          objectToUpdate[key] = variantValueObject[key];
        }
      }
      const objectIndex = variantRule.variantValues.findIndex(
        (o) => o.id === action.payload.objectId,
      );

      state.variantRules.splice(variantRuleIndex, 1, {
        ...state.variantRules[variantRuleIndex],
        variantValues: [
          ...variantRule.variantValues.slice(0, objectIndex),
          objectToUpdate,
          ...variantRule.variantValues.slice(objectIndex + 1),
        ],
      });
    },
  },
});

export const { reducer } = slice;

export const setTopItemRevision = (itemRev) => async (dispatch) => {
  dispatch(slice.actions.setTopItemRevision({ itemRev }));
};

export const setSelectedBOMLine = (node) => async (dispatch) => {
  dispatch(slice.actions.setSelectedBOMLine(node));
};

export const openSidebar = () => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = () => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export const createBOMWindowThunk =
  (itemRev: any, variantRules: VariantRule[]) => async (dispatch: Function) => {
    const request = {
      revRuleId: '',
      variantRuleIds: variantRules.map((vr) => vr.id),
      expandAll: true,
    };

    const data = await structureApi.generateBOM(itemRev.id, request);
    const bomWindow = prepareBOMWindow(itemRev, data.data);

    dispatch(slice.actions.setBOMWindow({ bomWindow }));
  };

export const createBOMLineThunk =
  (itemOrItemRev, parentBOMLine) => async (dispatch) => {
    showTalkToServer();

    // Ignore it completely if the same item already there
    if (
      parentBOMLine?.childLines?.find(
        (l: any) => l?.itemRevision?.id === itemOrItemRev.id,
      )
    ) {
      return;
    }

    const bomData = await structureApi.createBOMLine(
      parentBOMLine.itemRevision.id,
      itemOrItemRev.id,
    );

    if (bomData?.status === 200) {
      const occ = bomData.data.occurrence;
      const bomLine = prepareBOMLine(itemOrItemRev, parentBOMLine, occ);
      // if the itemOrItemRev have BVR object,
      // we need to check if need expand its child lines as well
      if (
        !itemOrItemRev.bomViewRevisions ||
        itemOrItemRev.bomViewRevisions.length !== 0
      ) {
        bomLine.childLines = bomData.data.childLines || [];
      }

      dispatch(slice.actions.addBOMLine({ bomLine, parentBOMLine }));
    }
  };

export const deleteBOMLineThunk = (bomLine, parentLine) => async (dispatch) => {
  showTalkToServer();

  await structureApi.deleteBOMLine(
    parentLine.itemRevision.id,
    bomLine.occurrence.id,
  );
  dispatch(slice.actions.removeBOMLine({ bomLine }));
};

export const getVariantOptionsThunk =
  (itemRevId: string) => async (dispatch: Function) => {
    const data = await structureApi.getVariantOptions(itemRevId);
    dispatch(slice.actions.setVariantOptions(data.data.variantOptions || []));
  };

export const createVariantOptionThunk =
  (itemRevId: string, objectToBeAdded: VariantOption) =>
  async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      create: {
        variantOptions: [objectToBeAdded],
      },
    };
    await structureApi.updateVariantOptions(itemRevId, request);
    dispatch(slice.actions.addVariantOption({ itemRevId, objectToBeAdded }));
  };

export const deleteVariantOptionThunk =
  (itemRevId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        variantOptions: [{ id: objectId }],
      },
    };
    await structureApi.updateVariantOptions(itemRevId, request);
    dispatch(slice.actions.deleteVariantOption({ itemRevId, objectId }));
  };

export const updateVariantOptionThunk =
  (itemRevId: string, objectId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        variantOptions: [{ id: objectId }],
      },
    };
    request.update.variantOptions[0][attrName] = attrValue;

    await structureApi.updateVariantOptions(itemRevId, request);
    dispatch(
      slice.actions.updateVariantOption({
        itemRevId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

// export const getVariantConditionsThunk = (
//   occId: string
//   ) => async (dispatch: Function) => {
//   const data = await structureApi.getVariantConditions(occId);
//   dispatch(slice.actions.setVariantConditions(data.data.variantOptions));
// }

export const createVariantConditionThunk =
  (occId: string, objectToBeAdded: VariantCondition) =>
  async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      create: {
        variantConditions: [objectToBeAdded],
      },
    };
    await structureApi.updateVariantConditions(occId, request);
    dispatch(slice.actions.addVariantCondition({ occId, objectToBeAdded }));
  };

export const deleteVariantConditionThunk =
  (occId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        variantConditions: [{ id: objectId }],
      },
    };
    await structureApi.updateVariantConditions(occId, request);
    dispatch(slice.actions.deleteVariantCondition({ occId, objectId }));
  };

export const updateVariantConditionThunk =
  (occId: string, objectId: string, objectToUpdate) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        variantConditions: [{ id: objectId, ...objectToUpdate }],
      },
    };

    await structureApi.updateVariantConditions(occId, request);
    dispatch(
      slice.actions.updateVariantCondition({ occId, objectId, objectToUpdate }),
    );
  };

export const listVariantRulesThunk = () => async (dispatch: Function) => {
  let filters = [
    {
      name: 'type',
      value: 'VariantRule',
    },
  ];
  const data = await objectApi.listObjects(filters, undefined, '', '10');
  dispatch(slice.actions.setVariantRules(data.data.objects));
};

export const createVariantRuleThunk =
  (objectToBeAdded: VariantRule) => async (dispatch: Function) => {
    showTalkToServer();

    const newVariantRuleData = await objectApi.createObject(objectToBeAdded);
    if (newVariantRuleData.status === 200) {
      //
    } else {
      throw new Error(newVariantRuleData.data?.message);
    }

    dispatch(slice.actions.addVariantRule(objectToBeAdded));
  };

export const deleteVariantRuleThunk =
  (ruleId: string) => async (dispatch: Function) => {
    showTalkToServer();

    await objectApi.deleteObject(ruleId);

    dispatch(slice.actions.deleteVariantRule(ruleId));
  };

export const updateVariantRuleThunk =
  (ruleId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      type: 'VariantRule',
    };
    request[attrName] = attrValue;

    try {
      await objectApi.updateObject(ruleId, request);
      dispatch(
        slice.actions.updateVariantRule({ ruleId, attrName, attrValue }),
      );
    } catch (error) {
      console.error('Error occurred during updating variant rule' + error);
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during updating variant rule, please report the issue to administrator.`,
        ),
      );
    }
  };

// export const getVariantValuesThunk = (
//   variantRuleId: string
//   ) => async (dispatch: Function) => {
//   const data = await structureApi.getVariantValues(variantRuleId);
//   dispatch(slice.actions.setVariantValues(data.data.variantOptions));
// }

export const createVariantValueThunk =
  (variantRuleId: string, objectToBeAdded: VariantValue) =>
  async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      create: {
        variantValues: [objectToBeAdded],
      },
    };
    await structureApi.updateVariantValues(variantRuleId, request);
    dispatch(slice.actions.addVariantValue({ variantRuleId, objectToBeAdded }));
  };

export const deleteVariantValueThunk =
  (variantRuleId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        variantValues: [{ id: objectId }],
      },
    };
    await structureApi.updateVariantValues(variantRuleId, request);
    dispatch(slice.actions.deleteVariantValue({ variantRuleId, objectId }));
  };

export const updateVariantValueThunk =
  (variantRuleId: string, objectId: string, objectToUpdate) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        variantValues: [{ id: objectId, ...objectToUpdate }],
      },
    };

    await structureApi.updateVariantValues(variantRuleId, request);
    dispatch(
      slice.actions.updateVariantValue({
        variantRuleId,
        objectId,
        objectToUpdate,
      }),
    );
  };
