import axios from './base';

const prefix = '/v1/structure';

class StructureApi {
  async generateBOM(itemRevId, request) {
    return axios
      .post(`${prefix}/${itemRevId}/generateBOM`, request)
      .then((response) => response);
  }

  async addBOMViewRevision(itemRevId, bvrId) {
    return axios
      .put(`${prefix}/${itemRevId}/${bvrId}/addBOMViewRevision`)
      .then((response) => response);
  }

  async addOccurrence(bvrId, occId) {
    return axios
      .put(`${prefix}/${bvrId}/addOccurrence/${occId}`)
      .then((response) => response);
  }

  async removeOccurrence(bvrId, occId) {
    return axios
      .put(`${prefix}/${bvrId}/removeOccurrence/${occId}`)
      .then((response) => response);
  }

  async createBOMLine(parentItemRevId, childItemOrItemRevId) {
    return axios
      .put(`${prefix}/${parentItemRevId}/createBOMLine/${childItemOrItemRevId}`)
      .then((response) => response);
  }

  async deleteBOMLine(parentItemRevId, childOccId) {
    return axios
      .put(`${prefix}/${parentItemRevId}/deleteBOMLine/${childOccId}`)
      .then((response) => response);
  }

  async getVariantOptions(itemRevId) {
    return axios
      .get(`${prefix}/${itemRevId}/getVariantOptions`)
      .then((response) => response);
  }

  async updateVariantOptions(itemRevId, request) {
    return axios
      .post(`${prefix}/${itemRevId}/updateVariantOptions`, request)
      .then((response) => response);
  }

  // async getVariantConditions(occId) {
  //   return axios.get(`${prefix}/${occId}/getVariantConditions`).then(response => response);
  // }

  async updateVariantConditions(occId, request) {
    return axios
      .post(`${prefix}/${occId}/updateVariantConditions`, request)
      .then((response) => response);
  }

  // async getVariantValues(variantRuleId) {
  //   return axios.get(`${prefix}/${variantRuleId}/getVariantValues`).then(response => response);
  // }

  async updateVariantValues(variantRuleId, request) {
    return axios
      .post(`${prefix}/${variantRuleId}/updateVariantValues`, request)
      .then((response) => response);
  }
}

export const structureApi = new StructureApi();
