import moment from 'moment';
import { User } from 'src/tools/tracker/models/user';
import {
  ActivityCategoryOption,
  VehicleActivity,
  VehicleInstance,
} from 'src/tools/vehicle/models/vehicle-models';
import { createObjectId } from './utils';

const getActiveUser = (): User => {
  const activeUser: User = JSON.parse(localStorage.getItem('activeUser'));
  return activeUser;
};

export const prepareVehicleInstance = (vehicle: VehicleInstance) => {
  const activeUser = getActiveUser();
  const newVehicleInstance: VehicleInstance = {
    id: vehicle.id || createObjectId('VehicleInstance'),
    type: 'VehicleInstance',
    name: vehicle.name,
    createdAt: vehicle.createdAt || moment().toISOString(),
    createdBy: vehicle.createdBy || activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    owner: activeUser.email,
    desc: vehicle.desc,
    vin: vehicle.vin?.toString().toUpperCase(),
    sequence: vehicle.sequence,
    isDevice: vehicle.isDevice,
    active: vehicle.active,
    activities: vehicle.activities || [],
    location: vehicle.location,
    vehicleTemplateName: vehicle.vehicleTemplateName,
  };

  return newVehicleInstance;
};

export const prepareVehicleActivity = (activity: VehicleActivity) => {
  const activeUser = getActiveUser();
  let newVehicleActivity: VehicleActivity = {
    id: activity.id || createObjectId('VehicleActivity'),
    type: 'VehicleActivity',
    name: activity.name,
    createdAt: activity.createdAt || moment().toISOString(),
    createdBy: activity.createdBy || activeUser.email,
    modifiedAt: moment().toISOString(),
    modifiedBy: activeUser.email,
    desc: activity.desc,
    vehicleInstance: activity.vehicleInstance,
    category: activity.category,
  };

  switch (activity.category) {
    case ActivityCategoryOption[ActivityCategoryOption.NFC]:
      newVehicleActivity = {
        ...newVehicleActivity,
        nfcMasterKey: activity.nfcMasterKey ? activity.nfcMasterKey : '',
        readerProgramBPillar: activity.readerProgramBPillar,
        cardProgramBPillar: activity.cardProgramBPillar,
        newVINBPillar: activity.newVINBPillar,
        oldVINBPillar: activity.oldVINBPillar,
        needProgramBPillar: activity.needProgramBPillar,
        needFlashCardBPillar: activity.needFlashCardBPillar,
        needFlashReaderBPillar: activity.needFlashReaderBPillar,
        readerProgramCConsole: activity.readerProgramCConsole,
        cardProgramCConsole: activity.cardProgramCConsole,
        newVINCConsole: activity.newVINCConsole,
        oldVINCConsole: activity.oldVINCConsole,
        needProgramCConsole: activity.needProgramCConsole,
        needFlashCardCConsole: activity.needFlashCardCConsole,
        needFlashReaderCConsole: activity.needFlashReaderCConsole,
      };
      break;

    case ActivityCategoryOption[ActivityCategoryOption.Software]:
      newVehicleActivity = {
        ...newVehicleActivity,
        swVersionFileBase64: activity.swVersionFileBase64,
        swVersionReleaseDate: activity.swVersionReleaseDate,
        swVersionReleaseNote: activity.swVersionReleaseNote,
        swVersionReleaseNoteUrl: activity.swVersionReleaseNoteUrl,
        swVersionReleasePackageName: activity.swVersionReleasePackageName,
        swVersionReleaseVersion: activity.swVersionReleaseVersion,
        swECUStatuses: activity.swECUStatuses,
      };
      break;

    case ActivityCategoryOption[ActivityCategoryOption.Package]:
      newVehicleActivity = {
        ...newVehicleActivity,
        swRCPackage: activity.swRCPackage,
        swOTAPackage: activity.swOTAPackage,
        swECUStatuses: activity.swECUStatuses,
      };
      break;

    case ActivityCategoryOption[ActivityCategoryOption.Sensor]:
      newVehicleActivity = {
        ...newVehicleActivity,
        srECUSelfChecks: activity.srECUSelfChecks,
      };
      break;

    default:
      break;
  }
  return newVehicleActivity;
};
