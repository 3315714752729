import { CacheProvider } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Head from 'next/head';
import Router from 'next/router';
import nProgress from 'nprogress';
import { useEffect } from 'react';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';

import { isInNativeMode } from 'src/utils/native';
import { RTL } from '../components/rtl';
import { SplashScreen } from '../components/splash-screen';
import { gtmConfig } from '../config';
import { AuthConsumer, AuthProvider } from '../contexts/jwt-context';
import {
  SettingsConsumer,
  SettingsProvider,
} from '../contexts/settings-context';
import { store } from '../store';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';

import { runCoreModule } from 'src/apis/flash-api';

import '../i18n';
import { gtm } from '../lib/gtm';

// global css for checkbox tree
import 'src/pages/tools/flash/dashboard/style/react-checkbox-tree.css';

// TODO: need to double if we can load it from server
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(
  '0dc1a2dc24643fe37b8ab6547552f182T1JERVI6MzY5OTYsRVhQSVJZPTE2NzUyODczNTIwMDAsS0VZVkVSU0lPTj0x',
);

import { LicenseInfo as DatePickerLicense } from '@mui/x-date-pickers-pro';
import { INDEXEDDB_DB_NAME, IndexedDBAPI } from 'src/apis/indexeddb-api';
import IndexeddbContext from 'src/contexts/indexeddb-context';
DatePickerLicense.setLicenseKey(
  '0dc1a2dc24643fe37b8ab6547552f182T1JERVI6MzY5OTYsRVhQSVJZPTE2NzUyODczNTIwMDAsS0VZVkVSU0lPTj0x',
);

Router.events.on('routeChangeStart', (r) => {
  // So run core module only when we are on a flash page
  // but do not stop it when we are leaving a flash page
  // since it will introduce bugs,
  // especially, when handling the socket connection
  // and the code module start/end cycle
  if (r.startsWith('/tools/flash/')) {
    console.log('Running core module...');
    runCoreModule();
  }
  nProgress.start();
});
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);
  const indexedDBAPI = IndexedDBAPI.getInstance(INDEXEDDB_DB_NAME);

  useEffect(() => {
    gtm.initialize(gtmConfig);
    indexedDBAPI
      .checkAndDeleteExistingDB()
      .then(() => {
        console.log('Database checked and deleted if necessary.');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Galaxy - EV Tools Platform</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <IndexeddbContext.Provider value={indexedDBAPI}>
            <AuthProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {({ settings }) => (
                    <ThemeProvider
                      theme={createTheme({
                        direction: settings.direction,
                        responsiveFontSizes: settings.responsiveFontSizes,
                        // Light mode for app
                        // TODO: remove settings.theme
                        // or use it somehow.
                        // See settings-context.js
                        mode: isInNativeMode() ? 'dark' : 'light',
                      })}
                    >
                      <RTL direction={settings.direction}>
                        <CssBaseline />
                        <Toaster
                          position="top-center"
                          toastOptions={{ duration: 5000 }}
                        >
                          {(t) => (
                            <ToastBar toast={t}>
                              {({ icon, message }) => (
                                <>
                                  {icon}
                                  {message}
                                  {t.type !== 'loading' && (
                                    <IconButton
                                      onClick={() => toast.dismiss(t.id)}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </ToastBar>
                          )}
                        </Toaster>
                        {/* <SettingsButton /> */}
                        <AuthConsumer>
                          {(auth) =>
                            !auth.isInitialized ? (
                              <SplashScreen />
                            ) : (
                              getLayout(<Component {...pageProps} />)
                            )
                          }
                        </AuthConsumer>
                      </RTL>
                    </ThemeProvider>
                  )}
                </SettingsConsumer>
              </SettingsProvider>
            </AuthProvider>
          </IndexeddbContext.Provider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
};

export default App;
