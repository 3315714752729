import { createSlice } from '@reduxjs/toolkit';
import { objectApi } from 'src/apis/object-api';
import { s3Api } from 'src/apis/s3-api';
import {
  showErrorAlert,
  showSuccessAlert,
} from 'src/slices/common-settings-slice';
import {
  prepareDataset,
  prepareECUAddress,
  prepareHardwareRevision,
  prepareItem,
  prepareItemAndItemRevision,
  prepareItemRevision,
  prepareJenkinsJob,
  prepareSoftware,
  prepareSoftwareAddress,
  prepareSoftwareListType,
} from 'src/utils/convert-object';
import { showTalkToServer } from 'src/utils/toast-message';
import {
  Dataset,
  ECUAddress,
  HardwareRevision,
  Item,
  ItemRevision,
  JenkinsJob,
  Software,
  SoftwareAddress,
} from '../models/object-models';

const initialState: {
  objects: Item[];
} = {
  objects: [],
};

const slice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    setObjects(state, action) {
      const existIds = state.objects.map((o) => o.id);
      const newIds = action?.payload?.map((o) => o.id);
      const existObjects = state.objects.filter((o: Item) =>
        newIds.includes(o.id),
      );
      const newObjects = action?.payload?.filter(
        (o: Item) => !existIds.includes(o.id),
      );

      state.objects = [...existObjects, ...newObjects];
    },
    setECUItemDetails(state, action) {
      const indexToChange = state.objects.findIndex(
        (o) => o.id === action.payload.id,
      );
      const changedObject = {
        ...state.objects[indexToChange],
        details: action.payload,
      };
    },
    createItemAndItemRevision(state, action) {
      state.objects = [...state.objects, action.payload];
    },
    deleteItem(state, action) {
      state.objects = [...state.objects.filter((o) => o.id !== action.payload)];
    },
    updateItem(state, action) {
      const indexToChange = state.objects.findIndex(
        (o) => o.id === action.payload.id,
      );
      const changedObject: Item = {
        ...state.objects[indexToChange],
        ...action.payload.newObjectParts,
      };
      state.objects.splice(indexToChange, 1, changedObject);
    },
    createItemRevision(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id == action.payload.objectId,
      );
      const objectToAdd = state.objects.find(
        (o) => o.id == action.payload.objectId,
      );

      const newRevision = {
        ...action.payload.newRevision,
      };

      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...objectToAdd,
          revisions: [...objectToAdd.revisions, newRevision],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    deleteItemRevision(state, action) {
      const objectRevision = state.objects.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects = [
        ...state.objects.slice(0, objectRevision),
        {
          ...state.objects[objectRevision],
          revisions: [
            ...state.objects[objectRevision].revisions.filter(
              (r) => r.id != action.payload.revisionId,
            ),
          ],
        },
        ...state.objects.slice(objectRevision + 1),
      ];
    },
    updateItemRevision(state, action) {
      const indexToChange = state.objects.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects.splice(indexToChange, 1, {
        ...state.objects[indexToChange],
        revisions: [
          ...state.objects[indexToChange].revisions.filter(
            (r) => r.id != action.payload.revisionId,
          ),
          {
            ...action.payload.newRevisionParts,
          },
        ],
      });
    },
    addSoftwareAddress(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );

      const objectToAdd = {
        ...action.payload.objectToAdd,
      };

      // for a new ECU item, there may be no this attribute, so add a protection here
      if (!ecuItem.softwareAddresses) {
        ecuItem.softwareAddresses = [];
      }

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          softwareAddresses: [
            ...(ecuItem.softwareAddresses || []),
            objectToAdd,
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteSoftwareAddress(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...state.objects[objectIndex],
          softwareAddresses: [
            ...state.objects[objectIndex].softwareAddresses.filter(
              (r) => r.id != action.payload.objectId,
            ),
          ],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    updateSoftwareAddress(state, action) {
      const ecuItem = state.objects.find(
        (o) => o.id === action.payload.ecuItemId,
      );
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      let objectToUpdate = ecuItem.softwareAddresses.find(
        (o) => o.id === action.payload.objectId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;
      const objectIndex = ecuItem.softwareAddresses.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          softwareAddresses: [
            ...ecuItem.softwareAddresses.slice(0, objectIndex),
            objectToUpdate,
            ...ecuItem.softwareAddresses.slice(objectIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    addHardwareRevision(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );

      const objectToAdd = {
        ...action.payload.objectToAdd,
      };

      // for a new ECU item, there may be no this attribute, so add a protection here
      if (!ecuItem.hardwareRevisions) {
        ecuItem.hardwareRevisions = [];
      }

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          hardwareRevisions: [
            ...(ecuItem.hardwareRevisions || []),
            objectToAdd,
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteHardwareRevision(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...state.objects[objectIndex],
          hardwareRevisions: [
            ...state.objects[objectIndex].hardwareRevisions.filter(
              (r) => r.id != action.payload.objectId,
            ),
          ],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    updateHardwareRevision(state, action) {
      const ecuItem = state.objects.find(
        (o) => o.id === action.payload.ecuItemId,
      );
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      let objectToUpdate = ecuItem.hardwareRevisions.find(
        (o) => o.id === action.payload.objectId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;
      const objectIndex = ecuItem.hardwareRevisions.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          hardwareRevisions: [
            ...ecuItem.hardwareRevisions.slice(0, objectIndex),
            objectToUpdate,
            ...ecuItem.hardwareRevisions.slice(objectIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    addSoftware(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );

      const objectToAdd = {
        ...action.payload.objectToAdd,
      };

      // for a new ECU item, there may be no this attribute, so add a protection here
      if (!ecuItem.softwares) {
        ecuItem.softwares = [];
      }

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          softwares: [...(ecuItem.softwares || []), objectToAdd],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteSoftware(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...state.objects[objectIndex],
          softwares: [
            ...state.objects[objectIndex].softwares.filter(
              (r) => r.id != action.payload.objectId,
            ),
          ],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    updateSoftware(state, action) {
      const ecuItem = state.objects.find(
        (o) => o.id === action.payload.ecuItemId,
      );
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      let objectToUpdate = ecuItem.softwares.find(
        (o) => o.id === action.payload.objectId,
      );

      const softwareObject = action.payload.object;
      if (softwareObject) {
        delete softwareObject.id;
        for (const key of Object.keys(softwareObject)) {
          objectToUpdate[key] = softwareObject[key];
        }
      }

      const objectIndex = ecuItem.softwares.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          softwares: [
            ...ecuItem.softwares.slice(0, objectIndex),
            objectToUpdate,
            ...ecuItem.softwares.slice(objectIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    addJenkinsJob(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );

      const objectToAdd = {
        ...action.payload.objectToAdd,
      };

      // for a new ECU item, there may be no this attribute, so add a protection here
      if (!ecuItem.jenkinsJobs) {
        ecuItem.jenkinsJobs = [];
      }

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          jenkinsJobs: [...(ecuItem.jenkinsJobs || []), objectToAdd],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteJenkinsJob(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...state.objects[objectIndex],
          jenkinsJobs: [
            ...state.objects[objectIndex].jenkinsJobs.filter(
              (r) => r.id != action.payload.objectId,
            ),
          ],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    updateJenkinsJob(state, action) {
      const ecuItem = state.objects.find(
        (o) => o.id === action.payload.ecuItemId,
      );
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      let objectToUpdate = ecuItem.jenkinsJobs.find(
        (o) => o.id === action.payload.objectId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;
      const objectIndex = ecuItem.jenkinsJobs.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          jenkinsJobs: [
            ...ecuItem.jenkinsJobs.slice(0, objectIndex),
            objectToUpdate,
            ...ecuItem.jenkinsJobs.slice(objectIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    addSoftwareListType(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          softwareListTypes: [
            // even if it's null, it will add nothing
            // but processed normally
            ...(ecuItem.softwareListTypes || []),
            {
              ...action.payload.objectToAdd,
            },
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteSoftwareListType(state, action) {
      const objectIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );

      state.objects = [
        ...state.objects.slice(0, objectIndex),
        {
          ...state.objects[objectIndex],
          softwareListTypes: [
            ...state.objects[objectIndex].softwareListTypes.filter(
              (r) => r.id != action.payload.objectId,
            ),
          ],
        },
        ...state.objects.slice(objectIndex + 1),
      ];
    },
    updateSoftwareListType(state, action) {
      const ecuItem = state.objects.find(
        (o) => o.id === action.payload.ecuItemId,
      );
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id === action.payload.ecuItemId,
      );
      const objectToUpdate = ecuItem.softwareListTypes.find(
        (o) => o.id === action.payload.objectId,
      );
      const objectIndex = ecuItem.softwareListTypes.findIndex(
        (o) => o.id === action.payload.objectId,
      );

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...ecuItem,
          softwareListTypes: [
            ...ecuItem.softwareListTypes.slice(0, objectIndex),
            {
              ...objectToUpdate,
              [action.payload.attrName]: action.payload.attrValue,
            },
            ...ecuItem.softwareListTypes.slice(objectIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    updateSoftwareListTypes(state, action) {
      action.payload.softwareListTypes.forEach((softwareListType) => {
        const ecuItem = state.objects.find(
          (o) => o.id === action.payload.ecuItemId,
        );
        const ecuItemIndex = state.objects.findIndex(
          (o) => o.id === action.payload.ecuItemId,
        );
        const objectToUpdate = ecuItem.softwareListTypes.find(
          (o) => o.id === softwareListType.id,
        );
        const objectIndex = ecuItem.softwareListTypes.findIndex(
          (o) => o.id === softwareListType.id,
        );

        state.objects = [
          ...state.objects.slice(0, ecuItemIndex),
          {
            ...ecuItem,
            softwareListTypes: [
              ...ecuItem.softwareListTypes.slice(0, objectIndex),
              {
                ...objectToUpdate,
                ['name']: softwareListType.name,
                ['path']: softwareListType.path,
              },
              ...ecuItem.softwareListTypes.slice(objectIndex + 1),
            ],
          },
          ...state.objects.slice(ecuItemIndex + 1),
        ];
      });
    },
    addECUAddress(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItemRevIndex = ecuItem.revisions.findIndex(
        (o) => o.id == action.payload.ecuItemRevId,
      );
      const ecuItemRev = ecuItem.revisions.find(
        (o) => o.id == action.payload.ecuItemRevId,
      );

      const objectToAdd = {
        ...action.payload.objectToAdd,
      };

      // for a new ECU item Rev, there may be no this attribute, so add a protection here
      if (!ecuItemRev.ecuAddresses) {
        ecuItemRev.ecuAddresses = [];
      }

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          revisions: [
            ...ecuItem.revisions.slice(0, ecuItemRevIndex),
            {
              ...state.objects[ecuItemIndex].revisions[ecuItemRevIndex],
              ecuAddresses: [...(ecuItemRev.ecuAddresses || []), objectToAdd],
            },
            ...ecuItem.revisions.slice(ecuItemRevIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    deleteECUAddress(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItemRevIndex = ecuItem.revisions.findIndex(
        (o) => o.id == action.payload.ecuItemRevId,
      );
      const ecuItemRev = ecuItem.revisions.find(
        (o) => o.id == action.payload.ecuItemRevId,
      );

      //const objectIndex = state.objects.findIndex(o => o.id === action.payload.ecuItemId);

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          revisions: [
            ...ecuItem.revisions.slice(0, ecuItemRevIndex),
            {
              ...state.objects[ecuItemIndex].revisions[ecuItemRevIndex],
              ecuAddresses: [
                ...state.objects[ecuItemIndex].revisions[
                  ecuItemRevIndex
                ].ecuAddresses.filter((r) => r.id != action.payload.objectId),
              ],
            },
            ...ecuItem.revisions.slice(ecuItemRevIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
    updateECUAddress(state, action) {
      const ecuItemIndex = state.objects.findIndex(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItem = state.objects.find(
        (o) => o.id == action.payload.ecuItemId,
      );
      const ecuItemRevIndex = ecuItem.revisions.findIndex(
        (o) => o.id == action.payload.ecuItemRevId,
      );
      const ecuItemRev = ecuItem.revisions.find(
        (o) => o.id == action.payload.ecuItemRevId,
      );
      const objectIndex = ecuItemRev.ecuAddresses.findIndex(
        (o) => o.id === action.payload.objectId,
      );
      const objectToUpdate = ecuItemRev.ecuAddresses.find(
        (o) => o.id === action.payload.objectId,
      );
      objectToUpdate[action.payload.attrName] = action.payload.attrValue;

      state.objects = [
        ...state.objects.slice(0, ecuItemIndex),
        {
          ...state.objects[ecuItemIndex],
          revisions: [
            ...ecuItem.revisions.slice(0, ecuItemRevIndex),
            {
              ...state.objects[ecuItemIndex].revisions[ecuItemRevIndex],
              ecuAddresses: [
                ...state.objects[ecuItemIndex].revisions[
                  ecuItemRevIndex
                ].ecuAddresses.slice(0, objectIndex),
                objectToUpdate,
                ...state.objects[ecuItemIndex].revisions[
                  ecuItemRevIndex
                ].ecuAddresses.slice(objectIndex + 1),
              ],
            },
            ...ecuItem.revisions.slice(ecuItemRevIndex + 1),
          ],
        },
        ...state.objects.slice(ecuItemIndex + 1),
      ];
    },
  },
});

export const { reducer } = slice;

export const listItemsThunk =
  (onDone?: () => void) => async (dispatch: Function) => {
    const data = await objectApi.listItems();
    dispatch(slice.actions.setObjects(data.data.objects));
    onDone?.();
  };

export const getSoftwareThunk =
  (ecuItemId: string, softwareId: string) => async (dispatch: Function) => {
    try {
      let result = await objectApi.getObject(ecuItemId);
      if (result.status === 200) {
        const ecuItem = result.data;
        const software = ecuItem.softwares?.find((sw) => sw.id === softwareId);
        dispatch(
          slice.actions.updateSoftware({
            ecuItemId,
            objectId: softwareId,
            object: software,
          }),
        );
      }
    } catch (error) {
      console.error(
        'Error occurred during getting software object.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during getting software object, please report the issue to administrator.`,
        ),
      );
    }
  };

export const createItemAndItemRevisionThunk =
  (newObject: Item, newRevision: ItemRevision) =>
  async (dispatch: Function) => {
    const { item, itemRevision } = prepareItemAndItemRevision(
      newObject.level,
      newObject,
      newRevision,
    );

    // const itemRevData = await objectApi.createObject(itemRevision);
    // if (itemRevData.status === 200) {
    //   item["revisions"] = [itemRevData.data?.id];
    // } else {
    //   throw new Error(itemRevData.data?.message);
    // }

    const itemData = await objectApi.createObject(item);

    if (
      itemData.status === 200
      // && itemRevData.status === 200
    ) {
      ///
      // const itemId = itemData.data.id;
      // dispatch(
      //   slice.actions.createItemAndItemRevision({
      //     ...newObject,
      //     id: itemId,
      //     revisions: [
      //       {
      //         ...newRevision,
      //         id: createObjectId(),
      //         name: `${newObject.name}${NAME_REV_SEPARATOR}${newRevision.revision}`,
      //         level: newObject.level,
      //         itemId: itemId
      //       }
      //     ]
      //   })
      // );
    } else {
      throw new Error(itemData.data?.message);
    }
  };

export const deleteItemThunk = (item: Item) => async (dispatch: Function) => {
  const revIds = item.revisions?.map((r) => r.id);
  const idsToDelete = [...revIds, item.id];
  const failDeleteIds = [];
  for (const id of idsToDelete) {
    const result = await objectApi.deleteObject(id);
    if (result.status !== 200) {
      failDeleteIds.push(id);
    }
  }
  dispatch(slice.actions.deleteItem(item.id));
};

export const updateItemThunk =
  (id: string, newObjectParts: Item) => async (dispatch: Function) => {
    const itemRevs = newObjectParts.revisions;
    const item = prepareItem(newObjectParts.level, newObjectParts);
    //Save revision Ids to DB
    item['revisions'] = itemRevs.map((it) => it.id);
    try {
      await objectApi.updateObject(id, item);
      //After saved to DB, switch back to use revision object in memory
      item['revisions'] = itemRevs;
      dispatch(slice.actions.updateItem({ id, newObjectParts: item }));
    } catch (error) {
      console.error(
        'Error occurred during updating item.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during updating item, please report the issue to administrator.`,
        ),
      );
    }
  };

export const createItemRevisionThunk =
  (objectId: string, newRevision: ItemRevision) =>
  async (dispatch: Function) => {
    newRevision.itemId = objectId;
    const itemRev = prepareItemRevision(newRevision);
    const itemRevData = await objectApi.createObject(itemRev);
    if (itemRevData.status === 200) {
      await objectApi.addItemRevision(objectId, itemRevData.data?.id);
    } else {
      throw new Error(itemRevData.data?.message);
    }

    dispatch(
      slice.actions.createItemRevision({ objectId, newRevision: itemRev }),
    );
  };

export const deleteItemRevisionThunk =
  (objectId: string, revisionId: string) => async (dispatch: Function) => {
    await objectApi.removeItemRevision(objectId, revisionId);
    await objectApi.deleteItemRevision(revisionId);

    dispatch(slice.actions.deleteItemRevision({ objectId, revisionId }));
  };

export const updateItemRevisionThunk =
  (objectId: string, revisionId: string, newRevisionParts: ItemRevision) =>
  async (dispatch: Function) => {
    newRevisionParts.itemId = objectId;
    const itemRev = prepareItemRevision(newRevisionParts);
    try {
      await objectApi.updateObject(revisionId, itemRev);
      dispatch(
        slice.actions.updateItemRevision({
          objectId,
          revisionId,
          newRevisionParts: itemRev,
        }),
      );
    } catch (error) {
      console.error(
        'Error occurred during updating item revision.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during updating item revision, please report the issue to administrator.`,
        ),
      );
    }
  };

export const createSoftwareAddressThunk =
  (
    ecuItemId: string,
    objectToBeAdded: SoftwareAddress,
    existingRows: SoftwareAddress[],
  ) =>
  async (dispatch: Function) => {
    showTalkToServer();
    const objectToAdd = prepareSoftwareAddress(
      ecuItemId,
      objectToBeAdded,
      existingRows,
    );
    let request = {
      create: {
        softwareAddresses: [objectToAdd],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.addSoftwareAddress({ ecuItemId, objectToAdd }));
  };

export const deleteSoftwareAddressThunk =
  (ecuItemId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        softwareAddresses: [{ id: objectId }],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.deleteSoftwareAddress({ ecuItemId, objectId }));
  };

export const updateSoftwareAddressThunk =
  (ecuItemId: string, objectId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        softwareAddresses: [{ id: objectId }],
      },
    };
    request.update.softwareAddresses[0][attrName] = attrValue;

    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(
      slice.actions.updateSoftwareAddress({
        ecuItemId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

export const createHardwareRevisionThunk =
  (
    ecuItemId: string,
    objectToBeAdded: HardwareRevision,
    existingRows: HardwareRevision[],
  ) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const objectToAdd = prepareHardwareRevision(
      ecuItemId,
      objectToBeAdded,
      existingRows,
    );
    let request = {
      create: {
        hardwareRevisions: [objectToAdd],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.addHardwareRevision({ ecuItemId, objectToAdd }));
  };

export const deleteHardwareRevisionThunk =
  (ecuItemId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        hardwareRevisions: [{ id: objectId }],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.deleteHardwareRevision({ ecuItemId, objectId }));
  };

export const updateHardwareRevisionThunk =
  (ecuItemId: string, objectId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        hardwareRevisions: [{ id: objectId }],
      },
    };
    request.update.hardwareRevisions[0][attrName] = attrValue;

    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(
      slice.actions.updateHardwareRevision({
        ecuItemId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

export const createSoftwareThunk =
  (
    ecuItemId: string,
    objectToBeAdded: Software,
    existingRows: Software[],
    source: string,
  ) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const objectToAdd = prepareSoftware(
      ecuItemId,
      objectToBeAdded,
      existingRows,
      source,
    );
    let request = {
      create: {
        softwares: [objectToAdd],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.addSoftware({ ecuItemId, objectToAdd }));
  };

export const downloadImageForSoftware =
  (ecuItemId: string, softwareId: string) => async (dispatch: Function) => {
    try {
      let result = await objectApi.getObject(ecuItemId);
      if (result.status === 200) {
        const ecuItem = result.data;
        const software = ecuItem.softwares?.find((sw) => sw.id === softwareId);
        let datasetId = software.image;
        if (datasetId) {
          const resp = await s3Api.getDownloadTicket(datasetId);
          if (resp.status === 200) {
            const link = document.createElement('a');
            link.href = resp.data.url;
            link.click();
            dispatch(
              showSuccessAlert(
                `Start download latest image for ${software.name}`,
              ),
            );
          } else {
            throw Error('Cannot retrieve a download link for the software');
          }
        } else {
          throw Error('Cannot retrieve a dataset ID for the software');
        }
      } else {
        throw Error('Cannot retrieve object data for the software');
      }
    } catch (error) {
      console.error(
        'Error occurred during getting software image.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during getting software image, ` +
            'please report the issue to administrator.',
        ),
      );
    }
  };

export const deleteSoftwareThunk =
  (ecuItemId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        softwares: [{ id: objectId }],
      },
    };
    try {
      await objectApi.updateECUItemDetails(ecuItemId, request);
      dispatch(slice.actions.deleteSoftware({ ecuItemId, objectId }));
    } catch (error) {
      console.error(
        'Error occurred during removing software.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during removing software, please report the issue to administrator.`,
        ),
      );
    }
  };

export const updateSoftwareThunk =
  (
    ecuItemId: string,
    objectId: string,
    attrName: string,
    attrValue: any,
    softwareObj: any,
  ) =>
  async (dispatch: Function) => {
    let softwareTobeUpdate = {
      id: objectId,
    };
    softwareTobeUpdate[attrName] = attrValue;

    if (attrName === 'softwareType' && attrValue === 'Code') {
      softwareTobeUpdate['version'] = 'Auto';
      softwareTobeUpdate['built'] = true;
    }
    if (attrName === 'softwareType' && attrValue === 'Virtual') {
      softwareTobeUpdate['version'] = '';
      softwareTobeUpdate['built'] = true;
    }
    if (
      attrName === 'softwareType' &&
      attrValue === 'Image' &&
      softwareObj?.version === 'Auto' &&
      softwareObj?.built === true
    ) {
      softwareTobeUpdate['version'] = '';
      softwareTobeUpdate['built'] = false;
    }
    let request = {
      update: {
        softwares: [softwareTobeUpdate],
      },
    };

    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(
      slice.actions.updateSoftware({
        ecuItemId,
        objectId,
        object: softwareTobeUpdate,
      }),
    );
  };

export const createJenkinsJobThunk =
  (
    ecuItemId: string,
    objectToBeAdded?: JenkinsJob,
    existingRows?: JenkinsJob[],
  ) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const objectToAdd = prepareJenkinsJob(
      ecuItemId,
      objectToBeAdded,
      existingRows,
    );
    let request = {
      create: {
        jenkinsJobs: [objectToAdd],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.addJenkinsJob({ ecuItemId, objectToAdd }));
  };

export const deleteJenkinsJobThunk =
  (ecuItemId: string, objectId: string) => async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        jenkinsJobs: [{ id: objectId }],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.deleteJenkinsJob({ ecuItemId, objectId }));
  };

export const updateJenkinsJobThunk =
  (ecuItemId: string, objectId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        jenkinsJobs: [{ id: objectId }],
      },
    };
    request.update.jenkinsJobs[0][attrName] = attrValue;

    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(
      slice.actions.updateJenkinsJob({
        ecuItemId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

export const createSoftwareListTypeThunk =
  (ecuItemId: string, parentPath: [], onApiDone?: () => void) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const objectToAdd = prepareSoftwareListType(ecuItemId, parentPath);
    const request = {
      create: {
        softwareListTypes: [objectToAdd],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.addSoftwareListType({ ecuItemId, objectToAdd }));
    onApiDone?.();
  };

export const deleteSoftwareListTypeThunk =
  (ecuItemId: string, objectId: string, onApiDone?: () => void) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const request = {
      delete: {
        softwareListTypes: [{ id: objectId }],
      },
    };
    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(slice.actions.deleteSoftwareListType({ ecuItemId, objectId }));
    onApiDone?.();
  };

export const updateSoftwareListTypeThunk =
  (ecuItemId: string, objectId: string, attrName: string, attrValue: any) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        softwareListTypes: [{ id: objectId }],
      },
    };
    request.update.softwareListTypes[0][attrName] = attrValue;

    await objectApi.updateECUItemDetails(ecuItemId, request);
    dispatch(
      slice.actions.updateSoftwareListType({
        ecuItemId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

export const updateSoftwareListTypesThunk =
  (ecuItemId: string, softwareListTypes: []) => async (dispatch: Function) => {
    let request = {
      update: {
        softwareListTypes: softwareListTypes,
      },
    };

    await objectApi.updateECUItemDetails(ecuItemId, request);

    dispatch(
      slice.actions.updateSoftwareListTypes({ ecuItemId, softwareListTypes }),
    );
  };

export const createECUAddressThunk =
  (
    ecuItemId: string,
    ecuItemRevId: string,
    objectToBeAdded: ECUAddress,
    existingRows: ECUAddress[],
  ) =>
  async (dispatch: Function) => {
    showTalkToServer();

    const objectToAdd = prepareECUAddress(
      ecuItemRevId,
      objectToBeAdded,
      existingRows,
    );
    let request = {
      create: {
        ecuAddresses: [objectToAdd],
      },
    };
    await objectApi.updateECUAddresses(ecuItemRevId, request);
    dispatch(
      slice.actions.addECUAddress({ ecuItemId, ecuItemRevId, objectToAdd }),
    );
  };

export const deleteECUAddressThunk =
  (ecuItemId: string, ecuItemRevId: string, objectId: string) =>
  async (dispatch: Function) => {
    showTalkToServer();

    let request = {
      delete: {
        ecuAddresses: [{ id: objectId }],
      },
    };
    await objectApi.updateECUAddresses(ecuItemRevId, request);
    dispatch(
      slice.actions.deleteECUAddress({ ecuItemId, ecuItemRevId, objectId }),
    );
  };

export const updateECUAddressThunk =
  (
    ecuItemId: string,
    ecuItemRevId: string,
    objectId: string,
    attrName: string,
    attrValue: any,
  ) =>
  async (dispatch: Function) => {
    let request = {
      update: {
        ecuAddresses: [{ id: objectId }],
      },
    };
    request.update.ecuAddresses[0][attrName] = attrValue;

    await objectApi.updateECUAddresses(ecuItemRevId, request);
    dispatch(
      slice.actions.updateECUAddress({
        ecuItemId,
        ecuItemRevId,
        objectId,
        attrName,
        attrValue,
      }),
    );
  };

export const updateSoftwareDatasetThunk =
  (
    ecuItemId: string,
    softwareId: string,
    fileName: string,
    uploadImageData: any,
    propToChange = 'image', // image or notes so far
    isSupplier?: boolean,
  ) =>
  async (dispatch: Function) => {
    try {
      let result = await objectApi.getObject(ecuItemId);
      if (result.status === 200) {
        const ecuItem = result.data;
        const software = ecuItem.softwares?.find((sw) => sw.id === softwareId);
        const datasetField =
          propToChange === 'image' ? 'image' : 'releaseNotes';
        let datasetId =
          propToChange === 'image' ? software.image : software.releaseNotes;
        let softwareToBeUpdate: any =
          propToChange === 'image'
            ? {
                filePath: fileName,
              }
            : {
                releaseNotesPath: fileName,
              };

        const versionFileNamePart = fileName
          .split('-')
          .filter((f) => f.startsWith('FABE'));
        if (
          propToChange === 'image' &&
          isSupplier &&
          versionFileNamePart &&
          versionFileNamePart.length > 0
        ) {
          softwareToBeUpdate['version'] = versionFileNamePart[0];
        }

        if (datasetId) {
          // store the old image dataset to outdatedImages list
          if (propToChange === 'image') {
            softwareToBeUpdate['outdatedImages'] =
              software.outdatedImages && Array.isArray(software.outdatedImages)
                ? [...software.outdatedImages, datasetId]
                : [datasetId];
          }

          const newDataset = prepareDataset(
            uploadImageData.Bucket,
            uploadImageData.Key,
            fileName,
          );
          result = await objectApi.createObject(newDataset);
          const dataset = result.data as Dataset;

          softwareToBeUpdate = {
            ...softwareToBeUpdate,
            id: softwareId,
            [datasetField]: dataset.id,
          };
          let request = {
            update: {
              softwares: [softwareToBeUpdate],
            },
          };
          datasetId = dataset.id;
          await objectApi.updateECUItemDetails(ecuItemId, request);
        } else {
          const newDataset = prepareDataset(
            uploadImageData.Bucket,
            uploadImageData.Key,
            fileName,
          );
          result = await objectApi.createObject(newDataset);
          const dataset = result.data as Dataset;
          softwareToBeUpdate = {
            ...softwareToBeUpdate,
            id: softwareId,
            [datasetField]: dataset.id,
          };
          let request = {
            update: {
              softwares: [softwareToBeUpdate],
            },
          };
          datasetId = dataset.id;
          await objectApi.updateECUItemDetails(ecuItemId, request);
        }
        dispatch(
          slice.actions.updateSoftware({
            ecuItemId,
            objectId: softwareId,
            object: softwareToBeUpdate,
          }),
        );
      }
    } catch (error) {
      console.error(
        'Error occurred during updating software dataset.',
        error?.data?.message || '',
      );
      dispatch(
        showErrorAlert(
          `${
            error?.data?.message || ''
          } Error occurred during updating software dataset, please report the issue to administrator.`,
        ),
      );
    }
  };

export const generateId = (type: string) => async (dispatch: Function) => {
  let request = {
    type: type,
  };
  const data = await objectApi.generateId(request);
  return data.data.id;
};
