import { createSlice } from '@reduxjs/toolkit';
import { adminApi, FilterItem } from 'src/apis/admin-api';

const initialState: {
  // TODO: add models
  users: any[];
  groups: any[];
  roles: any[];
  accounts: any[];
} = {
  users: [],
  groups: [],
  roles: [],
  accounts: [],
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateUserData(state, action) {
      const userId = action.payload.userId;
      const userData = action.payload.userData;
      const userIndex = state.users.findIndex((u) => u.id === userId);

      state.users = [
        ...state.users.slice(0, userIndex),
        {
          ...state.users[userIndex],
          ...userData,
        },
        ...state.users.slice(userIndex + 1),
      ];
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setGroups(state, action) {
      state.groups = action.payload;
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    updateRole(state, action) {
      const roleId = action.payload.roleId;
      const roleData = action.payload.roleData;
      const roleIndex = state.roles.findIndex((u) => u.id === roleId);

      state.roles = [
        ...state.roles.slice(0, roleIndex),
        {
          ...state.roles[roleIndex],
          ...roleData,
        },
        ...state.roles.slice(roleIndex + 1),
      ];
    },
    updateGroup(state, action) {
      const groupId = action.payload.groupId;
      const groupData = action.payload.groupData;
      const groupIndex = state.groups.findIndex((u) => u.id === groupId);

      state.groups = [
        ...state.groups.slice(0, groupIndex),
        {
          ...state.groups[groupIndex],
          ...groupData,
        },
        ...state.groups.slice(groupIndex + 1),
      ];
    },
    deleteRole(state, action) {
      state.roles = [...state.roles.filter((r) => r.id !== action.payload)];
    },
    deleteGroup(state, action) {
      state.groups = [...state.groups.filter((g) => g.id !== action.payload)];
    },
    deleteUser(state, action) {
      state.users = [...state.users.filter((g) => g.id !== action.payload)];
    },
    addRole(state, action) {
      state.roles = [...state.roles, action.payload];
    },
    addGroup(state, action) {
      state.groups = [...state.groups, action.payload];
    },
  },
});

export const { reducer } = slice;

export const getUsers =
  (filters?: FilterItem[], onError?: () => void) =>
    async (dispatch: Function) => {
      const response = await adminApi.getListUser(filters);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setUsers(response?.data?.objects));
    };

export const getRoles =
  (filters?: FilterItem[], onError?: () => void) =>
    async (dispatch: Function) => {
      const response = await adminApi.getListRoles(filters);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setRoles(response?.data?.objects));
    };

export const getAccounts =
  (filters?: FilterItem[], onError?: () => void) =>
    async (dispatch: Function) => {
      const response = await adminApi.getListAccounts(filters);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setAccounts(response?.data?.objects));
    };

export const deleteUser =
  (id: string, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.deleteUser(id);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.deleteUser(id));
  };

export const addRole =
  (role: any, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.addRole(role);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.addRole(response.data));
  };

export const deleteRole =
  (id: string, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.deleteRole(id);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.deleteRole(id));
  };

export const updateRole =
  (id: string, role: any, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.updateRole(id, role);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.updateRole({ roleId: id, roleData: role }));
  };

export const getGroups =
  (filters?: FilterItem[], onError?: () => void) =>
    async (dispatch: Function) => {
      const response = await adminApi.getListGroup(filters);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.setGroups(response?.data?.objects));
    };

export const addGroup =
  (group: any, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.addGroup(group);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.addGroup(response.data));
  };

export const deleteGroup =
  (id: string, onError?: Function) => async (dispatch: Function) => {
    const response = await adminApi.deleteGroup(id);
    if (response?.status != 200) {
      onError?.();
    }
    dispatch(slice.actions.deleteGroup(id));
  };

export const updateGroup =
  (id: string, group: any, onError?: Function) =>
    async (dispatch: Function) => {
      const response = await adminApi.updateGroup(id, group);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.updateGroup({ groupId: id, groupData: group }));
    };

export const updateGroupRoles =
  (id: string, groupRoles: any, onError?: Function) =>
    async (dispatch: Function) => {
      const response = await adminApi.updateGroupRoles(id, groupRoles);
      if (response?.status != 200) {
        onError?.();
      }
      dispatch(slice.actions.updateGroup({ groupId: id, groupData: groupRoles }));
    };

export const updateUser =
  (userId: string, userData: any, onError?: () => void) =>
    async (dispatch: Function) => {
      // Update on UI first
      dispatch(slice.actions.updateUserData({ userId, userData }));

      const response = await adminApi.updateUser(userId, userData);
      if (response?.status != 200) {
        onError?.();
      }
    };

export const updateUserGroups =
  (userId: string, userData: any, onError?: () => void) =>
    async (dispatch: Function) => {
      // Update on UI first
      dispatch(slice.actions.updateUserData({ userId, userData }));

      const response = await adminApi.updateUserGroup(userId, userData);
      if (response?.status != 200) {
        onError?.();
      }
    };
