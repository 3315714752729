import { createSlice } from '@reduxjs/toolkit';

import { Account } from 'src/tools/tracker/models/user';

export type WorkspaceName =
  | 'admin'
  | 'tracker'
  | 'vendor'
  | 'flash'
  | 'gateway'
  | 'vehicle'
  | 'predictor'
  | 'dem';

const initialState: {
  workspace: WorkspaceName;
  changedFromPopover: boolean;
  alertOpen: boolean;
  alertMsg: string;
  alertSeverity: 'error' | 'success' | 'warning' | 'info';
  account: Account;
} = {
  workspace: null,
  changedFromPopover: false,
  alertOpen: false,
  alertMsg: '',
  alertSeverity: 'success',
  account: null,
};

const slice = createSlice({
  name: 'common-settings',
  initialState,
  reducers: {
    setWorkspace(state, action) {
      state.workspace = action.payload;
      state.changedFromPopover = true;
    },
    restoreWorkspace(state, action) {
      state.workspace = action.payload;
    },
    resetJustChanged(state) {
      state.changedFromPopover = false;
    },
    showAlert(state, action) {
      state.alertOpen = true;
      state.alertMsg = action.payload.msg;
      state.alertSeverity = action.payload.severity || 'success';
    },
    hideAlert(state) {
      state.alertOpen = false;
      state.alertMsg = '';
    },
    setAccount(state, action) {
      state.account = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setAccount = (account: Account) => (dispatch: Function) => {
  dispatch(slice.actions.setAccount(account));
};

export const restoreWorkspace =
  (newWorkspace: string) => (dispatch: Function) => {
    dispatch(slice.actions.restoreWorkspace(newWorkspace));
  };

export const setWorkspace =
  (newWorkspace: WorkspaceName) => (dispatch: Function) => {
    dispatch(slice.actions.setWorkspace(newWorkspace));
  };

export const applyChanges = () => (dispatch: Function) =>
  dispatch(slice.actions.resetJustChanged());

export const hideAlert = () => (dispatch: Function) => {
  dispatch(slice.actions.hideAlert());
};

export const showErrorAlert = (msg: string) => (dispatch: Function) => {
  dispatch(slice.actions.showAlert({ msg: msg, severity: 'error' }));
};

export const showSuccessAlert = (msg: string) => (dispatch: Function) => {
  dispatch(slice.actions.showAlert({ msg: msg, severity: 'success' }));
};

export const showInfoAlert = (msg: string) => (dispatch: Function) => {
  dispatch(slice.actions.showAlert({ msg: msg, severity: 'info' }));
};

export const showWarningAlert = (msg: string) => (dispatch: Function) => {
  dispatch(slice.actions.showAlert({ msg: msg, severity: 'warning' }));
};
