import { createSlice } from '@reduxjs/toolkit';
import { objectApi } from 'src/apis/object-api';
import { packageApi } from 'src/apis/package-api';
import { settingApi } from 'src/apis/setting-api';
import { vehicleApi } from 'src/apis/vehicle-api';
import { showErrorAlert } from 'src/slices/common-settings-slice';
import { prepareVehicleActivity, prepareVehicleInstance } from 'src/utils/convert-vehicle';
import { showTalkToServer } from 'src/utils/toast-message';
import { Setting } from '../models/setting-model';
import { VehicleActivity, VehicleInstance } from '../models/vehicle-models';

const initialState: {
  vehicles: VehicleInstance[],
  vehicleECUList: {},
  vehicleLatestPackages: {},
  vehicleNfcMasterKey: Setting,
} = {
  vehicles: [],
  vehicleECUList: {},
  vehicleLatestPackages: {},
  vehicleNfcMasterKey: undefined,
};

const slice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicles(state, action) {
      // const existIds = state.vehicles.map((o) => o.id);
      // const newIds = action?.payload?.map((o) => o.id);
      // const existVehicleInstances = state.vehicles.filter((o: VehicleInstance) =>
      //   newIds.includes(o.id),
      // );
      // const newObjects = action?.payload?.filter(
      //   (o: VehicleInstance) => !existIds.includes(o.id),
      // );

      // state.vehicles = [...existVehicleInstances, ...newObjects];
      state.vehicles = action.payload;
    },
    updateVehicle(state, action) {
      const indexToChange = state.vehicles.findIndex(
        (vi) => vi.id === action.payload.instanceId,
      );
      const existActivities = state.vehicles[indexToChange]?.activities || action.payload.instance?.activities || [];
      const changedObject: VehicleInstance = {
        ...state.vehicles[indexToChange],
        ...action.payload.instance,
        activities: existActivities,
      };
      state.vehicles.splice(indexToChange, 1, changedObject);
    },
    removeVehicle(state, action) {
      const indexToChange = state.vehicles.findIndex((vi) => vi.id === action.payload);
      state.vehicles.splice(indexToChange, 1);
    },
    setActivities(state, action) {
      const indexToChange = state.vehicles.findIndex(
        (o) => o.id === action.payload.id,
      );
      const changedObject: VehicleInstance = {
        ...state.vehicles[indexToChange],
        activities: [...action.payload.activities],
      };
      state.vehicles.splice(indexToChange, 1, changedObject);
    },
    updateActivityObject(state, action) {
      const indexToChange = state.vehicles.findIndex(
        (vi) => vi.id === action.payload.instanceId,
      );
      const existActivities = state.vehicles[indexToChange]?.activities;
      if (existActivities?.every((a) => typeof a === 'object')) {
        const indexOfActivity = existActivities.findIndex((va) => va.id === action.payload.activity.id);
        existActivities.splice(indexOfActivity, 1, action.payload.activity);
      }

      const changedObject: VehicleInstance = {
        ...state.vehicles[indexToChange],
        activities: existActivities,
      };
      state.vehicles.splice(indexToChange, 1, changedObject);
    },
    removeActivityObjectFromVehicle(state, action) {
      const indexToChange = state.vehicles.findIndex(
        (vi) => vi.id === action.payload.instanceId,
      );
      const existActivities = state.vehicles[indexToChange]?.activities;
      if (existActivities?.every((a) => typeof a === 'object')) {
        const indexOfActivity = existActivities.findIndex((va) => va.id === action.payload.activityId);
        existActivities.splice(indexOfActivity, 1);
      }

      const changedObject: VehicleInstance = {
        ...state.vehicles[indexToChange],
        activities: existActivities,
      };
      state.vehicles.splice(indexToChange, 1, changedObject);
    },
    addOrUpdateECUList(state, action) {
      state.vehicleECUList[action.payload.vehicleId] = {
        vehicleTemplateName: action.payload.vehicleTemplateName,
        softwareActivities: action.payload.vehicleECUList
      };
    },
    addOrUpdateVehicleLatestPackages(state, action) {
      state.vehicleLatestPackages[action.payload.vehicleTemplateName] = action.payload.latestPackages;
    },
    setVehicleNfcMasterKey(state, action) {
      state.vehicleNfcMasterKey = action.payload;
    }
  }
});

export const { reducer } = slice;

export const listVehicleInstancesThunk =
  (onDone?: () => void, onError?: () => void) => async (dispatch: Function) => {
    const filter = [{ name: 'type', value: 'VehicleInstance' }];
    const result = await objectApi.listObjects(filter);
    if (result.status !== 200 /*|| result.data.total === 0*/) {
      onError?.();
      return;
    }
    dispatch(slice.actions.setVehicles(result.data.objects));
    onDone?.();
  };

export const setVehicleActivitiesThunk =
  (vehicleInstanceId: string, onDone?: () => void, onError?: () => void) => async (dispatch: Function) => {
    const filter = [
      { name: 'type', value: 'VehicleActivity' },
      { name: 'vehicleInstance', value: vehicleInstanceId },
    ];
    // get vehicle activities by vehicle instance id
    try {
      // const resultFromGalaxy = await objectApi.listObjects(filter);
      const resultFromFOTA = await vehicleApi.getECUList(vehicleInstanceId);

      let activities = [];
      // if (resultFromGalaxy.status === 200 && resultFromGalaxy.data.total > 0) {
      //   activities = [...resultFromGalaxy.data.objects];
      // }
      if (resultFromFOTA.status === 200 && resultFromFOTA.data.total > 0) {
        activities = [...activities, ...resultFromFOTA.data.objects];
      }

      if (resultFromFOTA.status === 200 && resultFromFOTA.data.total > 0) {
        dispatch(slice.actions.setActivities({ id: vehicleInstanceId, activities: activities }));
      }
      onDone?.();
    } catch (error) {
      console.error(
        'Error occurred during getting vehicle activities.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during getting vehicle activities, please report the issue to administrator.`,
        ),
      );
      onError?.();
      return;
    }
  };

export const createVehicleInstanceThunk =
  (newObject: VehicleInstance) => async (dispatch: Function) => {
    const vehicleInstance = prepareVehicleInstance(newObject);
    try {
      await objectApi.createObject(vehicleInstance);
    } catch (error) {
      console.error(
        'Error occurred during adding vehicle instance.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during adding vehicle instance, please report the issue to administrator.`,
        ),
      );
    }
  };

export const createVehicleActivityThunk =
  (vehicleInstance: VehicleInstance, newActivity: VehicleActivity) => async (dispatch: Function) => {
    newActivity.vehicleInstance = vehicleInstance.id;
    const toBeCreatedActivity = prepareVehicleActivity(newActivity);
    try {
      await vehicleApi.createActivity(vehicleInstance.id, toBeCreatedActivity);
    } catch (error) {
      console.error(
        'Error occurred during adding vehicle activity.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during adding vehicle activity, please report the issue to administrator.`,
        ),
      );
    }
  };


export const deleteVehicleInstanceThunk =
  (vehicleInstance: VehicleInstance) => async (dispatch: Function) => {
    showTalkToServer();
    try {
      const result = await vehicleApi.deleteInstance(vehicleInstance.id);
      if (result.status === 200) {
        dispatch(slice.actions.removeVehicle(vehicleInstance.id));
      }
    } catch (error) {
      console.error(
        'Error occurred during deleting vehicle instance.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during deleting vehicle instance, please report the issue to administrator.`,
        ),
      );
    }
  };


export const deleteVehicleActivityThunk =
  (activity: VehicleActivity) => async (dispatch: Function) => {
    showTalkToServer();
    try {
      const result = await vehicleApi.deleteActivity(activity.id);
      if (result.status === 200) {
        dispatch(slice.actions.removeActivityObjectFromVehicle(
          {
            instanceId: activity.vehicleInstance,
            activityId: activity.id,
          }
        ));
      }
    } catch (error) {
      console.error('Error occurred during deleting vehicle activity.', error);
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during deleting vehicle activity, please report the issue to administrator.`,
        ),
      );
    }
  };

export const updateVehicleInstanceThunk =
  (instanceId: string, toBeUpdateInstance: VehicleInstance) => async (dispatch: Function) => {
    showTalkToServer();
    const instance = prepareVehicleInstance(toBeUpdateInstance);
    try {
      await vehicleApi.updateInstance(instanceId, instance);
      dispatch(
        slice.actions.updateVehicle({
          instanceId,
          instance: toBeUpdateInstance,
        }),
      );
    } catch (error) {
      console.error(
        'Error occurred during updating vehicle instance.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during updating vehicle instance, please report the issue to administrator.`,
        ),
      );
    }
  };

export const updateVehicleActivityThunk =
  (toBeUpdateActivity: VehicleActivity) => async (dispatch: Function) => {
    showTalkToServer();
    const activity = prepareVehicleActivity(toBeUpdateActivity);
    try {
      const result = await objectApi.updateObject(activity.id, activity);
      if (result.status === 200 && result.data.vehicleInstance) {
        dispatch(slice.actions.updateActivityObject(
          {
            instanceId: result.data.vehicleInstance,
            activity: result.data,
          }
        ));
      }
    } catch (error) {
      console.error(
        'Error occurred during updating vehicle activity.',
        error,
      );
      dispatch(
        showErrorAlert(
          `${error?.response?.data?.message || error?.data?.message || ''
          } Error occurred during updating vehicle activity, please report the issue to administrator.`,
        ),
      );
    }
  };

export const retrieveAllVehicleECUListThunk =
  (vehicles: VehicleInstance[], onDone?: () => void, onError?: () => void) => async (dispatch: Function) => {
    try {
      if (vehicles.length) {
        Promise.all(vehicles.map(async v => {
          return { "vehicleId": v.id, "vehicleTemplateName": v.vehicleTemplateName, "result": await vehicleApi.getECUList(v.id) };
        })).then((responses) => {
          responses.map((r) => {
            if (r.result.status === 200) {
              dispatch(slice.actions.addOrUpdateECUList({
                vehicleId: r.vehicleId,
                vehicleTemplateName: r.vehicleTemplateName,
                vehicleECUList: r.result.data?.objects
              }));
            }
          });
        });
      }
      onDone?.();
    } catch (error) {
      console.error(
        'Error occurred during retrieve all vehicle ecu list.',
        error,
      );
      onError?.();
    }
  };

export const retrieveVehicleLatestPackagesThunk =
  (vehicleTemplateName: string, onDone?: () => void, onError?: () => void) => async (dispatch: Function) => {
    try {
      const result = await packageApi.getLatestPackages(vehicleTemplateName);

      if (result.status === 200) {
        dispatch(slice.actions.addOrUpdateVehicleLatestPackages({
          vehicleTemplateName: vehicleTemplateName,
          latestPackages: result.data,
        }));
      }
      onDone?.();
    } catch (error) {
      console.error(
        'Error occurred during getting latest packages.',
        error,
      );
      onError?.();
    }
  };

export const getVehicleNfcMasterKeyThunk = () => async (dispatch: Function) => {
  showTalkToServer();
  try {
    const nfcMasterKey = [{ key: "vehicle_nfcMasterKey" }];
    const result = await settingApi.getSettings(nfcMasterKey);
    if (result.status === 200) {
      dispatch(slice.actions.setVehicleNfcMasterKey(result.data?.[0]));
    }
  } catch (error) {
    console.error(
      'Error occurred during getting vehicle NFC master key.',
      error,
    );
    dispatch(
      showErrorAlert(
        `${error?.response?.data?.message || error?.data?.message || ''
        } Error occurred during getting vehicle NFC master key, please report the issue to administrator.`,
      ),
    );
  }
};

export const updateVehicleNfcMasterKeyThunk = (nfcMasterKey: string) => async (dispatch: Function) => {
  showTalkToServer();
  try {
    const toBeUpdateKey = [{ key: "vehicle_nfcMasterKey", value: nfcMasterKey, type: "UserSetting" }];
    let result = await settingApi.updateSettings(toBeUpdateKey);
    if (result.status === 200) {
      result = await settingApi.getSettings([{ key: "vehicle_nfcMasterKey" }]);
      if (result.status === 200) {
        dispatch(slice.actions.setVehicleNfcMasterKey(result.data?.[0]));
      }
    }
  } catch (error) {
    console.error(
      'Error occurred during updating vehicle NFC master key.',
      error,
    );
    dispatch(
      showErrorAlert(
        `${error?.response?.data?.message || error?.data?.message || ''
        } Error occurred during updating vehicle NFC master key, please report the issue to administrator.`,
      ),
    );
  }
};
