import axios from './base';

const userPrefix = '/v1/user';

export type FilterItem = {
  name: string;
  value: string;
};

class AdminApi {
  async getListUser(filters?: FilterItem[]) {
    return axios.post(`${userPrefix}/list/User`, {
      filters: filters ? filters : [],
      // TODO: do we need it for web?
      // objectIds: [
      //   'Item_TEST',
      //   'U_2fd34f53-32e0-46e3-98be-46d537bff336'
      // ],
      // exclusiveStartKey: '',
      // limit: 100
    });
  }

  async getUserInfo(userId: string) {
    return axios.get(`${userPrefix}/get/User/${userId}`);
  }

  async updateUser(userId: string, userData: any) {
    return axios.post(`${userPrefix}/update/User/${userId}`, {
      ...userData,
    });
  }

  // Doesn't make sense to have any slice
  // for this one, so check response status
  // just here
  async sendInvite(
    userEmail: string,
    userGroupId: string,
    userRoleId: string,
    tenantAccount: string,
  ) {
    const response = await axios.put(`${userPrefix}/inviteUser/${userEmail}`, {
      defaultGroup: userGroupId,
      defaultRole: userRoleId,
      defaultAccount: tenantAccount,
    });
    return response?.status == 200;
  }

  async getListGroup(filters?: FilterItem[]) {
    return axios.post(`${userPrefix}/list/Group`, {
      filters: filters ? filters : [],
    });
  }

  async getListRoles(filters?: FilterItem[]) {
    return axios.post(`${userPrefix}/list/Role`, {
      filters: filters ? filters : [],
    });
  }

  async getListAccounts(filters?: FilterItem[]) {
    return axios.post(`${userPrefix}/list/Account`, {
      filters: filters ? filters : [],
    });
  }

  async deleteGroup(id: string) {
    return axios.delete(`${userPrefix}/delete/Group/${id}`);
  }

  async deleteRole(id: string) {
    return axios.delete(`${userPrefix}/delete/Role/${id}`);
  }

  async deleteUser(id: string) {
    return axios.delete(`${userPrefix}/delete/User/${id}`);
  }

  async addGroup(group: any) {
    return axios.post(`${userPrefix}/create/Group`, group);
  }

  async addRole(role: any) {
    return axios.post(`${userPrefix}/create/Role`, role);
  }

  async updateGroup(id: string, group: any) {
    return axios.post(`${userPrefix}/update/Group/${id}`, group);
  }

  async updateGroupRoles(id: string, group: any) {
    return axios.post(`${userPrefix}/setGroupRoles/${id}`, group);
  }

  async updateUserGroup(id: string, user: any) {
    return axios.post(`${userPrefix}/setUserRoleGroups/${id}`, user);
  }

  async updateRole(id: string, role: any) {
    return axios.post(`${userPrefix}/update/Role/${id}`, role);
  }
}

export const adminApi = new AdminApi();
