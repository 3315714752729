export const MAX_32_BIT_UNSIGNED_INT = 4294967295;

export const SCREEN_PRE_EXIT = 'Screen_PreExit';
export const SCREEN_POST_EXIT = 'Screen_PostExit';

export const SCREEN_PRE_ENTER = 'Screen_PreEnter';
export const SCREEN_POST_ENTER = 'Screen_PostEnter';

export const NAME_REV_SEPARATOR = '_';

export const FLASH_SELECTED_SUCCESS_ALERT =
  'A version comparison message has been sent, please wait for a result from the core module';

export const FLASH_SELECTED_ERROR_ALERT =
  'Updating progress for version comparison has been stopped after waiting for core module response for';

export const RELEASE_PACKAGE_REFRESH_STATUS_WAIT_SEC = 10;

// export const SOFTWARE_VERSION_REGEX = /^(\d{2})\.(\d{1,3})\.RC(\d{1,2})\.(\d{1,3})\.(\d{1,3})$/;
export const SOFTWARE_VERSION_REGEX =
  /^(\d{1,2})\.(\d{1,3})\.(\d{1,2})\.(\d{1,3})\.(\d{1,3})$/;

// ! The screen value MUST be unique
export const AVAILABLE_SCREENS = [
  // {
  //   label: 'Gateway',
  //   value: 'gateway',
  //   priority: 1,
  // },
  {
    label: 'Admin Workspace',
    value: 'admin',
    priority: 2,
  },
  {
    label: 'ECU Tracker',
    value: 'tracker',
    priority: 3,
  },
  {
    label: 'Vehicle Systems',
    value: 'vehicle',
    priority: 4,
  },
  {
    label: 'Flash Station',
    value: 'flash',
    priority: 5,
  },
  {
    label: 'Vendor Portal',
    value: 'vendor',
    priority: 6,
  },
  {
    label: 'ML Ops',
    value: 'mlops',
    priority: 7,
  },
  {
    label: 'Ask Galaxy',
    value: 'askgalaxy',
    priority: 8,
  },
  // {
  //   label: 'Predictive Maintenance',
  //   value: 'predictor',
  //   priority: 9,
  // }
];

export const AVAILABLE_ROLES = [
  {
    screen: 'tracker',
    roles: [
      {
        name: 'screen:tracker',
        purpose: 'Add and Configure ECU',
      },
      {
        name: 'screen:tracker',
        purpose: 'Create Release and OTA Package',
      },
    ],
  },
  {
    screen: 'flash',
    roles: [
      {
        name: 'screen:flash',
        purpose: 'Manage station configuration',
      },
      {
        name: 'screen:flash',
        purpose: 'Only do flashing and diagnostics tasks',
      },
    ],
  },
  {
    screen: 'vendor',
    roles: [
      {
        name: 'screen:vendor',
        purpose: 'Manage portal configuration',
      },
      {
        name: 'screen:vendor',
        purpose: 'Upload ECU Image',
      },
    ],
  },
  {
    screen: 'vehicle',
    roles: [
      {
        name: 'screen:vehicle',
        purpose: 'Manage vehicle activities',
      },
      {
        name: 'screen:vehicle',
        purpose: 'Only view activities',
      },
    ],
  },
  {
    screen: 'mlops',
    roles: [
      {
        name: 'screen:mlops',
        purpose: 'Train a ML model',
      },
    ],
  },
  {
    screen: 'askgalaxy',
    roles: [
      {
        name: 'screen:askgalaxy',
        purpose: 'Use AI features',
      },
    ],
  },
];
