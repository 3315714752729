import axios from './base';

const prefix = '/v1/security';

class VendorApi {
  async getListKeys(vendor?: string) {
    return axios.post(`${prefix}/listKeys`);
  }

  async getPublicKey(keyId: string) {
    return axios.get(`${prefix}/${keyId}/getPublicKey`);
  }
}

export const vendorApi = new VendorApi();
