import axios from './base';

const prefix = '/v1/release';

class PackageApi {
  async validateJiraTicket(ticket: string) {
    return axios.get(`${prefix}/validateJiraTicket/${ticket}`);
  }

  async listPackages(
    level: string,
    group: string,
    version: string,
    limit?: number,
    exclusiveStartKey?: string,
    releaseTag?: string,
  ) {
    return axios.post(`${prefix}/listPackages`, {
      level,
      group,
      version,
      exclusiveStartKey,
      limit,
      releaseTag,
    });
  }

  async getPackageDetails(id: string) {
    return axios.get(`${prefix}/${id}/getPackageDetails`);
  }

  async getPackageImages(id: string) {
    return axios.get(`${prefix}/${id}/getPackageImages`);
  }

  // Build release package for ECU or Library level
  async buildPackage(
    itemRevisionId: string,
    version: string,
    revisionRuleId: string,
    buildSource: string,
    variantRuleIds: string[],
    createdBy: string,
    validateJiraTickets,
  ) {
    return axios.post(`${prefix}/${itemRevisionId}/buildPackage`, {
      version,
      revisionRuleId,
      buildSource,
      variantRuleIds,
      createdBy,
      validateJiraTickets
    });
  }

  // Build release package for Vehicle or Domain level
  async buildGroupPackage(
    itemRevisionId: string,
    version: string,
    revisionRuleId: string,
    variantRuleIds: string[],
    createdBy: string,
  ) {
    return axios.post(`${prefix}/${itemRevisionId}/buildGroupPackage`, {
      version,
      revisionRuleId,
      variantRuleIds,
      createdBy,
    });
  }

  async createOTAPackageWithRules(
    packageId: string,
    manifestId: string,
    otaPackageInfo: any,
  ) {
    return axios.post(
      `${prefix}/${packageId}/buildOTAPackageWithRules/${manifestId}`,
      otaPackageInfo,
    );
  }

  async updatePackageVersions(id, request) {
    return axios
      .post(`${prefix}/${id}/updatePackageVersions`, request)
      .then((response) => response);
  }

  // reBuild release package for ECU or Library level
  async rebuildPackage(packageId: string, modifiedBy: string) {
    return axios.post(`${prefix}/${packageId}/rebuildPackage`, { modifiedBy });
  }

  async getPackageCount(
    releaseVersion: string,
    startDate: string,
    endDate: string,
  ) {
    return axios.get(
      `/v1/dashboard/getPackageSummary/${releaseVersion}/${startDate}/${endDate}`,
    );
  }

  async triggerPackageRCTest(id, manifestId) {
    return axios
      .post(`${prefix}/${id}/triggerPackageRCTest/${manifestId}`, {})
      .then((response) => response);
  }

  async updatePackage(packageId, object) {
    return axios
      .post(`${prefix}/${packageId}/updatePackage`, object)
      .then((response) => response);
  }

  async getLatestPackages(VehicleTemplateName: string,
  ) {
    return axios.get(
      `${prefix}/getLatestPackages/${VehicleTemplateName}`,
    );
  }

  async addReleaseTag(packageId, object) {
    return axios
      .post(`${prefix}/${packageId}/addReleaseTag`, object)
      .then((response) => response);
  }
}

export const packageApi = new PackageApi();
