export enum TestStationAction {
  No_Action,

  RepairStation_Diagnostic_Execution,
  RepairStation_Diagnostic_SecurityAccess,

  RepairStation_Read_DTCs,
  RepairStation_Clear_DTCs,
  RepairStation_Clear_Read_DTCs,

  RepairStation_TesterPresent,

  ADASCalibration_Trigger_Run_Calibration,
  ADASCalibration_Run_Calibration,
  ADASCalibration_Trigger_Run_Calibration_SVC,
  ADASCalibration_Run_Calibration_SVC,
  ADASCalibration_Trigger_Run_Calibration_SVC_AS,
  ADASCalibration_Run_Calibration_SVC_AS,

  BGWStation_BGW_Write_VIN,
  BGWStation_BGW_Read_VIN,

  EOLStation_SRS_Execute_Variant_Coding,
  EOLStation_Run_EOL_Checks,

  EDRStation_SRS_Execute_Read_SRSEDR,

  EDRStation_SVC_Trigger_Read_SVC_EDR,
  EDRStation_SVC_Execute_Read_SVC_EDR,

  SteeringColumnAssembly_SASCalibration,
  BrakesChassis_ASDM_EOL,
  RollsTest_ESP_EOL,
  RollsTest_TPMS_EOL,

  Enable_VDM_in_MPC,
  Disable_VDM_in_MPC,

  SeatAssembly_SeatCalibration,

  // ECUCheckAction
  ECUCheck_Trigger_Load_Versions,
  ECUCheck_Load_Versions,
  ECUCheck_Upload_Vehicle_Versions,

  // FlashAction
  Flash_Version_Comparision,
  Flash_Swith_Page,
}
