import { uniq } from 'lodash';
import axios from './base';

import { ObjectTypePrefixToRequestParam } from 'src/utils/utils';

const prefix = '/v1/object';

class ObjectApi {
  async listObjects(filters, objectIds, exclusiveStartKey, limit) {
    if (objectIds?.length > 50) {
      const chunkSize = 50;
      const uniqSortKeyValues = uniq(objectIds);
      let objectIdChunks = [];
      for (let i = 0; i < uniqSortKeyValues.length; i += chunkSize) {
        const chunk = uniqSortKeyValues.slice(i, i + chunkSize);
        objectIdChunks.push(chunk);
      }
      let objectResult = [];
      let status = 200;
      let total = 0;

      await Promise.allSettled(
        objectIdChunks.map(async (idChunk) => {
          return axios.post(`${prefix}/listObjects`, {
            filters: filters,
            objectIds: idChunk,
            exclusiveStartKey: exclusiveStartKey,
            limit: limit,
          });
        }),
      )
        .then((responses) => {
          responses
            .filter((r) => r.status === 'fulfilled' && r.value.status === 200)
            .forEach((res) => {
              objectResult = [...objectResult, ...res.value.data.objects];
              total += res.value.data.total;
            });
        })
        .catch((error) => {
          console.error(error);
        });
      return { status, data: { total, objects: objectResult } };
    }
    return axios.post(`${prefix}/listObjects`, {
      filters: filters,
      objectIds: objectIds,
      exclusiveStartKey: exclusiveStartKey,
      limit: limit,
    });
  }

  async listItems(exclusiveStartKey, limit) {
    return axios.post(`${prefix}/listItems`, {
      exclusiveStartKey: exclusiveStartKey,
      limit: limit,
    });
  }

  async listItemRevisions(level, group, version) {
    return axios.post(`${prefix}/listItemRevisions`, {
      level,
      group,
      version,
    });
  }

  async updateECUItemDetails(ecuItemId, request) {
    return axios
      .post(`${prefix}/${ecuItemId}/updateECUItemDetails`, request)
      .then((response) => response);
  }

  // async getECUAddresses(ecuItemRevId, request) {
  //   return axios.post(`${prefix}/${ecuItemRevId}/getECUAddresses`, request).then(response => response);
  // }

  async updateECUAddresses(ecuItemRevId, request) {
    return axios
      .post(`${prefix}/${ecuItemRevId}/updateECUAddresses`, request)
      .then((response) => response);
  }

  async generateId(request) {
    return axios
      .post(`${prefix}/generateId`, request)
      .then((response) => response);
  }

  async createObject(object) {
    return axios
      .post(`${prefix}/createObject`, object)
      .then((response) => response);
  }

  async deleteObject(id) {
    return axios
      .delete(`${prefix}/${id}/deleteObject`)
      .then((response) => response);
  }

  async addItemRevision(itemId, itemRevsionId) {
    return axios
      .put(`${prefix}/${itemId}/addItemRevision/${itemRevsionId}`)
      .then((response) => response);
  }

  async removeItemRevision(itemId, itemRevId) {
    return axios
      .put(`${prefix}/${itemId}/removeItemRevision/${itemRevId}`)
      .then((response) => response);
  }

  async updateObject(objectId, object) {
    return axios
      .post(`${prefix}/${objectId}/updateObject`, object)
      .then((response) => response);
  }

  async deleteItemRevision(itemRevId) {
    return axios
      .delete(`${prefix}/${itemRevId}/deleteItemRevision`)
      .then((response) => response);
  }

  async getObject(objectId) {
    return axios
      .get(`${prefix}/${objectId}/getObject`)
      .then((response) => response);
  }

  async getDesignSpec(objectId, ecuNodeId) {
    const response = await axios.get(
      `${prefix}/${objectId}/getDesignSpec/${ecuNodeId}`,
    );
    if (response?.status === 200) {
      return response.data;
    }
    return null;
  }

  async updateEEArchDetails(objectId, request, action = 'create') {
    const requestNodeType = ObjectTypePrefixToRequestParam[request.type];

    let data = {
      create: {
        [requestNodeType]: [{ ...request }],
      },
    };

    if (action === 'delete') {
      data = {
        delete: {
          [requestNodeType]: [{ id: request.id }],
        },
      };
    } else if (action === 'update') {
      data = {
        update: {
          [requestNodeType]: [{ ...request }],
        },
      };
    }

    return axios
      .post(`${prefix}/${objectId}/updateEEArchDetails`, data)
      .then((response) => response);
  }

  async updateMultipleEEArchDetails(
    objectId,
    requestArray,
    nodeType,
    action = 'create',
  ) {
    const requestNodeType = ObjectTypePrefixToRequestParam[nodeType];

    let data = {
      create: {
        [requestNodeType]: requestArray,
      },
    };

    if (action === 'delete') {
      data = {
        delete: {
          [requestNodeType]: requestArray,
        },
      };
    } else if (action === 'update') {
      data = {
        update: {
          [requestNodeType]: requestArray,
        },
      };
    }

    return axios
      .post(`${prefix}/${objectId}/updateEEArchDetails`, data)
      .then((response) => response);
  }

  async getDesignSpecTicket(objectId, ecuNodeName) {
    return axios
      .get(`${prefix}/${objectId}/getDesignSpecTicket/${ecuNodeName}`)
      .then((response) => response);
  }
}

export const objectApi = new ObjectApi();
