import { PSObject } from 'src/tools/tracker/models/base-models';

export enum VehicleLocationOption {
  HQ,
  LA,
  BJ,
  SV,
  None,
}

export enum ActivityCategoryOption {
  NFC,
  Package,
  Software,
  Sensor,
  // Workshop,
  // RoadUse,
  // Troubleshooting,
  // Development,
  // TestTrack
}

export interface VehicleInstance extends PSObject {
  owner: string;
  desc: string;
  vin: string;
  sequence: number;
  isDevice: boolean;
  active: boolean;
  activities: any[];
  location: string;
  vehicleTemplateName: string;
}

export interface VehicleActivity extends PSObject {
  desc: string;
  vehicleInstance: string | VehicleInstance;
  category: string;
  swRCPackage?: string;
  swOTAPackage?: string;
  swECUStatuses?: VehicleECUStatus[];
  nfcMasterKey?: string;
  needProgramBPillar?: boolean;
  readerProgramBPillar?: number;
  cardProgramBPillar?: number;
  newVINBPillar?: string;
  oldVINBPillar?: string;
  needFlashCardBPillar?: boolean;
  needFlashReaderBPillar?: boolean;
  needProgramCConsole?: boolean;
  readerProgramCConsole?: number;
  cardProgramCConsole?: number;
  newVINCConsole?: string;
  oldVINCConsole?: string;
  needFlashCardCConsole?: boolean;
  needFlashReaderCConsole?: boolean;
  swVersionFileBase64?: string;
  swVersionReleaseDate?: string;
  swVersionReleaseNote?: string;
  swVersionReleaseNoteUrl?: string;
  swVersionReleasePackageName?: string;
  swVersionReleaseVersion?: string;
  srECUSelfChecks?: object[];
}

export interface VehicleECUStatus extends PSObject {
  package: string;
  images: object[];
}
