import { isUndefined, omitBy } from 'lodash';

import axios from './base';

import { User } from 'src/tools/tracker/models/user';

const prefix = '/v1/notification';

class NotificationApi {
  async subscribe(
    notificationType: string,
    protocals: string[],
    email: string,
    phone?: string,
    enabled?: boolean,
    group?: string,
    level?: string,
  ) {
    return axios.post(
      `${prefix}/subscribe`,
      omitBy(
        {
          notificationType,
          protocals,
          email,
          phone,
          group,
          level,
          enabled,
        },
        isUndefined,
      ),
    );
  }

  async getSubscriptions(
    user: User,
    notificationType?: string,
    group?: string,
    level?: string,
  ) {
    return axios.post(
      `${prefix}/subscriptions/${user.id}/list`,
      omitBy(
        {
          notificationType: notificationType,
          group: group,
          level: level,
        },
        isUndefined,
      ),
    );
  }

  async getUnreadNotifications(user: User, limit?: number) {
    return axios.post(
      `${prefix}/list`,
      omitBy(
        {
          filters: [
            {
              name: 'userId',
              value: user?.id,
            },
            {
              name: 'read',
              value: false,
            },
          ],
          limit,
        },
        isUndefined,
      ),
    );
  }

  async getAllNotifications(user: User, limit?: number) {
    return axios.post(
      `${prefix}/list`,
      omitBy(
        {
          filters: [
            {
              name: 'userId',
              value: user?.id,
            },
          ],
          limit,
        },
        isUndefined,
      ),
    );
  }

  async markAllNotificationsRead(userId: string) {
    return axios.post(
      `${prefix}/markAllAsRead`,
      {
        'userId': userId
      },
    );
  }

  async markNotificationRead(user: User, notificationId: string, flag: boolean) {
    return axios.post(`${prefix}/${notificationId}/updateRead`, {
      read: flag,
      userId: user?.id,
    });
  }
}

export const notificationApi = new NotificationApi();
