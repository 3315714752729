import AWS from 'aws-sdk';
import { getEnv } from 'src/utils/utils';
import { s3Config } from '../config';

import axios from './base';

const prefix = '/v1/storage';

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-west-2:dd922b03-5385-439a-a3f8-3fa681c21283',
});

const POST_UPLOAD_DRIVE_TYPE = 'fromDrivePath';
const POST_UPLOAD_IOV_TYPE = 'fromIoVUrl';

const EXPIRATION_TIME = 60 * 60;
const MAX_UPLOAD_TRIES = 3;

const client = new AWS.S3({
  apiVersion: '2006-03-01',
  region: 'us-west-2',
});

const file2Buffer = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const readFile = () => {
      const buffer = reader.result;
      resolve(buffer);
    };
    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });
};

class S3Api {
  lastUploadParams: {
    Bucket: string;
    Key: string;
    UploadId: string;
  };
  justAborted: boolean;

  /**
   * Abort current multipart upload, do nothing if
   * upload is done already
   *
   * @param callback - call when abort is done
   */
  abortCurrentUpload(callback: (err, data) => void) {
    if (this.lastUploadParams) {
      client.abortMultipartUpload(this.lastUploadParams, callback);
      this.lastUploadParams = null;
      this.justAborted = true;
    }
  }

  singlePartUpload(
    imageName: string,
    imageFile: any,
    callback: (err: any, data: any) => void,
  ) {
    const env = getEnv();
    const vaultId = env.vaultId;
    const bucket = vaultId || s3Config.defaultImageBucker;
    const uploadParams = {
      Bucket: bucket,
      Key: imageName,
      Body: imageFile,
    };
    client.upload(uploadParams as any, callback);
  }

  _uploadPart(
    multipart: any,
    partParams: any,
    multipartMap: any,
    tryNum: number,
    callback: (err: any, data: any) => void,
    onPartDone: () => void,
  ) {
    if (this.justAborted) {
      return;
    }

    const tryNumUpload = tryNum || 1;
    const uploadAgain = () =>
      this._uploadPart(
        multipart,
        partParams,
        multipartMap,
        tryNumUpload + 1,
        callback,
        onPartDone,
      );
    client.uploadPart(partParams, function (multiErr, mData) {
      if (multiErr) {
        console.warn('multiErr, upload part error:', multiErr);
        if (tryNumUpload < MAX_UPLOAD_TRIES) {
          console.warn('Retrying upload of part: #', partParams.PartNumber);
          uploadAgain();
        } else {
          console.error('Failed uploading part: #', partParams.PartNumber);
          // Doesn't matter which part is failed, need to show error feedback right away
          callback(
            `Failed uploading part: #${partParams.PartNumber}, out of retries`,
            null,
          );
        }
        return;
      }
      multipartMap.Parts[this.request.params.PartNumber - 1] = {
        // NOTES: need to make sure that the ETag header
        // is exposed for the bucket
        ETag: mData.ETag,
        PartNumber: Number(this.request.params.PartNumber),
      };
      onPartDone();
    });
  }

  multiPartUpload(
    imageName: string,
    imageFile: any,
    callback: (err: any, data: any) => void,
    onPartCompleted?: (percentDone: number) => void,
  ) {
    const env = getEnv();
    const vaultId = env.vaultId;
    const bucket = vaultId || s3Config.defaultImageBucker;
    const baseParams = {
      Bucket: bucket,
      Key: imageName,
    };
    const multipartMap = {
      Parts: [],
    };

    file2Buffer(imageFile).then((buffer: Buffer) => {
      client.createMultipartUpload(baseParams, (mpErr, multipart) => {
        if (mpErr) {
          console.error('Error!', mpErr);
          return;
        }
        this.justAborted = false;

        let partNum = 0;
        const partSize = 1024 * 1024 * 5;

        const totalNumber = Math.ceil(buffer.byteLength / partSize);
        let numPartsLeft = totalNumber;

        const doneParams = {
          ...baseParams,
          MultipartUpload: multipartMap,
          UploadId: multipart.UploadId,
        };

        for (
          let rangeStart = 0;
          rangeStart < buffer.byteLength;
          rangeStart += partSize
        ) {
          partNum++;
          const end = Math.min(rangeStart + partSize, buffer.byteLength);
          const partParams = {
            ...baseParams,
            Body: buffer.slice(rangeStart, end),
            PartNumber: String(partNum),
            UploadId: multipart.UploadId,
          };
          this.lastUploadParams = {
            Bucket: baseParams.Bucket,
            Key: baseParams.Key,
            UploadId: multipart.UploadId,
          };
          this._uploadPart(
            multipart,
            partParams,
            multipartMap,
            0,
            callback,
            () => {
              numPartsLeft -= 1;
              onPartCompleted?.((1 - numPartsLeft / totalNumber) * 100);
              if (numPartsLeft == 0) {
                client.completeMultipartUpload(doneParams, (err, data) => {
                  if (err) {
                    console.error(
                      'An error occurred while completing the multipart upload',
                    );
                    console.error(err);
                    callback(err, null);
                  } else {
                    callback(null, data);
                  }
                  this.lastUploadParams = null;
                });
              }
            },
          );
        }
      });
    });
  }

  /**
   * Save a file to s3, either as a single upload
   * or with multiple parts and progress bar
   *
   * @param imageName - a name which the file will have on s3
   * @param imageFile - actual file
   * @param callback - final callback whil will ba called on upload done
   * @param onPartCompleted (optional) - a callback which will be called wheh
   *  an upload part is done. Should be used to track the upload progress.
   *  Ignored if it's single part upload
   * @param useMultiPartUpload (default: false) - using multiparts upload
   *  instead of single upload
   */
  save(
    imageName: string,
    imageFile: any,
    callback: (err: any, data: any) => void,
    onPartCompleted?: (percentDone: number) => void,
    useMultiPartUpload = false,
  ) {
    if (useMultiPartUpload) {
      this.multiPartUpload(imageName, imageFile, callback, onPartCompleted);
    } else {
      this.singlePartUpload(imageName, imageFile, callback);
    }
  }

  getPresignedURL(
    imageName: string,
    fileName: string,
    callback: (err: any, url: string) => void,
  ) {
    const env = getEnv();
    const vaultId = env.vaultId;
    const bucket = vaultId || s3Config.defaultImageBucker;
    const presignedURLParams = {
      Bucket: bucket,
      Key: imageName,
      Expires: EXPIRATION_TIME,
      ResponseContentDisposition: 'attachment; filename ="' + fileName + '"',
    };
    return client.getSignedUrl('getObject', presignedURLParams, callback);
  }

  getPresignedURLs(
    objectsWithKeyAndName: Array<{
      key: string;
      filename: string;
      ecuName: string;
    }>,
  ): Promise<Array<object>> {
    const promises = objectsWithKeyAndName.map((object) =>
      this.getPresignedURLPromise(object.key, object.filename, object.ecuName),
    );
    return Promise.all(promises);
  }

  getPresignedURLPromise(
    imageName: string,
    filename: string,
    ecuName: string,
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const env = getEnv();
      const vaultId = env.vaultId;
      const bucket = vaultId || s3Config.defaultImageBucker;
      const presignedURLParams = {
        Bucket: bucket,
        Key: imageName,
        Expires: EXPIRATION_TIME,
        ResponseContentDisposition: 'attachment; filename ="' + filename + '"',
      };

      client.getSignedUrl('getObject', presignedURLParams, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve({ url, filename, imageName, ecuName });
        }
      });
    });
  }

  async uploadFileInServer(
    key: string,
    isDrive: boolean,
    pathValue: string,
    ecuItem: any,
    software: any,
  ) {
    return axios.post(`${prefix}/${getEnv().vaultId}/upload`, {
      key,
      uploadType: isDrive ? POST_UPLOAD_DRIVE_TYPE : POST_UPLOAD_IOV_TYPE,
      uploadPath: pathValue,
      ecuItemId: ecuItem.id,
      ecuName: ecuItem.name,
      group: ecuItem.group,
      level: ecuItem.level,
      softwareId: software.id,
      softwareName: software.name,
    });
  }

  async getDownloadTicket(vaultDatestId: string) {
    return axios.get(
      `${prefix}/${getEnv().vaultId}/getDownloadTicket/${vaultDatestId}`,
    );
  }

  async listInstallers() {
    const env = getEnv().env;
    return new Promise((resolve, reject) => {
      client.listObjects(
        {
          Bucket: 'et-web-app-package',
          Prefix: '',
        },
        function (err, data) {
          if (err) {
            return alert(
              'There was an error listing installers: ' + err.message,
            );
          } else {
            let installers = data.Contents.map(function (object) {
              let key = object.Key;

              if (key !== `/`) {
                let name = decodeURIComponent(key);
                let url =
                  'https://et-web-app-package.s3.us-west-2.amazonaws.com/' +
                  name;
                return { name, url };
              }
            });
            resolve(installers.filter((i) => i != null));
          }
        },
      );
    });
  }
}

export const s3Api = new S3Api();
