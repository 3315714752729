import { createSlice } from '@reduxjs/toolkit';
import {
  TestStationAction
} from '../components/utils/execution-action-enum';

import { flashApi } from 'src/apis/flash-api';
import {
  showErrorAlert,
  showInfoAlert,
} from 'src/slices/common-settings-slice';
import { MAX_32_BIT_UNSIGNED_INT } from 'src/utils/constants';

const initialState: {
  // The number should be in proper order
  // [
  //  <integer>, // representing FL
  //  <integer>, // representing FR
  //  <integer>, // representing RR
  //  <integer>, // representing RL
  // ]
  rollsTestSensorValues: number[];
  rollsTestSensorValuesError: boolean;
  rollsTestSensorPressure: number;
  // ^ TODO: move rollsTestSensorPressure to its own slice
  enableSecurityAccess: boolean;
  selectedTreeNodes: any[];
  selectedFilters: string;
  coreModuleResponses: object;
  coreModuleGetProgress: string;
  coreModuleCachedData: any;
  executeAction: TestStationAction | TestStationAction | TestStationAction;
  onGetProgress: boolean;
  selectedDataSource: string;
  vehiclePackageName: string;
  vehicleOTAPackageDownloadResult: any;
} = {
  rollsTestSensorValues: [],
  rollsTestSensorValuesError: false,
  rollsTestSensorPressure: null,
  enableSecurityAccess: true,
  selectedTreeNodes: [],
  selectedFilters: undefined,
  coreModuleResponses: {},
  coreModuleGetProgress: '',
  coreModuleCachedData: {},
  executeAction: TestStationAction.No_Action,
  onGetProgress: false,
  selectedDataSource: 'VehiclePackage',
  vehiclePackageName: undefined,
  vehicleOTAPackageDownloadResult: undefined,
};

const slice = createSlice({
  name: 'flash',
  initialState,
  reducers: {
    setTreeNodesSelection(state, action) {
      state.selectedTreeNodes = action.payload;
    },
    setFiltersSelection(state, action) {
      state.selectedFilters = action.payload;
    },
    updateCoreModuleResponse(state, action) {
      state.coreModuleResponses = {
        ...state.coreModuleResponses,
        [action.payload.messageType]: action.payload.data,
      };
    },
    updateCoreModuleGetProgress(state, action) {
      if (action.payload === '{}') {
        const times = isNaN(parseInt(state.coreModuleGetProgress))
          ? 0
          : parseInt(state.coreModuleGetProgress);
        state.coreModuleGetProgress = (times + 1).toString();
      } else {
        state.coreModuleGetProgress = action.payload;
      }
    },
    updateCoreModuleCacheData(state, action) {
      state.coreModuleCachedData = {
        ...state.coreModuleCachedData,
        [action.payload.messageType]: action.payload.data,
      };
    },
    triggerExecuteAction(state, action) {
      state.executeAction = action.payload;
    },
    updateOnGetProgress(state, action) {
      state.onGetProgress = action.payload;
    },
    updateRollsTestSensorValues(state, action) {
      if (action.payload.isError) {
        state.rollsTestSensorValues = [];
        state.rollsTestSensorValuesError = true;
        return;
      }

      if (action.payload.pressureValue) {
        state.rollsTestSensorPressure = action.payload.pressureValue;
        return;
      }

      state.rollsTestSensorValuesError = false;
      state.rollsTestSensorValues = action.payload.rollsTestSensorValues;
    },
    setSecurityAccess(state, action) {
      state.enableSecurityAccess = action.payload;
    },
    setSelectedDataSource(state, action) {
      state.selectedDataSource = action.payload;
      if (action.payload === 'Latest') {
        state.vehiclePackageName = undefined;
      }
    },
    setVehiclePackageName(state, action) {
      state.vehiclePackageName = action.payload;
    },
    setVehicleOTAPackageDownloadResult(state, action) {
      state.vehicleOTAPackageDownloadResult = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setSecurityAccess =
  (isEnabled: boolean) => async (dispatch: Function) => {
    dispatch(slice.actions.setSecurityAccess(isEnabled));
  };

export const setFiltersSelection =
  (filters: string) => async (dispatch: Function) => {
    dispatch(slice.actions.setFiltersSelection(filters));
  };

export const runRoolsTestTPMSEOL =
  () => async (dispatch: Function, getState: Function) => {
    const sensorValues = getState().flash.rollsTestSensorValues;
    const pressureValue = getState().flash.rollsTestSensorPressure;
    if (
      sensorValues &&
      pressureValue &&
      sensorValues.length === 4 &&
      sensorValues.every(
        (value: number) => value >= 0 && value <= MAX_32_BIT_UNSIGNED_INT,
      )
    ) {
      flashApi.runTpmsEOL([...sensorValues, pressureValue]);
      dispatch(
        slice.actions.triggerExecuteAction(
          TestStationAction.RollsTest_TPMS_EOL,
        ),
      );
      dispatch(
        showInfoAlert(
          `Sensor values has been sent: ${sensorValues.join(', ')}`,
        ),
      );
    } else {
      dispatch(
        showErrorAlert(
          'Sensor values are not valid. Please, check input values.',
        ),
      );
    }
  };

export const updateRollsTestSensorValues =
  (rollsTestSensorValues: number[], isError?: boolean) =>
    async (dispatch: Function) => {
      dispatch(
        slice.actions.updateRollsTestSensorValues({
          rollsTestSensorValues,
          isError,
        }),
      );
    };

export const updateRollsTestPressureValues =
  (pressureValue: number) => async (dispatch: Function) => {
    dispatch(slice.actions.updateRollsTestSensorValues({ pressureValue }));
  };

export const setTreeNodesSelection =
  (data: any[]) => async (dispatch: Function) => {
    dispatch(slice.actions.setTreeNodesSelection(data));
  };

export const updateCoreModuleResponse =
  (messageType: string, data: string) => async (dispatch: Function) => {
    dispatch(
      slice.actions.updateCoreModuleResponse({
        messageType: messageType,
        data: data,
      }),
    );
  };

export const updateCoreModuleGetProgress =
  (data: string) => async (dispatch: Function) => {
    dispatch(slice.actions.updateCoreModuleGetProgress(data));
  };

export const updateCoreModuleCacheData =
  (messageType: string, data: object) => async (dispatch: Function) => {
    dispatch(
      slice.actions.updateCoreModuleCacheData({
        messageType: messageType,
        data: data,
      }),
    );
  };

export const triggerExecuteAction =
  (action: TestStationAction | TestStationAction | TestStationAction) =>
    async (dispatch: Function) => {
      dispatch(slice.actions.triggerExecuteAction(action));
    };

export const updateOnGetProgress =
  (status: boolean) => async (dispatch: Function) => {
    dispatch(slice.actions.updateOnGetProgress(status));
  };

export const setSelectedDataSource =
  (dataSource: string) => async (dispatch: Function) => {
    dispatch(slice.actions.setSelectedDataSource(dataSource));
  };

export const setVehiclePackageName =
  (vehiclePackageName: string) => async (dispatch: Function) => {
    dispatch(slice.actions.setVehiclePackageName(vehiclePackageName));
  };

export const setVehicleOTAPackageDownloadResult =
  (data: any) => async (dispatch: Function) => {
    dispatch(slice.actions.setVehicleOTAPackageDownloadResult(data));
  };
