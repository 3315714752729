import axios from './base';

const prefix = '/v1/user';

class UserApi {
  async login({ username, password }) {
    // TODO: remove promise and just use async/await approach
    const userResponse = await axios
      .post(`${prefix}/et/login`, {
        username: username.trim().toLowerCase(),
        password: password,
      })
      .then((response) => {
        console.log('Login Response!');
        return response.data;
      })
      .catch((error) => {
        console.log('Login Error: ' + JSON.stringify(error));
        if (error.response) {
          console.log(error.response.data?.message);
          return error.response.data;
        }
      });

    return new Promise((resolve, reject) => {
      try {
        if (!userResponse || !userResponse.user || !userResponse.accessToken) {
          reject(
            new Error(
              userResponse?.message ||
                'Please check your username and password',
            ),
          );
          return;
        }

        const user = userResponse.user;
        user.name = user?.firstName?.concat(' ', user?.lastName) || 'Guest';

        const accessToken = userResponse.accessToken;

        resolve({ accessToken, user });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async registerApi({
    email,
    firstName,
    lastName,
    phone,
    company,
    password,
    inviteToken,
  }) {
    const registerResponse = await axios.post(
      `${prefix}/registerUser/${email}`,
      {
        firstName,
        lastName,
        password,
        phone,
        company,
        inviteToken,
      },
    );

    if (registerResponse.status !== 200) {
      return null;
    }

    return registerResponse.data;
  }

  async requestAccess(userId, requestObject) {
    return axios
      .post(`${prefix}/requestAccess/${userId}`, requestObject)
      .then((response) => response);
  }
}

export const userApi = new UserApi();
